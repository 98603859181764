import React from 'react';
import { version } from '../../../package.json';
import { getBuildInfoOfServer } from '../../utils/RestAPIs.js';

class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      buildInfoCore: {},
      buildInfoApp: {}
    }
  }

  componentWillMount() {
    getBuildInfoOfServer().then(buildInfo => {
      this.setState({
        buildInfoCore: buildInfo.core,
        buildInfoApp: buildInfo.app
      });
    }).catch(err => {
      console.error("Failed to get build info of server,", err);
    });
  }

  render() {
    return (
      <footer className="footer text-center">
        <div>UI Version: {version}</div>
        <div>
          <span>{`Server Version: `}</span>
          <span>{`Core: ${this.state.buildInfoCore.version}-${this.state.buildInfoCore.githash} | `}</span>
          <span>{`App: ${this.state.buildInfoApp.version}-${this.state.buildInfoApp.githash}`}</span>
        </div>
      </footer>
    );
  }
}
export default Footer;
