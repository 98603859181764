/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
Form, FormGroup, Label, Input } from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

class UpdateRMSDetailsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rmsDetails: _.cloneDeep(this.props.rmsDetails),
      rmsDetailsUpdated: false
    }

    this.updateRMSDetails = this.updateRMSDetails.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onFieldChange(filedName, event) {
    const updatedRMSDetails = {...this.state.rmsDetails};
    updatedRMSDetails[filedName] = _.toInteger(event.target.value); // Note: In future we may need to convert to float/double for some fields
    this.setState({
      rmsDetails: updatedRMSDetails
    });
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.rmsDetailsUpdated);
    }
  }

  updateRMSDetails(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      statusMessage: null,
      error: null
    });

    const data = { 
      username: this.state.rmsDetails.username,
      broker: this.state.rmsDetails.broker,
      rms: {}
    };
    if (this.state.rmsDetails.maxBuyOrdersPerDay !== this.props.rmsDetails.maxBuyOrdersPerDay) {
      data.rms.maxBuyOrdersPerDay = this.state.rmsDetails.maxBuyOrdersPerDay;
    }
    if (this.state.rmsDetails.maxSellOrdersPerDay !== this.props.rmsDetails.maxSellOrdersPerDay) {
      data.rms.maxSellOrdersPerDay = this.state.rmsDetails.maxSellOrdersPerDay;
    }
    if (this.state.rmsDetails.maxFnOBuyQtyPerSymbolPerDay !== this.props.rmsDetails.maxFnOBuyQtyPerSymbolPerDay) {
      data.rms.maxFnOBuyQtyPerSymbolPerDay = this.state.rmsDetails.maxFnOBuyQtyPerSymbolPerDay;
    }
    if (this.state.rmsDetails.maxFnOSellQtyPerSymbolPerDay !== this.props.rmsDetails.maxFnOSellQtyPerSymbolPerDay) {
      data.rms.maxFnOSellQtyPerSymbolPerDay = this.state.rmsDetails.maxFnOSellQtyPerSymbolPerDay;
    }

    HttpRequest.put({
        url: config.serverHost + "/apis/user/rms",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('Update RMS details status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          this.setState({
            statusMessage: "RMS params updated successfully.",
            updateInProgress: false,
            rmsDetailsUpdated: true
          });
        } else if (resp.statusCode === 404) {
          this.setState({
            error: 'Update RMS details end point not found',
            updateInProgress: false
          });
        } else {
          console.error('Update RMS details error => ', respBody);
          this.setState({
            error: JSON.parse(respBody).error || JSON.parse(respBody).result,
            updateInProgress: false
          });
        }
      });
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen}>
        <ModalHeader>Update RMS Details for {this.props.rmsDetails.username + ' | ' + this.props.rmsDetails.broker}</ModalHeader>
        
        <ModalBody>
          <Form>
            {/*<FormGroup>
              <Label for="maxBuyOrdersPerDay">Max Buy orders per day</Label>
              <Input type="number" name="maxBuyOrdersPerDay" id="maxBuyOrdersPerDay" 
                value={this.state.rmsDetails.maxBuyOrdersPerDay}
                onChange={(e) => this.onFieldChange('maxBuyOrdersPerDay', e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="maxSellOrdersPerDay">Max Sell orders per day</Label>
              <Input type="number" name="maxSellOrdersPerDay" id="maxSellOrdersPerDay" 
                value={this.state.rmsDetails.maxSellOrdersPerDay} 
                onChange={(e) => this.onFieldChange('maxSellOrdersPerDay', e)}
              />
            </FormGroup>*/}
            <FormGroup>
              <Label for="maxFnOBuyQtyPerSymbolPerDay">Max FnO Buy qty per symbol per day</Label>
              <Input type="number" name="maxFnOBuyQtyPerSymbolPerDay" id="maxFnOBuyQtyPerSymbolPerDay" 
                value={this.state.rmsDetails.maxFnOBuyQtyPerSymbolPerDay}
                onChange={(e) => this.onFieldChange('maxFnOBuyQtyPerSymbolPerDay', e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="maxFnOSellQtyPerSymbolPerDay">Max FnO Sell qty per symbol per day</Label>
              <Input type="number" name="maxFnOSellQtyPerSymbolPerDay" id="maxFnOSellQtyPerSymbolPerDay" 
                value={this.state.rmsDetails.maxFnOSellQtyPerSymbolPerDay}
                onChange={(e) => this.onFieldChange('maxFnOSellQtyPerSymbolPerDay', e)}
              />
            </FormGroup>
          </Form>

          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Updating RMS details in progress. Please wait...</div>}

        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress}
            onClick={this.updateRMSDetails}>
              Update RMS Details
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UpdateRMSDetailsModal;
