/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Alert,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Badge,
  Button
} from 'reactstrap';
import DatePicker from "react-datepicker";

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

import UpdateBillDetailsModal from './UpdateBillDetailsModal.js';
import UpdateBillDetailsAsPaidModal from './UpdateBillDetailsAsPaidModal.js';

class UsersBills extends React.Component {

  constructor(props) {
    super(props);
    
    this.rangeStartDate = new Date('2021-04-01');
    this.rangeEndDate = new Date();

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    
    let startYear = currentYear;
    let startMonth = 4;
    if (currentMonth <= 5) {
      startYear = startYear - 1;
    };
    const startDate = new Date(startYear, startMonth - 1, 1);
    this.state = {
      startDate,
      endDate: new Date(),
      upcomingBills: [],
      usersBills: [],
      fetchingDataInProgress: false,
      error: null,
      showUpdateBillDetailsModal: false,
      inputError: null
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);

    this.launchUpdateBillDetailsModal = this.launchUpdateBillDetailsModal.bind(this);
    this.closeUpdateBillDetailsModal = this.closeUpdateBillDetailsModal.bind(this);

    this.launchUpdateBillDetailsAsPaidModal = this.launchUpdateBillDetailsAsPaidModal.bind(this);
    this.closeUpdateBillDetailsAsPaidModal = this.closeUpdateBillDetailsAsPaidModal.bind(this);
  }

  componentWillMount() {
    this.fetchNextBillDetails().then(upcomingBills => {
      this.setState({
        upcomingBills
      }, () => {
        this.fetchUsersBills();
      });
    });
  }

  fetchNextBillDetails() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/clientmanager/billing/nextbillingdetails", { json: true }, (err, resp, respBody) => {
        if (err) {
          console.log('fetchNextBillDetails: error = ', err);
          return resolve([]); // resolving with empty array in case of error
        }
        if (resp) {
          console.log('fetchNextBillDetails: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            const upcomingBills = respBody || [];
            resolve(upcomingBills);
          } else {
            resolve([]);
          }
        }
      });
    });
  }

  fetchUsersBills() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    HttpRequest(config.serverHost + "/apis/clientmanager/billing", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchUsersBills: error = ', err);
        this.setState({
          fetchingDataInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchUsersBills: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          const usersBills = respBody || [];
          
          this.setState({
            usersBills: usersBills,
            fetchingDataInProgress: false
          });
        } else {
          this.setState({
            usersBills: [],
            fetchingDataInProgress: false,
            error: respBody.error || 'Failed to fetch usersBills'
          });
        }
      }
    });
  }

  launchUpdateBillDetailsModal(bill = {}) {
    this.setState({
      showUpdateBillDetailsModal: true,
      selectedBill: bill
    });
  }

  closeUpdateBillDetailsModal(billDetailsUpdated = false) {
    this.setState({
      showUpdateBillDetailsModal: false,
      selectedBill: null
    }, () => {
      if (billDetailsUpdated === true) {
        this.fetchUsersBills(); // refresh all bills
      }
    });
  }

  launchUpdateBillDetailsAsPaidModal(bill = {}) {
    this.setState({
      showUpdateBillDetailsAsPaidModal: true,
      selectedBill: bill
    });
  }

  closeUpdateBillDetailsAsPaidModal(billDetailsUpdated = false) {
    this.setState({
      showUpdateBillDetailsAsPaidModal: false,
      selectedBill: null
    }, () => {
      if (billDetailsUpdated === true) {
        this.fetchUsersBills(); // refresh all bills
      }
    });
  }

  approveBill(bill = {}) {
    this.setState({
      approvingInProgressUsername: bill.username
    });

    var data = { 
      username: bill.username, 
      broker: bill.broker,
      invoiceNumber: bill.invoiceNumber,
      approved: true
    };

    HttpRequest.put({
        url: config.serverHost + "/apis/clientmanager/billing/update",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('Approve bill status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: JSON.parse(respBody).status,
          approvingInProgressUsername: null
        }, () => {
          this.fetchUsersBills(); // refresh all bills
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'approve bill end point not found',
          approvingInProgressUsername: null
        });
      } else {
        console.error('Approve bill error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          approvingInProgressUsername: null
        });
      }
    });
  }

  getBill(bill = {}) {
    return (
      <tr key={"bill-" + bill.invoiceNumber}>
        <td>
          <Button  color="primary" 
            disabled={bill.isPaid || bill.totalCostWithGST === 0 || bill.isWrittenOff}
            onClick={() => this.launchUpdateBillDetailsAsPaidModal(bill)} style={{'marginRight': '10px'}}>Update Payment</Button>
        </td>
        <td>{bill.invoiceNumber}</td>
        <td>{bill.username}</td>
        <td>{bill.billingPlan}</td>
        <td>{Utils.formatDateToString(new Date(bill.billingStartDate))}</td>
        <td>{Utils.formatDateToString(new Date(bill.billingEndDate))}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.totalCostWithGST, true)}</td>
        <td>{Utils.formatDateToString(new Date(bill.paymentDueDate))}</td>
        <td>{bill.totalCost > 0 ? (bill.isPaid ? 'YES' : (bill.isWrittenOff ? 'Write-Off' : 'NO')) : '-'}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.averageCapital)}</td>
        <td className={bill.netProfitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.netProfitLoss, true)}</td>
        <td className={bill.previousLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.previousLoss, true)}</td>
        <td className={bill.unAccountedPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.unAccountedPnl, true)}</td>
        <td className={bill.netProfitLossAfterAdjustments > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.netProfitLossAfterAdjustments, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.fixedCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.variableCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.GST, true)}</td>
      </tr>
    );
  }

  getUnApprovedBill(bill = {}) {
    return (
      <tr key={"bill-" + bill.invoiceNumber}>
        <td>
          <Button  color="primary" 
            onClick={() => this.launchUpdateBillDetailsModal(bill)} style={{'marginRight': '10px'}}>Edit</Button>
          <Button  color="primary" 
            disabled={this.state.approvingInProgressUsername}
            onClick={() => this.approveBill(bill)}>{this.state.approvingInProgressUsername === bill.username ? 'Approving..' : 'Approve'}</Button>
        </td>
        <td>{bill.invoiceNumber}</td>
        <td>{bill.username}</td>
        <td>{bill.billingPlan}</td>
        <td>{Utils.formatDateToString(new Date(bill.billingStartDate))}</td>
        <td>{Utils.formatDateToString(new Date(bill.billingEndDate))}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.totalCostWithGST, true)}</td>
        <td className={bill.unAccountedPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.unAccountedPnl, true)}</td>
        <td className={bill.netProfitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.netProfitLoss, true)}</td>
        <td className={bill.previousLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.previousLoss, true)}</td>
        <td className={bill.netProfitLossAfterAdjustments > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.netProfitLossAfterAdjustments, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.fixedCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.variableCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.GST, true)}</td>
      </tr>
    );
  }

  getFilteredBills() {
    const filteredBills = _.filter(this.state.usersBills, bill => {
      return bill.billingStartDate >= this.state.startDate && bill.billingEndDate <= this.state.endDate;
    });
    return filteredBills;
  }

  validateInput() {
    if (this.state.startDate.getTime() > this.state.endDate.getTime()) {
      this.setState({
        inputError: 'Start Date can not be greater than End Date'
      });
    } else {
      this.setState({
        inputError: null
      });
    }
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date
    }, () => {
      this.validateInput();
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date
    }, () => {
      this.validateInput();
    });
  }

  getInputFields() {
    return (<div className="trades-history-input" style={{'margin-top': '20px'}}>
      <div className="date-picker-wrapper">
        <label>Start Date:</label>
        <DatePicker 
          dateFormat="dd-MM-yyyy"
          minDate={this.rangeStartDate}
          maxDate={this.rangeEndDate}
          selected={this.state.startDate}
          onChange={this.handleStartDateChange} />
      </div>

      <div className="date-picker-wrapper">
        <label>End Date:</label>
        <DatePicker 
          dateFormat="dd-MM-yyyy"
          minDate={this.rangeStartDate}
          maxDate={this.rangeEndDate}
          selected={this.state.endDate}
          onChange={this.handleEndDateChange} />
      </div>
      {this.state.inputError && <div className="text-danger">{this.state.inputError}</div>}
    </div>);
  }

  getSummary() {
    const summary = {
      netProfitLoss: 0,
      netProfitLossFromFreePlans: 0,
      netProfitLossFromPaidPlans: 0,
      totalCost: 0,
      GST: 0,
      totalCostWithGST: 0,
      paidAmount: 0,
      toBeReceivedAmount: 0,
      unAccountedPnl: 0,
      writeOffs: 0
    };
    _.each(this.getFilteredBills(), bill => {
      const isFreePlan = bill.billingPlan === 'FREE' || bill.billingPlan.startsWith('TRIAL');
      summary.netProfitLoss += bill.netProfitLoss;
      summary.netProfitLossFromFreePlans += (isFreePlan ? bill.netProfitLoss : 0);
      summary.netProfitLossFromPaidPlans += (!isFreePlan ? bill.netProfitLoss : 0);
      summary.totalCost += bill.totalCost;
      summary.GST += bill.GST;
      summary.totalCostWithGST += bill.totalCostWithGST;
      summary.paidAmount += (bill.isPaid ? bill.totalCost : 0);
      summary.toBeReceivedAmount += (!bill.isPaid && !bill.isWrittenOff ? bill.totalCost : 0);
      summary.unAccountedPnl += bill.unAccountedPnl;
      summary.writeOffs += (bill.isWrittenOff ? bill.totalCost : 0);
    });

    return (<Alert color="light">
      <Row>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color={summary.netProfitLoss > 0 ? "success" : "warning"}>
              {"Net P/L: " + Utils.formatNumberToCommaSeparated(summary.netProfitLoss)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color={summary.unAccountedPnl > 0 ? "success" : "warning"}>
              {"Total UnAccounted P/L: " + Utils.formatNumberToCommaSeparated(summary.unAccountedPnl)}
            </Badge>
          </h4>
        </Col>
        {/*<Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color={summary.netProfitLossFromPaidPlans > 0 ? "success" : "warning"}>
              {"Net P/L (Paid Plans): " + Utils.formatNumberToCommaSeparated(summary.netProfitLossFromPaidPlans)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="warning">
              {"Net P/L (FREE Plans): " + Utils.formatNumberToCommaSeparated(summary.netProfitLossFromFreePlans)}
            </Badge>
          </h4>
        </Col>*/}
      </Row>
      <Row>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color={summary.totalCost > 0 ? "success" : "warning"}>
              {"Total Bill (Without GST): " + Utils.formatNumberToCommaSeparated(summary.totalCost)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color={summary.totalCost > 0 ? "success" : "warning"}>
              {"Total GST: " + Utils.formatNumberToCommaSeparated(summary.GST)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color={summary.totalCost > 0 ? "success" : "warning"}>
              {"Total Invoiced Amount: " + Utils.formatNumberToCommaSeparated(summary.totalCostWithGST)}
            </Badge>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="success">
              {"Received Amount (without GST): " + Utils.formatNumberToCommaSeparated(summary.paidAmount)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="warning">
              {"To Be Received (without GST): " + Utils.formatNumberToCommaSeparated(summary.toBeReceivedAmount)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="danger">
              {"Write-offs: " + Utils.formatNumberToCommaSeparated(summary.writeOffs)}
            </Badge>
          </h4>
        </Col>
      </Row>
      <Row>
        
      </Row>
    </Alert>);
  }

  getUpcomingBills() {
    let upcomingBillsStr = '';
    // showing 5 only
    const displayCount = Math.min(5, this.state.upcomingBills.length);
    for (let i = 0; i < displayCount; i++) {
      const ub = this.state.upcomingBills[i];
      upcomingBillsStr += `${ub.username} (${ub.nextBillGenerationDate})`;
      if (i < displayCount - 1) {
        upcomingBillsStr += ' | ';
      }
    }
    return (<Alert color="info">
      <label style={{'fontSize': '14px', 'marginRight':'15px'}}>Upcoming Bills:</label>
      <span>{upcomingBillsStr}</span>
    </Alert>);
  }

  getUnApprovedBills() {

    const unApprovedBills = _.filter(this.getFilteredBills(), bill => !bill.isApproved);
    return (<div>
      <h5>Pending Approval:</h5>
      <Card>
        <CardBody>
          <div>
            {this.state.fetchingDataInProgress === true && <div>
                Fetching details in progress...
              </div>}
            {this.state.error && <div className='text-orange'>
              {this.state.error}
            </div>}
            {!this.state.fetchingDataInProgress && !this.state.error 
              && unApprovedBills.length === 0 && <div>
              No invoices found.
            </div>}
            {!this.state.fetchingDataInProgress && !this.state.error 
              && unApprovedBills.length > 0 && <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">Actions</th>
                  <th className="border-0">Invoice No.</th>
                  <th className="border-0">User</th>
                  <th className="border-0">Billing Plan</th>
                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">Invoice Amount</th>
                  <th className="border-0">UnAccounted P/L</th>
                  <th className="border-0">Net P/L</th>
                  <th className="border-0">Prev Loss</th>
                  <th className="border-0">Net P/L After Adjustments</th>
                  <th className="border-0">Fixed Cost</th>
                  <th className="border-0">Profit Sharing</th>
                  <th className="border-0">GST</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(unApprovedBills, bill => {
                    return this.getUnApprovedBill(bill);
                  })
                }
              </tbody>
            </Table>
            }
          </div>
        </CardBody>
      </Card>
    </div>);

  }

  getApprovedBills() {

    let approvedBills = _.filter(this.getFilteredBills(), bill => bill.isApproved === true);
    approvedBills = approvedBills.sort((b1, b2) => {
      const isB1Free = b1.billingPlan === 'FREE' || _.includes(b1.billingPlan, 'TRIAL');
      const isB2Free = b2.billingPlan === 'FREE' || _.includes(b2.billingPlan, 'TRIAL');
      if (isB1Free && !isB2Free) {
        return 1;
      } else if (!isB1Free && isB2Free) {
        return -1;
      } else {
        if (b2.totalCost > 0 && !b2.isPaid) {
          return 1;
        }
        if (b1.totalCost > 0 && !b1.isPaid) {
          return -1; 
        }
        // sort by billing end date - latest date on top
        return b2.billingEndDate - b1.billingEndDate; 
      }
    });
    const summary = {
      averageCapital: 0,
      netProfitLoss: 0,
      fixedCost: 0,
      variableCost: 0,
      totalCost: 0,
      GST: 0,
      totalCostWithGST: 0,
      unAccountedPnl: 0
    };
    _.each(approvedBills, bill => {
      summary.averageCapital += bill.averageCapital;
      summary.netProfitLoss += bill.netProfitLoss;
      summary.fixedCost += bill.fixedCost;
      summary.variableCost += bill.variableCost;
      summary.totalCost += bill.totalCost;
      summary.GST += bill.GST;
      summary.totalCostWithGST += bill.totalCostWithGST;
      summary.unAccountedPnl += bill.unAccountedPnl;
    });
    if (approvedBills && approvedBills.length > 0) {
      summary.averageCapital = Math.floor(summary.averageCapital / approvedBills.length);
    }

    return (<Card>
      <CardBody>
        <div>
          {this.state.fetchingDataInProgress === true && <div>
              Fetching details in progress...
            </div>}
          {this.state.error && <div className='text-orange'>
            {this.state.error}
          </div>}
          {!this.state.fetchingDataInProgress && !this.state.error 
            && approvedBills.length === 0 && <div>
            No invoices found.
          </div>}
          {!this.state.fetchingDataInProgress && !this.state.error 
            && approvedBills.length > 0 && <Table className="no-wrap v-middle" size="sm" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">Status</th>
                <th className="border-0">Invoice No.</th>
                <th className="border-0">User</th>
                <th className="border-0">Billing Plan</th>
                <th className="border-0">Start Date</th>
                <th className="border-0">End Date</th>
                <th className="border-0">Invoice Amount</th>
                <th className="border-0">Due By</th>
                <th className="border-0">Paid</th>
                <th className="border-0">Average Capital</th>
                <th className="border-0">Net P/L</th>
                <th className="border-0">Prev Loss</th>
                <th className="border-0">UnAccounted P/L</th>
                <th className="border-0">Net P/L After Adjustments</th>
                <th className="border-0">Fixed Cost</th>
                <th className="border-0">Profit Sharing</th>
                <th className="border-0">GST</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(approvedBills, bill => {
                  return this.getBill(bill);
                })
              }
              <tr>
                <td colSpan="6">TOTAL</td>
                <td className="number-right">{Utils.formatNumberToCommaSeparated(summary.totalCostWithGST, true)}</td>
                <td colSpan="4" className={summary.netProfitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.netProfitLoss, true)}</td>
                <td colSpan="2" className={summary.unAccountedPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.unAccountedPnl, true)}</td>
                <td colSpan="2" className="number-right">{Utils.formatNumberToCommaSeparated(summary.fixedCost, true)}</td>
                <td className={summary.variableCost > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.variableCost, true)}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
              </tr>
            </tbody>
          </Table>
          }
        </div>
      </CardBody>
    </Card>);
  }

  render() {

    return (<div>
      <h4>Users Billing</h4>
      <div>
        {/*this.getUpcomingBills()*/}
        {this.getInputFields()}
        {this.getSummary()}
        {this.getUnApprovedBills()}
        {this.getApprovedBills()}
      </div>
      {this.state.showUpdateBillDetailsModal && <UpdateBillDetailsModal 
        isModalOpen={this.state.showUpdateBillDetailsModal}
        onCancel={this.closeUpdateBillDetailsModal}
        bill={this.state.selectedBill}
      />}
      {this.state.showUpdateBillDetailsAsPaidModal && <UpdateBillDetailsAsPaidModal 
        isModalOpen={this.state.showUpdateBillDetailsAsPaidModal}
        onCancel={this.closeUpdateBillDetailsAsPaidModal}
        bill={this.state.selectedBill}
      />}
    </div>);
  }
}

export default UsersBills;
