
import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Col,
  Row,
  Alert,
  Label
} from 'reactstrap';
import { Line, Bar/*, Doughnut*/ } from 'react-chartjs-2';

import {
  getUserDetails,
  fetchDaywiseReport,
  fetchAllStrategies 
} from '../../utils/RestAPIs.js';
import Colors from '../../utils/Colors.js';
import Utils from '../../utils/Utils.js';

class MonthChart extends React.Component {

  constructor(props) {
    super(props);

    let yearMonth = '';
    if (this.props.month) {
      yearMonth = this.props.month;
    } else {
      // consider current month
      yearMonth = Utils.formateDateToYYYYMM(new Date());
    }
    const year = _.split(yearMonth, '-')[0];
    const month = _.split(yearMonth, '-')[1];
    
    this.state = {
      isCumulative: true,
      isQuarterly: false,
      chartData: {},
      selectedMonth: yearMonth,
      selectedQuarter: this.getQuarterOption(year, month),
      selectedStrategy: 'CAPITAL-DEPLOYED'
    };

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleQuarterChange = this.handleQuarterChange.bind(this);
    this.handleStrategyChange = this.handleStrategyChange.bind(this);
    this.toggleCumulative = this.toggleCumulative.bind(this);
    this.toggleQuarterly = this.toggleQuarterly.bind(this);

    this.prepareMonthOptions();
    this.prepareQuarterOptions();

    this.strategies = [];
    this.records = [];

    this.colorsArr = _.transform(Colors, (result, value, key) => {
      result.push({
        name: key,
        value: value
      });
    }, []);
  }

  getQuarterOption(year, month) {
    const quarterNum = Math.floor((month - 1) / 3) + 1;
    return `${year}-Q${quarterNum}`;
  }

  prepareQuarterOptions(startYear, startMonth) {
    this.quarterOptions = [];
    // start month from where we have data of trades
    let month = startMonth || 10
    let year = startYear || 2019;
    const curMonth = new Date().getMonth() + 1;
    const curYear = new Date().getFullYear();

    while (true) {
      if (year > curYear || (year === curYear && month > curMonth)) {
        break;
      }
      const quarter = this.getQuarterOption(year, month);
      if (_.some(this.quarterOptions, qo => qo.value === quarter) === false) {
        const quarterNum = Math.floor((month - 1) / 3) + 1;
        const lastMonthOfQuarter = (quarterNum - 1) * 3 + 3;
        const startDateOfQuarter = `${year}-`+ Utils.leadZeros(month) + '-01';
        const tempStr = `${year}-`+ Utils.leadZeros(lastMonthOfQuarter) + '-01';
        const endDateOfQuarter = `${year}-`+ Utils.leadZeros(lastMonthOfQuarter) + '-' + Utils.getLastDateOfTheMonth(Utils.parseTimestamp(tempStr));;
        this.quarterOptions.push({
          label: quarter,
          value: quarter,
          startDateStr: startDateOfQuarter,
          endDateStr: endDateOfQuarter
        });
      }
      month++;
      if (month === 13)  {
        month = 1;
        year++;
      }
    }

    // reverse the array to show the latest quarter first
    this.quarterOptions = this.quarterOptions.reverse();
  }

  prepareMonthOptions(startYear, startMonth) {
    // prepare all valid months for which we are having data from
    this.monthOptions = [];
    // start month from where we have data of trades
    let month = startMonth || 10
    let year = startYear || 2019;
    const curMonth = new Date().getMonth() + 1;
    const curYear = new Date().getFullYear();

    while (true) {
      if (year > curYear || (year === curYear && month > curMonth)) {
        break;
      }
      const yearMonth = `${year}-`+ Utils.leadZeros(month);
      const startDateOfMonth = yearMonth + '-01';
      const endDateOfMonth = yearMonth + '-' + Utils.getLastDateOfTheMonth(Utils.parseTimestamp(startDateOfMonth));
      this.monthOptions.push({
        label: yearMonth,
        value: yearMonth,
        startDateStr: startDateOfMonth,
        endDateStr: endDateOfMonth
      });
      month++;
      if (month === 13)  {
        month = 1;
        year++;
      }
    }

    // reverse the array to show the latest month first
    this.monthOptions = this.monthOptions.reverse();    
  }

  componentWillMount() {
    fetchAllStrategies().then(allStrategies => {
      this.setState({
        allStrategies: allStrategies || []
      }, () => {
        this.fetchReportAndUpdateChart();  
      });
    });
    getUserDetails().then(user => {
      const activationDate = new Date(user.activationDate);
      const startYear = activationDate.getFullYear();
      const startMonth = activationDate.getMonth() + 1;
      this.prepareMonthOptions(startYear, startMonth);
      this.prepareQuarterOptions(startYear, startMonth);
    });
  }

  isOverlapStrategy(strategyName) {
    const strategyData = _.find(this.state.allStrategies, s => s.name === strategyName);
    return strategyData ? strategyData.isOverlapCapital || false : false;
  }

  handleMonthChange(e) {
    const selectedMonth = e.target.value;
    this.setState({
      selectedMonth
    }, () => {
      this.fetchReportAndUpdateChart();
    });
  }

  handleQuarterChange(e) {
    const selectedQuarter = e.target.value;
    this.setState({
      selectedQuarter
    }, () => {
      this.fetchReportAndUpdateChart();
    });
  }

  handleStrategyChange(e) {
    const selectedStrategy = e.target.value;
    this.updateChart(selectedStrategy);
  }

  toggleCumulative() {
    const isCumulative = this.state.isCumulative;
    this.setState({
      isCumulative: !isCumulative
    }, () => {
      this.updateChart(this.state.selectedStrategy);
    });
  }

  toggleQuarterly() {
    const isQuarterly = this.state.isQuarterly;
    this.setState({
      isQuarterly: !isQuarterly
    }, () => {
      this.fetchReportAndUpdateChart();
    });
  }

  fetchReportAndUpdateChart() {
    this.fetchReport().then(() => {
      let selectedStrategy = this.state.selectedStrategy;
      if (this.strategies.length === 1) {
        selectedStrategy = this.strategies[0].name;
      }
      this.updateChart(selectedStrategy);
    });
  }

  fetchReport() {

    let startDateStr;
    let endDateStr;

    if (this.state.isQuarterly) {
      const quarterOption = _.find(this.quarterOptions, option => option.value === this.state.selectedQuarter);
      startDateStr = quarterOption.startDateStr;
      endDateStr = quarterOption.endDateStr;

    } else {
      // monthly
      const monthOption = _.find(this.monthOptions, option => option.value === this.state.selectedMonth);
      startDateStr = monthOption.startDateStr;
      endDateStr = monthOption.endDateStr;

    }
    //const startDateStr = `${this.state.selectedMonth}-01`;
    //const endDateStr = `${this.state.selectedMonth}-` + Utils.getLastDateOfTheMonth(Utils.parseTimestamp(startDateStr));

    return fetchDaywiseReport({
      username: this.props.username,
      broker: this.props.broker,
      startDateStr,
      endDateStr
    }).then(records => {
      this.records = records || [];
      // sort records by date
      this.records.sort((r1, r2) => r1.dateStr.localeCompare(r2.dateStr));

      this.strategies = [];
      let colorIndex = 1;
      _.each(this.records, record => {
        if (_.some(this.strategies, s => s.name === record.strategy) === false) {
          this.strategies.push({
            name: record.strategy,
            color: this.colorsArr[colorIndex++].value,
            displayOrder: Utils.getStrategyDisplayOrder(record.strategy)
          });
        }
      });
      // sort strategies by display order
      this.strategies.sort((s1, s2) => s1.displayOrder - s2.displayOrder);

      return records;

    }).catch(err => {
      this.records = [];
      this.strategies = [];
      this.setState({
        error: err,
        chartData: {}
      });
    });
  }

  updateChart(selectedStrategy) {

    if (!this.records || this.records.length === 0) {
      this.setState({
        chartData: {}
      });
      return;
    }

    let dateLabels = [{index: 0, label: ''}];    
    let dateIndex = 1;
    _.each(this.records, record => {
      
      const date = record.dateStr.substring(5);
      if (_.some(dateLabels, dl => dl.label === date) === false) {
        dateLabels.push({
          index: dateIndex++,
          label: date
        });
      }

      const strategy = _.find(this.strategies, s => s.name === record.strategy);
      const dateLabel = _.find(dateLabels, dl => dl.label === date);
      if (!strategy.records) {
        strategy.records = [];
      }
      strategy.records[dateLabel.index] = record;
      // adding capital at each index
      if (!strategy.capital) { // this is an array
        strategy.capital = [];
      }
      strategy.capital[dateLabel.index] = record.capital || 0;
    });

    // sort dateLabels by index
    dateLabels.sort((a, b) => a.index - b.index);
    
    // calculate cumulative pl of each strategy
    _.each(this.strategies, s => {
      s.PL = [0];
      s.cumulativePL = [0];
      for (let i = 1; i < dateLabels.length; i++) {
        const index = dateLabels[i].index;
        const record = s.records[index];
        if (record) {
          s.PL[index] = record.netPL;
          s.cumulativePL[index] = s.cumulativePL[index - 1] + record.netPL;
        } else {
          s.PL[index] = 0;
          s.cumulativePL[index] = s.cumulativePL[index - 1];
          s.capital[index] = 0;
        }
      }
    });
    
    const xAxisLabels = _.map(dateLabels, dl => dl.label);
    const chartData = {
      labels: this.state.isCumulative ? xAxisLabels : xAxisLabels.slice(1),
      datasets: []
    }

    if (!selectedStrategy) {
      _.each(this.strategies, s => {
        chartData.datasets.push({
          //stack: 'stockKey1234',
          label: Utils.getStrategyDisplayName(s.name),
          borderWidth: 1,
          backgroundColor: `${s.color}1A`, // adding alpha at the end
          borderColor: s.color,
          pointBorderColor: s.color,
          pointBackgroundColor: s.color,
          data: this.state.isCumulative ? s.cumulativePL : s.PL.slice(1)
        });
      });
    } else {
      const s = _.find(this.strategies, s => s.name === selectedStrategy);
      if (s) {
        chartData.datasets.push({
          label: Utils.getStrategyDisplayName(s.name),
          borderWidth: 1,
          backgroundColor: `${s.color}1A`, // adding alpha at the end
          borderColor: s.color,
          pointBorderColor: s.color,
          pointBackgroundColor: s.color,
          data: this.state.isCumulative ? s.cumulativePL : s.PL.slice(1)
        });
      }
    }
    console.log('==== chartData => ', chartData);

    // prepare data for pie chart
    const strategiesForPieChart = [...this.strategies];
    strategiesForPieChart.sort((s1, s2) => {
      const pnl1 = s1.cumulativePL[s1.cumulativePL.length - 1];
      const pnl2 = s2.cumulativePL[s2.cumulativePL.length - 1];
      if (pnl1 > 0 && pnl2 < 0) {
        return -1;
      }
      if (pnl1 < 0 && pnl2 > 0) {
        return 1;
      }
      return pnl1 - pnl2;
    });

    const doughnutChartData = {datasets: []};
    doughnutChartData.labels = _.map(strategiesForPieChart, s => Utils.getStrategyDisplayName(s.name));
    doughnutChartData.datasets[0] = {
      data: _.map(strategiesForPieChart, s => {
        const profitLoss = s.cumulativePL[s.cumulativePL.length - 1];
        return profitLoss;
      }),
      backgroundColor: _.map(strategiesForPieChart, s => {
        const profitLoss = s.cumulativePL[s.cumulativePL.length - 1];
        return profitLoss > 0 ? s.color : `${s.color}4D`;
      }),
      hoverBackgroundColor: _.map(strategiesForPieChart, s => {
        const profitLoss = s.cumulativePL[s.cumulativePL.length - 1];
        return profitLoss > 0 ? s.color : `${s.color}4D`;
      })
    };
    

    this.setState({
      selectedStrategy,
      chartData,
      doughnutChartData
    });
  }

  renderDoughnutChart() {
    return null;
    /*return (<div>
      <Doughnut data={this.state.doughnutChartData} />
    </div>);*/
  }

  render() {
    const thisMonthSelected = this.state.selectedMonth === this.monthOptions[0].value;
    const thisQuarterSelected = this.state.selectedQuarter === this.quarterOptions[0].value;
    let profitLoss = null, profitPercentage = 0, minCapital = 0, maxCapital = 0;
    const s = _.find(this.strategies, s => s.name === this.state.selectedStrategy);
    if (s && _.isEmpty(this.state.chartData) === false) {
      profitLoss = s.cumulativePL[s.cumulativePL.length - 1];
      for (let i = 0; i < s.PL.length; i++) {
        if (s.capital[i] > 0) {
          profitPercentage += (100 * s.PL[i] / s.capital[i]);
          if (minCapital === 0) {
            minCapital = s.capital[i];
          }
          if (maxCapital === 0) {
            maxCapital = s.capital[i];
          }
          minCapital = Math.min(minCapital, s.capital[i]);
          maxCapital = Math.max(maxCapital, s.capital[i]);
        }
      }
      if (profitPercentage !== 0) {
        profitPercentage = Utils.roundOff(profitPercentage);
      }
      console.log(this.props.broker + ' render() minCapital = ' + minCapital + ", maxCapital = " + maxCapital + ", strategy = " + s.name + ', returns = ' + profitPercentage);
    }
    let capitalStr = '';
    if (minCapital !== maxCapital) {
      capitalStr = `a capital range of ${Utils.formatNumberToCommaSeparated(minCapital)} - ${Utils.formatNumberToCommaSeparated(maxCapital)}`;
    } else {
      capitalStr = `a capital of ${Utils.formatNumberToCommaSeparated(maxCapital)}`;
    }
    
    const chartOptions = { 
      maintainAspectRatio: false,
      legend: { 
        display: true,
        labels: { 
          fontFamily: "Nunito Sans" 
        } 
      }, 
      scales: { 
        yAxes: [{ 
          stacked: false, //this.state.isCumulative ? false : true, 
          gridLines: { 
            display: false 
          }, 
          ticks: { 
            fontFamily: "Nunito Sans", 
            beginAtZero: true
          } 
        }],
        xAxes: [{ 
          gridLines: { 
            display: false 
          }, 
          ticks: { 
            fontFamily: "Nunito Sans" 
          } 
        }] 
      } 
    };

    let selectedTitle = 'This Month';
    if (this.state.isQuarterly) {
      selectedTitle = thisQuarterSelected ? 'This Quarter' : this.state.selectedQuarter;
    } else {
      selectedTitle = thisMonthSelected ? 'This Month' : this.state.selectedMonth;
    }

    return (<div>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <div>
              <CardTitle>Profit/Loss{' [' + _.toUpper(this.props.broker) + '] '}: {selectedTitle}</CardTitle>
              <CardSubtitle>{this.state.isCumulative ? 'Cumulative ' : 'Daily '} profit/loss of the selected {this.state.isQuarterly ? 'quarter' : 'month'}</CardSubtitle>
            </div>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Label check>
                  <Input type="checkbox" 
                    checked={this.state.isCumulative}
                    onChange={this.toggleCumulative}
                    />{' '}
                  <h5>Cumulative</h5>
                </Label>
              </div>
            </div>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Label check>
                  <Input type="checkbox" 
                    checked={this.state.isQuarterly}
                    onChange={this.toggleQuarterly}
                    />{' '}
                  <h5>Qtrly</h5>
                </Label>
              </div>
            </div>
            {this.props.showPastMonths === true && <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Input type="select" 
                  className="custom-select" 
                  value={this.state.isQuarterly ? this.state.selectedQuarter : this.state.selectedMonth}
                  onChange={this.state.isQuarterly ? this.handleQuarterChange : this.handleMonthChange}>
                  {
                    _.map(this.state.isQuarterly ? this.quarterOptions : this.monthOptions, o => {
                      return (<option
                        key={o.value}
                        value={o.value}>
                        {o.label}
                      </option>);
                    })
                  }
                </Input>
              </div>
            </div>}
            {this.strategies.length > 0 && <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Input type="select" 
                  className="custom-select" 
                  value={this.state.selectedStrategy}
                  onChange={this.handleStrategyChange}>
                  {
                    _.map(this.strategies, s => {
                      return (<option
                        key={s.name}
                        value={s.name}>
                        {Utils.getStrategyDisplayName(s.name)}
                      </option>);
                    })
                  }
                </Input>
              </div>
            </div>}
          </div>
          {profitLoss && <div className="d-flex align-items-center">
            <Alert color={profitLoss > 0 ? 'success' : 'warning'}>
              Net Pnl = {Utils.formatNumberToCommaSeparated(profitLoss)} ({profitPercentage + ' %'}) on {capitalStr}
            </Alert>
          </div>}
          <Row>
            <Col>
              {this.state.chartData && <div className="campaign ct-charts">
                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 280 }}>
                  {_.isEmpty(this.state.chartData) ? <div className="d-flex align-items-center">
                    <Alert color={'warning'}>
                      <h3>No data found for selected input</h3>
                    </Alert>
                  </div> : (this.state.isCumulative ? 
                    <Line data={this.state.chartData} options={chartOptions} /> 
                    :
                    <Bar data={this.state.chartData} options={chartOptions} />)
                  }
                </div>
              </div>}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderDoughnutChart()}
            </Col>
          </Row>
          <div className='bottom-notes'><b>NOTE:</b> The returns shown above are indicative numbers. Please refer to your Broker`s P&L Statement for actuals returns.</div>
        </CardBody>
      </Card>
    </div>);
  }

}

export default MonthChart;