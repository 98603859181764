

import React from 'react';
import HttpRequest from "request";
import config from "../../config.js";
import Utils from "../../utils/Utils.js";

import {
  Card,
  CardBody,
  CardTitle,
  Table
} from 'reactstrap';

class IntradayLongOnlyFnoScanner extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scanResults: []
    };
  }

  componentWillMount() {
    this.fetchScanResults();
  }

  fetchScanResults() {
    HttpRequest(config.serverHost + "/apis/public/intraday-long-only-fno-scanner", { json: true }, (err, resp, result) => {
      console.log('fetchScanResults: resp.statusCode = ' + resp.statusCode);
      if (resp.statusCode === 200) {
        this.setState({
          scanResults: result
        });
      } else {
        this.setState({
          scanResults: []
        });
        console.error("fetchScanResults: Error. ", err, result);
      }
    });
  }

  getSignalRow(scanData = {}, index) {
    return (
      <tr key={"scan-" + index}>
        <td>{index + 1}</td>
        <td>{Utils.formatDate(new Date(scanData.date))}</td>
        <td>{scanData.tradingSymbol}</td>
        <td>{scanData.dayOpen}</td>
        <td>{scanData.lastTradedPrice}</td>
        <td>{scanData.change + ' %'}</td>
      </tr>
    );
  }

  render() {
    return (<Card>
      <CardBody>
        <div className="d-flex align-items-center">
          <div>
            <CardTitle>Intraday Long Only Fno Scanner</CardTitle>
          </div>
        </div>
        <Table className="no-wrap v-middle" responsive>
          <thead>
            <tr className="border-0">
              <th className="border-0">SNo</th>
              <th className="border-0">Timestamp</th>
              <th className="border-0">Symbol</th>
              <th className="border-0">Open</th>
              <th className="border-0">Last Price</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {              
              this.state.scanResults.map((scanData, index) => {
                return this.getSignalRow(scanData, index);
              })
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }
}

export default IntradayLongOnlyFnoScanner;
