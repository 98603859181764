
import _ from 'lodash';
import React from 'react';
import { 
  Card, 
  CardTitle,
  CardText,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";
import Utils from "../../utils/Utils.js";

import AlertModal from '../common/AlertModal.js';

const USE_PAYMENT_LINKS = true;

class PlanCard extends React.Component {

  constructor(props) {
    super(props);

    this.validityOptions = _.map(props.plan.planCosts, pc => {
      return {
        label: `${pc.cost} INR per ${pc.validityPeriodDays} days`,
        value: pc.validityPeriodDays
      };
    });

    this.state = {
      selectedValidity: this.validityOptions[0].value,
      isDropdownOpen: {}
    };

    this.launchAlertModal = this.launchAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
  }

  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null
    }, () => {
      if (this.state.subscriptionSuccessful === true) {
        this.props.refreshUserPlans();
      }
    });
  }

  onValidityChange(event) {
    const value = event.target ? event.target.value : '';
    this.setState({
      selectedValidity: _.toInteger(value)
    });
  }

  toggleDropdownMenu(plan = {}) {
    const isDropdownOpen = {...this.state.isDropdownOpen};
    isDropdownOpen[plan.name] = isDropdownOpen[plan.name] ? false : true;
    this.setState({
      isDropdownOpen
    });
  }

  subscriberToPlan(planCost = {}) {
    this.setState({
      subscribeRequestInProgress: true
    });
    const data = {
      plan: planCost.planName,
      validity: planCost.validityPeriodDays
    };
    HttpRequest.post({
      url: config.serverHost + "/apis/user/plans",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('subscriberToPlan: status code = ' + resp.statusCode);
      const title = `Subscribe to plan`;
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        console.log('subscriberToPlan: respBody = ',  respBody);
        this.setState({
          subscriptionSuccessful: true,
          subscribeRequestInProgress: false
        }, () => {
          this.launchAlertModal(title, 'Successfully subscribed to plan ' + planCost.planName);
        });
      } else if (resp.statusCode === 404) {
        console.log('subscriberToPlan: end point not found');
        this.setState({
          subscribeRequestInProgress: false
        }, () => {
          this.launchAlertModal(title, 'Failed to subscribe to plan ' + planCost.planName);
        });
      } else {
        console.error('subscriberToPlan error => ', respBody);
        const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
        this.setState({
          subscribeRequestInProgress: false
        }, () => {
          this.launchAlertModal(title, error);
        });
      }
    });
  }

  createOrder(planCost = {}) {
    const data = {
      ispaymentlink: USE_PAYMENT_LINKS,
      amount: planCost.cost,
      plan: planCost.planName,
      validity: planCost.validityPeriodDays
    };

    return new Promise((resolve, reject) => {
      HttpRequest.post({
        url: config.serverHost + "/apis/user/payments/createorder",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('createOrder: status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          console.log('createOrder: respBody = ',  respBody);
          resolve(JSON.parse(respBody));
        } else if (resp.statusCode === 404) {
          console.log('createOrder: end point not found');
          reject('Invalid end point');
        } else {
          console.error('createOrder error => ', respBody);
          const error = JSON.parse(respBody).error || JSON.parse(respBody).result
          reject(error);
        }
      });
    });
  }

  onBuyPlanCost(planCost) {
    /*if (Utils.isMarketOpen()) {
      this.launchAlertModal('Buy Plan', 'Not allowed now. Please try during non-market hours');
      return;
    }*/
    console.log('onBuyPlanCost => ', planCost);
    if (planCost.cost === 0) {
      // its a free plan, bypass payment gateway
      this.subscriberToPlan(planCost);
      return;
    }
    const currency = 'INR'; // default for now
   
    this.createOrder(planCost).then(paymentOrder => {
      if (USE_PAYMENT_LINKS) {
        window.location.href = "https://razorpay.com/payment-link/" + paymentOrder.paymentLinkId;
      } else {
        const user = this.props.user || {};
        const options = {
          "key_id": paymentOrder.apiKey,
          "amount": Math.floor(paymentOrder.amount * 100), // In paisa
          "currency": currency,
          "name": paymentOrder.businessName,
          "description": paymentOrder.description,
          "image": config.serverHost + "/android-icon-192x192.png",
          "order_id": paymentOrder.orderId,
          "callback_url": paymentOrder.callbackUrl,
          "prefill": {
            "name": user.firstname + " " + user.lastname,
            "email": user.email,
            "contact": user.mobile
          },
          "notes": {
            "address": paymentOrder.businessAddress
          },
          "theme": {
            "color": "#e5687e"
          }
        };
        console.log('checkout options => ', options);
        window.openRazorpay(options);
      }
    }).catch(err => {
      console.log('Failed to launch payment option');
    });
  }

  getPlanCostsDropdownMenu(plan = {}, actionName) {
    return (<Dropdown style={{'marginTop': '15px'}}
      size="md"
      isOpen={this.state.isDropdownOpen[plan.name]}
      toggle={() => this.toggleDropdownMenu(plan)}>
      <DropdownToggle caret style={{'backgroundColor': '#e5687e'}}>
        {actionName}
      </DropdownToggle>
      <DropdownMenu>
        {
          _.map(plan.planCosts, pc => {
            return (<DropdownItem onClick={() => this.onBuyPlanCost(pc)}>
              <i className="fa fa-inr"></i> {pc.cost} / {pc.validityPeriodDays} days
            </DropdownItem>)
          })
        }
      </DropdownMenu>
    </Dropdown>);
  }

  renderSubscribedPlan() {
    const userPlan = this.props.plan;
    return userPlan.expired === true ? (<Card body inverse style={{'backgroundColor': '#a8a7a7'}}>
      <CardTitle tag="h5">{userPlan.name} | <span>Status: Expired</span></CardTitle>
      <CardText>
        {userPlan.enabled && userPlan.planCosts[0].cost > 0 && 
          <span>{this.getPlanCostsDropdownMenu(userPlan, 'Renew this plan')}</span>
        }
      </CardText>
    </Card>) : (<Card body inverse style={{'backgroundColor': '#527b4f'}}>
      <CardTitle tag="h5">{userPlan.name} | <span>Status: Active | {userPlan.daysLeftToExpire} days left</span></CardTitle>
      <CardText>
        {userPlan.enabled && userPlan.planCosts[0].cost > 0 && 
          <span>{this.getPlanCostsDropdownMenu(userPlan, 'Extend this plan')}</span>
        }
      </CardText>
    </Card>);
  }

  renderPlan() {
    const plan = this.props.plan;
    const selectedPlanCost = _.find(plan.planCosts, pc => pc.validityPeriodDays === this.state.selectedValidity);

    return (<Card body inverse style={{'lineHeight': '1.5', 'backgroundColor': '#3c628c'}}>
      <CardTitle tag="h5">{plan.name}</CardTitle>
      <hr />
      <CardText>{plan.description}</CardText>
      <Input
        className="col-form-control"
        type="select" 
        name="select" 
        id={plan.name}
        value={this.state.selectedValidity}
        onChange={this.onValidityChange.bind(this)}>
        {
          _.map(this.validityOptions, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
      <Button
        size="lg"
        style={{'marginTop': '15px', 'marginBottom': '15px', 'backgroundColor': '#e5687e'}}
        disabled={this.props.disabled || this.state.subscribeRequestInProgress}
        onClick={() => this.onBuyPlanCost(selectedPlanCost)}>
        {this.state.subscribeRequestInProgress ? 'Buying' : 'Buy'} (<i className="fa fa-inr"></i> {selectedPlanCost.cost} / {selectedPlanCost.validityPeriodDays} days)
      </Button>
      <div>Min capital required: {Utils.formatNumberToCommaSeparated(plan.minCapitalPerUser)}</div>
      <div>Max capital allowed: {Utils.formatNumberToCommaSeparated(plan.maxCapitalPerUser)}</div>
    </Card>);
  }

  render() {
    return (<div>
      {this.props.subscribed ? this.renderSubscribedPlan() : this.renderPlan()}
      <AlertModal
        isModalOpen={this.state.showAlertModal}
        title={this.state.alertModalTitle}
        message={this.state.alertModalMessage}
        onCancel={this.closeAlertModal}
      />
    </div>);
  }
}

export default PlanCard;
