/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {  
  Row,
  Col,
  Alert
} from 'reactstrap';

import config from "../../config.js";
import Utils from "../../utils/Utils.js";
import { getUserPlans, getAllPlans } from '../../utils/RestAPIs.js'; 

import PlanCard from './PlanCard.js';

class Plans extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      broker: null,
      allPlans: [],
      userPlans: [],
      brokerSetupError: null
    };
  }

  componentWillMount() {
    const user = this.props.user || {};
    let brokerSetupError = null;
    let broker = null;
    if (user.brokers == null || user.brokers.length === 0) {
      brokerSetupError = 'No broker account is setup. Please setup the same at';
    } else if (!user.brokers[0].loginVerified) {
      brokerSetupError = 'Your broker account login is not verified yet. Please verify the same at';
    }
    if (user.brokers && user.brokers.length > 0) {
      broker = user.brokers[0].broker;
    }

    this.setState({
      broker,
      fetchingDataInProgress: true,
      brokerSetupError: null,
      error: null
    });

    getAllPlans().then(allPlans => {
      getUserPlans().then(userPlans => {
        this.setState({
          user,
          allPlans,
          userPlans,
          brokerSetupError,
          fetchingDataInProgress: false,
        });
      }).catch(err => {
        this.setState({
          userPlans: [],
          fetchingDataInProgress: false,
          brokerSetupError,
          error: err
        });
      });
    }).catch(err => {
      this.setState({
        allPlans: [],
        fetchingDataInProgress: false,
        brokerSetupError,
        error: err
      });
    });

  }

  refreshUserPlans() {
    getUserPlans().then(userPlans => {
      this.setState({
        userPlans
      });
    }).catch(err => {
      console.error('Error while refreshing user plans. ', err);
    });
  }

  render() {
    let activePlans = [];
    let expiredPlans = [];
    const now = new Date();
    _.each(this.state.userPlans, userPlan => {
      const expiryTimestamp = new Date(userPlan.expiryDate);
      if (now > expiryTimestamp) {
        userPlan.expired = true;
        expiredPlans.push(userPlan);
      } else {
        userPlan.daysLeftToExpire = Utils.calculateDaysDiff(expiryTimestamp, now);
        activePlans.push(userPlan);
      }
    });
    let myPlans = _.map(_.concat(activePlans, expiredPlans), myPlan => {
      const planInfo = _.find(this.state.allPlans, plan => plan.name === myPlan.planName);
      return {
        ...planInfo,
        ..._.omit(myPlan, ['planName'])
      }
    });
    
    if (myPlans.length > 1) {
      myPlans = _.filter(myPlans, plan => {
        if (plan.expired && plan.planCosts[0].cost === 0) {
          return false; // Do not show if trial plan gets expired
        }
        return true;
      });
    }
    const otherPlans = _.filter(this.state.allPlans, plan => {
      if (!plan.enabled) {
        return false;
      }
      if (_.some(myPlans, myPlan => myPlan.name === plan.name)) {
        return false;
      }
      if (plan.planCosts[0].cost === 0 && myPlans.length > 0) {
        // Do not show trial plan if user already having plans
        return false; 
      }
      // TODO: following hardcoded stuff to be removed and handled with the response of server
      if (plan.name === 'BOS' && this.state.broker === 'zerodha') {
        return false;
      }
      return true;
    });
    const brokersPageLink = config.serverHost + "/brokers";
    const disablePlanBuy = myPlans.length === 0 && this.state.brokerSetupError;

    return (<div>
      <h4>Plans</h4>
      
      <hr />
      <div>
        <h5>My Plans:</h5>
        <Row>
          {
            myPlans.length === 0 && <Col><Alert color="warning">
              <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
                <p>You dont have any active plans</p>
                {this.state.brokerSetupError && <p>{this.state.brokerSetupError}&nbsp;
                  <a href={brokersPageLink}>{brokersPageLink}</a> before buying any plan.
                </p>}
              </div>
            </Alert></Col>
          }
          {
            _.map(myPlans, plan => {
              return (<Col key={plan.name} className="col-sm-12 col-md-12 col-lg-6">
                <PlanCard plan={plan} subscribed={true} user={this.props.user} disabled={disablePlanBuy} />
              </Col>);
            })
          }
        </Row> 
      </div>
      <hr />
      <div>
        <h5>More Plans:</h5>
        {/*<Alert color="danger" className="col-6">
          IMPORTANT NOTE: 
          <br />
          Currently only UPI payment method is working through the payment gateway.
          If you dont want to use UPI method then please transfer money to our bank account and send us
          email at support@thesystematicsolutions.com with your disciplinetrader username, transaction id, plan name, plan days, amount paid.
          We will enable the corresponding plan in your account within 24 hours after verifying your payment.
          <Table className="no-wrap v-middle" responsive>
              <tr>
                <td>
                  Bank Account Name:
                </td>
                <td>
                 Systematic Solutions
                </td>
              </tr>
              <tr>
                <td>
                  Bank:
                </td>
                <td>
                 ICICI Bank
                </td>
              </tr>
              <tr>
                <td>
                  Branch:
                </td>
                <td>
                 Malad West
                </td>
              </tr>
              <tr>
                <td>
                  A/c No:
                </td>
                <td>
                 122105000706
                </td>
              </tr>
              <tr>
                <td>
                  IFSC code:
                </td>
                <td>
                 ICIC0001221
                </td>
              </tr>
          </Table>
        </Alert>*/}
        <Row>
          {
            _.map(otherPlans, plan => {
              return (<Col key={plan.name} className="col-sm-6 col-md-6 col-lg-3">
                <PlanCard 
                  plan={plan}
                  user={this.props.user}
                  disabled={disablePlanBuy}
                  refreshUserPlans={this.refreshUserPlans.bind(this)} />
              </Col>);
            })
          }
        </Row>  
      </div>
    </div>);
  }
}

export default Plans;
