/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

import UpdatePasswordModal from './UpdatePasswordModal.js';

class UsersBrokerLoginStatus extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      fetchingDataInProgress: false,
      error: null,
      showUpdatePasswordModal: false
    };

    this.launchUpdatePasswordModal = this.launchUpdatePasswordModal.bind(this);
    this.closeUpdatePasswordModal = this.closeUpdatePasswordModal.bind(this);
  }

  componentWillMount() {
    this.fetchUsersBrokerLoginStatusDetails();
  }

  fetchUsersBrokerLoginStatusDetails() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    HttpRequest(config.serverHost + "/apis/clientmanager/users/brokerloginstatus", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchUsersBrokerLoginStatusDetails: error = ', err);
        this.setState({
          fetchingDataInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchUsersBrokerLoginStatusDetails: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          const users = respBody || [];
          // sort users based on password expiry days
          users.sort((u1, u2) => {
            if ((!u1.isLoginSuccess && u2.isLoginSuccess) ||
              (u1.isLoginSuccess && !u2.isLoginSuccess)) {
              return u1.isLoginSuccess === false ? -1 : 1;  
            }
            const dateStr1 = Utils.formatDateToString(new Date(u1.updatedOn));
            const dateStr2 = Utils.formatDateToString(new Date(u2.updatedOn));
            if (dateStr1 !== dateStr2) {
              return dateStr2.localeCompare(dateStr1);
            }
            if (u1.isLoginSuccess === u2.isLoginSuccess) {
              return u1.passwordExpiryDays - u2.passwordExpiryDays;
            }
            return u1.isLoginSuccess === false ? -1 : 1;
          });

          this.setState({
            users: users || [],
            fetchingDataInProgress: false
          });
        } else {
          this.setState({
            users: [],
            fetchingDataInProgress: false,
            error: respBody.error || 'Failed to fetch users broker login status details'
          });
        }
      }
    });
  }

  launchUpdatePasswordModal(username, broker, clientID) {
    this.setState({
      showUpdatePasswordModal: true,
      selectedUsername: username,
      selectedBroker: broker,
      selectedClientID: clientID
    });
  }

  closeUpdatePasswordModal() {
    this.setState({
      showUpdatePasswordModal: false,
      selectedUsername: null,
      selectedBroker: null,
      selectedClientID: null
    });
  }

  getUserBrokerLoginDetails(user = {}, sno) {
    return (
      <tr key={"user-" + user.username + user.broker}>
        <td>{sno}</td>
        <td>{user.username}</td>
        <td>{_.toUpper(user.broker)}</td>
        <td>{user.clientID}</td>
        <td className={user.isLoginSuccess === true ? 'text-success' : 'text-orange'}>
          {user.isLoginSuccess === true ? 'YES' : 'NO'}
        </td>
        {/*<td className={user.passwordExpiryDays > 10 ? 'text-success' : 'text-orange'}>
          {user.passwordExpiryDays} days
        </td>*/}
        <td>{Utils.formatDate(user.updatedOn)}</td>
        <td>
          <Button 
            color="primary"
            onClick={() => this.launchUpdatePasswordModal(user.username, user.broker, user.clientID)}>
            Change Password
          </Button>
        </td>
        <td>{user.loginErrorMessage}</td>
      </tr>
    );
  }

  render() {

    return (<div>
      <h4>Users Broker Login Status</h4>
      <Card>
        <CardBody>
          <div>
            {this.state.fetchingDataInProgress === true && <div>
              Fetching details in progress...
            </div>}
            {this.state.error && <div className='text-orange'>
              {this.state.error}
            </div>}
            {!this.state.fetchingDataInProgress && !this.state.error 
              && this.state.users.length === 0 && <div>
              No users found.
            </div>}
            {this.state.users && this.state.users.length > 0 && <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Username</th>
                  <th className="border-0">Broker</th>
                  <th className="border-0">Client ID</th>
                  <th className="border-0">Logged In</th>
                  {/*<th className="border-0">Password Expires In</th>*/}
                  <th className="border-0">Updated On</th>
                  <th className="border-0">Actions</th>
                  <th className="border-0">Login Error</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(this.state.users, (user, index) => {
                    return this.getUserBrokerLoginDetails(user, index + 1);
                  })
                }
              </tbody>
            </Table>}
          </div>
        </CardBody>
      </Card>
      <UpdatePasswordModal 
        isModalOpen={this.state.showUpdatePasswordModal}
        onCancel={this.closeUpdatePasswordModal}
        username={this.state.selectedUsername}
        broker={this.state.selectedBroker}
        clientID={this.state.selectedClientID}
      />
    </div>);
  }
}

export default UsersBrokerLoginStatus;
