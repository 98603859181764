/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
Form, FormGroup, Label, Input } from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class UpdateBillDetailsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleBrokerPnlChange = this.handleBrokerPnlChange.bind(this);
    this.handleRemarksChange = this.handleRemarksChange.bind(this);
    this.updateBillDetails = this.updateBillDetails.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  getInitialState(props) {
    props = props || this.props;
    const brokerPnl = props.bill.unAccountedPnl + props.bill.netProfitLoss;
    return {
      brokerPnl,
      unAccountedPnl: props.bill.unAccountedPnl,
      remarks: 'pnl diff',
      error: null,
      statusMessage: null,
      updateInProgress: false,
      billDetailsUpdated: false
    };
  }

  handleBrokerPnlChange(e) {
    const brokerPnl = e.target.value;
    this.setState({
      brokerPnl,
      unAccountedPnl: brokerPnl - this.props.bill.netProfitLoss,
      error: null
    });
  }

  handleRemarksChange(e) {
    this.setState({
      remarks: e.target.value
    });
  }

  onCancel() {
    // reset to initial state
    this.setState(this.getInitialState());

    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.billDetailsUpdated);
    }
  }

  updateBillDetails(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true
    });

    var data = { 
      username: this.props.bill.username,
      invoiceNumber: this.props.bill.invoiceNumber,
      unAccountedPnl: this.state.unAccountedPnl,
      reason: this.state.remarks
    };

    HttpRequest.put({
        url: config.serverHost + "/apis/clientmanager/billing/update",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('Update bill details status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          this.setState({
            statusMessage: JSON.parse(respBody).status,
            updateInProgress: false,
            billDetailsUpdated: true
          });
        } else if (resp.statusCode === 404) {
          this.setState({
            error: 'Update bill details end point not found',
            updateInProgress: false
          });
        } else {
          console.error('Update bill details error => ', respBody);
          this.setState({
            error: JSON.parse(respBody).error || JSON.parse(respBody).result,
            updateInProgress: false
          });
        }
      });
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen}>
        <ModalHeader>Update Bill Details for {this.props.bill.username}</ModalHeader>
        
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="invoiceNumber">Invoice No.</Label>
              <Input type="text" name="invoiceNumber" id="invoiceNumber" 
                value={this.props.bill.invoiceNumber}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="billingStartDate">Billing Start Date</Label>
              <Input type="text" name="billingStartDate" id="billingStartDate" 
                value={Utils.formatDateToString(new Date(this.props.bill.billingStartDate))}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="billingEndDate">Billing End Date</Label>
              <Input type="text" name="billingEndDate" id="billingEndDate" 
                value={Utils.formatDateToString(new Date(this.props.bill.billingEndDate))}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="autoQuantNetPL">AutoQuant Net Profit/Loss</Label>
              <Input type="text" name="autoQuantNetPL" id="autoQuantNetPL" 
                value={this.props.bill.netProfitLoss}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="brokerPnl">Broker Net Profit/Loss</Label>
              <Input type="number" name="brokerPnl" id="brokerPnl"
                value={this.state.brokerPnl}
                placeholder="Enter broker pnl"
                disabled={this.state.updateInProgress}
                onChange={this.handleBrokerPnlChange} />
            </FormGroup>
            <FormGroup>
              <Label for="unAccountedPnl">UnAccounted Profit/Loss</Label>
              <Input type="text" name="unAccountedPnl" id="unAccountedPnl" 
                value={this.state.unAccountedPnl}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="remarks">Remarks</Label>
              <Input type="text" name="remarks" id="remarks"
                value={this.state.remarks}
                placeholder="Enter remarks"
                onChange={this.handleRemarksChange} />
            </FormGroup>
          </Form>

          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Updating bill details in progress. Please wait...</div>}

        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress}
            onClick={this.updateBillDetails}>
              Update Bill Details
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UpdateBillDetailsModal;
