/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";
import WebSocketClient from '../common/WebSocketClient.js';

import { getUserDetails } from "../../utils/RestAPIs.js";

import {
  TabContent,
  TabPane, 
  Nav, 
  NavItem, 
  NavLink
} from 'reactstrap';

import classnames from 'classnames';

import HttpRequest from "request";
import config from "../../config.js";
import Utils from "../../utils/Utils.js";

import TradesComp from './TradesComp.js';
import TradesSummaryComp from './TradesSummaryComp.js';

const riskDistances = [-10, -5, -3, -1, 0, 1, 3, 5, 10];

class TradesMainComp extends React.Component {

  constructor(props) {
    super(props);

    this.TRADES_REFRESH_INTERVAL_SECONDS = 60;

    this.state = {
      username: (props.user || {}).username,
      broker: _.map((props.user || {}).brokers, b => b.broker)[0],
      activeTab: 'INTRADAY',
      strategiesMap: {},
      openTrades: [],
      activeTrades: [],
      completedTrades: [],
      cancelledTrades: [],
      algoPositions: [],
      brokerPositions: []
    };

    this.socket = new WebSocketClient('TradesMainComp');

    this.refreshAllTrades = this.refreshAllTrades.bind(this);
    this.getOpenTrades = this.getOpenTrades.bind(this);
    this.getActiveTrades = this.getActiveTrades.bind(this);
    this.getCompletedTrades = this.getCompletedTrades.bind(this);
    this.getCancelledTrades = this.getCancelledTrades.bind(this);
    this.getAlgoPositions = this.getAlgoPositions.bind(this);
    this.getBrokerPositions = this.getBrokerPositions.bind(this);

    this.onDemandRefreshUserTradesTimer = null;
  }

  componentWillMount() {
    getUserDetails().then(user => {
      const strategiesMap = {};
      _.each(user.strategies, s => {
        if (s.broker === this.state.broker) {
          strategiesMap[s.strategyName] = s; 
        }
      });
      console.log('strategiesMap => ', strategiesMap);
      this.setState({
        strategiesMap
      });
    }).catch(err => {
      console.error('Failed to get user details: ', err);
    })

    this.socket.connect();
    this.startAllIntervals();
  }

  componentWillReceiveProps(newProps) {
    console.log('TradesMainComp componentWillReceiveProps: name = ' + newProps.broker);
    if (this.props.broker !== newProps.broker) {
      this.setState({
        openTrades: [],
        activeTrades: [],
        completedTrades: [],
        cancelledTrades: [],
        algoPositions: [],
        brokerPositions: []
      }, () => {
        this.clearAllIntervals();
        this.startAllIntervals();
      });
    }
  }

  componentWillUnmount() {
    this.socket.disconnect();
    this.clearAllIntervals();
  }

  startAllIntervals() {
    this.elapsedSecondsFromLastRefresh = this.TRADES_REFRESH_INTERVAL_SECONDS; // setting the value to fetch first time
    this.refreshAllTradesInterval = setInterval(this.refreshAllTrades, 1000);
  }

  clearAllIntervals() {
    clearTimeout(this.refreshAllTradesInterval);
  }

  onOrderUpdate(order = {}) {
    // No need to refresh on order complete status as it is covered by position update
    if (order.username && order.broker) {
      if (!this.onDemandRefreshUserTradesTimer) {
        // Delaying the refresh operation to 3 seconds in order to control the flood of many updates within a second.
        console.log('OrderUpdate: scheduling refresh user trades.');
        this.onDemandRefreshUserTradesTimer = setTimeout(() => {
          this.onDemandRefreshUserTradesTimer = null;
          this.refreshAllTrades(true);
        }, 3000);
      } else {
        console.log('OrderUpdate: ignoring refresh as its already scheduled.');
      }
    }
  }

  onPositionUpdate(position = {}) {
    if (position.username && position.broker) {
      if (!this.onDemandRefreshUserTradesTimer) {
        console.log('PositionUpdate: scheduling refresh user trades.');
        // Delaying the refresh operation to 3 seconds in order to control the flood of many updates within a second.
        this.onDemandRefreshUserTradesTimer = setTimeout(() => {
          this.onDemandRefreshUserTradesTimer = null;
          this.refreshAllTrades(true);
        }, 3000);
      } else {
        console.log('PositionUpdate: ignoring refresh as its already scheduled.');
      }
    }
  }

  fetchUserTrades() {
    const broker = this.state.broker;
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + `/apis/trades/all?broker=${broker}&fetchbrokerpos=true`, { json: true }, (err, resp, respBody) => {
        if (err) {
          console.error(`fetchUserTrades:${broker}: error = `, err);
          return reject(err);
        } else {
          if (resp) {
            console.log(`fetchUserTrades:${broker}: resp.statusCode = ` + resp.statusCode);
            if (resp.statusCode === 200) {
              const trades = respBody || {};
              resolve(trades);
            } else {
              reject(`fetchUserTrades:${broker}: ` + (respBody.error || 'Failed to fetch users'));
            }
          } else {
            reject(`fetchUserTrades:${broker}: No error and no response`);
          }
        }
      });
    });
  }

  refreshAllTrades(force = false) {
    if (force === true || this.elapsedSecondsFromLastRefresh >= this.TRADES_REFRESH_INTERVAL_SECONDS) {
      if (this.state.fetchTradesInProgress === true) {
        return;
      }
      this.setState({
        fetchTradesInProgress: true
      });
      this.fetchUserTrades().then(allTrades => {
        allTrades = allTrades || {};
        _.each(allTrades.activeTrades || [], td => {
          td.profitLoss = Utils.roundOff(td.profitLoss);
          td.charges = Utils.roundOff(td.charges);
          td.netProfitLoss = Utils.roundOff(td.netProfitLoss);
        });
        this.setState({
          openTrades: allTrades.openTrades,
          activeTrades: allTrades.activeTrades,
          completedTrades: allTrades.completedTrades,
          cancelledTrades: allTrades.cancelledTrades,
          algoPositions: allTrades.algoPositions,
          brokerPositions: allTrades.brokerPositions,
          fetchTradesInProgress: false
        });

        this.elapsedSecondsFromLastRefresh = 0;

        if (!Utils.isMarketOpen()) {
          clearTimeout(this.refreshAllTradesInterval);
        }

      }).catch(err => {
        console.error(`fetchUserTrades failed: `, err);

        if (!Utils.isMarketOpen()) {
          clearTimeout(this.refreshAllTradesInterval);
        }
      });
    } else {
      const activeTrades = _.map(this.state.activeTrades, trade => {
        const latestCMP = this.socket.getCMP(trade.tradingSymbol);
        let updatedTrade = trade;
        if (latestCMP > 0) {
          updatedTrade = {
            ...trade,
            cmp: latestCMP
          };
          return Utils.calculateProfitLossCharges(updatedTrade, true);
        }
        return trade;
      });
      const algoPositions = _.map(this.state.algoPositions, pos => {
        const latestCMP = this.socket.getCMP(pos.tradingSymbol);
        return Utils.calculatePositionPnl(pos, latestCMP);
      });
      const brokerPositions = _.map(this.state.brokerPositions, pos => {
        const latestCMP = this.socket.getCMP(pos.tradingSymbol);
        return Utils.calculatePositionPnl(pos, latestCMP);
      });
      this.setState({
        activeTrades,
        algoPositions,
        brokerPositions
      });
      this.elapsedSecondsFromLastRefresh++;
    }
  }

  getOpenTrades(product) {
    return _.filter(this.state.openTrades, trade => trade.product === product);
  }

  getActiveTrades(product) {
    return _.filter(this.state.activeTrades, trade => trade.product === product);
  }

  getCompletedTrades(product) {
    return _.filter(this.state.completedTrades, trade => trade.product === product);
  }

  getCancelledTrades(product) {
    return _.filter(this.state.cancelledTrades, trade => trade.product === product);
  }

  getAlgoPositions(product) {
    return _.filter(this.state.algoPositions, pos => {
      if (product === 'INTRADAY' && pos.productType === 'MIS') {
        return true;
      } 
      if (product === 'POSITIONAL' && pos.productType === 'NRML') {
        return true;
      }
      return false;
    });
  }

  getBrokerPositions(product) {
    return _.filter(this.state.brokerPositions, pos => {
      if (product === 'INTRADAY' && pos.productType === 'MIS') {
        return true;
      } 
      if (product === 'POSITIONAL' && pos.productType === 'NRML') {
        return true;
      }
      return false;
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (<div className='trades-main-comp'>
      <TradesSummaryComp
        allTrades={_.concat(this.state.activeTrades, this.state.completedTrades)}
        strategiesSummary={this.state.strategiesMap}
        algoPositions={this.state.algoPositions}
        brokerPositions={this.state.brokerPositions}
        riskDistances={riskDistances}
        isAdmin={false}
        username={this.state.username}
        broker={this.state.broker} />

      <Nav justified pill tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === 'INTRADAY' })}
            onClick={() => { this.toggle('INTRADAY'); }}
          >
            INTRADAY
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === 'POSITIONAL' })}
            onClick={() => { this.toggle('POSITIONAL'); }}
          >
            POSITIONAL
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={this.state.activeTab}>
        {
          _.map(['INTRADAY', 'POSITIONAL'], product => {
            return (<TabPane tabId={product} key={[product]}>
              <TradesComp
                product={product}
                user={this.props.user}
                broker={this.state.broker}
                showPositions={true}
                getOpenTrades={this.getOpenTrades}
                getActiveTrades={this.getActiveTrades}
                getCompletedTrades={this.getCompletedTrades}
                getCancelledTrades={this.getCancelledTrades}
                getAlgoPositions={this.getAlgoPositions}
                getBrokerPositions={this.getBrokerPositions}
              />
            </TabPane>);
          })
        }
      </TabContent>
    </div>);
  }
}

export default TradesMainComp;
