
import _ from 'lodash';
import React from 'react';
import BNFLevels from './BNFLevels.js';
import MRDT1Levels from './MRDT1Levels.js';
import OPTSNF1Levels from './OPTSNF1Levels.js';
//import PivotPointsLevels from './PivotPointsLevels.js';
import QualityInvestingScanner from './QualityInvestingScanner.js'
import LTIScanner from './LTIScanner.js';
import IntradayLongOnlyFnoScanner from './IntradayLongOnlyFnoScanner.js';
import Header from '../header/header.jsx';

const supportedQueries = [
  //'bnf-volatility-breakout-system-levels',
  //'bnf-volatility-breakout-system-levels-v2',
  'mr-intraday-trading-levels',
  //'intraday-nifty-straddle-strangle-levels',
  //'pivot-points-trading-levels',
  'quality-investing-scanner',
  //'intraday-long-only-fno-scanner',
  'lti'
];

class PublicMain extends React.Component {

  render() {
    return (<div>
      <Header public={true}/>
      {this.props.query === 'bnf-volatility-breakout-system-levels' && <BNFLevels />}
      {this.props.query === 'bnf-volatility-breakout-system-levels-v2' && <BNFLevels />}
      {this.props.query === 'mr-intraday-trading-levels' && <MRDT1Levels />}
      {this.props.query === 'intraday-nifty-straddle-strangle-levels' && <OPTSNF1Levels />}
      {/*{this.props.query === 'pivot-points-trading-levels' && <PivotPointsLevels />}*/}
      {this.props.query === 'quality-investing-scanner' && <QualityInvestingScanner />}
      {this.props.query === 'lti' && <LTIScanner />}
      {this.props.query === 'intraday-long-only-fno-scanner' && <IntradayLongOnlyFnoScanner />}
      {_.includes(supportedQueries, this.props.query) === false && <h3>Seems you hit wrong url...</h3>}
    </div>);
  }
}

export default PublicMain;
