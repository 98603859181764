/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Badge,
  Button, 
  Card, 
  CardBody, 
  Table,
  Label,
  Input,
  CardHeader,
  Alert
} from 'reactstrap';

import AlertModal from '../common/AlertModal.js';

import config from '../../config.js';
import HttpRequest from "request";
import { fetchAllStrategies } from "../../utils/RestAPIs.js";

class Strategies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showAlertModal: false,
      strategies: [],
      fetchingDataInProgress: false,
      error: null
    };

    this.fetchAllStrategies = this.fetchAllStrategies.bind(this);
    this.launchAlertModal = this.launchAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
  }

  componentWillMount() {
    this.fetchAllStrategies();
  }

  fetchAllStrategies() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    fetchAllStrategies({publicOnly: true}).then(allStrategies => {
      this.setState({
        strategies: allStrategies || [],
        fetchingDataInProgress: false
      });
    }).catch(err => {
      this.setState({
        strategies: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch all strategies'
      });
    });
  }

  stopUnstopStrategies(stop, strategyName) {
    this.setState({
      updateInProgress: true,
      error: null
    });

    const data = {
      strategies: strategyName ? [strategyName] : _.filter(this.state.strategies, s => s.selected === true).map(s => s.name)
    };

    HttpRequest.put({
      url: config.serverHost + `/apis/clientmanager/${stop ? 'stop' : 'unstop'}/strategies`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('stopUnstopStrategies status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          updateInProgress: false,
          selectedAll: false // make this false in case selected all
        }, () => {
          // fetch all strategies again
          this.fetchAllStrategies();
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'stopUnstopStrategies end point not found',
          updateInProgress: false
        });
      } else {
        console.error('stopUnstopStrategies error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          updateInProgress: false
        });
      }
    });
  }

  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null,

    });
  }

  onStrategyEnableDisableButtonClick(e, strategy = {}) {
    e.preventDefault();
    var data = { 
      strategy: strategy.name
    };

    HttpRequest.put({
      url: config.serverHost + "/apis/clientmanager/" + (strategy.enabled ? "disable" : "enable")  + "/strategy",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      let error = (JSON.parse(respBody) || {}).error;
      console.log('Enable/Disable strategy status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        // refresh all strategy details
        this.fetchAllStrategies();
        this.launchAlertModal('Strategy Status', `Strategy ${strategy.displayName} ${strategy.enabled ? 'disabled' : 'enabled'} successfully`);
      } else if (resp.statusCode === 404) {
        this.launchAlertModal('Strategy Status', `Failed to ${strategy.enabled ? 'disable' : 'enable'} strategy ${strategy.displayName} : ${error}`);
      } else {
        this.launchAlertModal('Strategy Status', `Failed to ${strategy.enabled ? 'disable' : 'enable'} strategy ${strategy.displayName} : ${error}`);
      }
    });
  }

  toggleSelect(strategyName, event) {
    const value = event.target.checked;
    const strategies = [...this.state.strategies];
    _.each(strategies, s => {
      if (s.name === strategyName) {
        s.selected = value || false;
        return false; // breaks the loop
      }
    });
    
    this.setState({
      strategies
    });
  }

  toggleSelectAll(event) {
    const newVal = event.target.checked;
    const strategies = [...this.state.strategies];
    _.each(strategies, s => {
      s.selected = newVal;
    });
    this.setState({
      selectedAll: newVal,
      strategies
    });
  }

  getStrategyDetails(s, sno) {
    if (s.name.startsWith('CAPITAL')) {
      return null;
    }
    return (<tr key={s.name}>
      <td style={{verticalAlign: 'middle', textAlign:"center", "padding-left": '10px'}}>
        <Label check>
          <Input type="checkbox"
            style={{'position': 'relative'}}
            checked={s.selected}
            onChange={(e) => this.toggleSelect(s.name, e)} 
          />
        </Label>
      </td>
      <td>{s.displayName}</td>
      <td>{s.product}</td>
      <td>
        <Badge color={s.enabled === true ? 'success' : 'danger'}>
          {s.enabled === true ? 'Yes' : 'No'}
        </Badge>
      </td>
      <td>
        <Badge color={s.stopped === true ? 'danger' : 'success'}>
          {s.stopped === true ? 'Yes' : 'No'}
        </Badge>
      </td>
      <td>
        <Button 
          color={s.enabled ? 'light' : 'dark'}
          onClick={(e) => this.onStrategyEnableDisableButtonClick(e, s)}>
            {s.enabled ? 'Disable' : 'Enable'}
        </Button>
        <Button 
          color={!s.stopped ? 'light' : 'dark'}
          style={{'margin-left': '15px'}}
          onClick={() => this.stopUnstopStrategies(!s.stopped ? true : false, s.name)}>
            {!s.stopped ? 'Stop' : 'Unstop'}
        </Button>
        </td>
    </tr>);
  }

  render() {
    const selectedCount = _.filter(this.state.strategies, s => s.selected === true).length;
    return (<div>
      <h4>Strategies</h4>
      <Card>
        <CardHeader>
          <Button color='dark'
            style={{'margin-right': '10px'}}
            disabled={!selectedCount}
            onClick={() => this.stopUnstopStrategies(true)}>
              Stop selected
          </Button>
          <Button color='dark'
            disabled={!selectedCount}
            onClick={() => this.stopUnstopStrategies(false)}>
            Unstop selected
          </Button>
        </CardHeader>
        <CardBody>
          {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}
          {this.state.fetchingDataInProgress && <Alert color='secondary'>Fetching in progress</Alert>}
          {this.state.updateInProgress && <Alert color='secondary'>Updating in progress</Alert>}
          <Table className="no-wrap v-middle" size="sm" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0" style={{verticalAlign: 'middle', textAlign:"center", "padding-left": '10px'}}>
                  <Label check>
                    <Input type="checkbox"
                      style={{'position': 'relative'}}
                      checked={this.state.selectedAll === true ? true : false}
                      onChange={(e) => this.toggleSelectAll(e)}
                    />
                  </Label>
                </th>
                <th className="border-0">Strategy</th>
                <th className="border-0">Product</th>
                <th className="border-0">Enabled</th>
                <th className="border-0">Stopped</th>
                <th className="border-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(this.state.strategies, (s, index) => {
                  return this.getStrategyDetails(s, index + 1);
                })
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <AlertModal
        isModalOpen={this.state.showAlertModal}
        title={this.state.alertModalTitle}
        message={this.state.alertModalMessage}
        onCancel={this.closeAlertModal}
      />
    </div>);
  }
}

export default Strategies;
