/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Alert
} from 'reactstrap';

import DatePicker from "react-datepicker";
import { updateUserNote } from '../../utils/RestAPIs.js';
import Utils from "../../utils/Utils.js";

const MAX_NOTE_LENGTH = 10000;

class UserNoteComp extends React.Component {

  constructor(props) {
    super(props);

    this.rangeStartDate = new Date('2020-04-01');
    this.rangeEndDate = new Date();

    this.state = {
      date: props.note.date || null,
      notes: props.note.notes || '',
      validations: {}
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.createOrUpdateUserNote = this.createOrUpdateUserNote.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.userNoteUpdated ? true : false);
    }
  }

  isNewNote() {
    if (!this.props.note.date && !this.props.note.notes) {
      return true;
    }
    return false;
  }

  createOrUpdateUserNote(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      userNoteUpdated: false,
      statusMessage: null,
      error: null
    });

    const data = {
      username: this.props.note.username,
      date: Utils.formatDateToString(new Date(this.state.date)),
      notes: this.state.notes
    };

    updateUserNote(data).then(resp => {
      this.setState({
        statusMessage: resp.status,
        updateInProgress: false,
        userNoteUpdated: true
      });
    }).catch(error => {
      this.setState({
        error,
        updateInProgress: false
      });
    });
  }

  getValidationError(key, value) {
    if (_.isEmpty(value)) {
      return null;
    }

    if (key === 'notes') {
      if (value.length > MAX_NOTE_LENGTH) {
        return `Max ${MAX_NOTE_LENGTH} characters are allowed.`;
      }
    }

    return null;
  }

  handleNotesChange(event) {
    let value = event.target ? event.target.value : '';
  
    const newValidations = {...this.state.validations};
    
    const key = 'notes';
    newValidations[key] = this.getValidationError(key, value);

    this.setState({
      notes: value,
      validations: newValidations,
      statusMessage: null,
      error: null
    });
  }

  getInputField(opts = {}) {
    const validationError = _.isEmpty(opts.value) ? null : this.state.validations[opts.key];
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      {
        opts.type === 'date' ? <DatePicker 
          dateFormat="yyyy-MM-dd"
          minDate={this.rangeStartDate}
          maxDate={this.rangeEndDate}
          selected={opts.value}
          onChange={opts.onChange}
          disabled={opts.disabled} />
        :
        <Input valid={validationError ? false : true}
          className="col-sm-6 col-form-control"
          type={opts.type || 'text'} 
          name="text" 
          id={opts.key} 
          onChange={opts.onChange}
          value={opts.value}
          disabled={opts.disabled}
          placeholder={opts.placeholder}
          rows={opts.type === 'textarea' ? 10 : undefined}>
        </Input>
      }
      {validationError && <div className="text-orange col-sm-6 col-form-control">{validationError}</div>}
    </FormGroup>);
  }

  isFormValid() {
    console.log('date = ' + this.state.date);
    console.log('notes = ' + this.state.notes);
    if (_.isEmpty(this.state.notes) || !this.state.date) {
      return false;
    }
    
    let isValid = true;
    _.each(this.state.validations, (v, key) => {
      if (v) {
        console.log('key = ' + key + ' isValid = false');
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  handleDateChange(date) {
    this.setState({
      date: date
    });
  }

  renderBody() {
    const showAlert = this.state.error || this.state.statusMessage || this.state.updateInProgress;
    const error = this.state.error;

    return (<div>
      {this.getInputField({
        key: 'username',
        label: 'Username',
        disabled: true,
        value: this.props.note.username
      })}
      {this.getInputField({
        key: 'date',
        label: 'Date',
        type: 'date',
        value: this.state.date,
        disabled: !this.isNewNote(),
        onChange: (date) => this.handleDateChange(date)
      })}
      {this.getInputField({
        key: 'notes',
        label: 'Notes',
        type: 'textarea',
        value: this.state.notes,
        onChange: (e) => this.handleNotesChange(e)
      })}
     
      {showAlert && <Alert color={error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {error && <div>{error}</div>}
          {this.state.statusMessage && <div>{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Request is in progress. Please wait...</div>}
        </div>
      </Alert>}
    </div>);
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen} size="lg">
        <ModalHeader>{this.props.note.username + ' -> ' + (this.isNewNote() ? 'New Note' : 'Edit Note')}</ModalHeader>
        
        <ModalBody>
          {this.renderBody()}
        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress || !this.isFormValid()}
            onClick={this.createOrUpdateUserNote}>
              {this.isNewNote() ? 'Create' : 'Update'}
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UserNoteComp;
