
import _ from 'lodash';
import React from 'react';
import {
  Table
} from 'reactstrap';

import Utils from '../../utils/Utils.js';
import config from '../../config.js';

class PerformanceReport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {      
    };
  }

  calculateStatistics() {

    this.strategies = [];
    _.each(this.props.strategies, (s, index) => {
      if (!this.props.selectedStrategy || this.props.selectedStrategy === s) {
        this.strategies.push({
          name: s,
          displayOrder: Utils.getStrategyDisplayOrder(s),
          records: [],
          startCapital: 0,
          endCapital: 0,
          tradeDates: [],
          numOfTradedDays: 0,
          numOfWinDays: 0,
          numOfLossDays: 0,
          maxLossOfADay: 0,
          maxProfitOfADay: 0,
          avgProfitOfProfitDays: 0,
          avgLossOfLossDays: 0,
          totalPnl: 0,
          totalCharges: 0,
          totalNetPnl: 0,
          maxDrawdownPnl: 0,
          currentDrawdownPnl: 0,
          maxDrawdown: 0,
          currentDrawdown: 0
        });
      }
    });

    _.each(this.props.records, record => {
      const strategy = _.find(this.strategies, s => s.name === this.props.getStrategyDisplayName(record));
      if (strategy) {
        strategy.records.push(record);
      }
    });

    _.each(this.strategies, s => {
      let sumOfProfitDays = 0;
      let sumOfLossDays = 0;
      let peakSoFar = 0;
      let cumulativeCapital = 0;
      _.each(s.records, (record, index) => {
        if (index === 0) {
          s.startCapital = record.capital;
          peakSoFar = s.startCapital;
          cumulativeCapital = s.startCapital;
        } else if (index === s.records.length - 1) {
          s.endCapital = Math.floor(record.capital + record.netPL);
        }
        if (_.includes(s.tradeDates, record.dateStr) === false) {
          s.tradeDates.push(record.dateStr);
        }
        if (record.netPL > 0) {
          s.numOfWinDays++;
          sumOfProfitDays += record.netPL;
        } else {
          s.numOfLossDays++;
          sumOfLossDays += record.netPL;
        }
        if (record.netPL > s.maxProfitOfADay) {
          s.maxProfitOfADay = record.netPL;
        }
        if (record.netPL < s.maxLossOfADay) {
          s.maxLossOfADay = record.netPL;
        }
        s.totalPnl += record.pl;
        s.totalCharges += record.charges;
        s.totalNetPnl += record.netPL;

        cumulativeCapital += record.netPL;
        if (cumulativeCapital > peakSoFar) {
          peakSoFar = cumulativeCapital;
        }
        s.currentDrawdownPnl = Utils.roundOff(-1 * (peakSoFar - cumulativeCapital));
        s.currentDrawdown = Utils.roundOff(100 * (peakSoFar - cumulativeCapital) / peakSoFar);
        if (s.currentDrawdown > s.maxDrawdown) {
          s.maxDrawdownPnl = s.currentDrawdownPnl;
          s.maxDrawdown = s.currentDrawdown;
        }
      });

      s.numOfTradedDays = s.tradeDates.length;
      s.avgProfitOfProfitDays = Utils.roundOff(s.numOfWinDays > 0 ? sumOfProfitDays / s.numOfWinDays : 0);
      s.avgLossOfLossDays = Utils.roundOff(s.numOfLossDays > 0 ? sumOfLossDays / s.numOfLossDays : 0);
    });

    if (config.isAllocationModelEnabled()) {
      // show only capital deployed strategy
      this.strategies = _.filter(this.strategies, s => s.name === 'CAPITAL-DEPLOYED');
    }
    this.strategies = this.strategies.sort((s1, s2) => s1.displayOrder - s2.displayOrder);
  }

  getHeaderRow() {
    return (<tr className="border-0">
      <th className="border-0">Strategy</th>
      {
        _.map(this.strategies, s => {
          return (<th key={s.name} className="border-0 number-right">
            {s.name === 'CAPITAL-DEPLOYED' ? 'TOTAL' : Utils.getStrategyDisplayName(s.name)}
          </th>);
        })
      }
    </tr>);
  }

  getRow(fieldName, convertNumToCommaSep, isDecimal = false, displayRedGreen = false, isPercentage = false) {
    return (<tr>
      <td>{_.upperFirst(_.startCase(fieldName))}</td>
      {
        _.map(this.strategies, s => {
          const value = s[fieldName];
          const classNameColor = value > 0 ? "number-right number-pos" : "number-right number-neg";
          return (<td key={s.name} className={displayRedGreen ? classNameColor : "number-right"}>
            {convertNumToCommaSep ? 
              Utils.formatNumberToCommaSeparated(value, isDecimal) + (isPercentage ? ' %' : '')
              : 
              value}
          </td>);
        })
      }
    </tr>);
  }

  render() {

    this.calculateStatistics();

    return (<div>
      {this.strategies && this.strategies.length > 0 && <Table className="v-middle" responsive>
        <thead>
          {!config.isAllocationModelEnabled() && this.getHeaderRow()}
        </thead>
        <tbody>
          {this.getRow('numOfTradedDays')}
          {this.getRow('numOfWinDays')}
          {this.getRow('numOfLossDays')}
          {this.getRow('maxProfitOfADay', true, true, true)}
          {this.getRow('maxLossOfADay', true, true, true)}
          {this.getRow('avgProfitOfProfitDays', true, true, true)}
          {this.getRow('avgLossOfLossDays', true, true, true)}
          {this.getRow('totalPnl', true, true, true)}
          {this.getRow('totalCharges', true, true)}
          {this.getRow('totalNetPnl', true, true, true)}
          {/*this.getRow('maxDrawdown', true, true, false, true)}
          {this.getRow('currentDrawdown', true, true, false, true)}
          {this.getRow('startCapital', true, false)}
          {this.getRow('endCapital', true, false)*/}
        </tbody>
      </Table>}
    </div>);
  }

}

export default PerformanceReport;
