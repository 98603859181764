/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
Form, FormGroup, Label, Input } from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class UpdateBillDetailsAsPaidModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handlePaidAmountChange = this.handlePaidAmountChange.bind(this);
    this.handleTDSChange = this.handleTDSChange.bind(this);
    this.handleOtherDeductionsChange = this.handleOtherDeductionsChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  getInitialState(props) {
    props = props || this.props;
    return {
      paidAmount: 0,
      tds: 0,
      otherDeductions: 0,
      error: null,
      statusMessage: null,
      updateInProgress: false,
      billDetailsUpdated: false
    };
  }

  handlePaidAmountChange(e) {
    this.setState({
      paidAmount: e.target.value,
      error: null
    });
  }

  handleTDSChange(e) {
    this.setState({
      tds: e.target.value,
      error: null
    });
  }

  handleOtherDeductionsChange(e) {
    this.setState({
      otherDeductions: e.target.value,
      error: null
    });
  }

  onCancel() {
    // reset to initial state
    this.setState(this.getInitialState());

    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.billDetailsUpdated);
    }
  }

  updateBillDetailsAsPaid(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true
    });

    var data = { 
      username: this.props.bill.username,
      invoiceNumber: this.props.bill.invoiceNumber,
      paidAmount: this.state.paidAmount,
      tds: this.state.tds,
      otherDeductions: this.state.otherDeductions
    };

    HttpRequest.put({
        url: config.serverHost + "/apis/clientmanager/billing/updatestatus/paid",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('Update bill details status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          this.setState({
            statusMessage: JSON.parse(respBody).status,
            updateInProgress: false,
            billDetailsUpdated: true
          });
        } else if (resp.statusCode === 404) {
          this.setState({
            error: 'Update bill details end point not found',
            updateInProgress: false
          });
        } else {
          console.error('Update bill details error => ', respBody);
          this.setState({
            error: JSON.parse(respBody).error || JSON.parse(respBody).result,
            updateInProgress: false
          });
        }
      });
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen}>
        <ModalHeader>Update Payment Details for {this.props.bill.username}</ModalHeader>
        
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="invoiceNumber">Invoice No.</Label>
              <Input type="text" name="invoiceNumber" id="invoiceNumber" 
                value={this.props.bill.invoiceNumber}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="billingStartDate">Billing Start Date</Label>
              <Input type="text" name="billingStartDate" id="billingStartDate" 
                value={Utils.formatDateToString(new Date(this.props.bill.billingStartDate))}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="billingEndDate">Billing End Date</Label>
              <Input type="text" name="billingEndDate" id="billingEndDate" 
                value={Utils.formatDateToString(new Date(this.props.bill.billingEndDate))}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="totalCostWithGST">Total Cost with GST</Label>
              <Input type="text" name="totalCostWithGST" id="totalCostWithGST" 
                value={this.props.bill.totalCostWithGST}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="totalCost">Total Cost</Label>
              <Input type="text" name="totalCost" id="totalCost" 
                value={this.props.bill.totalCost}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="GST">GST</Label>
              <Input type="text" name="GST" id="GST" 
                value={this.props.bill.GST}
                disabled={true} />
            </FormGroup>
            <FormGroup>
              <Label for="paidAmount">Amount Paid</Label>
              <Input type="number" name="paidAmount" id="paidAmount"
                value={this.state.paidAmount}
                placeholder="Enter Amount Paid"
                disabled={this.state.updateInProgress}
                onChange={this.handlePaidAmountChange} />
            </FormGroup>
            <FormGroup>
              <Label for="tds">TDS</Label>
              <Input type="number" name="tds" id="tds" 
                value={this.state.tds}
                placeholder="Enter TDS"
                disabled={this.state.updateInProgress}
                onChange={this.handleTDSChange} />
            </FormGroup>
            <FormGroup>
              <Label for="otherDeductions">Other Deductions</Label>
              <Input type="number" name="otherDeductions" id="otherDeductions"
                value={this.state.otherDeductions}
                placeholder="Enter Other Deductions"
                onChange={this.handleOtherDeductionsChange} />
            </FormGroup>
          </Form>

          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Updating bill details in progress. Please wait...</div>}

        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress}
            onClick={this.updateBillDetailsAsPaid.bind(this)}>
              Update Payment
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UpdateBillDetailsAsPaidModal;
