/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  Button
} from 'reactstrap';
import BrokerLogin from './BrokerLogin.js';

import imgDefault from '../../assets/images/default.png';
import imgZerodha from '../../assets/images/zerodha.png';
import imgFyers from '../../assets/images/fyers.png';
import img5Paisa from '../../assets/images/5paisa.png';
import imgIIFL from '../../assets/images/iifl.png';

import AlertModal from '../common/AlertModal.js';
import UserBrokerComp from '../common/UserBrokerComp.js';

import HttpRequest from "request";
import config from "../../config.js";

class Brokers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedUserBrokerDetails: null,
      showUserBrokerComp: false
    };

    this.launchUserBrokerComp = this.launchUserBrokerComp.bind(this);
    this.closeUserBrokerComp = this.closeUserBrokerComp.bind(this);
    this.launchAlertModal = this.launchAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
  }

  launchUserBrokerComp(userBrokerDetails) {
    this.setState({
      selectedUserBrokerDetails: userBrokerDetails,
      showUserBrokerComp: true
    });
  }

  closeUserBrokerComp(reload) {
    this.setState({
      selectedUserBrokerDetails: null,
      showUserBrokerComp: false
    }, () => {
      if (reload === true && _.isFunction(this.props.refreshUserDetails)) {
        this.props.refreshUserDetails(); 
      }
    });
  }

  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null
    });
  }

  onAddNewBroker() {
    const newUserBroker = {
      username: this.props.user.username,
      broker: '',
      enabled: true,
      autoLogin: false,
      loginVerified: false
    };
    this.launchUserBrokerComp(newUserBroker); // null for new broker
  }

  testAutoLogin(userBrokerDetails = {}) {

    this.setState({
      testAutoLoginUsername: userBrokerDetails.username,
      testAutoLoginBroker: userBrokerDetails.broker
    });
    const data = {
      username: userBrokerDetails.username,
      broker: userBrokerDetails.broker
    };
    
    HttpRequest.post({
      url: config.serverHost + "/apis/broker/login/auto",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('testAutoLogin status code = ' + resp.statusCode);
      const title = `Test Auto Login : ${userBrokerDetails.username}-${userBrokerDetails.broker}`;
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.launchAlertModal(title, 'Auto Login successful');
      } else if (resp.statusCode === 404) {
        this.launchAlertModal(title, 'Auto Login End point not valid');
      } else {
        console.error('testAutoLogin error => ', respBody);
        const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
        this.launchAlertModal(title, error);
      }
      this.setState({
        testAutoLoginUsername: null,
        testAutoLoginBroker: null
      });
    });
  }

  getBrokerLogo(broker) {
    let brokerLogo = null;
    if (broker === 'zerodha') {
      brokerLogo = imgZerodha;
    } else if (broker === 'fyers') {
      brokerLogo = imgFyers;
    } else if (broker === '5paisa') {
      brokerLogo = img5Paisa;
    } else if (broker === 'iifl') {
      brokerLogo = imgIIFL;
    } else {
      brokerLogo = imgDefault;
    }
    return brokerLogo;
  }

  render() {
    const brokers = (this.props.user || {}).brokers;

    return (<div>
      <h4>Brokers : {(!brokers || brokers.length === 0) && <Button color="primary" 
        onClick={() => this.onAddNewBroker()}>
        SETUP BROKER ACCOUNT
      </Button>}</h4>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <div>
              <CardTitle>Brokers</CardTitle>
              <CardSubtitle>This is the list of brokers configured for your account.</CardSubtitle>
            </div>
          </div>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">Broker</th>
                <th className="border-0">Client ID</th>
                <th className="border-0">API Key</th>
                <th className="border-0">Login Status</th>
                <th className="border-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(brokers, (ub, index) => {
                  const brokerName = (ub.broker || '').toUpperCase();
                  const brokerLogo = this.getBrokerLogo(ub.broker);
                  const testAutoLoingInProgress = this.state.testAutoLoginUsername === ub.username && this.state.testAutoLoginBroker === ub.broker;
                  return (<tr key={"broker-" + index}>
                    <td>
                      <div className="d-flex no-block align-items-center">
                        <div className="mr-2"><img src={brokerLogo} alt="user" width="45" /></div>
                        <div className="">
                          <h5 className="mb-0 font-16 font-medium">{brokerName}</h5></div>
                      </div>
                    </td>
                    <td>{ub.clientID}</td>
                    <td>{ub.appKey}</td>
                    <td><BrokerLogin broker={ub.broker} /></td>
                    <td>
                      <Button 
                        color="primary" 
                        onClick={() => this.launchUserBrokerComp(ub)}>
                          EDIT
                      </Button>
                      {ub.autoLogin === true && <Button 
                        style={{'marginLeft': '5px'}}
                        color="secondary" 
                        disabled={testAutoLoingInProgress}
                        onClick={() => this.testAutoLogin(ub)}>
                          {testAutoLoingInProgress ? 'Testing Auto Login...' : 'Test Auto Login'}
                      </Button>}
                    </td>
                  </tr>);
                })
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {this.state.showUserBrokerComp && <UserBrokerComp 
        userBrokerDetails={this.state.selectedUserBrokerDetails}
        supportedBrokers={this.props.supportedBrokers}
        isModalOpen={this.state.showUserBrokerComp}
        onCancel={this.closeUserBrokerComp}
      />}
      <AlertModal
        isModalOpen={this.state.showAlertModal}
        title={this.state.alertModalTitle}
        message={this.state.alertModalMessage}
        onCancel={this.closeAlertModal}
      />
    </div>);
  }
}

export default Brokers;
