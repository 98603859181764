

import React from 'react';
import HttpRequest from "request";
import config from "../../config.js";
import Utils from "../../utils/Utils.js";

import {
  Card,
  CardBody,
  CardTitle,
  Table
} from 'reactstrap';

class BNFLevels extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      signals: []
    };
  }

  componentWillMount() {
    this.fetchBNFSignals();
  }

  fetchBNFSignals() {
    HttpRequest(config.serverHost + "/apis/public/bnf-golden-ratio-levels", { json: true }, (err, resp, result) => {
      console.log('fetchBNFSignals: resp.statusCode = ' + resp.statusCode);
      if (resp.statusCode === 200) {
        this.setState({
          signals: result
        });
      } else {
        this.setState({
          signals: []
        });
        console.error("fetchBNFSignals: Error. ", err, result);
      }
    });
  }

  getSignalRow(signal = {}, index) {
    return (
      <tr key={"signal-" + index}>
        <td>{index + 1}</td>
        <td>{Utils.formatDateToString(new Date(signal.timestamp))}</td>
        <td>{signal.tradingSymbol}</td>
        <td>{signal.direction}</td>
        <td>{signal.trigger}</td>
        <td>{signal.stopLoss}</td>
        <td>{signal.target}</td>
      </tr>
    );
  }

  render() {
    return (<Card>
      <CardBody>
        <div className="d-flex align-items-center">
          <div>
            <CardTitle>Bank Nifty Volatility Breakout System Levels</CardTitle>
          </div>
        </div>
        <Table className="no-wrap v-middle" responsive>
          <thead>
            <tr className="border-0">
              <th className="border-0">SNo</th>
              <th className="border-0">Date</th>
              <th className="border-0">Symbol</th>
              <th className="border-0">Direction</th>
              <th className="border-0">Breakout Level</th>
              <th className="border-0">Stoploss</th>
              <th className="border-0">Target</th>
            </tr>
          </thead>
          <tbody>
            {              
              this.state.signals.map((signal, index) => {
                return this.getSignalRow(signal, index);
              })
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }
}

export default BNFLevels;
