/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";
import TradeRow from "./TradeRow.js";
import Utils from "../../utils/Utils.js";
import config from '../../config.js';

import TradeAlterModal from '../common/TradeAlterModal.js';
import SquareOffModal from '../common/SquareOffModal.js';

import {
  Card,
  CardBody,
  //CardTitle,
  Table,
  Button
} from 'reactstrap';

class ActiveTrades extends React.Component {

  constructor(props) {
    super(props);

    this.cols = [{
      name: 'SNo',
      key: 'sno'
    }, {
      name: 'Trade Start',
      key: 'startTimestamp',
      type: 'timestamp'
    }, {
      name: 'Strategy',
      key: 'strategy'
    }, {
      name: 'Product',
      key: 'productType'
    }, {
      name: 'Group',
      key: 'group'
    }, {
      name: 'Symbol',
      key: 'tradingSymbol'
    }, {
      name: 'Direction',
      key: 'direction'
    }, {
      name: 'Qty',
      key: 'quantity'
    }, {
      name: 'Entry',
      key: 'entry',
      alignRight: true
    }, {
      name: 'CMP',
      key: 'cmp',
      alignRight: true
    }, {
      name: 'P/L',
      key: 'profitLoss',
      type: 'plus-minus',
      alignRight: true
    }, {
      name: 'Charges',
      key: 'charges',
      alignRight: true
    }, {
      name: 'Net P/L',
      key: 'netProfitLoss',
      type: 'plus-minus',
      alignRight: true
    }, {
      name: 'P/L %',
      key: 'plPercentage',
      type: 'plus-minus',
      alignRight: true
    }, {
      name: 'TradeID',
      key: 'tradeID'
    }, {
      name: 'StopLoss',
      key: 'stopLoss',
      alignRight: true
    }, {
      name: 'Target',
      key: 'target',
      alignRight: true
    }, {
      name: 'Req Entry',
      key: 'requestedEntry',
      alignRight: true
    }, {
      name: 'Remarks',
      key: 'remarks'
    }, {
      name: 'Original Order',
      key: 'order',
      getValue: (trade) => {
        return Utils.getOrderDetailsDisplayValue(trade.order);
      }
    }, {
      name: 'SL Order',
      key: 'slOrder',
      getValue: (trade) => {
        return Utils.getOrderDetailsDisplayValue(trade.slOrder);
      }
    }, {
      name: 'Target Order',
      key: 'targetOrder',
      getValue: (trade) => {
        return Utils.getOrderDetailsDisplayValue(trade.targetOrder);
      }
    }];

    if (!config.isProXtremeQuant() && this.props.showLess) {
      this.cols = _.filter(this.cols, col => {
        if (col.key === 'stopLoss' || col.key === 'target'
          || col.key === 'requestedEntry' || col.key === 'exitReason'
          || col.key === 'remarks' || col.key === 'order'
          || col.key === 'slOrder' || col.key === 'targetOrder') {
          return false;
        }
        return true;
      });
    }

    if (props.allowAlterTrades === true) {
      this.cols.push({
        name: 'Actions',
        key: 'actions',
        getValue: (trade) => {
          return (<Button color="secondary" onClick={() => this.launchTradeAlterModal(trade)}>Set As Complete</Button>);
        }
      });
      this.cols.push({
        name: 'SquareOff',
        key: 'squareoff',
        getValue: (trade) => {
          return (<Button color="warning" onClick={() => this.launchSquareOffModal(trade)}>Square Off</Button>);
        }
      });
    }

    this.state = {
      showTradeAlterModal: false,
      selectedTrade: null
    };
  }

  launchTradeAlterModal(trade = {}) {
    this.setState({
      showTradeAlterModal: true,
      selectedTrade: trade
    });
  }

  closeTradeAlterModal() {
    this.setState({
      showTradeAlterModal: false,
      selectedTrade: null
    }); 
  }

  launchSquareOffModal(trade) {
    this.setState({
      showSquareOffModal: true,
      selectedTrade: trade
    });
  }

  closeSquareOffModal() {
    this.setState({
      showSquareOffModal: false,
      selectedTrade: null
    });
  }

  render() {
    let trades = Utils.sortTrades(this.props.trades);
    let summary = Utils.calculateTradesSummary(trades);

    let prevStrategy = '', prevHedgeCorrelationID = '';
    let color = true;
    trades = _.map(trades, (trade, index) => {
      let separator = false;
      if (trade.strategy !== prevStrategy) {
        prevStrategy = trade.strategy;
        prevHedgeCorrelationID = trade.hedgeCorrelationID;
        color = !color;
        separator = true;
      } else if (trade.hedgeCorrelationID !== prevHedgeCorrelationID) {
        prevHedgeCorrelationID = trade.hedgeCorrelationID;
        color = !color;
      }
      return {
        ...trade,
        color,
        separator,
        sno: index + 1
      }
    });

    return (<div>
      <Card>
        <CardBody>
          {/*<div className="d-flex align-items-center">
            <div>
              <CardTitle>Active Trades</CardTitle>
            </div>
          </div>*/}
          <div style={this.props.fixHeight ? { maxHeight: "calc(100vh - 300px)", overflowY: "auto" } : {}}>
            <Table className="no-wrap v-middle" size="sm" responsive>
              <thead style={{ position: "sticky", top: "0" }}>
                <tr className="border-0">
                  {
                    this.cols.map((col, index) => {
                      return (<th className={col.alignRight ? "border-0 number-right" : "border-0"} key={index}>{col.name}</th>);
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  trades.map(td => {
                    return (
                      <TradeRow 
                        cols={this.cols}
                        trade={td}
                        key={"trade" + td.sno} />
                    );
                  })
                }
                {!this.props.showLess && <tr>
                  <td colSpan="10">TOTAL PROFIT/LOSS</td>
                  <td className={summary.totalPL > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.totalPL, true)}</td>
                  <td className="number-right">{Utils.formatNumberToCommaSeparated(summary.totalCharges, true)}</td>
                  <td className={summary.totalNetPL > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.totalNetPL, true)}</td>
                  <td>{' '}</td>
                  <td>{' '}</td>
                </tr>}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      {this.state.showTradeAlterModal && <TradeAlterModal
        isModalOpen={this.state.showTradeAlterModal}
        trade={this.state.selectedTrade}
        onCancel={this.closeTradeAlterModal.bind(this)}
        allowedAlterOperations="completeTrade"
      />}

      {this.state.showSquareOffModal && <SquareOffModal
        isModalOpen={this.state.showSquareOffModal}
        onCancel={this.closeSquareOffModal.bind(this)}
        trade={this.state.selectedTrade}
      />}

    </div>);
  }
}

export default ActiveTrades;

