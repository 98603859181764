/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Alert
} from 'reactstrap';

import { changeUserPassword } from "../../utils/RestAPIs.js";

class ChangeUserPasswordComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      validations: {}
    };

    this.changeUserPassword = this.changeUserPassword.bind(this);
  }

  componentWillMount() {
    this.setState({
      data: this.prepareDataFromProps()
    });
  }

  prepareDataFromProps(props) {
    props = props || this.props;
    const data = {
      username: props.username
    };
    return data;
  }

  changeUserPassword(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      statusMessage: null,
      error: null
    });

    const data = {
      username: _.get(this.state.data, 'username'),
      password: _.get(this.state.data, 'password'),
      newPassword: _.get(this.state.data, 'new_password')
    };

    changeUserPassword(data).then(resp => {
      this.setState({
        statusMessage: resp.status,
        updateInProgress: false
      });
    }).catch(err => {
      this.setState({
        error: err,
        updateInProgress: false
      });
    });
  }

  getValidationError(key, value) {
    if (_.isEmpty(value)) {
      return null;
    }

    if (key === 'new_password') {
      if (value.length < 8) {
        return 'Password should be of minimum 8 characters';
      }
      if (_.isEmpty(_.get(this.state.data, 'retype_new_password')) === false 
        && value !== _.get(this.state.data, 'retype_new_password')) {
        return 'New password and retype new password does not match';
      }
    }

    if (key === 'retype_new_password') {
      if (value !== _.get(this.state.data, 'new_password')) {
        return 'New password and retype new password does not match';
      }
    }

    return null;
  }

  onFieldChange(key, event) {
    let value = event.target ? event.target.value : '';
    // console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const newValidations = {...this.state.validations};
    if (key === 'new_password' || key === 'retype_new_password') {
      newValidations['new_password'] = null;
      newValidations['retype_new_password'] = null;  
    }

    const data = {...this.state.data};
    _.set(data, key, value);
    
    newValidations[key] = this.getValidationError(key, value);

    this.setState({
      data,
      validations: newValidations,
      statusMessage: null,
      error: null
    });
  }

  getInputField(opts = {}) {
    const validationError = _.isEmpty(opts.value) ? null : this.state.validations[opts.key];
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input valid={validationError ? false : true}
        className="col-sm-6 col-form-control"
        type={opts.type || 'text'} 
        name="text" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
      </Input>
      {validationError && <div className="col-sm-6 text-orange">{validationError}</div>}
    </FormGroup>);
  }

  isFormValid() {
    if (_.isEmpty(_.get(this.state.data, 'password'))
      || _.isEmpty(_.get(this.state.data, 'new_password'))
      || _.isEmpty(_.get(this.state.data, 'retype_new_password'))) {
      return false;
    }

    let isValid = true;
    _.each(this.state.validations, (v, key) => {
      if (v) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  renderBody(validationError) {
    const showAlert = this.state.error || this.state.statusMessage || this.state.updateInProgress;
    const error = this.state.error;

    return (<div>
      {this.getInputField({
        key: 'username',
        label: 'Username',
        disabled: true,
        value: _.get(this.state.data, 'username'),
        onChange: (e) => this.onFieldChange('username', e)
      })}
      {this.getInputField({
        key: 'password',
        label: 'Current Password',
        value: _.get(this.state.data, 'password'),
        onChange: (e) => this.onFieldChange('password', e)
      })}
      {this.getInputField({
        key: 'new_password',
        label: 'New Password',
        value: _.get(this.state.data, 'new_password'),
        onChange: (e) => this.onFieldChange('new_password', e)
      })}
      {this.getInputField({
        key: 'retype_new_password',
        label: 'Retype New Password',
        value: _.get(this.state.data, 'retype_new_password'),
        onChange: (e) => this.onFieldChange('retype_new_password', e)
      })}
      
      {showAlert && <Alert color={error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {error && <div>{error}</div>}
          {this.state.statusMessage && <div>{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Request is in progress. Please wait...</div>}
        </div>
      </Alert>}
    </div>);
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen} size="lg">
        <ModalHeader>Change Password</ModalHeader>
        
        <ModalBody>
          {this.renderBody()}
        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress || !this.isFormValid()}
            onClick={this.changeUserPassword}>
              Change
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.props.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default ChangeUserPasswordComp;
