/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button,
  Alert
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

class SquareOffModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      squareOffInProgress: false
    }

    this.onCancel = this.onCancel.bind(this);
    this.squareOff = this.squareOff.bind(this);
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  squareOff(e) {
    e.preventDefault();

    this.setState({
      squareOffInProgress: true,
      error: null,
      statusMessage: null
    });

    var data = {
      tradeID: this.props.trade ? this.props.trade.tradeID : undefined,
      username: this.props.trade ? this.props.trade.username : this.props.username, 
      broker: this.props.trade ? this.props.trade.broker : this.props.broker,
      strategy: this.props.trade ? this.props.trade.strategy : this.props.strategy || 'ALL'
    };

    const product = this.props.trade ? this.props.trade.product : this.props.product || 'ALL';
    HttpRequest.post({
        url: config.serverHost + "/apis/trades/squareoff/" + _.toLower(product),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('Square Off Trades status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          this.setState({
            statusMessage: JSON.parse(respBody).status,
            squareOffInProgress: false
          });
        } else if (resp.statusCode === 404) {
          this.setState({
            error: 'Square Off Trades end point not found',
            squareOffInProgress: false
          });
        } else {
          console.error('Square Off Trades error => ', respBody);
          this.setState({
            error: JSON.parse(respBody).error || JSON.parse(respBody).result,
            squareOffInProgress: false
          });
        }
      });
  }

  render() {

    const strategyStr = this.props.strategy || 'ALL strategies';
    const tradeStr = this.props.trade ? `${this.props.trade.tradingSymbol}-${this.props.trade.strategy}-${this.props.trade.group}` : null;

    return (<div>
      <Modal isOpen={this.props.isModalOpen}>
        <ModalHeader>{(this.props.trade ? this.props.trade.product : this.props.product || 'All') + " - Square Off Trade/s"}</ModalHeader>
        
        <ModalBody>
          {!this.props.trade && <div>Are you sure you want to square off all trades of {strategyStr} for <strong>{this.props.username + '-' + _.toUpper(this.props.broker)}</strong> ?</div>}
          {this.props.trade && <div>Are you sure you want to square off {tradeStr} for <strong>{this.props.trade.username + '-' + _.toUpper(this.props.trade.broker)}</strong> ?</div>}
          <div className="status-panel">
            {this.state.error && <Alert className="text-orange">{this.state.error}</Alert>}
            {this.state.statusMessage && <Alert className="text-success">{this.state.statusMessage}</Alert>}
            {this.state.squareOffInProgress && <Alert>Squaring off trade/s in progress. Please wait...</Alert>}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button 
            color="secondary" 
            disabled={this.state.squareOffInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
          <Button 
            color="primary" 
            disabled={this.state.squareOffInProgress}
            onClick={this.squareOff}>
            Square Off
          </Button>
        </ModalFooter>

      </Modal>
    </div>);
  }
}

export default SquareOffModal;