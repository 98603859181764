/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
Form, FormGroup, Label, Input } from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

class UpdatePasswordModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.toggleAutoGeneratePassword = this.toggleAutoGeneratePassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  getInitialState() {
    return {
      newPassword: '',
      confirmPassword: '',
      autoGeneratePassword: false,
      error: null,
      statusMessage: null,
      updateInProgress: false
    };
  }

  toggleAutoGeneratePassword(e) {
    const newVal = e.target.checked ? true : false;
    this.setState({
      autoGeneratePassword: newVal,
      error: null
    });
  }

  handleNewPasswordChange(e) {
    this.setState({
      newPassword: e.target.value,
      error: null
    });
  }

  handleConfirmPasswordChange(e) {
    this.setState({
      confirmPassword: e.target.value,
      error: null
    });
  }

  onCancel() {
    // reset to initial state
    this.setState(this.getInitialState());

    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  updatePassword(e) {
    e.preventDefault();

    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        error: 'Password and confirm password do not match'
      });
      return;
    }

    this.setState({
      updateInProgress: true
    });

    var data = { 
      username: this.props.username, 
      broker: this.props.broker,
      password: this.state.newPassword,
      autoGeneratePassword: this.state.autoGeneratePassword
    };

    HttpRequest.post({
        url: config.serverHost + "/apis/broker/password",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('Update password status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          this.setState({
            statusMessage: JSON.parse(respBody).status,
            updateInProgress: false
          });
        } else if (resp.statusCode === 404) {
          this.setState({
            error: 'Update password end point not found',
            updateInProgress: false
          });
        } else {
          console.error('Update password error => ', respBody);
          this.setState({
            error: JSON.parse(respBody).error || JSON.parse(respBody).result,
            updateInProgress: false
          });
        }
      });
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen}>
        <ModalHeader>Update Password for {this.props.username + '-' + _.toUpper(this.props.broker) + '-' + this.props.clientID}</ModalHeader>
        
        <ModalBody>
          <Form>
            <FormGroup check style={{'margin-bottom': '20px', 'font-size': 'large'}}>
              <Label check>
                <Input type="checkbox" onChange={this.toggleAutoGeneratePassword} />{' '}
                Auto generate password ?
              </Label>
            </FormGroup>
            {!this.state.autoGeneratePassword && <FormGroup>
              <Label for="newPassword">New Password</Label>
              <Input type="password" name="newPassword" id="newPassword" 
                placeholder="Enter new password"
                disabled={this.state.updateInProgress}
                onChange={this.handleNewPasswordChange} />
            </FormGroup>}
            {!this.state.autoGeneratePassword && <FormGroup>
              <Label for="confirmPassword">Re-enter Password</Label>
              <Input type="password" name="confirmPassword" id="confirmPassword" 
                placeholder="Re-enter new password"
                disabled={this.state.updateInProgress}
                onChange={this.handleConfirmPasswordChange} />
            </FormGroup>}
          </Form>

          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Updating password in progress. Please wait...</div>}

        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress}
            onClick={this.updatePassword}>
              Update Password
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UpdatePasswordModal;