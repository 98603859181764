/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button,
  Alert,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import HttpRequest from "request";
import Utils from "../../utils/Utils.js";
import config from "../../config.js";

const allAlterOperations = [
  { value: 'completeTrade', label: 'Set Trade As Complete' },
  { value: 'alterExitPrice', label: 'Change Exit Price' },
  { value: 'resetTrade', label: 'Reset Trade' }
];

class TradeAlterModal extends React.Component {

  constructor(props) {
    super(props);

    const allowedAlterOperations = _.split(this.props.allowedAlterOperations, ',');
    this.alterOperations = _.filter(allAlterOperations, op => {
      return _.includes(allowedAlterOperations, op.value);
    });

    this.state = {
      data: {
        operation: this.alterOperations.length > 0 ? this.alterOperations[0].value : '',
        exitPrice: props.trade.cmp,
        exitDate: Utils.formatDateToString(new Date())
      },
      alterTradeInProgress: false,
      statusMessage: null,
      error: null
    }

    this.alterTrade = this.alterTrade.bind(this);
    this.onCancel = this.onCancel.bind(this); 
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  alterTrade() {
    this.setState({
      alterTradeInProgress: true
    });

    const trade = this.props.trade || {};
    const data = {
      username: trade.username,
      broker: trade.broker,
      tradeID: trade.tradeID,
      operation: _.get(this.state.data, 'operation'),
      exitPrice: _.get(this.state.data, 'exitPrice'),
      exitDate: _.get(this.state.data, 'exitDate')
    };

    HttpRequest.post({
      url: config.serverHost + "/apis/trades/alter",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('alterTrade status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: JSON.parse(respBody).result,
          alterTradeInProgress: false
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'alterTrade end point not found',
          alterTradeInProgress: false
        });
      } else {
        console.error('alterTrade error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          alterTradeInProgress: false
        });
      }
    });
  }

  onFieldChange(key, event) {
    const value = event.target ? event.target.value : '';
    console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const data = {...this.state.data};
    _.set(data, key, value);

    this.setState({
      data,
      statusMessage: null,
      error: null
    });
  }

  getInputField(opts = {}) {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type={opts.type || 'text'} 
        name="text" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        placeholder={opts.placeholder}>
      </Input>
    </FormGroup>);
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  renderBody() {
    const showAlert = this.state.error || this.state.statusMessage || this.state.alterTradeInProgress;

    return (<div>
      {this.getSelectField({
        key: 'operation',
        label: 'Operation',
        value: _.get(this.state.data, 'operation'),
        options: this.alterOperations,
        placeholder: 'Select operation',
        onChange: (e) => this.onFieldChange('operation', e)
      })}
      {this.getInputField({
        key: 'exitPrice',
        label: 'Exit Price',
        value: _.get(this.state.data, 'exitPrice'),
        onChange: (e) => this.onFieldChange('exitPrice', e)
      })}
      {this.getInputField({
        key: 'exitDate',
        label: 'Exit Date',
        value: _.get(this.state.data, 'exitDate'),
        onChange: (e) => this.onFieldChange('exitDate', e)
      })}
      
      {showAlert && <Alert color={this.state.error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.alterTradeInProgress && <div>Altering trade is in progress. Please wait...</div>}
        </div>
      </Alert>}
    </div>);
  }

  render() {
    const trade = this.props.trade || {};
    return (<div>
      <Modal 
        size="md"
      
        isOpen={this.props.isModalOpen}
        >
        <ModalHeader>Alter trade : {trade.username + '-' + _.toUpper(trade.broker) + '-' + trade.strategy + '-' + trade.tradingSymbol + '-' + trade.direction}</ModalHeader>
        
        <ModalBody>
          {this.renderBody()}
        </ModalBody>

        <ModalFooter>
          <Button 
            color="primary" 
            disabled={false}
            onClick={this.alterTrade}>
              Alter Trade
          </Button>
          <Button 
            color="secondary" 
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>

      </Modal>
    </div>);
  }
}

export default TradeAlterModal;
