
import _ from 'lodash';
import React from "react";
import StrategiesByBroker from './StrategiesByBroker.js';

import { TabContent, TabPane, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import classnames from 'classnames';

import { fetchAllStrategies, getUserDetails } from "../../utils/RestAPIs.js";

class Strategies extends React.Component {

  constructor(props) {
    super(props);

    this.brokers = _.map((this.props.user || {}).brokers, b => b.broker);

    this.state = {
      activeBroker: _.isEmpty(props.broker) === false ? props.broker : this.brokers[0],
      user: {},
      strategies: []
    };

    this.fetchUserDetails = this.fetchUserDetails.bind(this);
  }

  componentWillMount() {
    if (this.props.serverConfig.isPrepaid === true) {
      this.fetchUserDetails();
    } else {
      this.fetchAllStrategies().then(resp => {
        this.fetchUserDetails();
      });    
    }
  }

  fetchAllStrategies() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    return fetchAllStrategies({publicOnly: true}).then(strategies => {
      console.log('fetchAllStrategies => ', strategies);
      this.setState({
        strategies: strategies || [],
        fetchingDataInProgress: false
      });
      return strategies;
    }).catch(err => {
      this.setState({
        strategies: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch strategies'
      });
      return err;
    });
  }

  fetchUserDetails() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    getUserDetails().then(user => {
      this.setState({
        user,
        fetchingDataInProgress: false
      });
    }).catch(err => {
      this.setState({
        fetchingDataInProgress: false,
        error: err
      });
    });
  }

  getUserStrategies() {
    return _.filter(this.state.user.strategies, us => us.broker === this.state.activeBroker)
  }

  toggle(broker) {
    if (broker === this.state.activeBroker) {
      return;
    }
    console.log('toggle broker => ' + broker);
    this.setState({
      activeBroker: broker
    });
  }

  render() {
    return (<div>
      <h4>Strategies</h4>
      <Nav tabs>
        {
          _.map(this.brokers, broker => {
            return (<NavItem key={broker}>
              <NavLink
                className={classnames({ active: this.state.activeBroker === broker })}
                onClick={() => { this.toggle(broker); }}
              >
                {_.toUpper(broker)}
              </NavLink>
            </NavItem>);
          })
        }
      </Nav>
      <h3><Badge color="warning">{this.state.error}</Badge></h3>
      <TabContent activeTab={this.state.activeBroker}>
        <TabPane tabId={this.state.activeBroker}>
          {this.state.user && <StrategiesByBroker 
            broker={this.state.activeBroker}
            username={this.state.user.username}
            userStrategies={this.getUserStrategies()}
            allStrategies={this.state.strategies}
            serverConfig={this.props.serverConfig}
            fetchUserDetails={this.fetchUserDetails} />
          }
        </TabPane>
      </TabContent>
    </div>);    
  }
}

export default Strategies;
