
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import indexRoutes from './routes/index.jsx';
//import { Router, Route, Switch, browserHistory  } from 'react-router';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import './assets/scss/style.css';
import './assets/scss/app-style.css';

import url from "url";
import config from "./config.js";
import history from './history.js';
import HttpRequest from "request";
import { 
  fetchServerConfig,
  getSystemConfig,
  getUserPlans,
  fetchAllStrategies 
} from './utils/RestAPIs.js';
import Utils from './utils/Utils.js';
import PublicMain from "./components/public/PublicMain.js";

const req = url.parse(window.location.href, false);
var serverHost = req.protocol + '//' + req.host;
config.updateServerHost(serverHost);
const queryString = req.query || '';

console.log('req pathname = ', req.pathname);
console.log('query params = ' + queryString);

const queryObj = url.parse(window.location.href, true).query;

if (req.pathname === '/public' || req.pathname === '/public/') {
  ReactDOM.render(<div>
    <PublicMain query={queryObj.query} />
  </div>, document.getElementById('root'));

} else { // private section

  const isClientManagerUI = req.pathname === '/clientmanager' || req.pathname === '/clientmanager/';
  let user = null, serverConfig = {}, supportedBrokers = [];
  let userPlans = [];

  const sortUserBrokers = (user = {}) => {
    let sortedBrokers = [];
    const zerodhaBroker = _.find(user.brokers, b => b.broker === 'zerodha');
    const otherBrokers = _.filter(user.brokers, b => b.broker !== 'zerodha');
    if (zerodhaBroker) {
      sortedBrokers.push(zerodhaBroker);
    }
    sortedBrokers = _.concat(sortedBrokers, otherBrokers);
    user.brokers = sortedBrokers;
    return user;
  };

  const fetchSystemConfigAndRender = () => {
    getSystemConfig().then(systemConfig => {
      console.log('systemConfig => ', systemConfig);
      supportedBrokers = systemConfig.supportedBrokers || [];
      render();
    }).catch(err => {
      console.error('Failed to fetch system config. ', err);
    });
  };

  const checkAuthenticated = () => {

    fetchAllStrategies().then(strategies => {
      Utils.updateStrategyToDetailsMappings(strategies);
    });

    fetchServerConfig().then(sConfig => {
      serverConfig = sConfig;
      console.log('serverConfig => ', serverConfig);
      HttpRequest(config.serverHost + "/authentication/status", { json: true }, (err, resp, data) => {
        if (err) {
          console.error('Not authenticated: ', err);
        }
        if (data && data.isAuthenticated) {
          user = sortUserBrokers(data.user);
          console.log('Authenticate: user: ', data.user);

          if (serverConfig.isPrepaid) {
            // fetch user plans
            getUserPlans().then(uPlans => {
              userPlans = uPlans;
              fetchSystemConfigAndRender();
            }).catch(err => {
              console.error('Failed to fetch user plans. ', err);
            });
          } else {
            fetchSystemConfigAndRender();
          }
        } else {
          render();
        }
      });
    }).catch(err => {
      console.error('Failed to fetch server config.');
    });
  };
  // check if user is authenticated (using browser cookies)
  checkAuthenticated();

  const onLoginSuccess = (userDetails) => {
    console.log('onLoginSuccess callback called');
    fetchAllStrategies().then(strategies => {
      Utils.updateStrategyToDetailsMappings(strategies);
    });
    
    user = sortUserBrokers(userDetails);
    if (serverConfig.isPrepaid) {
      // fetch user plans
      getUserPlans().then(uPlans => {
        userPlans = uPlans;
        fetchSystemConfigAndRender();
      }).catch(err => {
        console.error('Failed to fetch user plans. ', err);
      });
    } else {
      render();
      //fetchSystemConfigAndRender();
    }
  };

  const onLogoutSuccess = () => {
    console.log('onLogoutSuccess callback called');
    user = null;
    history.push(isClientManagerUI ? '/clientmanager' : '/');
    window.location.reload();
    //render();
  };

  const refreshUserDetails = () => {
    HttpRequest(config.serverHost + "/apis/user", { json: true }, (err, resp, data) => {
      if (err) {
        console.log('refreshUserDetails: error = ', err);
        return;
      }
      if (resp) {
        console.log('refreshUserDetails: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          const userDetails = data || {};
          user = sortUserBrokers(userDetails);
          fetchSystemConfigAndRender();
        } else {
          console.error('refreshUserDetails: ' + (data.error || 'Failed to fetch user details'));
        }
      }
    });
  };

  const getRouter = () => {
    return (<Router>
      <Switch>
        {indexRoutes.map((prop, key) => {
          const Comp = prop.component;
          return (<Route 
            path={prop.path} 
            key={key} 
            render={props => <Comp {...props} user={user} serverConfig={serverConfig} userPlans={userPlans}
              onLoginSuccess={onLoginSuccess} 
              onLogoutSuccess={onLogoutSuccess}
              refreshUserDetails={refreshUserDetails}
              supportedBrokers={supportedBrokers}
            />}
          />);
        })}
      </Switch>
    </Router>);
  };
  
  const render = () => {
    ReactDOM.render(getRouter(), document.getElementById("root"));
  };

  render();

}
