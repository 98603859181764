/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Table
} from 'reactstrap';

import HttpRequest from "request";
import Utils from "../../utils/Utils.js";
import config from "../../config.js";

const allAlterOperations = [
  { value: 'completeTradeBulk', label: 'Set Trades As Complete' },
  { value: 'alterExitPriceBulk', label: 'Change Exit Price' },
  { value: 'resetTradeBulk', label: 'Reset Trades' }
];

class AlterTradesBulkModal extends React.Component {

  constructor(props) {
    super(props);

    const allowedAlterOperations = _.split(props.allowedAlterOperations, ',');
    this.alterOperations = _.filter(allAlterOperations, op => {
      return _.includes(allowedAlterOperations, op.value);
    });

    this.state = {
      operation: this.alterOperations.length > 0 ? this.alterOperations[0].value : '',
      broker: null,
      strategy: null,
      tradingSymbol: null,
      trades: [],
      selectedAll: false,
      alterTradesInProgress: false,
      error: null
    }

    this.brokers = ['ALL'];
    this.strategies = ['ALL'];
    this.tradingSymbols = ['ALL'];
    _.each(props.trades, t => {
      if (_.includes(this.brokers, t.broker) === false) {
        this.brokers.push(t.broker);
      }
      if (_.includes(this.strategies, t.strategy) === false) {
        this.strategies.push(t.strategy);
      }
      if (_.includes(this.tradingSymbols, t.tradingSymbol) === false) {
        this.tradingSymbols.push(t.tradingSymbol);
      }
    });
    console.log('All brokers    => ', this.brokers);
    console.log('All strategies => ', this.strategies);
    console.log('All symbols    => ', this.tradingSymbols);

    this.alterAllTrades = this.alterAllTrades.bind(this);
    this.onCancel = this.onCancel.bind(this); 

    this.handleOperationChange = this.handleOperationChange.bind(this);
    this.handleExitPriceChange = this.handleExitPriceChange.bind(this);
    this.handleExitDateChange = this.handleExitDateChange.bind(this);

    this.handleBrokerChange = this.handleBrokerChange.bind(this);
    this.handleStrategyChange = this.handleStrategyChange.bind(this);
    this.handleSymbolChange = this.handleSymbolChange.bind(this);
  }

  componentWillMount() {
    this.setState({
      trades: this.filterTradesByAll()
    });
  }

  filterTradesByAll() {
    // apply all filters
    let trades = this.filterTradesByOperation(this.props.trades);
    trades = this.filterTradesByBroker(trades);
    trades = this.filterTradesByStrategy(trades);
    trades = this.filterTradesByTradingSymbol(trades);
    console.log('filterTradesByAll: trades => ', trades);
    return trades;
  }

  filterTradesByOperation(trades = []) {
    console.log('Applying filter operation = ' + this.state.operation);
    trades = _.filter(trades, t => {
      if (this.state.operation === 'completeTradeBulk') {
        // consider only active trades
        if (!t.isActive) {
          return false;
        }
      } else if (this.state.operation === 'alterExitPriceBulk') {
        // consider completed trades only and not cancelled
        if (t.isActive || t.isCancelled) {
          return false;
        }
      }
      return true;
    }).map(t => {
      return {
        selected: false,
        tradeID: t.tradeID,
        username: t.username,
        broker: t.broker,
        strategy: t.strategy,
        tradingSymbol: t.tradingSymbol,
        exitPrice: t.exit > 0 ? t.exit : t.cmp,
        exitDate: t.endTimestamp == null ? Utils.formatDateToString(new Date()) : Utils.formatDateToString(new Date(t.endTimestamp)),
        alterStatus: null
      };
    });
    
    return trades;
  }

  filterTradesByBroker(trades = []) {
    console.log('Applying filter broker = ' + this.state.broker);
    if (this.state.broker && this.state.broker !== 'ALL') {
      return _.filter(trades, t => t.broker === this.state.broker);
    }
    return trades;
  }

  filterTradesByStrategy(trades = []) {
    console.log('Applying filter strategy = ' + this.state.strategy);
    if (this.state.strategy && this.state.strategy !== 'ALL') {
      return _.filter(trades, t => t.strategy === this.state.strategy);
    }
    return trades;
  }

  filterTradesByTradingSymbol(trades = []) {
    console.log('Applying filter tradingSymbol = ' + this.state.tradingSymbol);
    if (this.state.tradingSymbol && this.state.tradingSymbol !== 'ALL') {
      return _.filter(trades, t => t.tradingSymbol === this.state.tradingSymbol);
    }
    return trades;
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  alterAllTrades() {
    this.setState({
      alterTradesInProgress: true
    });

    const data = {
      operation: this.state.operation,
      trades: _.filter(this.state.trades, t => t.selected === true)
    };

    HttpRequest.post({
      url: config.serverHost + "/apis/trades/alter",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('alterTradeBulk status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        const statusArray = JSON.parse(respBody);
        const trades = [...this.state.trades];
        let counter = 0;
        _.each(trades, t => {
          if (!t.selected) {
            return;
          }
          t.alterStatus = statusArray[counter++];
        });
        this.setState({
          alterTradesInProgress: false,
          trades
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'alterTradeBulk end point not found',
          alterTradesInProgress: false
        });
      } else {
        console.error('alterTradeBulk error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          alterTradesInProgress: false
        });
      }
    });
  }

  handleOperationChange(event) {
    const operation = event.target.value;
    this.setState({
      operation: operation
    }, () => {
      this.setState({
        trades: this.filterTradesByAll()
      });
    });
  }

  handleBrokerChange(event) {
    const broker = event.target.value;
    this.setState({
      broker: broker
    }, () => {
      this.setState({
        trades: this.filterTradesByAll()
      });
    });
  }

  handleStrategyChange(event) {
    const strategy = event.target.value;
    this.setState({
      strategy: strategy
    }, () => {
      this.setState({
        trades: this.filterTradesByAll()
      });
    });
  }

  handleSymbolChange(event) {
    const tradingSymbol = event.target.value;
    this.setState({
      tradingSymbol: tradingSymbol
    }, () => {
      this.setState({
        trades: this.filterTradesByAll()
      });
    });
  }

  handleExitPriceChange(tradeID, event) {
    const value = event.target.value;
    const trades = [...this.state.trades];
    _.each(trades, t => {
      if (t.tradeID === tradeID) {
        t.exitPrice = _.toNumber(value);
        return false; // breaks the loop
      }
    });
    
    this.setState({
      trades
    });
  }

  handleExitDateChange(tradeID, event) {
    const value = event.target.value;
    const trades = [...this.state.trades];
    _.each(trades, t => {
      if (t.tradeID === tradeID) {
        t.exitDate = value || '';
        return false; // breaks the loop
      }
    });
    
    this.setState({
      trades
    });
  }

  toggleSelect(tradeID, event) {
    const value = event.target.checked;
    const trades = [...this.state.trades];
    _.each(trades, t => {
      if (t.tradeID === tradeID) {
        t.selected = value || false;
        return false; // breaks the loop
      }
    });
    
    this.setState({
      trades
    });
  }

  toggleSelectAll(event) {
    const newVal = event.target.checked;
    const trades = [...this.state.trades];
    _.each(trades, t => {
      t.selected = newVal;
    });
    this.setState({
      selectedAll: newVal,
      trades
    });
  }

  getOperationField() {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for="operation"><b>Operation</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id="operation" 
        onChange={this.handleOperationChange}
        value={this.state.operation}
        placeholder="Select operation">
        {
          _.map(this.alterOperations, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  getBrokerField() {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for="broker"><b>Broker</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id="broker" 
        onChange={this.handleBrokerChange}
        value={this.state.broker}
        placeholder="Select broker">
        {
          _.map(this.brokers, b => {
            return (<option key={b} value={b}>{b}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  getStrategyField() {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for="strategy"><b>Strategy</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id="strategy" 
        onChange={this.handleStrategyChange}
        value={this.state.strategy}
        placeholder="Select strategy">
        {
          _.map(this.strategies, s => {
            return (<option key={s} value={s}>{Utils.getStrategyDisplayName(s)}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  getSymbolField() {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for="symbol"><b>Trading Symbol</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id="symbol" 
        onChange={this.handleSymbolChange}
        value={this.state.tradingSymbol}
        placeholder="Select symbol">
        {
          _.map(this.tradingSymbols, t => {
            return (<option key={t} value={t}>{t}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  getAlterTradeRow(trade = {}) {
    return (<tr key={trade.tradeID}>
      <td style={{'verticalAlign': 'middle', textAlign:"center"}}>
        <Label check>
          <Input type="checkbox"
            style={{'position': 'relative'}}
            checked={trade.selected}
            onChange={(e) => this.toggleSelect(trade.tradeID, e)} 
          />
        </Label>
      </td>
      <td style={{'verticalAlign': 'middle'}}>{trade.username}</td>
      <td style={{'verticalAlign': 'middle'}}>{trade.broker}</td>
      <td style={{'verticalAlign': 'middle'}}>{Utils.getStrategyDisplayName(trade.strategy)}</td>
      <td style={{'verticalAlign': 'middle'}}>{trade.tradingSymbol}</td>
      <td style={{'verticalAlign': 'middle'}}>
        <Input type="number" step=".05" 
          name="exitPrice"
          value={trade.exitPrice}
          disabled={this.state.alterTradesInProgress}
          onChange={(e) => this.handleExitPriceChange(trade.tradeID, e)} />
      </td>
      <td style={{'verticalAlign': 'middle', textAlign:"center"}}>
        <Input type="text"
          name="exitDate"
          maxLength="10"
          value={trade.exitDate}
          disabled={this.state.alterTradesInProgress}
          onChange={(e) => this.handleExitDateChange(trade.tradeID, e)} />
      </td>
      <td 
        style={{'verticalAlign': 'middle'}}
        className={trade.alterStatus === 'success' ? "text-success" : "text-orange"}>
        {trade.alterStatus}
      </td>
    </tr>);
  }

  render() {
    const selectedCount = _.filter(this.state.trades, t => t.selected === true).length;
    return (<div>
      <Modal isOpen={this.props.isModalOpen}
        style={{maxWidth: '1600px', width: '80%'}} 
        size="lg">
        <ModalHeader>Bulk Alter Trades Operation</ModalHeader>
        
        <ModalBody>
          {this.getOperationField()}
          {this.getBrokerField()}
          {this.getStrategyField()}
          {this.getSymbolField()}
          <Table>
            <thead>
              <tr className="border-0">
                <th style={{'verticalAlign': 'middle', textAlign:"center"}}>
                  <Label check>
                    <Input type="checkbox"
                      style={{'position': 'relative'}}
                      checked={this.state.selectedAll === true ? true : false}
                      onChange={(e) => this.toggleSelectAll(e)}
                    />
                  </Label>
                </th>
                <th className="border-0">Username</th>
                <th className="border-0">Broker</th>
                <th className="border-0">Strategy</th>
                <th className="border-0">Symbol</th>
                <th className="border-0">Exit Price</th>
                <th className="border-0">Exit Date</th>
                <th className="border-0">Alter Status</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(this.state.trades, t => {
                  return this.getAlterTradeRow(t);
                })
              }
            </tbody>
          </Table>
          
          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.alterTradesInProgress && <div>Altering trades in progress. Please wait...</div>}

        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.alterTradesInProgress || selectedCount === 0}
            onClick={this.alterAllTrades}>
              Alter Trades
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.alterTradesInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default AlterTradesBulkModal;
