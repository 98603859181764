/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import async from 'async';

import UserNoteComp from '../common/UserNoteComp.js';
import { getUserNotes, deleteUserNote } from '../../utils/RestAPIs.js';
import Utils from "../../utils/Utils.js";

class UserNotes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      usernameToUserNotesMap: {},
      selectedNote: null,
      showUserNoteComp: false,
      filterTextUser: null
    };

    this.launchUserNoteComp = this.launchUserNoteComp.bind(this);
    this.closeUserNoteComp = this.closeUserNoteComp.bind(this);
    this.onUserFilterTextChange = this.onUserFilterTextChange.bind(this);
  }

  componentWillMount() {
    const tasks = [];
    _.each(this.props.users, user => {
      tasks.push(cb => {
        this.fetchUserNotes(user.username).then(result => {
          cb(null, {result, error: null});
        }).catch(err => {
          cb(null, {error: err})
        });
      });
    });

    async.parallelLimit(tasks, 5, (error, results) => {
      if (error) {
        console.error('fetchUserNotesOfAllUsers: error: ', error);
        return;
      }
      console.log(new Date() + ' | fetchUserNotesOfAllUsers: Done');
    });
  }

  fetchUserNotes(username) {
    return getUserNotes(username).then(notes => {
      const usernameToUserNotesMap = {...this.state.usernameToUserNotesMap};
      usernameToUserNotesMap[username] = notes;
      this.setState({
        usernameToUserNotesMap
      });
      return notes;
    }).catch(err => {
      console.error('Failed to fetch user notes for ' + username + ". error = ", err);
      throw err;
    });
  }

  deleteUserNote(note) {
    const data = {
      ...note,
      date: Utils.formatDateToString(new Date(note.date))
    }
    deleteUserNote(data).then(resp => {
      this.fetchUserNotes(note.username);
    }).catch(err => {
      console.error('Failed to delete user note for ' + note.username + ". error = ", err);
    });
  }
  
  launchUserNoteComp(note = {}) {
    this.setState({
      selectedNote: note,
      showUserNoteComp: true
    });
  }

  closeUserNoteComp(reload) {
    const username = (this.state.selectedNote || {}).username;
    this.setState({
      selectedNote: null,
      showUserNoteComp: false
    }, () => {
      if (reload === true) {
        this.fetchUserNotes(username);
      }
    });
  }

  onUserFilterTextChange(e) {
    this.setState({
      filterTextUser: e.target.value
    });
  }

  getUserNotesDetails(username, sno) {
    const notes = this.state.usernameToUserNotesMap[username] || [];
    return (
      <tr key={"user-" + username}>
        <td>{sno}</td>
        <td>{username}</td>
        <td>
          <Button 
            color="primary" 
            onClick={() => this.launchUserNoteComp({username: username})}>
              New Note
          </Button>
          <Table className="v-middle" size="sm" responsive>
            <tbody>
            {
              _.map(notes, (note, index) => {
                return (<tr key={"note-" + index}>
                  <td style={{"whiteSpace": "nowrap", "width": "10%"}}>{note.date ? Utils.formatDateToString(new Date(note.date)) : ''}</td>
                  <td style={{"width": "75%"}}>{note.notes}</td>
                  <td style={{"whiteSpace": "nowrap", "width": "15%"}} className="number-right">
                    <Button 
                      color="primary" 
                      onClick={() => this.launchUserNoteComp(note)}>
                        Edit
                    </Button>
                    <Button 
                      style={{'marginLeft': '5px'}}
                      color="warning" 
                      onClick={() => this.deleteUserNote(note)}>
                        Delete
                    </Button>
                  </td>
                </tr>);
              })
            }
            </tbody>
          </Table>
        </td>
      </tr>
    );
  }

  render() {
    let users = this.props.users;
    if (_.isEmpty(this.state.filterTextUser) === false) {
      users = _.filter(users, user => {
        if (_.includes(_.toLower(user.username), _.toLower(this.state.filterTextUser))) {
          return true;
        }
        return false;
      });
    }

    return (<div>
      <Card>
        <CardBody>
          <div className="filter-inputs">
            <div className="filter-input">
              <label>Filter By:</label>
              <input 
                value={this.state.filterTextUser || ''}
                placeholder="username" 
                onChange={this.onUserFilterTextChange}></input>
            </div>
          </div>
          <div>
            {this.props.fetchingDataInProgress === true && <div>
              Fetching details in progress...
            </div>}
            {this.props.error && <div className='text-orange'>
              {this.props.error}
            </div>}
            {!this.props.fetchingDataInProgress && !this.props.error 
              && (!users || users.length === 0) && <div>
              No users found.
            </div>}
            {users && users.length > 0 && <Table className="v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Username</th>
                  <th className="border-0">Notes</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(users, (user, index) => {
                    return this.getUserNotesDetails(user.username, index + 1);
                  })
                }
              </tbody>
            </Table>}
          </div>
        </CardBody>
      </Card>
      {this.state.showUserNoteComp && <UserNoteComp
        note={this.state.selectedNote}
        isModalOpen={this.state.showUserNoteComp}
        onCancel={this.closeUserNoteComp}
        fromClientManager={true}
      />}
    </div>);
  }
}

export default UserNotes;
