/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";
import { getSystemConfig } from "../../utils/RestAPIs.js";

const squareOffTimeStampsOptions = [
  {label: 'None', value: 'null'},
  {label: '15:10:00', value: '15:10:00'},
  {label: '15:05:00', value: '15:05:00'},
  {label: '15:00:00', value: '15:00:00'},
  {label: '14:55:00', value: '14:55:00'},
  {label: '14:50:00', value: '14:50:00'},
  {label: '14:45:00', value: '14:45:00'},
  {label: '14:40:00', value: '14:40:00'},
  {label: '14:35:00', value: '14:35:00'},
  {label: '14:30:00', value: '14:30:00'},
  {label: '14:25:00', value: '14:25:00'},
  {label: '14:20:00', value: '14:20:00'},
  {label: '14:15:00', value: '14:15:00'},
  {label: '14:10:00', value: '14:10:00'},
  {label: '14:05:00', value: '14:05:00'},
  {label: '14:00:00', value: '14:00:00'},
  {label: '13:45:00', value: '13:45:00'},
  {label: '13:30:00', value: '13:30:00'},
  {label: '13:15:00', value: '13:15:00'},
  {label: '13:00:00', value: '13:00:00'},
  {label: '12:45:00', value: '12:45:00'},
  {label: '12:30:00', value: '12:30:00'},
  {label: '12:15:00', value: '12:15:00'},
  {label: '12:00:00', value: '12:00:00'},
  {label: '09:15:00', value: '09:15:00'}
];

class Configuration extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      config: {},
      fetchingDataInProgress: false,
      updateInProgress: false,
      statusMessage: null,
      error: null
    };

    this.updateConfiguration = this.updateConfiguration.bind(this);
  }

  componentWillMount() {
    this.fetchConfigurationDetails();
  }

  fetchConfigurationDetails() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    getSystemConfig().then(systemConfig => {
      this.setState({
        config: systemConfig || {},
        fetchingDataInProgress: false
      });
    }).catch(err => {
      this.setState({
        config: {},
        fetchingDataInProgress: false,
        error: err
      });
    });
  }

  updateConfiguration(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true
    });

    const data = _.pick(this.state.config, "intradaySquareOffTime") || {};

    HttpRequest.post({
      url: config.serverHost + "/apis/config",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('Update configuration status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: JSON.parse(respBody).status,
          updateInProgress: false
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'Update configuration end point not found',
          updateInProgress: false
        });
      } else {
        console.error('Update configuration error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          updateInProgress: false
        });
      }
    });
  }

  onFieldChange(key, event) {
    const value = event.target ? event.target.value : '';
    console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const config = {...this.state.config};
    _.set(config, key, value);
    this.setState({
      config,
      statusMessage: null,
      error: null
    });
  }

  getSelectField(opts = {}) {
    return (<FormGroup>
      <Label for={opts.key}><b>{opts.label}</b></Label>
      <Input type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}>
        {
          _.map(opts.options, o => {
            return (<option value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  render() {
    const broker = config.getSubDomain();
    return (<div>
      <h4>Configuration</h4>
      <Card>
        <CardBody>
          <div>
            {this.state.fetchingDataInProgress === true && <div>
              Fetching details in progress...
            </div>}
          </div>
          {!this.state.fetchingDataInProgress && <div>
            {!broker && this.getSelectField({
              key: 'intradaySquareOffTime.zerodha',
              label: 'Zerodha Intraday SquareOff Time Override',
              value: _.get(this.state.config, 'intradaySquareOffTime.zerodha'),
              options: squareOffTimeStampsOptions,
              onChange: (e) => this.onFieldChange('intradaySquareOffTime.zerodha', e)
            })}
            {!broker && this.getSelectField({
              key: 'intradaySquareOffTime.fyers',
              label: 'Fyers Intraday SquareOff Time Override',
              value: _.get(this.state.config, 'intradaySquareOffTime.fyers'),
              options: squareOffTimeStampsOptions,
              onChange: (e) => this.onFieldChange('intradaySquareOffTime.fyers', e)
            })}
            {broker && this.getSelectField({
              key: 'intradaySquareOffTime.' + broker,
              label: broker + ' Intraday SquareOff Time Override',
              value: _.get(this.state.config, 'intradaySquareOffTime.' + broker),
              options: squareOffTimeStampsOptions,
              onChange: (e) => this.onFieldChange('intradaySquareOffTime.' + broker, e)
            })}

            <div style={{'margin-top': '10px', 'margin-bottom': '10px'}} >
              {this.state.error && <div className="text-orange">{this.state.error}</div>}
              {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
              {this.state.updateInProgress && <div>Updating configuration in progress. Please wait...</div>}
            </div>

            <Button 
              color="primary" 
              disabled={this.state.updateInProgress}
              onClick={this.updateConfiguration}>
                Update Configuration
            </Button>
          </div>}
        </CardBody>
      </Card>
    </div>);
  }
}

export default Configuration;
