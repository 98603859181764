/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert,
  Button 
} from 'reactstrap';

import HttpRequest from "request";
import Utils from "../../utils/Utils.js";
import config from '../../config.js';

const supportedProviders = ['xts', 'xts-hostlookup'];

class BrokerModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        provider: props.brokerDetails.provider,
        useDealerAPIs: props.brokerDetails.useDealerAPIs,
        serverUrl: props.brokerDetails.serverUrl,
        dataServerUrl: props.brokerDetails.dataServerUrl,
        xtremeAgentDestUrl: props.brokerDetails.xtremeAgentDestUrl
      },
      validations: {}
    }
    console.log('data => ', this.state.data);

    this.onCancel = this.onCancel.bind(this);
    this.updateBrokerDetails = this.updateBrokerDetails.bind(this);
  }

  updateBrokerDetails(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      brokerDetailsUpdated: false,
      statusMessage: null,
      error: null
    });

    const data = {
      broker: this.props.brokerDetails.name,
      provider: _.get(this.state.data, 'provider'),
      useDealerAPIs: _.get(this.state.data, 'useDealerAPIs'),
      serverUrl: _.get(this.state.data, 'serverUrl'),
      dataServerUrl: _.get(this.state.data, 'dataServerUrl'),
      xtremeAgentDestUrl: _.get(this.state.data, 'xtremeAgentDestUrl')
    };

    HttpRequest.put({
      url: config.serverHost + "/apis/brokers",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('updateBrokerDetails status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: JSON.parse(respBody).status,
          updateInProgress: false,
          brokerDetailsUpdated: true
        });
      } else if (resp.statusCode === 404) {
        const error = 'updateBrokerDetails end point not found';
        this.setState({
          error,
          updateInProgress: false
        });
      } else {
        console.error('updateBrokerDetails error => ', respBody);
        const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
        this.setState({
          error,
          updateInProgress: false
        });
      }
    });
  }

  getValidationError(key, value) {
    if (key === 'provider') {
      if (_.isEmpty(value) === false && _.includes(supportedProviders, value) === false) {
        return 'Invalid provider';
      }
    }

    if (key === 'serverUrl' && _.isEmpty(value) === false) {
      if (!Utils.isValidUrl(value)) {
        return 'Invalid server url';
      }
    }

    if (key === 'dataServerUrl' && _.isEmpty(value) === false) {
      if (!Utils.isValidUrl(value)) {
        return 'Invalid data server url';
      }
    }

    if (key === 'xtremeAgentDestUrl' && _.isEmpty(value) === false) {
      if (!Utils.isValidUrl(value)) {
        return 'Invalid xtreme agent destination url';
      }
    }
    return null;
  }

  onFieldChange(key, event) {
    let value = event.target ? (event.target.type.toLowerCase() === 'checkbox' ? event.target.checked : event.target.value) : '';
    // console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const newValidations = {...this.state.validations};
    
    const data = {...this.state.data};
    _.set(data, key, value);
    
    newValidations[key] = this.getValidationError(key, value);

    this.setState({
      data,
      validations: newValidations,
      statusMessage: null,
      error: null
    });
  }

  getInputField(opts = {}) {
    const validationError = this.state.validations[opts.key];
    return (<div>{opts.type === 'checkbox' ? 
      <FormGroup check inline style={{'margin-bottom': '15px'}}>
        <Input type="checkbox" onChange={opts.onChange} checked={opts.value} />
        <Label check>
          {opts.label}
        </Label>
      </FormGroup> : <FormGroup>
        <Label className="col-form-label" for={opts.key}>{opts.label}</Label>
        <Input valid={validationError ? false : true}
          className="col-form-control"
          type={opts.type || 'text'} 
          name="text" 
          id={opts.key} 
          onChange={opts.onChange}
          value={opts.value}
          disabled={opts.disabled}
          placeholder={opts.placeholder}>
        </Input>
        {validationError && <div className="text-orange">{validationError}</div>}
      </FormGroup>}
    </div>);
  }

  getSelectField(opts = {}) {
    const validationError = this.state.validations[opts.key];
    return (<FormGroup>
      <Label className="col-form-label" for={opts.key}>{opts.label}</Label>
      <Input valid={validationError ? false : true}
        className="col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
      {validationError && <div className="text-orange">{validationError}</div>}
    </FormGroup>);
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.brokerDetailsUpdated ? true : false);
    }
  }

  isDataChanged() {
    const brokerDetails = this.props.brokerDetails;
    if (_.get(this.state.data, 'provider') !== brokerDetails.provider
      || _.get(this.state.data, 'useDealerAPIs') !== brokerDetails.useDealerAPIs
      || _.get(this.state.data, 'serverUrl') !== brokerDetails.serverUrl
      || _.get(this.state.data, 'dataServerUrl') !== brokerDetails.dataServerUrl
      || _.get(this.state.data, 'xtremeAgentDestUrl') !== brokerDetails.xtremeAgentDestUrl) {
      return true;
    }
    return false;
  }

  isFormValid() {
    let isValid = true;
    _.each(this.state.validations, (v, key) => {
      if (v) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  renderBody() {
    const showAlert = this.state.error || this.state.statusMessage || this.state.updateInProgress;
    const error = this.state.error;
    const providerOptions = _.concat([{label: 'None', value: ''}], _.map(supportedProviders, p => {
      return {
        label: p,
        value: p
      };
    }));

    return (<div>
      {this.getSelectField({
        key: 'provider',
        label: 'Provider',
        value: _.get(this.state.data, 'provider'),
        options: providerOptions,
        placeholder: 'Select provider',
        onChange: (e) => this.onFieldChange('provider', e)
      })}
      {this.getInputField({
        type: 'checkbox',
        key: 'useDealerAPIs',
        label: 'Use Dealer APIs?',
        value: _.get(this.state.data, 'useDealerAPIs'),
        onChange: (e) => this.onFieldChange('useDealerAPIs', e)
      })}
      {this.getInputField({
        key: 'serverUrl',
        label: 'Interactive Server Url',
        value: _.get(this.state.data, 'serverUrl'),
        onChange: (e) => this.onFieldChange('serverUrl', e)
      })}
      {this.getInputField({
        key: 'dataServerUrl',
        label: 'MarketData Server Url',
        value: _.get(this.state.data, 'dataServerUrl'),
        onChange: (e) => this.onFieldChange('dataServerUrl', e)
      })}
      {this.getInputField({
        key: 'xtremeAgentDestUrl',
        label: 'XtremeAgent Dest Url',
        value: _.get(this.state.data, 'xtremeAgentDestUrl'),
        onChange: (e) => this.onFieldChange('xtremeAgentDestUrl', e)
      })}
      
      {showAlert && <Alert color={error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {error && <div>{error}</div>}
          {this.state.statusMessage && <div>{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Request is in progress. Please wait...</div>}
        </div>
      </Alert>}
    </div>);
  }

  render() {
    return (<Modal 
        size="md"
        isOpen={this.props.isModalOpen}
        >
        <ModalHeader>Broker Details : {this.props.brokerDetails.name}</ModalHeader>
        
        <ModalBody>
          {this.renderBody()}
        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress || !this.isDataChanged() || !this.isFormValid()}
            onClick={this.updateBrokerDetails}>
              Update
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>

      </Modal>);
  }
}

export default BrokerModal;