
/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Collapse,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';

import config from '../../config.js';
import Utils from "../../utils/Utils.js";
import SquareOffModal from '../common/SquareOffModal.js';

const LOSS_ALERT_THRESHOLD = -0.7; // percentage

class TradesSummaryComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      riskProfileWrtCmp: Utils.isMarketOpen() ? false : true
    };

    this.launchSquareOffModal = this.launchSquareOffModal.bind(this);
    this.closeSquareOffModal = this.closeSquareOffModal.bind(this);

    this.renderRiskPopover = this.renderRiskPopover.bind(this);
    this.toggleRiskPopover = this.toggleRiskPopover.bind(this);
    this.toggleRiskProfileWrtCmp = this.toggleRiskProfileWrtCmp.bind(this);
  }

  getStrategiesSummaryList() {
    //console.log('----- this.props.strategiesSummary => ', this.props.strategiesSummary);
    const strategyToSummaryMap = {};
    _.each(this.props.strategiesSummary, s => { // Each object should contain strategyName and capital
      if (s.strategyName === 'CAPITAL-EXTERNAL-INTRA' || s.strategyName === 'CAPITAL-EXTERNAL-POS') {
        return;
      }
      strategyToSummaryMap[s.strategyName] = {
        ...s,
        realizedPnl: 0,
        unrealizedPnl: 0,
        charges: 0,
        netPnl: 0,
        returns: 0
      }
    });
    
    _.each(this.props.allTrades, trade => {
      //console.log(' trade.strategy = ' + trade.strategy);
      if (strategyToSummaryMap[trade.strategy]) {
        if (trade.isActive) {
          strategyToSummaryMap[trade.strategy].unrealizedPnl += trade.profitLoss;
        } else {
          strategyToSummaryMap[trade.strategy].realizedPnl += trade.profitLoss;
        }
        strategyToSummaryMap[trade.strategy].charges += trade.charges;
        strategyToSummaryMap[trade.strategy].netPnl += trade.netProfitLoss;
      }
    });

    // Add entry for Total
    const totalStrategy = {
      strategyName: 'Total',
      capital: strategyToSummaryMap['CAPITAL-DEPLOYED'] ? strategyToSummaryMap['CAPITAL-DEPLOYED'].capital : 0,
      realizedPnl: 0,
      unrealizedPnl: 0,
      charges: 0,
      netPnl: 0,
      returns: 0
    };
    _.each(strategyToSummaryMap, s => {
      if (!strategyToSummaryMap['CAPITAL-DEPLOYED'] && !Utils.isOverlapCapital(s.strategyName)) {
        totalStrategy.capital += s.capital;
      }
      totalStrategy.realizedPnl += s.realizedPnl;
      totalStrategy.unrealizedPnl += s.unrealizedPnl;
      totalStrategy.charges += s.charges;
      totalStrategy.netPnl += s.netPnl;
    });
    strategyToSummaryMap['Total'] = totalStrategy;

    delete strategyToSummaryMap['CAPITAL-DEPLOYED'];

    // Convert map to array
    const strategiesSummaryList = _.values(strategyToSummaryMap);
    _.each(strategiesSummaryList, s => {
      s.displayOrder = Utils.getStrategyDisplayOrder(s.strategyName);
      s.returns = s.capital > 0 ? Utils.roundOff(s.netPnl * 100 / s.capital) : 0;
    });
    // Sort as per display order
    strategiesSummaryList.sort((s1, s2) => s1.displayOrder - s2.displayOrder);

    return strategiesSummaryList;
  }

  getRiskProfilesArray(totalCapital = 0, wrtCmp = false) {
    const riskProfiles = [];
    const riskDistances = this.props.riskDistances;
    _.each(riskDistances, (rd, index) => {
      riskProfiles.push({
        index,
        rd,
        capital: 0,
        pnl: 0,
        returns: 0
      });
    });
    _.each(this.props.brokerPositions, pos => {
      const risks = Utils.calculatePositionRisks(pos, riskDistances, wrtCmp);
      const indexSymbol = Utils.extractIndexSymbol(pos.tradingSymbol);
      _.each(riskDistances, (rd, index) => {
        riskProfiles[index].pnl += risks[index].pnl;
        if (indexSymbol) {
          if (!riskProfiles[index][indexSymbol]) {
            riskProfiles[index][indexSymbol] = 0;
          }
          riskProfiles[index][indexSymbol] += risks[index].pnl;
        }
      });
    });
    
    _.each(riskProfiles, riskProfile => {
      riskProfile.capital = totalCapital;
      riskProfile.returns = totalCapital > 0 ? Utils.roundOff(100 * riskProfile.pnl / totalCapital) : 0;
    });
    return riskProfiles;
  }

  getBrokerCapital() {
    const strategiesSummaryList = this.getStrategiesSummaryList();
    const totalStrategy = strategiesSummaryList[strategiesSummaryList.length - 1];
    const externalCapitalIntra = (this.props.strategiesSummary['CAPITAL-EXTERNAL-INTRA'] || {}).capital || 0;
    const externalCapitalPos = (this.props.strategiesSummary['CAPITAL-EXTERNAL-POS'] || {}).capital || 0;
    const externalCapital = externalCapitalIntra + externalCapitalPos;
    const brokerCapital = totalStrategy.capital + externalCapital;
    return brokerCapital;
  }

  renderStrategyWiseTradesSummary(strategiesSummaryList) {
    return (<tbody>
      {_.map(strategiesSummaryList, s => {
        return (<tr key={s.strategyName} style={Utils.getStrategyProduct(s.strategyName) === 'POSITIONAL' ? {background: 'rgb(247, 247, 247)'} : {}}>
          <td>{Utils.getStrategyProduct(s.strategyName)}</td>
          <td>{Utils.getStrategyDisplayName(s.strategyName)}</td>
          <td className="number-right">{Utils.formatNumberToCommaSeparated(s.capital)}</td>
          <td className={s.realizedPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(s.realizedPnl, false)}</td>
          <td className={s.unrealizedPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(s.unrealizedPnl, false)}</td>
          <td className="number-right">{Utils.formatNumberToCommaSeparated(s.charges, false)}</td>
          <td className={s.netPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(s.netPnl, false)}</td>
          <td className={s.returns > 0 ? "number-right number-pos" : "number-right number-neg"}>{'' + s.returns + ' %'}</td>
        </tr>);
      })}
    </tbody>);
  }

  handleToggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  launchSquareOffModal(e) {
    e.stopPropagation();

    this.setState({
      showSquareOffModal: true
    });
  }

  closeSquareOffModal() {
    this.setState({
      showSquareOffModal: false
    });
  }

  toggleRiskProfileWrtCmp() {
    const riskProfileWrtCmp = !this.state.riskProfileWrtCmp;
    this.setState({
      riskProfileWrtCmp,
      selectedRiskProfiles: this.getRiskProfilesArray(this.getBrokerCapital(), riskProfileWrtCmp)
    });
  }

  toggleRiskPopover(e, targetId, riskProfiles = []) {
    e.stopPropagation();

    const isRiskPopoverOpen =  this.state.isRiskPopoverOpen ? false : true;
    this.setState({
      riskPopoverTargetId: targetId,
      isRiskPopoverOpen: isRiskPopoverOpen,
      selectedRiskProfiles: riskProfiles
    });
  }

  renderRiskPopover() {
    return this.state.isRiskPopoverOpen && this.state.riskPopoverTargetId ? (<Popover placement="right" 
      isOpen={this.state.isRiskPopoverOpen ? true : false} 
      target={this.state.riskPopoverTargetId} 
      toggle={this.toggleRiskPopover}>
      <PopoverHeader>
        <span>Risk Profile</span>
        <div className="filter-inputs">
          <div className="filter-input">
            <label>Only active positions w.r.t. CMP</label>
            <input
              type="checkbox"
              checked={this.state.riskProfileWrtCmp}
              onChange={this.toggleRiskProfileWrtCmp}></input>
          </div>
        </div>
      </PopoverHeader>
      <PopoverBody>
        <Table className="no-wrap v-middle" size="sm" responsive>
          <thead>
            <tr>
              <th>INDEX MOVE</th>
              <th className='number-right'>TOTAL</th>
              {
                _.map(['NIFTY', 'BANKNIFTY', 'FINNIFTY'], symbol => {
                  return (<th key={symbol} className='number-right'>{symbol}</th>);
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              _.map(this.state.selectedRiskProfiles, (riskProfile, index) => {
                return (<tr>
                  <td className='number-right'><b>{riskProfile.rd}%</b></td>
                  <td key={index} className={riskProfile.pnl < 0 ? 'number-neg number-right' : 'number-pos number-right'}>
                    {Utils.formatNumberToCommaSeparated(riskProfile.pnl)} [{riskProfile.returns}%]
                  </td>
                  {
                    _.map(['NIFTY', 'BANKNIFTY', 'FINNIFTY'], symbol => {
                      return (<td key={index} className={riskProfile[symbol] < 0 ? 'number-neg number-right' : 'number-pos number-right'}>
                        {Utils.formatNumberToCommaSeparated(riskProfile[symbol])}
                      </td>);
                    })
                  }
                </tr>);
              })
            }
          </tbody>
        </Table>
      </PopoverBody>
    </Popover>) : null;
  }

  renderSummaryHeader(strategiesSummaryList) {
    // Calculate algo pnl by algo positions
    const totalStrategy = strategiesSummaryList[strategiesSummaryList.length - 1];
    const algoCapital = totalStrategy.capital;
    let algoIntradayPnl = 0, algoPositionalPnl = 0, algoTotalPnl = 0;
    _.each(this.props.algoPositions, pos => {
      if (pos.productType === 'MIS') {
        algoIntradayPnl += pos.totalPnl;
      } else if (pos.productType === 'NRML') {
        algoPositionalPnl += pos.totalPnl;
      }
      algoTotalPnl += pos.totalPnl;
    });
    const algoReturns = algoCapital > 0 ? Utils.roundOff(100 * algoTotalPnl / algoCapital) : 0;
    // Calculate broker pnl by broker positions
    const brokerCapital = this.getBrokerCapital();
    let brokerIntradayPnl = 0, brokerPositionalPnl = 0, brokerTotalPnl = 0;
    _.each(this.props.brokerPositions, pos => {
      if (pos.productType === 'MIS') {
        brokerIntradayPnl += pos.totalPnl;
        brokerTotalPnl += pos.totalPnl;
      } else if (pos.productType === 'NRML') {
        brokerPositionalPnl += pos.totalPnl;
        brokerTotalPnl += pos.totalPnl;
      }
    });
    const brokerReturns = brokerCapital > 0 ? Utils.roundOff(100 * brokerTotalPnl / brokerCapital) : 0;

    const riskProfiles = this.getRiskProfilesArray(brokerCapital, this.state.riskProfileWrtCmp);
    
    return (<div>
      <h5>
        <span>Summary</span>
        <Button id='riskProfile' color="link" style={{padding: 0, marginLeft: '10px'}} onClick={(e) => this.toggleRiskPopover(e, 'riskProfile', riskProfiles)}>
          <span>Risk Profile: </span>
          <span className={riskProfiles[0].pnl < 0 ? 'number-neg' : 'number-pos'}>{Utils.formatNumberToCommaSeparated(riskProfiles[0].pnl)} ({riskProfiles[0].returns}%)</span>
          <span> .... </span>
          <span className={riskProfiles[riskProfiles.length - 1].pnl < 0 ? 'number-neg' : 'number-pos'}>{Utils.formatNumberToCommaSeparated(riskProfiles[riskProfiles.length - 1].pnl)} ({riskProfiles[riskProfiles.length - 1].returns}%)</span>
        </Button>
        {config.isProXtremeQuant() && this.props.isAdmin === false && <Button color='danger' style={{marginLeft: '10px'}}
          disabled={brokerReturns > LOSS_ALERT_THRESHOLD}
          onClick={(e) => this.launchSquareOffModal(e)}>
          SquareOff
        </Button>}
      </h5>
      <Table className="no-wrap v-middle" size="sm" responsive>
        <thead>
          <tr className="border-0">
            <th className="border-0"></th>
            <th className="border-0">Capital</th>
            <th className="border-0">Intraday</th>
            <th className="border-0">Positional</th>
            <th className="border-0">Total MTM</th>
            <th className="border-0">Returns</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Algo</b></td>
            <td>
              {Utils.formatNumberToCommaSeparated(algoCapital)}
            </td>
            <td className={algoIntradayPnl >= 0 ? "number-pos" : "number-neg"}>
              {Utils.formatNumberToCommaSeparated(algoIntradayPnl)}
            </td>
            <td className={algoPositionalPnl >= 0 ? "number-pos" : "number-neg"}>
              {Utils.formatNumberToCommaSeparated(algoPositionalPnl)}
            </td>
            <td className={algoTotalPnl >= 0 ? "number-pos" : "number-neg"}>
              {Utils.formatNumberToCommaSeparated(algoTotalPnl)}
            </td>
            <td className={algoTotalPnl >= 0 ? "number-pos" : "number-neg"}>
              {algoReturns} %
            </td>
          </tr>
          <tr>
            <td><b>Broker</b></td>
            <td>
              {Utils.formatNumberToCommaSeparated(brokerCapital)}
            </td>
            <td className={brokerIntradayPnl >= 0 ? "number-pos" : "number-neg"}>
              {Utils.formatNumberToCommaSeparated(brokerIntradayPnl)}
            </td>
            <td className={brokerPositionalPnl >= 0 ? "number-pos" : "number-neg"}>
              {Utils.formatNumberToCommaSeparated(brokerPositionalPnl)}
            </td>
            <td className={brokerTotalPnl >= 0 ? "number-pos" : "number-neg"}>
              {Utils.formatNumberToCommaSeparated(brokerTotalPnl)}
            </td>
            <td className={brokerTotalPnl >= 0 ? "number-pos" : "number-neg"}>
              {brokerReturns} %
            </td>
          </tr>
        </tbody>
      </Table>
    </div>);
  }

  render() {
    const strategiesSummaryList = this.getStrategiesSummaryList();
    
    return (<div>
      <Card>
        <CardHeader onClick={() => this.handleToggle()} style={{'cursor': 'pointer'}}>
          {this.renderSummaryHeader(strategiesSummaryList)}
        </CardHeader>
        <Collapse isOpen={this.state.isOpen}>
          <CardBody>
            <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">Product</th>
                  <th className="border-0">Strategy</th>
                  <th className="border-0 number-right">Capital</th>
                  <th className="border-0 number-right">Realized Pnl</th>
                  <th className="border-0 number-right">Unrealized Pnl</th>
                  <th className="border-0 number-right">Charges</th>
                  <th className="border-0 number-right">Net Pnl</th>
                  <th className="border-0 number-right">Returns</th>
                </tr>
              </thead>
              {this.renderStrategyWiseTradesSummary(strategiesSummaryList)}
            </Table>
          </CardBody>
        </Collapse>
      </Card>
      {this.state.showSquareOffModal && <SquareOffModal
        isModalOpen={this.state.showSquareOffModal}
        onCancel={this.closeSquareOffModal}
        username={this.props.username}
        broker={this.props.broker}
      />}
      {this.renderRiskPopover()}
    </div>);
  }
}

export default TradesSummaryComp;
