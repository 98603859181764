
import _ from 'lodash';
import React from 'react';

import { 
  TermsAndConditions,
} from '../../components/dashboard';

import TradesMainComp from '../../components/trades/TradesMainComp.js';
import ConfirmationModal from '../../components/common/ConfirmationModal.js';

const TNC_VALIDITY_PERIOD_IN_MILLIS = 7 * 24 * 60 * 60 * 1000; // 7 days

class Starter extends React.Component {
  
    constructor(props) {
      super(props);

      const tncAcceptedTimestamp = localStorage.getItem('TncAcceptedTimestamp');
      const millisElapsed = new Date().getTime() - _.toInteger(tncAcceptedTimestamp);
      
      this.state = {
        activeTab: 'intraday',
        showTermsAndConditionsModal: (millisElapsed >= TNC_VALIDITY_PERIOD_IN_MILLIS ? true : false)
      };

      this.onTnCConfirm = this.onTnCConfirm.bind(this);
      this.onTnCCancel = this.onTnCCancel.bind(this);

    }

    onTnCConfirm() {

      localStorage.setItem('TncAcceptedTimestamp', new Date().getTime());

      this.setState({
        showTermsAndConditionsModal: false
      });
    }

    onTnCCancel() {
      // do nothing
    }

    render() {
      return (<div>
        <TradesMainComp user={this.props.user}/>
        <ConfirmationModal
          isBigModal={true}
          isModalOpen={this.state.showTermsAndConditionsModal}
          title="Terms and Conditions"
          getBodyComp={() => (<TermsAndConditions />)}
          confirmButtonText="Yes, I agree."
          cancelButtonText="No, I don't agree."
          onConfirm={this.onTnCConfirm}
          onCancel={this.onTnCCancel}
        />
      </div>);
    }
}

export default Starter;
