/*
  Author: Sreenivas Doosa
*/

//import _ from 'lodash';

const config = {
  serverHost: "http://localhost:8080",

  updateServerHost: function updateServerHost(host) {
    console.log('updating server host to = ' + host);
    config.serverHost = host;
  },

  isAutoQuant: function isAutoQuant() {
    return config.serverHost.includes('autoquant');
  },

  isXtremeQuant: function isXtremeQuant() {
    return config.serverHost.includes('xtremequant') && !config.serverHost.includes('pro.xtremequant');
  },

  isProXtremeQuant: function isProXtremeQuant() {
    return config.serverHost.includes('pro.xtremequant');
  },

  isWay2Quant: function isWay2Quant() {
    return config.serverHost.includes('way2quant') || config.serverHost.includes('localhost');
  },

  isAllocationModelEnabled: function isAllocationModelEnabled() {
    if (config.isAutoQuant()) {
      return false;
    }
    return true;
  },

  getSubDomain: function getSubDomain() {
    if (config.isWay2Quant()) {
      const url = new URL(config.serverHost);
      const parts = url.hostname.split('.');
      if (parts.length >= 3) {
        return parts[0];
      }
    }
    return '';
  },

  getSupportEmail: function getSupportEmail() {
    if (config.isXtremeQuant() || config.isProXtremeQuant()) {
      return 'admin@xtremequant.com';
    } else if (config.isWay2Quant()) {
      return 'admin@way2quant.com';
    } else if (config.isAutoQuant()) {
      return 'admin@autoquant.in';
    }
    return '';
  },

  getProductName: function getProductName() {
    if (config.isXtremeQuant()) {
      return 'XtremeQuant';
    } else if (config.isProXtremeQuant()) {
      return 'ProXtremeQuant'; 
    } else if (config.isWay2Quant()) {
      return 'Way2Quant';
    }
    return 'AutoQuant';
  }
};

module.exports = config;
