/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  TabContent,
  TabPane, 
  Nav, 
  NavItem, 
  NavLink,
  Button
} from 'reactstrap';
import classnames from 'classnames';

import HttpRequest from "request";
import config from "../../config.js";
import Utils from '../../utils/Utils.js';
import { fetchAllStrategies, fetchAllocationModels } from "../../utils/RestAPIs.js";

import UserComp from '../common/UserComp.js';
import UsersBasicDetails from './UsersBasicDetails.js';
import UsersBrokersMap from './UsersBrokersMap.js';
import UsersStrategiesMap from './UsersStrategiesMap.js';
import StrategiesSummary from './StrategiesSummary.js';
import UserNotes from './UsersNotes.js';

class Users extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      strategies: [],
      allocationModels: [],
      activeTabTitle: 'Basic Details',
      fetchingDataInProgress: false,
      error: null
    };

    this.fetchAllStrategies = this.fetchAllStrategies.bind(this);
    this.launchUserComp = this.launchUserComp.bind(this);
    this.closeUserComp = this.closeUserComp.bind(this);
  }

  componentWillMount() {
    this.fetchAllStrategies().then(respStrategies => {
      this.fetchAllocationModels().then(respAllocationModels => {
        this.fetchUsersDetails();
      });
    });
  }

  fetchUsersDetails() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    HttpRequest(config.serverHost + "/apis/clientmanager/users", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchUsersDetails: error = ', err);
        this.setState({
          fetchingDataInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchUsersDetails: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          const users = respBody || [];
          // sort users based on activation date
          users.sort((u1, u2) => {
            if (u1.enabled === u2.enabled) {
              return u1.activationDate - u2.activationDate;
            }
            return u2.enabled - u1.enabled;
          });

          this.setState({
            users: users || [],
            fetchingDataInProgress: false
          });
        } else {
          this.setState({
            users: [],
            fetchingDataInProgress: false,
            error: respBody.error || 'Failed to fetch users'
          });
        }
      }
    });
  }

  fetchAllStrategies() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    return fetchAllStrategies({publicOnly: false}).then(allStrategies => {
      Utils.updateStrategyToDetailsMappings(allStrategies);
      this.setState({
        strategies: allStrategies || [],
        fetchingDataInProgress: false
      });
      return allStrategies;
    }).catch(err => {
      this.setState({
        strategies: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch strategies'
      });
      return err;
    });
  }

  getPublicStrategiesOnly() {
    return _.filter(this.state.strategies, s => {
      return s.isPrivate === true ? false : true;
    });
  }

  fetchAllocationModels() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    return fetchAllocationModels().then(allocationModels => {
      this.setState({
        allocationModels: allocationModels || [],
        fetchingDataInProgress: false
      });
      return allocationModels;
    }).catch(err => {
      this.setState({
        allocationModels: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch allocation models'
      });
      return err;
    });
  }

  launchUserComp(userDetails) {
    this.setState({
      selectedUserDetails: userDetails,
      showUserComp: true
    });
  }

  closeUserComp(reload) {
    this.setState({
      selectedUserDetails: null,
      showUserComp: false
    }, () => {
      if (reload === true) {
        this.fetchUsersDetails();
      }
    });
  }

  toggle(tab) {
    if (tab.title === this.state.activeTabTitle) {
      return;
    }
    this.setState({
      activeTabTitle: tab.title
    });
  }

  render() {

    const tabs = [{
      title: 'Basic Details',
      getContent: () => {
        return (<UsersBasicDetails users={this.state.users} 
          serverConfig={this.props.serverConfig}
          fetchingDataInProgress={this.state.fetchingDataInProgress}
          fetchUsersDetails={this.fetchUsersDetails.bind(this)}
          error={this.state.error} />);
      }
    }, {
      title: 'Broker Details',
      getContent: () => {
        return (<UsersBrokersMap users={this.state.users}
          serverConfig={this.props.serverConfig}
          supportedBrokers={this.props.supportedBrokers}
          fetchingDataInProgress={this.state.fetchingDataInProgress}
          fetchUsersDetails={this.fetchUsersDetails.bind(this)}
          error={this.state.error} />);
      }
    }, {
      title: 'Strategy Details',
      getContent: () => {
        return (<UsersStrategiesMap 
          users={this.state.users}
          strategies={this.getPublicStrategiesOnly()}
          allocationModels={this.state.allocationModels}
          serverConfig={this.props.serverConfig}
          fetchingDataInProgress={this.state.fetchingDataInProgress}
          error={this.state.error}
          reloadUsers={() => this.fetchUsersDetails()} />);
      }
    }, {
      title: 'Strategies Summary',
      getContent: () => {
        return (<StrategiesSummary
          strategies={this.state.strategies}
          users={this.state.users}
          serverConfig={this.props.serverConfig}
          fetchAllStrategies={this.fetchAllStrategies}
          fetchingDataInProgress={this.state.fetchingDataInProgress}
          error={this.state.error} />);
      }
    }, {
      title: 'User Notes',
      getContent: () => {
        return (<UserNotes
          users={this.state.users}
          fetchingDataInProgress={this.state.fetchingDataInProgress}
          error={this.state.error} />);
      }
    }];

    return (<div>
      <h4>Users</h4>
      <Button 
        style={{'marginBottom': '5px'}}
        color="primary" 
        onClick={(e) => this.launchUserComp()}>
          Create New User
      </Button>
      <div>
        <Nav tabs>
          {
            _.map(tabs, (tab) => {
              return (<NavItem key={tab.title}>
                <NavLink
                  className={classnames({ active: this.state.activeTabTitle === tab.title })}
                  onClick={() => { this.toggle(tab); }}
                >
                  {tab.title}
                </NavLink>
              </NavItem>);
            })
          }
        </Nav>
        <TabContent activeTab={this.state.activeTabTitle}>
          <TabPane tabId={this.state.activeTabTitle}>
            {
              (_.find(tabs, tab => tab.title === this.state.activeTabTitle) || {}).getContent()
            }
          </TabPane>
        </TabContent>
      </div>
      {this.state.showUserComp && <UserComp 
        userDetails={this.state.selectedUserDetails}
        isModalOpen={this.state.showUserComp}
        onCancel={this.closeUserComp}
        serverConfig={this.props.serverConfig}
        isAdmin={true}
      />}
    </div>);
  }
}

export default Users;
