
import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';

import config from '../../config.js'
import Login from '../auth/Login.js';

class Home extends React.Component {

  getWay2QuantContent() {
    return null;
    /*return (<Card className="no-wrap v-middle">
      <CardBody>
        <h3>Video Tutorials:</h3>
        <ol>
          <li>
            <a href="https://www.youtube.com/watch?v=mKkI6SeLh7g" target="_blank">
              How to Create Discipline Trader Account and Link it to Fyers
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=nhIAI9uOjpE" target="_blank">
              How to Create Discipline Trader Account and Link it to Zerodha
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=6PvkKoM1gF8" target="_blank">
              How to Manage Strategy and Change Capital
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=PpVaPqFk56k" target="_blank">
              Daily Morning Routine
            </a>
          </li>
        </ol>
      </CardBody>
    </Card>);*/
  }

  render() {
    return (<div className="home-content">
      {config.isWay2Quant() ? <Row>
        <Col xs={12} md={6} lg={4}>
            <Login 
              serverConfig={this.props.serverConfig}
              onLoginSuccess={this.props.onLoginSuccess}
            />
          </Col>
          <Col xs={12} md={6} lg={8}>
            {this.getWay2QuantContent()}
          </Col>
        </Row>
        :
        <Login 
          serverConfig={this.props.serverConfig}
          onLoginSuccess={this.props.onLoginSuccess}
        />
      }
    </div>);
  }
}

export default Home;