
import _ from 'lodash';
import React from 'react';
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';

const getInputField = (opts = {}, validations = {}) => {
  const validationError = _.isEmpty(opts.value) ? null : validations[opts.key];
  return (<FormGroup className="form-row">
    <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
    <Input valid={validationError ? false : true}
      className="col-sm-6 col-form-control"
      type={opts.type || 'text'} 
      name="text" 
      id={opts.key} 
      onChange={opts.onChange}
      value={opts.value}
      disabled={opts.disabled}
      placeholder={opts.placeholder}>
    </Input>
    {validationError && <div className="col-sm-6 text-orange">{validationError}</div>}
  </FormGroup>);
};

const getSelectField = (opts = {}, validations = {}) => {
  return (<FormGroup className="form-row">
    <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
    <Input valid
      className="col-sm-6 col-form-control"
      type="select" 
      name="select" 
      id={opts.key} 
      onChange={opts.onChange}
      value={opts.value}
      placeholder={opts.placeholder}>
      {
        _.map(opts.options, o => {
          return (<option key={o.label} value={o.value}>{o.label}</option>);
        })
      }
    </Input>
  </FormGroup>);
};

export {
  getInputField,
  getSelectField
}