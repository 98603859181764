/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Col
} from 'reactstrap';

import config from "../../config.js";
import Utils from "../../utils/Utils.js";

class StrategiesSummary extends React.Component {

  getStrategySummaryDetails(s = {}) {
    return (
      <tr key={s.strategyName}>
        <td>{Utils.getStrategyDisplayName(s.strategyName)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(s.totalCapital)}</td>
        {config.isAutoQuant() && <td className="number-right">{Utils.formatNumberToCommaSeparated(s.totalPaidPlanCapital)}</td>}
        {config.isAutoQuant() && <td className="number-right">{Utils.formatNumberToCommaSeparated(s.totalFreePlanCapital)}</td>}
      </tr>
    );
  }

  render() {
    let strategiesSummary = {
      all: {
        strategyName: 'ALL',
        totalCapital: 0,
        totalFreePlanCapital: 0,
        totalPaidPlanCapital: 0
      }
    };
    _.each(this.props.users, user => {
      _.each(user.strategies, strategy => {
        if (!strategiesSummary[strategy.strategyName] && strategy.enabled) {
          strategiesSummary[strategy.strategyName] = {
            strategyName: strategy.strategyName,
            totalCapital: 0,
            totalFreePlanCapital: 0,
            totalPaidPlanCapital: 0
          };
        }
        if (user.billingPlan === null) {
          user.billingPlan = '';
        }
        
        if (user.enabled && strategy.enabled) {
          strategiesSummary[strategy.strategyName].totalCapital += strategy.capital;
          if (!strategy.isOverlapCapital) {
            strategiesSummary.all.totalCapital += strategy.capital;
          }
          if (user.billingPlan === 'FREE' || user.billingPlan.startsWith('TRIAL')) {
            strategiesSummary[strategy.strategyName].totalFreePlanCapital += strategy.capital;
            if (!strategy.isOverlapCapital) {
              strategiesSummary.all.totalFreePlanCapital += strategy.capital;
            }
          } else {
            strategiesSummary[strategy.strategyName].totalPaidPlanCapital += strategy.capital;
            if (!strategy.isOverlapCapital) {
              strategiesSummary.all.totalPaidPlanCapital += strategy.capital;
            }
          }
        }
      });
    });

    if (config.isAllocationModelEnabled()) {
      // Not needed as we will consider CAPITAL-DEPLOYED for calculating whole capital
      delete strategiesSummary.all; 
    }

    _.each(strategiesSummary, s => {
      // Set enabled value from strategy global details
      const strategyDetails = _.find(this.props.strategies, sg => sg.name === s.strategyName);
      if (strategyDetails) {
        s.enabled = strategyDetails.enabled;
      } else {
        console.warn('strategy details not found for ' + s.strategyName);
      }
    });

    strategiesSummary = _.toArray(strategiesSummary);

    // sort by total capital
    strategiesSummary = strategiesSummary.sort((a, b) => b.totalCapital - a.totalCapital);

    if (config.isAllocationModelEnabled()) {
      const capitalDeploy = _.find(strategiesSummary, s => s.strategyName === 'CAPITAL-DEPLOYED');
      const capitalDeployExternalIntra = _.find(strategiesSummary, s => s.strategyName === 'CAPITAL-EXTERNAL-INTRA');
      const capitalDeployExternalPos = _.find(strategiesSummary, s => s.strategyName === 'CAPITAL-EXTERNAL-POS');
      strategiesSummary = strategiesSummary.filter(s => s.strategyName.startsWith('CAPITAL') === false);
      const capitalStrategiesSummary = [capitalDeploy];
      if (capitalDeployExternalIntra) {
        capitalStrategiesSummary.push(capitalDeployExternalIntra);
      }
      if (capitalDeployExternalPos) {
        capitalStrategiesSummary.push(capitalDeployExternalPos);
      }
      strategiesSummary = _.concat(capitalStrategiesSummary, strategiesSummary);
    }

    return (<div>
      <Card>
        <CardBody>
          <Col xs={12} md={12} lg={config.isAutoQuant() ? 12 : 6}>
            {
              <Table className="no-wrap v-middle" size="sm" responsive>
                <thead>
                  <tr className="border-0">
                    <th className="border-0">Strategy</th>
                    <th className="border-0 number-right">Capital</th>
                    {config.isAutoQuant() && <th className="border-0 number-right">Paid Plan Capital</th>}
                    {config.isAutoQuant() && <th className="border-0 number-right">Fee Plan Capital</th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(strategiesSummary, (s) => {
                      return this.getStrategySummaryDetails(s);
                    })
                  }
                </tbody>
              </Table>
            }
          </Col>
        </CardBody>
      </Card>
    </div>);
  }
}

export default StrategiesSummary;
