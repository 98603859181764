/*
  Author: Sreenivas Doosa
*/

//import update from 'immutability-helper';
const validUrl = require('valid-url');

const leadZeros = (val, spaces = 2) => {
  var s = "";
  if (spaces > 1) {
    for (var i = 0; i < spaces - 1; i++) {
      s += "0";
    }
    s += val;
    return s.substr(s.length - spaces);
  } else {
    return val;
  }
};
module.exports.leadZeros = leadZeros;

module.exports.getLastDateOfTheMonth = (date) => {
  if (!date) {
    date = new Date();
  }
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return leadZeros(lastDay.getDate());
};

const formatNumberToCommaSeparated = (number, isDecimal = false) => {
  if (!number) {
    return isDecimal ? '0.00' : '0';
  }
  if (isDecimal) {
    number = number.toLocaleString('en-IN');
    const zerosToSuffix = number.lastIndexOf('.') === -1 ? 2 : 2 - (number.length - 1 - number.lastIndexOf('.'));
    if (number.lastIndexOf('.') === -1) {
      number = number + '.';
    }
    for (let i = 0; i < zerosToSuffix; i++) {
      number = number + '0';
    }
    return number;
  } else {
    const numberStr = number.toLocaleString('en-IN');
    return numberStr.lastIndexOf('.') > 0 ? numberStr.substring(0, numberStr.lastIndexOf('.')) : numberStr;
  }
};
module.exports.formatNumberToCommaSeparated = formatNumberToCommaSeparated;

const roundOff = (value) => {
  // NOTE: not using tofixed here as it converts to string. 
  // formatNumberToCommaSeparated() will be used for formatting while rendering on UI.
  //return parseFloat(Math.round(value * 100) / 100).toFixed(2);
  return parseFloat(Math.round(value * 100) / 100);
};
module.exports.roundOff = roundOff;

module.exports.formatDate = (epochMillis, uptoMillis = false) => {
  var d = new Date(epochMillis);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var hours = d.getHours();
  var mins = d.getMinutes();
  var seconds = d.getSeconds();
  var millis = d.getMilliseconds();
  var dateStr = year + "-" + leadZeros(month) + "-" + leadZeros(date) + " " + leadZeros(hours) + ":" + leadZeros(mins) + ":" + leadZeros(seconds);
  if (uptoMillis) {
    dateStr += "." + leadZeros(millis, 3);
  }
  return dateStr;
};

module.exports.formatTime = (epochMillis, uptoMillis = false) => {
  var d = new Date(epochMillis);
  var hours = d.getHours();
  var mins = d.getMinutes();
  var seconds = d.getSeconds();
  var millis = d.getMilliseconds();
  var timeStr = leadZeros(hours) + ":" + leadZeros(mins) + ":" + leadZeros(seconds);
  if (uptoMillis) {
    timeStr += "." + leadZeros(millis, 3);
  }
  return timeStr;
};

module.exports.formatDateToString = (d) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const dateStr = year + "-" + leadZeros(month) + "-" + leadZeros(date);
  return dateStr;
};

module.exports.formatDateToDDMMYYYY = (d) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const dateStr = leadZeros(date) + "-" + leadZeros(month) + "-" + year;
  return dateStr;
};

module.exports.formateDateToYYYYMM = (d) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  return year + '-' + leadZeros(month);
}

module.exports.parseTimestamp = (timestampStr) => {
  return new Date(Date.parse(timestampStr));
};

module.exports.calculateDaysDiff = (d1, d2) => {
  const timeDiffMillis = d1.getTime() - d2.getTime();
  return Math.abs(Math.floor(timeDiffMillis / (1000 * 60 * 60 * 24)));
};

const getMarketStartTime = () => {
  var marketStartTime = new Date();
  marketStartTime.setHours(9, 15, 0);
  return marketStartTime;
};
module.exports.getMarketStartTime = getMarketStartTime;

const getMarketEndTime = () => {
  var marketEndTime = new Date();
  marketEndTime.setHours(15, 30, 0);
  return marketEndTime;
};
module.exports.getMarketEndTime = getMarketEndTime;

module.exports.getTimeOfToday = (hours, minutes = 0, seconds = 0) => {
  var now = new Date();
  now.setHours(hours, minutes, seconds);
  return now;
};

const isMarketOpen = () => {
  const marketStartTime = getMarketStartTime();
  const marketEndTime = getMarketEndTime();

  var now = new Date();
  return now.getTime() >= marketStartTime.getTime() && now.getTime() <= marketEndTime.getTime();
};
module.exports.isMarketOpen = isMarketOpen;

module.exports.isPreMarketOpen = () => {

  var marketStartTime = new Date();
  marketStartTime.setHours(9, 0, 0);

  var marketEndTime = new Date();
  marketEndTime.setHours(15, 30, 0);

  var now = new Date();

  return now.getTime() >= marketStartTime.getTime() && now.getTime() <= marketEndTime.getTime();
};

module.exports.calculateTradesSummary = (trades) => {

  trades = trades || [];

  var realizedPL = 0, realizedCharges = 0, realizedNetPL = 0;
  var unrealizedPL = 0, unrealizedCharges = 0, unrealizedNetPL = 0;
  var totalPL = 0;
  var totalCharges = 0;
  var totalNetPL = 0;
  trades.forEach(function (t) {
    if (t) {
      if (!t.isActive) { // completed trade
        realizedPL = realizedPL + Number(t.profitLoss);
        realizedCharges = realizedCharges + Number(t.charges);
        realizedNetPL = realizedNetPL + Number(t.netProfitLoss);
      } else {
        unrealizedPL = unrealizedPL + Number(t.profitLoss);
        unrealizedCharges = unrealizedCharges + Number(t.charges);
        unrealizedNetPL = unrealizedNetPL + Number(t.netProfitLoss);
      }
      totalPL = totalPL + Number(t.profitLoss);
      totalCharges = totalCharges + Number(t.charges);
      totalNetPL = totalNetPL + Number(t.netProfitLoss);
    }
  });

  return {
    realizedPL: roundOff(realizedPL),
    realizedCharges: roundOff(realizedCharges),
    realizedNetPL: roundOff(realizedNetPL),
    unrealizedPL: roundOff(unrealizedPL),
    unrealizedCharges: roundOff(unrealizedCharges),
    unrealizedNetPL: roundOff(unrealizedNetPL),
    totalPL: roundOff(totalPL),
    totalCharges: roundOff(totalCharges),
    totalNetPL: roundOff(totalNetPL)
  };
};

const calculateIntradayPositionalCharges = (trade) => {
  // The charges calculated are as per Zerodha
  let STT_PERCENTAGE = 0.025;
  let TRANSACTION_PERCENTAGE = 0.00325;
  if (trade.isFutures) {
    STT_PERCENTAGE = 0.01;
    TRANSACTION_PERCENTAGE = 0.0019;
  } else if (trade.isOptions) {
    STT_PERCENTAGE = 0.05;
    TRANSACTION_PERCENTAGE = 0.05;
  }

  const entry = trade.entry;
  const exit = trade.isActive ? trade.entry : trade.exit;

  const brokeragePercentage = 0.03; // both zerodha and fyers
  let brokerageBuy = Math.min(entry * trade.quantity * brokeragePercentage / 100, 20);
  let brokerageSell = Math.min(exit * trade.quantity * brokeragePercentage / 100, 20);
  if (trade.isFutures || trade.isOptions) {
    brokerageBuy = 20;
    brokerageSell = 20;
  }

  const turnoverChargesBuy = entry * trade.quantity * TRANSACTION_PERCENTAGE / 100;
  const turnoverChargesSell = exit * trade.quantity * TRANSACTION_PERCENTAGE / 100;

  const brokeragePlusTurnover = brokerageBuy + brokerageSell + turnoverChargesBuy + turnoverChargesSell;
  const GST = brokeragePlusTurnover * 18 / 100;

  const STTBuy = 0;
  const STTSell = exit * trade.quantity * STT_PERCENTAGE / 100; // on sell side only

  const sebiChargesBuy = entry * trade.quantity * 0.00010 / 100; // 10/- per 1 Crore transaction value
  const sebiChargesSell = exit * trade.quantity * 0.00010 / 100;

  // for Karnataka 0.003% of transaction value
  const stampDutyBuy = entry * trade.quantity * 0.003 / 100;
  const stampDutySell = exit * trade.quantity * 0.003 / 100;

  let totalCharges = brokeragePlusTurnover + GST + STTBuy + STTSell + sebiChargesBuy + sebiChargesSell + stampDutyBuy + stampDutySell;

  if (trade.isFnO === true) {
    totalCharges = totalCharges / 2;
  }

  return roundOff(totalCharges);
};
module.exports.calculateIntradayPositionalCharges = calculateIntradayPositionalCharges;

const calculateCNCCharges = (trade) => {
  const brokerageBuy = 0;
  const brokerageSell = 0;

  const turnoverChargesBuy = trade.entry * trade.quantity * 0.00325 / 100;
  const turnoverChargesSell = trade.exit * trade.quantity * 0.00325 / 100;

  const brokeragePlusTurnover = brokerageBuy + brokerageSell + turnoverChargesBuy + turnoverChargesSell;
  const GST = brokeragePlusTurnover * 18 / 100;

  const STTBuy = trade.entry * trade.quantity * 0.1 / 100;
  const STTSell = trade.exit * trade.quantity * 0.1 / 100;

  const sebiChargesBuy = trade.entry * trade.quantity * 0.00015 / 100;
  const sebiChargesSell = trade.exit * trade.quantity * 0.00015 / 100;

  const stampDutyBuy = trade.entry * trade.quantity * 0.003 / 100;
  const stampDutySell = trade.exit * trade.quantity * 0.003 / 100;

  const DPCharges = 15; // flat

  trade.turnoverCharges = roundOff(turnoverChargesBuy + turnoverChargesSell);
  trade.brokerageCharges = roundOff(brokerageBuy + brokerageSell);
  trade.sebiCharges = roundOff(sebiChargesBuy + sebiChargesSell);
  trade.sttCharges = roundOff(STTBuy + STTSell);
  trade.stampDutyCharges = roundOff(stampDutyBuy + stampDutySell);
  trade.gstCharges = roundOff(GST);
  trade.depositoryCharges = DPCharges;

  const totalCharges = trade.turnoverCharges + trade.brokerageCharges + trade.sebiCharges + trade.sttCharges + trade.stampDutyCharges + trade.gstCharges + trade.depositoryCharges;

  return roundOff(totalCharges);
};
module.exports.calculateCNCCharges = calculateCNCCharges;

module.exports.calculateProfitLossCharges = (trade, isActive) => {
  if (isActive) {
    trade.profitLoss = trade.direction === 'LONG' ? trade.cmp - trade.entry : trade.entry - trade.cmp;
    trade.profitLoss = roundOff(trade.profitLoss) * (trade.filledQuantity || 0);
  } else { // completed trade
    trade.profitLoss = trade.direction === 'LONG' ? trade.exit - trade.entry : trade.entry - trade.exit;
    trade.profitLoss = roundOff(trade.profitLoss) * (trade.quantity || 0);
  }
  
  if (trade.product === 'INTRADAY' || trade.product === 'POSITIONAL') {
    trade.charges = calculateIntradayPositionalCharges(trade);
  } else {
    trade.charges = calculateCNCCharges(trade);
  }
  trade.netProfitLoss = roundOff(trade.profitLoss - trade.charges);
  const totalValue = trade.entry * (trade.filledQuantity || 0);
  if (totalValue > 0) {
    trade.plPercentage = roundOff(trade.netProfitLoss * 100 / totalValue);
  }
  return trade;
};

module.exports.getOrderDetailsDisplayValue = (order) => {
  order = order || {};
  let displayValue = order.orderId;
  if (order.orderStatus) {
    displayValue += ' | ' + order.orderStatus;
  }
  if (order.message) {
    displayValue += ' | ' + order.message;
  }
  return displayValue;
};

/*module.exports.updateDataImmutable = (data, fieldName, fieldValue) => {

  if (!data || !fieldName) {
    return data;
  }

  const parts = fieldName.split('.');
  let obj = null;

  const tmp = (key, updateObj) => {
    if (updateObj === null) {
      return {$set: fieldValue};
    }
    return value => update(value || {}, updateObj);
  };

  for (let i = parts.length - 1; i >= 0; i--) {
    obj = {[parts[i]]: tmp(parts[i], obj)};
  }

  return update(data, obj);
};
*/

module.exports.isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

module.exports.isValidUrl = (url) => {
  return validUrl.isUri(url);
}

const strategyToDetailsMap = {};
module.exports.updateStrategyToDetailsMappings = (strategies = []) => {
  strategies.forEach(s => {
    strategyToDetailsMap[s.name] = {
      product: s.product,
      displayName: s.displayName,
      displayOrder: s.displayOrder,
      isOverlapCapital: s.isOverlapCapital
    };
  });
  console.log('Updated strategyToDetails Mappings => ', strategyToDetailsMap);
};


module.exports.getStrategyDisplayName = (strategyName) => {
  if (strategyName === 'Total' || strategyName === 'total' || strategyName === 'All' || strategyName === 'all') {
    return 'TOTAL';
  } 
  return strategyToDetailsMap[strategyName] ? strategyToDetailsMap[strategyName].displayName || strategyName : strategyName;
};

const getStrategyDisplayOrder = (strategyName) => {
  if (strategyName === 'Total' || strategyName === 'total' || strategyName === 'All' || strategyName === 'all') {
    return 9999999;
  }
  return strategyToDetailsMap[strategyName] ? strategyToDetailsMap[strategyName].displayOrder || 0 : 0;
};
module.exports.getStrategyDisplayOrder = getStrategyDisplayOrder;

const getStrategyProduct = (strategyName) => {
  if (strategyName === 'Total' || strategyName === 'total' || strategyName === 'All' || strategyName === 'all') {
    return 'ALL';
  }
  return strategyToDetailsMap[strategyName] ? strategyToDetailsMap[strategyName].product || '' : '';
};
module.exports.getStrategyProduct = getStrategyProduct;

const isOverlapCapital = (strategyName) => {
  if (strategyName === 'Total' || strategyName === 'total' || strategyName === 'All' || strategyName === 'all') {
    return true;
  }
  return strategyToDetailsMap[strategyName] ? strategyToDetailsMap[strategyName].isOverlapCapital || false : false;
}
module.exports.isOverlapCapital = isOverlapCapital;

module.exports.sortTrades = (trades = []) => {
  trades.sort((t1, t2) => {
    if (t1.strategy !== t2.strategy) {
      return getStrategyDisplayOrder(t1.strategy) - getStrategyDisplayOrder(t2.strategy);
    }
    if (t1.group !== t2.group) {
      return t1.group.localeCompare(t2.group);
    }
    if (t1.hedgeCorrelationID && t2.hedgeCorrelationID) {
      if (t1.hedgeCorrelationID !== t2.hedgeCorrelationID) {
        return t1.hedgeCorrelationID.localeCompare(t2.hedgeCorrelationID);
      } else {
        return t1.direction.localeCompare(t2.direction);
      }
    }
    return t1.netProfitLoss - t2.netProfitLoss;
  });

  return trades;
}

module.exports.calculatePositionPnl = (pos, cmp) => {
  if (cmp > 0 && pos.netQty !== 0) {
    if (pos.netQty > 0) {
      pos.unrealizedPnl = roundOff(pos.netQty * (cmp - pos.buyAvgPrice));
    } else {
      pos.unrealizedPnl = roundOff(Math.abs(pos.netQty) * (pos.sellAvgPrice - cmp));
    }
    pos.totalPnl = pos.realizedPnl + pos.unrealizedPnl;
  }
  return pos;
};

module.exports.calculatePositionRisks = (pos, riskDistances, wrtCmp = false) => {
  //console.log('---------------- STARTS ' + pos.tradingSymbol + ' ---------------------------');
  riskDistances = riskDistances && riskDistances.length > 0 ? riskDistances : [-10, -5, -3, -1, 0, 1, 3, 5, 10];
  const risks = [];
  riskDistances.forEach(rd => {
    let pnlAtRiskDistance = 0;
    if ((pos.tradingSymbol.endsWith("CE") || pos.tradingSymbol.endsWith("PE")) &&
      (pos.productType === "MIS" || pos.productType === "NRML")) {
      let spotPrice = 0;
      let strikePrice = 0;
      if (pos.netQty === 0) { // no open qty for this position
        if (!wrtCmp) {
          pnlAtRiskDistance = pos.totalPnl;
        }
      } else {
        spotPrice = roundOff(pos.spotCMP + pos.spotCMP * rd / 100);
        if (pos.tradingSymbol.endsWith('PE')) {
          strikePrice = Math.max(pos.strike - spotPrice, 0);
        } else {
          strikePrice = Math.max(spotPrice - pos.strike, 0);
        }
        if (pos.netQty < 0) { // SHORT
          if (wrtCmp) {
            pnlAtRiskDistance = pos.cmp - strikePrice;
          } else {
            pnlAtRiskDistance = pos.sellAvgPrice - strikePrice;
          }
        } else { // LONG
          if (wrtCmp) {
            pnlAtRiskDistance = strikePrice - pos.cmp;
          } else {
            pnlAtRiskDistance = strikePrice - pos.buyAvgPrice;
          }
        }
      }
      pnlAtRiskDistance = roundOff(pnlAtRiskDistance * Math.abs(pos.netQty));
      if (!wrtCmp) {
        pnlAtRiskDistance += pos.realizedPnl; // Also add realized pnl to the total pnl risk
      }
      //console.log(`${pos.tradingSymbol} ${pos.netQty < 0 ? 'SHORT' : 'LONG'} cmp=${pos.cmp} rd=${rd} spotAtRd=${spotPrice} strikePremiumAtRd=${strikePrice} pnlAtRd=${pnlAtRiskDistance}`);
    }
    risks.push({
      rd,
      pnl: pnlAtRiskDistance
    });
  });
  //console.log('---------------- ' + pos.tradingSymbol + '  ENDS ---------------------------');
  return risks;
};

module.exports.extractIndexSymbol = (tradingSymbol) => {
  if (!tradingSymbol) {
    return null;
  }
  if (!tradingSymbol.endsWith("CE") && !tradingSymbol.endsWith("PE")) {
    // Not an option symbol
    return null;
  }
  let indexSymbol = "";
  for (let i = 0; i < tradingSymbol.length; i++) {
    if (isNaN(tradingSymbol[i])) {
      indexSymbol += tradingSymbol[i];
    } else {
      break;
    }
  }
  return indexSymbol;
};
