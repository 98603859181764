/*
  Author: Sreenivas Doosa
*/

import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';

import ReferralCodeComp from '../common/ReferralCodeComp.js';
import GetBrokerPasswordComp from '../common/GetBrokerPasswordComp.js';

class Miscellaneous extends React.Component {

  render() {

    return (<div>
      <h4>Miscellaneous</h4>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <ReferralCodeComp />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <GetBrokerPasswordComp supportedBrokers={this.props.supportedBrokers} />
        </Col>
      </Row>
    </div>);
  }
}

export default Miscellaneous;
