/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import config from '../config.js';
import Utils from '../utils/Utils.js';
import { getUserAlerts } from '../utils/RestAPIs.js';
import UserBrokerComp from '../components/common/UserBrokerComp.js';

import { 
  Alert
} from 'reactstrap';

class WrapperComponent extends React.Component {

  constructor(props) {
    super(props);

    const brokers = _.get(props.user, 'brokers', []);
    this.state = {
      alertMessages: [],
      showUserBrokerComp: brokers.length === 0 ? true : false
    };  
  }

  componentWillMount() {
    if (config.isAutoQuant()) { // Not showing alerts for xtremequant and way2quant
      this.getUserAlertsFromServer().then(alerts => {
        let alertMessages = [];
        _.each(alerts, alert => {
          alertMessages.push((<div>{alert.message}</div>));
        });

        const user = this.props.user || {};
        const accountDisabled = user.enabled === false ? true : false;
        const disabledReason = user.disabledReason || null;
        
        if (accountDisabled) {
          let message = '';
          if (disabledReason) {
            message = 'ATTENTION: Your account is disabled due to ' + disabledReason + '. Please contact administrator.';
          } else {
            message = 'ATTENTION: Your account is disabled. Please contact administrator.';
          }
          alertMessages.push((<div>{message}</div>));
        }
        if (this.props.serverConfig.isPrepaid && this.noActivePlans()) {
          const plansPageLink = config.serverHost + "/plans";
          alertMessages.push((<div>You do not have any active plans. Please renew your current plan or buy new plans to continue trading. Please visit <a href={plansPageLink}>{plansPageLink}</a> for the same.
          </div>));
        }
        this.setState({
          alertMessages
        });
      });
    }
  }

  getUserAlertsFromServer() {
    return new Promise((resolve, reject) => {
      getUserAlerts().then(alerts => {
        resolve(alerts);
      }).catch(err => {
        resolve([]);
      });
    });
  }

  noActivePlans() {
    let activePlans = [];
    let expiredPlans = [];
    const now = new Date();
    _.each(this.props.userPlans, userPlan => {
      const expiryTimestamp = new Date(userPlan.expiryDate);
      if (now > expiryTimestamp) {
        userPlan.expired = true;
        expiredPlans.push(userPlan);
      } else {
        userPlan.daysLeftToExpire = Utils.calculateDaysDiff(expiryTimestamp, now);
        activePlans.push(userPlan);
      }
    });
    return activePlans.length === 0 ? true : false;
  }
  
  closeUserBrokerComp(reload) {
    if (!reload) {
      return; // dont take any action just block the user to set up new broker
    }
    
    this.setState({
      showUserBrokerComp: false
    }, () => {
      if (reload === true) {
        // do a full reload of page
        window.location.reload();
      }
    });
  }

  render() {
    const newUserBrokerDetails = {
      username: this.props.user.username,
      broker: '',
      enabled: true,
      autoLogin: false
    };

    return (<div>
      {this.state.alertMessages.length > 0 && _.map(this.state.alertMessages, (alertMessage, index) => {
        return (<Alert color="danger" key={'alert-' + index}>
          <div style={{'marginTop': '5px', 'marginBottom': '5px'}}>
            {alertMessage}
          </div>
        </Alert>);
      })}

      {this.props.children}

      {this.state.showUserBrokerComp === true && <UserBrokerComp 
        userBrokerDetails={newUserBrokerDetails}
        isModalOpen={this.state.showUserBrokerComp}
        onCancel={this.closeUserBrokerComp.bind(this)}
        title="You should setup your broker first in order to have further access."
        showLogoutOption={true}
      />}
    </div>);
  }
}

export default WrapperComponent;
