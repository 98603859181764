/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";
import TradeRow from "./TradeRow.js";
import Utils from "../../utils/Utils.js";

import {
  Card,
  CardBody,
  //CardTitle,
  Table
} from 'reactstrap';


class OpenTrades extends React.Component {
 
  constructor(props) {
    super(props);

    this.cols = [{
      name: 'SNo',
      key: 'sno'
    }, {
      name: 'Order Placed',
      key: 'startTimestamp',
      type: 'timestamp'
    }, {
      name: 'Strategy',
      key: 'strategy'
    }, {
      name: 'Product',
      key: 'productType'
    }, {
      name: 'Group',
      key: 'group'
    }, {
      name: 'Symbol',
      key: 'tradingSymbol'
    }, {
      name: 'Direction',
      key: 'direction'
    },  {
      name: 'Qty',
      key: 'quantity'
    }, {
      name: 'Req Entry',
      key: 'requestedEntry',
      alignRight: true
    }, {
      name: 'CMP',
      key: 'cmp',
      alignRight: true
    }, {
      name: 'Stop Loss',
      key: 'stopLoss',
      alignRight: true
    }, {
      name: 'TradeID',
      key: 'tradeID'
    }, {
      name: 'Remarks',
      key: 'remarks'
    }];

    if (this.props.showLess) {
      this.cols = _.filter(this.cols, col => {
        if (col.key === 'stopLoss' || col.key === 'target'
          || col.key === 'requestedEntry' || col.key === 'exitReason'
          || col.key === 'remarks' || col.key === 'order'
          || col.key === 'slOrder' || col.key === 'targetOrder') {
          return false;
        }
        return true;
      });
    }
  }

  render() {
    let trades = Utils.sortTrades(this.props.trades);
    trades = _.map(trades, (trade, index) => {
      return {
        ...trade,
        sno: index + 1
      }
    });

    return (<Card>
      <CardBody>
        {/*<div className="d-flex align-items-center">
          <div>
            <CardTitle>Open Trades</CardTitle>
          </div>
        </div>*/}
        <div style={this.props.fixHeight ? { maxHeight: "calc(100vh - 300px)", overflowY: "auto" } : {}}>
          <Table className="no-wrap v-middle" size="sm" responsive>
            <thead style={{ position: "sticky", top: "0" }}>
              <tr className="border-0">
                {
                  this.cols.map((col, index) => {
                    return (<th className={col.alignRight ? "border-0 number-right" : "border-0"} key={index}>{col.name}</th>);
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                trades.map(td => {
                  return (
                    <TradeRow 
                      cols={this.cols}
                      trade={td}
                      key={"trade" + td.sno} />
                  );
                })
              }
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>);
  }
}

export default OpenTrades;

