/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table
} from 'reactstrap';

import { getUserPayments } from "../../utils/RestAPIs.js";
import Utils from "../../utils/Utils.js";

class Payments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      error: null
    };
  }

  componentWillMount() {
    getUserPayments().then(payments => {
      this.setState({
        payments
      });
    }).catch(err => {
      this.setState({
        error: err
      });
    })
  }

  getPayment(payment = {}, sno) {
    return (
      <tr key={"payment-" + payment.receiptId}>
        <td>{sno}</td>
        <td>{payment.orderId}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(payment.amount, true)}</td>
        <td>{payment.planName}</td>
        <td className="number-right">{payment.validityPeriodDays} days</td>
        <td>{_.startCase(payment.status)}</td>
        <td>{payment.paymentId}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(payment.amountRefunded, true)}</td>
        <td>{Utils.formatDate(payment.lastUpdatedAt)}</td>
      </tr>
    );
  }

  getAllPayments() {

    const payments = this.state.payments;

    return (<Card>
      <CardBody>
        <div>
          {this.state.fetchingDataInProgress === true && <div>
              Fetching payments in progress...
          </div>}
          {this.state.error && <div className='text-orange'>
            {this.state.error}
          </div>}
          {!this.state.fetchingDataInProgress && !this.state.error 
            && (!this.state.payments || this.state.payments.length === 0) && <div>
            No payments found.
          </div>}
          {this.state.payments && this.state.payments.length > 0 && <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">SNo</th>
                <th className="border-0">Order Id</th>
                <th className="border-0 number-right">Amount Paid</th>
                <th className="border-0">Plan</th>
                <th className="border-0 number-right">Validity</th>
                <th className="border-0">Status</th>
                <th className="border-0">Payment ID</th>
                <th className="border-0 number-right">Amount Refunded</th>
                <th className="border-0">Last Updated At</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(payments, (payment, index) => {
                  return this.getPayment(payment, index + 1);
                })
              }
            </tbody>
          </Table>}
        </div>
      </CardBody>
    </Card>);
  }

  render() {

    return (<div>
      <h4>Payments</h4>
      <div>
        {this.getAllPayments()}
      </div>
    </div>);
  }
}

export default Payments;
