/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import AlertModal from '../common/AlertModal.js';
import UserBrokerComp from '../common/UserBrokerComp.js';

import HttpRequest from "request";
import config from "../../config.js";

class UsersBrokersMap extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedUserBrokerDetails: null,
      showUserBrokerComp: false,
      filterTextUser: null
    };

    this.launchUserBrokerComp = this.launchUserBrokerComp.bind(this);
    this.closeUserBrokerComp = this.closeUserBrokerComp.bind(this);
    this.launchAlertModal = this.launchAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
    this.onUserFilterTextChange = this.onUserFilterTextChange.bind(this);
  }

  launchUserBrokerComp(userBrokerDetails) {
    this.setState({
      selectedUserBrokerDetails: userBrokerDetails,
      showUserBrokerComp: true
    });
  }

  closeUserBrokerComp(reload) {
    this.setState({
      selectedUserBrokerDetails: null,
      showUserBrokerComp: false
    }, () => {
      if (reload === true && _.isFunction(this.props.fetchUsersDetails)) {
        // TODO: Need to optimize to call only changed user broker details
        this.props.fetchUsersDetails(); 
      }
    });
  }

  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null,

    });
  }

  onUserFilterTextChange(e) {
    this.setState({
      filterTextUser: e.target.value
    });
  }

  testAutoLogin(userBrokerDetails = {}) {

    this.setState({
      testAutoLoginUsername: userBrokerDetails.username,
      testAutoLoginBroker: userBrokerDetails.broker
    });
    const data = {
      username: userBrokerDetails.username,
      broker: userBrokerDetails.broker
    };
    
    HttpRequest.post({
      url: config.serverHost + "/apis/broker/login/auto",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('testAutoLogin status code = ' + resp.statusCode);
      const title = `Test Auto Login : ${userBrokerDetails.username}-${userBrokerDetails.broker}`;
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.launchAlertModal(title, 'Auto Login successful');
      } else if (resp.statusCode === 404) {
        this.launchAlertModal(title, 'Auto Login End point not valid');
      } else {
        console.error('testAutoLogin error => ', respBody);
        const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
        this.launchAlertModal(title, error);
      }
      this.setState({
        testAutoLoginUsername: null,
        testAutoLoginBroker: null
      });
    });
  }

  getUserBrokerDetails(ub = {}, sno) {
    const testAutoLoingInProgress = this.state.testAutoLoginUsername === ub.username 
      && this.state.testAutoLoginBroker === ub.broker;
      
    return (
      <tr key={"user-" + ub.username + ub.broker}>
        <td>{sno}</td>
        <td>{ub.username}</td>
        <td>{_.toUpper(ub.broker)}</td>
        <td>{ub.clientID}</td>
        <td className={ub.enabled === true ? 'text-success' : 'text-orange'}>
          {ub.enabled === true ? 'Enabled' : 'Disabled'}
        </td>
        <td>{ub.appKey}</td>
        <td>{ub.appSecret}</td>
        <td className={ub.autoLogin === true ? 'text-success' : 'text-orange'}>
          {ub.autoLogin === true ? 'Enabled' : 'Disabled'}
        </td>
        <td>
          <Button 
            color="primary" 
            onClick={() => this.launchUserBrokerComp(ub)}>
              {_.isEmpty(ub.broker) ? 'ADD' : 'EDIT'}
          </Button>
          {ub.autoLogin === true && <Button 
            style={{'marginLeft': '5px'}}
            color="secondary" 
            disabled={testAutoLoingInProgress}
            onClick={() => this.testAutoLogin(ub)}>
              {testAutoLoingInProgress ? 'Testing Auto Login...' : 'Test Auto Login'}
          </Button>}
        </td>
      </tr>
    );
  }

  render() {
    let users = this.props.users;
    if (_.isEmpty(this.state.filterTextUser) === false) {
      users = _.filter(users, user => {
        if (_.includes(_.toLower(user.username), _.toLower(this.state.filterTextUser))) {
          return true;
        }
        return false;
      });
    }

    const userBrokerList = [];
    _.each(users, user => {
      if (!user.brokers || user.brokers.length === 0) {
        userBrokerList.push({
          username: user.username,
          broker: '',
          enabled: true,
          autoLogin: false
        });
      } else {
        _.each(user.brokers, broker => {
          userBrokerList.push({
            username: user.username,
            broker: broker.broker,
            clientID: broker.clientID,
            clientPassword: broker.clientPassword,
            clientPIN: broker.clientPIN,
            panOrDOB: broker.panOrDOB,
            enabled: broker.enabled,
            appKey: broker.appKey,
            appSecret: broker.appSecret,
            xtremeAgentUrl: broker.xtremeAgentUrl,
            autoLogin: broker.autoLogin,
            useApiOf: broker.useApiOf,
            loginVerified: broker.loginVerified,
            brokeragePlan: broker.brokeragePlan
          });
        });
      }
    });

    return (<div>
      <Card>
        <CardBody>
          <div className="filter-inputs">
            <div className="filter-input">
              <label>Filter By:</label>
              <input 
                value={this.state.filterTextUser || ''}
                placeholder="username" 
                onChange={this.onUserFilterTextChange}></input>
            </div>
          </div>
          <div>
            {userBrokerList.length > 0 ? <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Username</th>
                  <th className="border-0">Broker</th>
                  <th className="border-0">Client ID</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">API Key</th>
                  <th className="border-0">API Secret</th>
                  <th className="border-0">Auto Login</th>
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(userBrokerList, (ub, index) => {
                    return this.getUserBrokerDetails(ub, index + 1);
                  })
                }
              </tbody>
            </Table> : 'No users brokers details found'
            }
          </div>
        </CardBody>
      </Card>
      {this.state.showUserBrokerComp && <UserBrokerComp 
        userBrokerDetails={this.state.selectedUserBrokerDetails}
        isModalOpen={this.state.showUserBrokerComp}
        onCancel={this.closeUserBrokerComp}
        allowAutoLogin={true}
        supportedBrokers={this.props.supportedBrokers}
        isAdmin={true}
      />}
      <AlertModal
        isModalOpen={this.state.showAlertModal}
        title={this.state.alertModalTitle}
        message={this.state.alertModalMessage}
        onCancel={this.closeAlertModal}
      />
    </div>);
  }
}

export default UsersBrokersMap;