/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import UserStrategiesModal from '../common/UserStrategiesModal.js';

import config from '../../config.js';
import Utils from "../../utils/Utils.js";

class UsersStrategiesMap extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filterTextUser: null,
      filterTextStrategy: null,
      userToEdit: null,
      brokerToEdit: null,
      allocationModelToEdit: null,
      showUserStrategiesModal: false
    }

    this.onUserFilterTextChange = this.onUserFilterTextChange.bind(this);
    this.onStrategyFilterTextChange = this.onStrategyFilterTextChange.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.closeUserStrategiesModal = this.closeUserStrategiesModal.bind(this);
  }

  onEditButtonClick(user = {}, broker, allocationModel) {
    this.setState({
      showUserStrategiesModal: true,
      userToEdit: user,
      brokerToEdit: broker,
      allocationModelToEdit: allocationModel
    });
  }

  closeUserStrategiesModal(reload = false) {
    this.setState({
      showUserStrategiesModal: false,
      userToEdit: null
    }, () => {
      if (reload) {
        this.props.reloadUsers();
      }
    });
  }

  getUserStrategyDetails(user = {}, sno) {
    let userStrategies = user.strategies;
    if (_.isEmpty(this.state.filterTextStrategy) === false) {
      userStrategies = _.filter(user.strategies, strategy => {
        if (_.startsWith(_.toLower(Utils.getStrategyDisplayName(strategy.strategyName)), _.toLower(this.state.filterTextStrategy))) {
          return true;
        }
        return false;
      });
      if (userStrategies.length === 0) {
        return null;
      }
    }
    // sort by display order
    userStrategies.sort((s1, s2) => {
      const s1Details = _.find(this.props.strategies, sg => sg.name === s1.strategyName);
      const s2Details = _.find(this.props.strategies, sg => sg.name === s2.strategyName);
      if (s1Details && s2Details) {
        return s1Details.displayOrder - s2Details.displayOrder;
      }
      return 0;
    });

    let totalCapital = 0;
    if (config.isAllocationModelEnabled()) {
      totalCapital = (_.find(userStrategies, us => us.strategyName === 'CAPITAL-DEPLOYED') || {}).capital;
      //userStrategies = _.filter(userStrategies, us => us.strategyName !== 'CAPITAL-DEPLOYED');
    } else {
      _.each(userStrategies, us => {
        if (!us.isOverlapCapital) {
          totalCapital += us.capital;
        }
      });
    }

    return (
      <tr key={"user-" + user.username}>
        <td>{sno}</td>
        <td>{user.username}</td>
        <td>
          <Table size="sm">
            <tbody>
              {
                _.map(userStrategies, us => {
                  return (<tr key={us.strategyName}>
                    <td>{_.toUpper(Utils.getStrategyDisplayName(us.strategyName))}</td>
                    <td>{_.toUpper(us.broker)}</td>
                    <td className={us.enabled === true ? 'text-success' : 'text-orange'}>
                      {us.enabled === true ? 'Enabled' : 'Disabled'}
                    </td>
                    <td className="number-right">{Utils.formatNumberToCommaSeparated(us.capital)}</td>
                  </tr>);
                })
              }
            </tbody>
          </Table>
        </td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(totalCapital)}</td>
        <td>
          {
            _.map(user.brokers, b => {
              return (<Button color="primary" key={b.broker}
                style={{'marginRight': '10px'}}
                onClick={() => this.onEditButtonClick(user, b.broker, b.allocationModel)}>EDIT {'(' + b.broker + ')'}</Button>
              );
            })
          }
        </td>
      </tr>
    );
  }

  onUserFilterTextChange(e) {
    this.setState({
      filterTextUser: e.target.value
    });
  }

  onStrategyFilterTextChange(e) {
    this.setState({
      filterTextStrategy: e.target.value
    });
  }

  render() {

    let users = this.props.users;

    if (_.isEmpty(this.state.filterTextUser) === false) {
      users = _.filter(users, user => {
        if (_.includes(_.toLower(user.username), _.toLower(this.state.filterTextUser))) {
          return true;
        }
        return false;
      });
    }

    const filteredUsers = _.filter(users, user => {
      if (!user.brokers || user.brokers.length === 0) {
        // If broker is not configured then do not show it
        return false;
      }
      if (_.isEmpty(this.state.filterTextStrategy) === false) {
        const userStrategies = _.filter(user.strategies, strategy => {
          if (_.startsWith(_.toLower(Utils.getStrategyDisplayName(strategy.strategyName)), _.toLower(this.state.filterTextStrategy))) {
            return true;
          }
          return false;
        });
        if (userStrategies.length === 0) {
          return false;
        }
      }
      return true;
    });
    
    return (<div>
      <Card>
        <CardBody>
          <div>
            <div className="filter-inputs">
              <div className="filter-input">
                <label>Filter By:</label>
                <input 
                  value={this.state.filterTextUser || ''}
                  placeholder="username" 
                  onChange={this.onUserFilterTextChange}></input>
              </div>
              <div className="filter-input">
                <label>Filter By:</label>
                <input 
                  value={this.state.filterTextStrategy || ''}
                  placeholder="strategy" 
                  onChange={this.onStrategyFilterTextChange}></input>
              </div>
            </div>
            {users.length > 0 ? <Table className="no-wrap v-middle" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Username</th>
                  <th className="border-0">Strategies</th>
                  <th className="border-0 number-right">Total Capital</th>
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(filteredUsers, (user, index) => {
                    return this.getUserStrategyDetails(user, index + 1);
                  })
                }
              </tbody>
            </Table> : 'No users strategy details found'
            }
          </div>
        </CardBody>
      </Card>
      {this.state.showUserStrategiesModal && <UserStrategiesModal
        isModalOpen={this.state.showUserStrategiesModal}
        username={this.state.userToEdit.username}
        broker={this.state.brokerToEdit}
        selectedAllocationModel={this.state.allocationModelToEdit}
        allocationModels={this.props.allocationModels}
        allStrategies={this.props.strategies}
        userStrategies={this.state.userToEdit.strategies}
        onCancel={this.closeUserStrategiesModal}
        fromClientManager={true}
        showMoreOptions={true}
      />}
    </div>);
  }
}

export default UsersStrategiesMap;
