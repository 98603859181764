/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardTitle,
  CardBody,
  Button,
  Alert
} from 'reactstrap';

import Utils  from '../../utils/Utils.js';
import { generateReferralCode } from "../../utils/RestAPIs.js";
import { getInputField } from './FormFields.js';

class ReferralCodeComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      validations: {},
      fetchingDataInProgress: false,
      updateInProgress: false,
      statusMessage: null,
      error: null
    };

  }

  generateReferralCode(e) {
    this.setState({
      updateInProgress: true,
      statusMessage: null,
      error: null
    });

    const data = {
      email: _.get(this.state.data, 'referral_email')
    };

    generateReferralCode(data).then(resp => {
      this.setState({
        updateInProgress: false,
        statusMessage: 'Generated referral code ' + resp.referralCode + ' and sent email to ' + resp.email
      });
    }).catch(err => {
      this.setState({
        updateInProgress: false,
        error: err
      });
    });
  }

  getValidationError(key, value) {
    if (_.isEmpty(value)) {
      return null;
    }
    if (key === 'referral_email') {
      if (Utils.isValidEmail(value) === false) {
        return 'Please enter valid email';
      }
    }
    return null;
  }

  onFieldChange(key, event) {
    const value = event.target ? event.target.value : '';
    console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const data = {...this.state.data};
    _.set(data, key, value);

    const newValidations = {...this.state.validations};
    newValidations[key] = this.getValidationError(key, value);

    this.setState({
      data,
      validations: newValidations,
      statusMessage: null,
      error: null
    });
  }

  isFormValid() {
    if (_.isEmpty(_.get(this.state.data, 'referral_email'))) {
      return false;
    }

    let isValid = true;
    _.each(this.state.validations, (v, key) => {
      if (v) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  render() {
    const showAlert = this.state.error || this.state.statusMessage || this.state.updateInProgress;
    const error = this.state.error;

    return (<Card>
      <CardBody>
        <div className="d-flex align-items-center">
          <div>
            <CardTitle>Referral Codes</CardTitle>
          </div>
        </div>
        {getInputField({
          key: 'referral_email',
          label: 'Enter email',
          value: _.get(this.state.data, 'referral_email'),
          onChange: (e) => this.onFieldChange('referral_email', e)
        }, this.state.validations)}

        {showAlert && <Alert color={error ? "danger" : "success"}>
          <div style={{'marginTop': '5px', 'marginBottom': '5px'}} >
            {error && <div>{error}</div>}
            {this.state.statusMessage && <div>{this.state.statusMessage}</div>}
            {this.state.updateInProgress && <div>Request is in progress. Please wait...</div>}
          </div>
        </Alert>}

        <Button 
          color="primary" 
          disabled={this.state.updateInProgress || !this.isFormValid()}
          onClick={this.generateReferralCode.bind(this)}>
            Generate Referral Code
        </Button>
      </CardBody>
    </Card>);
  }
}

export default ReferralCodeComp;
