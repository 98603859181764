/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";
import Utils from "../../utils/Utils.js";

class TradeRow extends React.Component {

  render() {
    let className = this.props.trade.color ? 'row-color-odd' : 'row-color-even';
    if (this.props.trade.sno > 1 && this.props.trade.separator) {
      className += ' row-separator';
    }
    return (
      <tr key={this.props.trade.sno} className={className}>
        {
          _.map(this.props.cols, col => {
            let value = this.props.trade[col.key];
            if (col.key === 'strategy') {
              value = Utils.getStrategyDisplayName(value);
            } else if (col.key === 'tradeID') {
              // extract the last part of trade ID
              const parts = _.split(value, '-');
              value = parts[parts.length - 1];
            }
            if (col.type === 'timestamp') {
              return (<td key={col.key}>{this.props.trade.product === 'INTRADAY' ? Utils.formatTime(value, true) : Utils.formatDate(value, true)}</td>);
            } else if (col.type === 'plus-minus') {
              return (<td key={col.key} className={value > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(value, true) + (col.suffix ? " " + col.suffix : "")}</td>);
            } else if (col.key === 'quantity') {
              return (<td key={col.key}>{`${this.props.trade.filledQuantity}/${value}`}</td>);
            } else if (col.key !== 'sno' && _.isNumber(value)) {
              return (<td key={col.key} className="number-right">{Utils.formatNumberToCommaSeparated(value, true)}</td>);
            } else if (col.key === 'group' && this.props.trade.group === 'DEFAULT') {
              return (<td key={col.key}>{"-"}</td>);
            }
            return (<td key={col.key}>{col.getValue ? col.getValue(this.props.trade) : value}</td>);
          })
        }
      </tr>
    );
  }
}

export default TradeRow;
