/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Table, FormGroup } from 'reactstrap';
import AlertModal from '../common/AlertModal.js';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class UserStrategiesModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedAllocationModel: this.props.selectedAllocationModel || 'Retail', // Retail is Default model
      strategies: []
    };

    this.handleStrategyCapitalChange = this.handleStrategyCapitalChange.bind(this);
    this.updateStrategies = this.updateStrategies.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
  }

  componentWillMount() {
    this.setState({
      strategies: this.prepareStrategiesFromProps()
    });
  }

  getAllocationModelOptions() {
    const options = [];
    _.each(this.props.allocationModels, model => {
      options.push({
        label: `${model.name} (${model.capital})`,
        value: model.name
      });
    });
    return options;
  }

  prepareStrategiesFromProps(props) {
    props = props || this.props;

    let strategies = _.filter(props.userStrategies, us => us.broker === props.broker);
    _.each(props.allStrategies, sg => {
      if (_.some(props.userStrategies, us => us.strategyName === sg.name && us.broker === props.broker) === false) {
        strategies.push({
          username: props.username,
          broker: props.broker,
          strategyName: sg.name,
          capital: 0,
          minCapital: sg.capitalPerLot,
          isOverlapCapital: sg.isOverlapCapital,
          strategyDisplayOrder: 0
        });
      }
    });
    _.each(strategies, s => {
      const strategyDetails = _.find(props.allStrategies, sg => sg.name === s.strategyName);
      if (strategyDetails) {
        s.strategyDisplayName = strategyDetails.displayName;
        s.strategyDisplayOrder = strategyDetails.displayOrder;
      }
    });

    const totalStrategy = {
      strategyName: 'Total',
      capital: 0,
      strategyDisplayOrder: Utils.getStrategyDisplayOrder('Total')
    };
    _.each(strategies, s => {
      if (!s.isOverlapCapital) {
        totalStrategy.capital += s.capital;
      }
    });
    if (!config.isAllocationModelEnabled()) {
      // Dont display total strategy row if allocation model is enabled
      strategies.push(totalStrategy);
    }
      
    strategies = _.map(strategies, s => {
      return {
        ...s,
        newCapital: s.capital,
        oldEnabled: s.enabled ? true : false,
        enabled: s.enabled ? true : false,
        tranchTimings: s.tranchTimings ? s.tranchTimings.join(',') : '', // convert to string
        newtranchTimings: s.tranchTimings ? s.tranchTimings.join(',') : '',
        newoptionPremium: s.optionPremium,
        newslPercentage: s.slPercentage,
        newtrailingLogic: s.trailingLogic
      }
    });

    // sort by display order
    strategies.sort((s1, s2) => s1.strategyDisplayOrder - s2.strategyDisplayOrder);
    console.log('strategies => ', strategies);

    return strategies;
  }

  isStrategySupportsTranches(strategyName) {
    const strategyDetails = _.find(this.props.allStrategies, sg => sg.name === strategyName);
    return strategyDetails ? strategyDetails.supportTranches : false;
  }

  getNumberOfTranches(strategyName) {
    const strategyDetails = _.find(this.props.allStrategies, sg => sg.name === strategyName);
    return strategyDetails && strategyDetails.tranchTimings ? strategyDetails.tranchTimings.length : 0;
  }

  getTotalNewCapital(strategies) {
    strategies = strategies || this.state.strategies;
    let totalNewCapital = 0;
    _.each(strategies, s => {
      if (s.strategyName !== 'Total' && !s.isOverlapCapital) {
        totalNewCapital += s.newCapital;
      }
    });
    return totalNewCapital;
  }

  handleStrategyCapitalChange(strategy, e) {
    let allocationModelEnabled = config.isAllocationModelEnabled();
    const value = e.target.value;
    console.log(strategy + ' ' + value);
    let strategies = _.map(this.state.strategies, s => {
      if (s.strategyName === strategy.strategyName) {
        let newCapital = _.toInteger(value);
        if (allocationModelEnabled || strategy.strategyName !== 'CAPITAL-DEPLOYED') {
          if (newCapital % strategy.minCapital !== 0) {
            newCapital = Math.floor(newCapital / strategy.minCapital) * strategy.minCapital;
            e.target.setAttribute("value", newCapital); // This is work around to sync value and attribute value even not blurred to consider the updated value while stepping up/down
          }
        }
        if (newCapital < 0) {
          newCapital = 0;
        }
        return {
          ...s,
          newCapital
        }
      }
      return s;
    });
    
    if (allocationModelEnabled) {
      if (strategy.strategyName === 'CAPITAL-DEPLOYED') {
        // Alter all strategies based on allocation model
        const strategyCapitalDeploy = _.find(strategies, sg => sg.strategyName === strategy.strategyName);
        const numOfSetsCapitalDeploy = Math.floor(strategyCapitalDeploy.newCapital / strategyCapitalDeploy.minCapital);
        const allocationModel = _.find(this.props.allocationModels, am => am.name === this.state.selectedAllocationModel);
        _.each(allocationModel.strategiesList, as => {
          const s = _.find(strategies, sg => sg.strategyName === as.strategyName);
          if (s) {
            s.newCapital = numOfSetsCapitalDeploy * as.numOfSets * s.minCapital;
            s.enabled = true;
          } else {
            console.log('strategy  ' + as.strategyName + ' not found in global list. something is wrong.');
          }
        });
        // remove strategies if any that are not in the selected allocation model
        _.each(strategies, s => {
          if (s.strategyName === 'CAPITAL-DEPLOYED' ||
            s.strategyName === 'CAPITAL-EXTERNAL-INTRA' ||
            s.strategyName === 'CAPITAL-EXTERNAL-POS' ||
            s.strategyName === 'Total') {
            return;
          }
          if (_.some(allocationModel.strategiesList, as => as.strategyName === s.strategyName) === false) {
            // Removing is done by setting capital to 0 and disabling
            s.newCapital = 0;
            s.enabled = false;
          }
        })
      }
    }

    const totalNewCapital = this.getTotalNewCapital(strategies);
    const totalStrategy = _.find(strategies, s => s.strategyName === 'Total');
    if (totalStrategy) {
      totalStrategy.newCapital = totalNewCapital;
    }

    // console.log('handleStrategyCapitalChange strategies => ', strategies);
    this.setState({
      strategies
    });
  }

  handleFieldChange(strategyName, fieldName, e) {
    const value = e.target.value;
    const strategies = [...this.state.strategies];
    _.each(strategies, s => {
      if (s.strategyName === strategyName) {
        if (fieldName === 'slPercentage') {
          s['new' + fieldName] = _.toNumber(value);
        } else if (fieldName === 'optionPremium') {
          s['new' + fieldName] = _.toInteger(value);
        } else {
          s['new' + fieldName] = _.trim(value);
        }
        return;
      }
    });
    
    // console.log('handleFieldChange strategies => ', strategies);
    this.setState({
      strategies
    });
  }

  toggleEnableStrategy(strategyName, e) {
    const checked = e.target.checked ? true : false;
    const strategies = [...this.state.strategies];
    _.each(strategies, s => {
      if (s.strategyName === strategyName) {
        s.enabled = checked;
        return;
      }
    });
    this.setState({
      strategies
    });
  }

  isCapitalChanged(strategy) {
    if (strategy) {
      return _.some(this.state.strategies, s => s.capital !== s.newCapital && s.strategyName === strategy);
    } else {
      return _.some(this.state.strategies, s => s.capital !== s.newCapital);
    }
  }

  isFieldChanged(strategy, fieldName) {
    if (strategy) {
      return _.some(this.state.strategies, s => {
        if (s.strategyName === strategy) {
          if (s[fieldName] !== s['new' + fieldName]) {
            return true;
          }
        }
        return false;
      });
    } else {
      return _.some(this.state.strategies, s => {
        if (s[fieldName] !== s['new' + fieldName]) {
          return true;
        }
        return false;
      });
    }
  }

  isEnabledToggled() {
    return _.some(this.state.strategies, s => s.oldEnabled !== s.enabled);
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null
    });

    if (this.state.strategyDetailsUpdated) {
      if (_.isFunction(this.props.onCancel)) {
        this.props.onCancel(true); // pass reload=true
      }
    }
  }

  updateStrategies(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      strategyDetailsUpdated: false,
      statusMessage: null,
      error: null
    });
    
    let updatedStrategies = _.map(this.state.strategies, s => {
      const updatedData = {
        ...s,
        oldCapital: s.capital,
        newCapital: s.newCapital
      };
      if (!updatedData.enabled && updatedData.oldCapital === 0 && updatedData.newCapital > 0) {
        updatedData.enabled = true;
      }
      _.each(['tranchTimings', 'optionPremium', 'slPercentage', 'trailingLogic'], field => {
        if (updatedData[field] !== updatedData['new' + field]) {
          updatedData[field] = updatedData['new' + field];
          updatedData.updated = true;
        } else {
          delete updatedData[field];
        }
      });
      return updatedData;
    }).filter(s => {
      if (s.strategyName === 'Total') {
        return false;
      }
      if (s.oldCapital !== s.newCapital) {
        return true;
      }
      if (s.oldEnabled !== s.enabled) {
        return true;
      }
      return s.updated || false;
    }).map(s => {
      return _.omit(s, ['oldEnabled', 'newtranchTimings', 'newoptionPremium', 'newslPercentage', 'newtrailingLogic', 'updated']);
    });

    const data = {
      username: this.props.username,
      broker: this.props.broker,
      allocationModel: this.state.selectedAllocationModel,
      strategies: updatedStrategies
    };

    HttpRequest.put({
        url: config.serverHost + "/apis/user/strategies/update",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }, (err, resp, respBody) => {
        console.log('Update strategies details status code = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          this.setState({
            statusMessage: 'Successfully updated strategies',
            updateInProgress: false,
            strategyDetailsUpdated: true
          }, () => {
            this.launchAlertModal('Status', 'Successfully updated strategies');
          });
        } else if (resp.statusCode === 404) {
          const error = 'Update strategies details end point not found';
          this.setState({
            error,
            updateInProgress: false
          });
        } else {
          console.error('Update strategies details error => ', respBody);
          const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
          this.setState({
            error,
            updateInProgress: false
          });
        }
      });
  }

  getStrategyRow(strategy = {}) {
    const newCapital = strategy.newCapital;
    let error = !strategy.minCapital || (newCapital % strategy.minCapital === 0) ? null : `Should be multiples of ${strategy.minCapital}`;
    let warning = null;
    if (!error && newCapital < 0) {
      error = "Negative number not allowed";
    }
    /*if (strategy.strategyName === 'CAPITAL-DEPLOYED') {
      if (strategy.newCapital < this.getTotalNewCapital()) {
        warning = "Deployed capital is less than total capital";
      }
    }*/
    let tranchError = null, trailError = null;
    const supportsTranches = this.isStrategySupportsTranches(strategy.strategyName);
    if (supportsTranches) {
      const requiredTranches = this.getNumberOfTranches(strategy.strategyName);
      if (strategy.newtranchTimings && strategy.newtranchTimings.split(',').length !== requiredTranches) {
        tranchError = 'Reqd tranches ' + requiredTranches;
      } else if (strategy.newtrailingLogic && strategy.newtrailingLogic.split(',').length !== requiredTranches) {
        trailError = 'Reqd tranches ' + requiredTranches;
      }
    }

    const showMoreOptions = this.props.showMoreOptions === true && strategy.strategyName !== 'CAPITAL-DEPLOYED';

    return (<tr key={strategy.strategyName}>
      <td style={{'verticalAlign': 'middle'}}>{strategy.strategyDisplayName || strategy.strategyName}</td>
      <td style={{'verticalAlign': 'middle'}}>{Utils.formatNumberToCommaSeparated(strategy.capital)}</td>
      <td style={{'verticalAlign': 'middle'}}>
        <Input type="number" step={strategy.minCapital > 0 ? strategy.minCapital : 1} 
          name="strategyCapital" id="strategyCapital"
          className={this.isCapitalChanged(strategy.strategyName) ? "text-success" : ""}
          value={newCapital}
          placeholder="Enter capital"
          disabled={this.state.updateInProgress || strategy.strategyName === 'Total'}
          onChange={(e) => this.handleStrategyCapitalChange(strategy, e)} />
        {warning && <div className="text-warning">{warning}</div>}
        {error && <div className="text-danger">{error}</div>}
      </td>
      {showMoreOptions && <td style={{'verticalAlign': 'middle'}}>
        <Input type="text" 
          name="tranchTimings" id="tranchTimings"
          className={this.isFieldChanged(strategy.strategyName, 'tranchTimings') ? "text-success" : ""}
          value={strategy.newtranchTimings}
          placeholder=""
          disabled={this.state.updateInProgress || strategy.strategyName === 'Total' || !supportsTranches}
          onChange={(e) => this.handleFieldChange(strategy.strategyName, 'tranchTimings', e)} />
        {tranchError && <div className="text-danger">{tranchError}</div>}
      </td>}
      {showMoreOptions && <td style={{'verticalAlign': 'middle'}}>
        <Input type="number" step={5} min={10} max={200}
          name="optionPremium" id="optionPremium"
          className={this.isFieldChanged(strategy.strategyName, 'optionPremium') ? "text-success" : ""}
          value={strategy.newoptionPremium}
          placeholder=""
          disabled={this.state.updateInProgress || strategy.strategyName === 'Total' || !supportsTranches}
          onChange={(e) => this.handleFieldChange(strategy.strategyName, 'optionPremium', e)} />
      </td>}
      {showMoreOptions && <td style={{'verticalAlign': 'middle'}}>
        <Input type="number" step={5} min={10} max={100} 
          name="slPercentage" id="slPercentage"
          className={this.isFieldChanged(strategy.strategyName, 'slPercentage') ? "text-success" : ""}
          value={strategy.newslPercentage}
          placeholder=""
          disabled={this.state.updateInProgress || strategy.strategyName === 'Total' || !supportsTranches}
          onChange={(e) => this.handleFieldChange(strategy.strategyName, 'slPercentage', e)} />
      </td>}
      {showMoreOptions && <td style={{'verticalAlign': 'middle'}}>
        <Input type="text" 
          name="trailingLogic" id="trailingLogic"
          className={this.isFieldChanged(strategy.strategyName, 'trailingLogic') ? "text-success" : ""}
          value={strategy.newtrailingLogic}
          placeholder=""
          disabled={this.state.updateInProgress || strategy.strategyName === 'Total' || !supportsTranches}
          onChange={(e) => this.handleFieldChange(strategy.strategyName, 'trailingLogic', e)} />
        {trailError && <div className="text-danger">{trailError}</div>}
      </td>}
      <td style={{'verticalAlign': 'middle', textAlign:"center"}}>
        {!strategy.strategyName.startsWith('CAPITAL') ? <Label check>
          <Input type="checkbox"
            style={{'position': 'relative'}}
            checked={strategy.enabled}
            onChange={(e) => this.toggleEnableStrategy(strategy.strategyName, e)} 
          />
        </Label> : null}
      </td>
    </tr>);
  }

  shouldDisableUpdateButton() {
    if (this.state.updateInProgress) {
      return true;
    }
    const dataChanged = this.isCapitalChanged() || this.isEnabledToggled()
      || this.isFieldChanged(null, 'tranchTimings') || this.isFieldChanged(null, 'optionPremium')
      || this.isFieldChanged(null, 'slPercentage') || this.isFieldChanged(null, 'trailingLogic');
    
    console.log('dataChanged = ' + dataChanged);
    if (!dataChanged) {
      return true;
    }

    let shouldDisable = false;
    _.each(this.state.strategies, strategy => {
      const requiredTranches = this.getNumberOfTranches(strategy.strategyName);
      if (requiredTranches > 1) {
        if (strategy.newtranchTimings && strategy.newtranchTimings.split(',').length !== requiredTranches) {
          shouldDisable = true;
          return false; // break the loop
        } else if (strategy.newtrailingLogic && strategy.newtrailingLogic.split(',').length !== requiredTranches) {
          shouldDisable = true;
          return false; // break the loop
        }
      }
    });

    if (!shouldDisable) {
      const capitalStrategy = _.find(this.state.strategies, s => s.strategyName === 'CAPITAL-DEPLOYED');
      if (capitalStrategy) { 
        console.log('capitalStrategy new capital = ' + capitalStrategy.newCapital);
        console.log('Total capital = ' + this.getTotalNewCapital());
        if (capitalStrategy.newCapital < this.getTotalNewCapital()) {
          //shouldDisable = true; // not disbaling update button
        }
      }
    }

    return shouldDisable;
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input valid
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  render() {
    let strategies = [];
    const capitalStrategy = _.find(this.state.strategies, s => s.strategyName === 'CAPITAL-DEPLOYED');
    if (capitalStrategy) {
      const allocationModel = _.find(this.props.allocationModels, am => am.name === this.state.selectedAllocationModel);
      if (allocationModel) {
        capitalStrategy.minCapital = allocationModel.capital;
      }
      strategies.push(capitalStrategy);
    }
    strategies = _.concat(strategies, _.filter(this.state.strategies, s => s.strategyName !== 'CAPITAL-DEPLOYED'));

    return (<div>
      <Modal isOpen={this.props.isModalOpen} size="lg">
        <ModalHeader toggle={this.onCancel}>Edit strategies {'(' + this.props.broker + ')'} {this.props.fromClientManager ? ' for ' + this.props.username : ''}</ModalHeader>
        
        <ModalBody>
          {config.isAllocationModelEnabled() && this.getSelectField({
            key: 'allocationModel',
            label: 'Allocation Model',
            options: this.getAllocationModelOptions(),
            placeholder: 'Select allocation model',
            value: this.state.selectedAllocationModel,
            onChange: (e) => {
              console.log('select allocation model => ', e.target.value);
              this.setState({
                selectedAllocationModel: e.target.value
              })
            }
          })}

          <Table>
            <thead>
              <tr className="border-0">
                <th className="border-0">STRATEGY</th>
                <th className="border-0">CURRENT CAPITAL</th>
                <th className="border-0">NEW CAPITAL</th>
                {this.props.showMoreOptions === true && <th className="border-0">TRANCH TIMINGS</th>}
                {this.props.showMoreOptions === true && <th className="border-0">PREMIUM</th>}
                {this.props.showMoreOptions === true && <th className="border-0">STOP LOSS</th>}
                {this.props.showMoreOptions === true && <th className="border-0">TRAIL LOGIC</th>}
                <th className="border-0">ENABLED</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(strategies, s => {
                  return this.getStrategyRow(s);
                })
              }
            </tbody>
          </Table>
          
          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Updating strategy details in progress. Please wait...</div>}

        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.shouldDisableUpdateButton()}
            onClick={this.updateStrategies}>
              Update
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <AlertModal
          isModalOpen={this.state.showAlertModal}
          title={this.state.alertModalTitle}
          message={this.state.alertModalMessage}
          onCancel={this.closeAlertModal}
        />
    </div>);
  }
}

export default UserStrategiesModal;
