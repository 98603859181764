/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Alert
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class UserComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      validations: {}
    };

    this.createOrUpdateUserDetails = this.createOrUpdateUserDetails.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillMount() {
    this.setState({
      data: this.prepareDataFromProps()
    });
    if (!this.props.serverConfig.isPrepaid) {
      this.fetchBillingPlans().then(billingPlans => {
        const billingPlanOptions = [{value: '', label: 'Select a billing plan'}];
        this.setState({
          billingPlanOptions: _.concat(billingPlanOptions, _.map(billingPlans, bp => {
            return {
              label: bp.planName,
              value: bp.planName
            }
          }))
        });
      }).catch(err => {
        this.setState({
          error: 'Failed to fetch billing plans',
          config: config
        });
      });
    }
  }

  fetchBillingPlans() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/billing/plans", { json: true }, (err, resp, respBody) => {
        if (err) {
          console.log('fetchBillingPlans: error = ', err);
          return reject(err);
        }
        if (resp) {
          console.log('fetchBillingPlans: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            let billingPlans = respBody || [];
            resolve(billingPlans);

          } else {
            reject(`fetchBillingPlans: ` + (respBody.error || 'Failed to fetch billing plans'));
          }
        } else {
          reject(`fetchBillingPlans: No error and no response`);
        }
      });
    });
  }

  isNewUser(props) {
    props = props || this.props;
    return _.isEmpty(props.userDetails) ? true : false;
  }

  prepareDataFromProps(props) {
    props = props || this.props;

    const userDetails = props.userDetails;
    const data = {
      enabled: true,
      autoLogin: true
    };
    if (!this.props.serverConfig.isPrepaid) {
      // billing plan applicable only for postpaid
      data.billingPlan = 'PS20_QTRLY'; // default
    }

    if (this.isNewUser(props)) {
      console.log('new user => ', data);
      // new user
      return data;
    }

    _.set(data, 'username', userDetails.username);
    _.set(data, 'password', userDetails.password);
    _.set(data, 'retype_password', userDetails.password);
    _.set(data, 'firstname', userDetails.firstname);
    _.set(data, 'lastname', userDetails.lastname);
    _.set(data, 'alias', userDetails.alias);
    _.set(data, 'email', userDetails.email);
    _.set(data, 'mobile', userDetails.mobile);
    _.set(data, 'billingPlan', userDetails.billingPlan);
    _.set(data, 'enabled', userDetails.enabled);
    _.set(data, 'autoLogin', userDetails.autoLogin);

    return data;
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.userDetailsUpdated ? true : false);
    }
  }

  createOrUpdateUserDetails(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      userDetailsUpdated: false,
      statusMessage: null,
      error: null
    });

    const data = {
      userDetails: {
        username: _.get(this.state.data, 'username'),
        password: _.get(this.state.data, 'password'),
        firstname: _.get(this.state.data, 'firstname'),
        lastname: _.get(this.state.data, 'lastname'),
        alias: _.get(this.state.data, 'alias'),
        email: _.get(this.state.data, 'email'),
        mobile: _.get(this.state.data, 'mobile'),
        billingPlan: _.get(this.state.data, 'billingPlan'),
        referralCode: _.get(this.state.data, 'referralCode'),
        enabled: _.get(this.state.data, 'enabled'),
        autoLogin: _.get(this.state.data, 'autoLogin')
      }
    };

    if (_.get(this.state.data, 'clientManager')) {
      data.userDetails.clientManager = _.get(this.state.data, 'clientManager');
    }

    // For new user POST else PUT
    const httpFunc = this.isNewUser() ? HttpRequest.post : HttpRequest.put;

    httpFunc({
      url: config.serverHost + "/apis/user",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('createOrUpdateUserDetails status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: JSON.parse(respBody).status,
          updateInProgress: false,
          userDetailsUpdated: true
        });
      } else if (resp.statusCode === 404) {
        const error = 'createOrUpdateUserDetails end point not found';
        this.setState({
          error,
          updateInProgress: false
        });
      } else {
        console.error('createOrUpdateUserDetails error => ', respBody);
        const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
        this.setState({
          error,
          updateInProgress: false
        });
      }
    });
  }

  getValidationError(key, value) {
    if (_.isEmpty(value)) {
      return null;
    }
    if (key === 'username') {
      if (value.length < 5 || value.length > 40) {
        return 'Username length should be between 5 and 40';
      }
      if (/^[A-Za-z0-9_.@]*$/.test(value) === false) {
        return 'Username should be alphanumeric (., _, @ are allowed)';
      }
    }

    if (key === 'firstname' || key === 'lastname') {
      if (/^[A-Za-z0-9 ]*$/.test(value) === false) {
        return key + ' should be alphanumeric';
      } 
    }

    if (key === 'email') {
      if (Utils.isValidEmail(value) === false) {
        return 'Please enter valid email';
      }
    }

    if (key === 'mobile') {
      if (/^\d{10}$/.test(value) === false) {
        return 'Please enter valid mobile number (10 digits)';
      }
    }

    if (key === 'password') {
      if (value.length < 8) {
        return 'Password should be of minimum 8 characters';
      }
      if (_.isEmpty(_.get(this.state.data, 'retype_password')) === false 
        && value !== _.get(this.state.data, 'retype_password')) {
        return 'Password and retype password does not match';
      }
    }

    if (key === 'retype_password') {
      if (value !== _.get(this.state.data, 'password')) {
        return 'Password and retype password does not match';
      }
    }

    return null;
  }

  onFieldChange(key, event) {
    let value = event.target ? event.target.value : '';
    // console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const newValidations = {...this.state.validations};
    if (key === 'password' || key === 'retype_password') {
      newValidations['password'] = null;
      newValidations['retype_password'] = null;  
    } else if (key === 'username') {
      value = _.toLower(value);
    } else if ((key === 'firstname' || key === 'lastname') && value.length > 1) {
      value = _.toUpper(value[0]) + value.substring(1);
    }

    const data = {...this.state.data};
    _.set(data, key, value);
    
    newValidations[key] = this.getValidationError(key, value);

    this.setState({
      data,
      validations: newValidations,
      statusMessage: null,
      error: null
    });
  }

  getInputField(opts = {}) {
    if (!this.isNewUser() && !this.props.isAdmin) {
      if (opts.key === 'password' || opts.key === 'retype_password') {
        // hide few fields if accessed by end user
        return null;
      }
      if (opts.key === 'username') {
        // Do not allow editing of few fields if accessed by end user
        opts.disabled = true;
      }
    }
    const validationError = _.isEmpty(opts.value) ? null : this.state.validations[opts.key];
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input valid={validationError ? false : true}
        className="col-sm-6 col-form-control"
        type={opts.type || 'text'} 
        name="text" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
      </Input>
      {validationError && <div className="col-sm-6 text-orange">{validationError}</div>}
    </FormGroup>);
  }

  getSelectField(opts = {}) {
    if (!this.isNewUser() && !this.props.isAdmin) {
      if (opts.key === 'billingPlan') {
        // Do not allow editing of few fields if accessed by end user
        opts.disabled = true;
      }
    }

    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input valid
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  isFormValid() {
    if (_.isEmpty(_.get(this.state.data, 'username'))
      || _.isEmpty(_.get(this.state.data, 'firstname'))
      || _.isEmpty(_.get(this.state.data, 'lastname'))
      || _.isEmpty(_.get(this.state.data, 'email'))
      || _.isEmpty(_.get(this.state.data, 'mobile'))) {
      return false;
    }
    if (this.props.serverConfig.betaMode === true 
      && this.isNewUser()
      && _.isEmpty(_.get(this.state.data, 'referralCode'))) {
      return false;
    }
    if (this.props.serverConfig.isPrepaid === false && _.isEmpty(_.get(this.state.data, 'billingPlan'))) {
      return false;
    }
    if (this.isNewUser()) { // for new user password field is mandatory
      if (_.isEmpty(_.get(this.state.data, 'password')) || _.isEmpty(_.get(this.state.data, 'retype_password'))) {
        return false;
      }
    }

    let isValid = true;
    _.each(this.state.validations, (v, key) => {
      if (v) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  renderBody(validationError) {
    const showAlert = this.state.error || this.state.statusMessage || this.state.updateInProgress;
    const error = this.state.error;

    return (<div>
      {this.getInputField({
        key: 'username',
        label: 'Username',
        disabled: !this.isNewUser() ? true : false,
        value: _.get(this.state.data, 'username'),
        onChange: (e) => this.onFieldChange('username', e)
      })}
      {this.getInputField({
        key: 'password',
        label: 'Password',
        type: 'password',
        value: _.get(this.state.data, 'password'),
        onChange: (e) => this.onFieldChange('password', e)
      })}
      {this.getInputField({
        key: 'retype_password',
        label: 'Retype Password',
        type: 'password',
        value: _.get(this.state.data, 'retype_password'),
        onChange: (e) => this.onFieldChange('retype_password', e)
      })}
      {this.getInputField({
        key: 'firstname',
        label: 'First Name',
        value: _.get(this.state.data, 'firstname'),
        onChange: (e) => this.onFieldChange('firstname', e)
      })}
      {this.getInputField({
        key: 'lastname',
        label: 'Last Name',
        value: _.get(this.state.data, 'lastname'),
        onChange: (e) => this.onFieldChange('lastname', e)
      })}
      {config.isXtremeQuant() && this.getInputField({
        key: 'alias',
        label: 'Alias',
        value: _.get(this.state.data, 'alias'),
        onChange: (e) => this.onFieldChange('alias', e)
      })}
      {this.getInputField({
        key: 'email',
        label: 'Email',
        value: _.get(this.state.data, 'email'),
        onChange: (e) => this.onFieldChange('email', e)
      })}
      {this.getInputField({
        key: 'mobile',
        label: 'Mobile',
        value: _.get(this.state.data, 'mobile'),
        onChange: (e) => this.onFieldChange('mobile', e)
      })}
      {this.props.serverConfig.betaMode === true && this.isNewUser() && this.getInputField({
        key: 'referralCode',
        label: 'Referral Code',
        value: _.get(this.state.data, 'referralCode'),
        onChange: (e) => this.onFieldChange('referralCode', e)
      })}
      {this.props.serverConfig.isPrepaid === false && this.getSelectField({
        key: 'billingPlan',
        label: 'Billing Plan',
        value: _.get(this.state.data, 'billingPlan'),
        options: this.state.billingPlanOptions,
        placeholder: 'Select billing plan',
        onChange: (e) => this.onFieldChange('billingPlan', e)
      })}
      {this.props.fromClientManager === true && this.getInputField({
        key: 'clientManager',
        label: 'Client Manager',
        value: _.get(this.state.data, 'clientManager'),
        onChange: (e) => this.onFieldChange('clientManager', e)
      })}
      
      {showAlert && <Alert color={error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {error && <div>{error}</div>}
          {this.state.statusMessage && <div>{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Request is in progress. Please wait...</div>}
        </div>
      </Alert>}
    </div>);
  }

  render() {
    return (<div>
      <Modal isOpen={this.props.isModalOpen} size="lg">
        <ModalHeader>{this.isNewUser() ? 'New User' : 'Edit Account Details'}</ModalHeader>
        
        <ModalBody>
          {this.renderBody()}
        </ModalBody>

         <ModalFooter>
          <Button 
            color="primary" 
            disabled={this.state.updateInProgress || !this.isFormValid()}
            onClick={this.createOrUpdateUserDetails}>
              {this.isNewUser() ? 'Create' : 'Update'}
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UserComp;
