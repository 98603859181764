/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Badge,
  Button, 
  Card, 
  CardBody, 
  Table,
  Label,
  Input,
  CardHeader,
  Alert
} from 'reactstrap';

import AlertModal from '../common/AlertModal.js';

import config from '../../config.js';
import HttpRequest from "request";
import { fetchAllBrokers } from "../../utils/RestAPIs.js";
import BrokerModal from './BrokerModal.js';

class Brokers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showAlertModal: false,
      brokers: [],
      fetchingDataInProgress: false,
      error: null
    };

    this.fetchAllBrokers = this.fetchAllBrokers.bind(this);
    this.launchAlertModal = this.launchAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
    this.launchBrokerModal = this.launchBrokerModal.bind(this);
    this.closeBrokerModal = this.closeBrokerModal.bind(this);
  }

  componentWillMount() {
    this.fetchAllBrokers();
  }

  fetchAllBrokers() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    fetchAllBrokers().then(allBrokers => {
      this.setState({
        brokers: allBrokers || [],
        fetchingDataInProgress: false
      });
    }).catch(err => {
      this.setState({
        brokers: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch all brokers'
      });
    });
  }

  stopUnstopBrokers(stop, brokerName) {
    this.setState({
      updateInProgress: true,
      error: null
    });

    const data = {
      brokers: brokerName ? [brokerName] : _.filter(this.state.brokers, s => s.selected === true).map(s => s.name)
    };

    HttpRequest.put({
      url: config.serverHost + `/apis/clientmanager/${stop ? 'stop' : 'unstop'}/trading`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('stopUnstopBrokers status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          updateInProgress: false,
          selectedAll: false // make this false in case selected all
        }, () => {
          // fetch all brokers again
          this.fetchAllBrokers();
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'stopUnstopBrokers end point not found',
          updateInProgress: false
        });
      } else {
        console.error('stopUnstopBrokers error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          updateInProgress: false
        });
      }
    });
  }

  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null,

    });
  }

  launchBrokerModal(brokerDetails) {
    this.setState({
      showBrokerModal: true,
      selectedBrokerDetails: brokerDetails
    });
  }

  closeBrokerModal(isBrokerUpdated = false) {
    this.setState({
      showBrokerModal: false,
      selectedBrokerDetails: null
    }, () => {
      if (isBrokerUpdated) {
        this.fetchAllBrokers();
      }
    });
  }

  toggleSelect(brokerName, event) {
    const value = event.target.checked;
    const brokers = [...this.state.brokers];
    _.each(brokers, s => {
      if (s.name === brokerName) {
        s.selected = value || false;
        return false; // breaks the loop
      }
    });
    
    this.setState({
      brokers
    });
  }

  toggleSelectAll(event) {
    const newVal = event.target.checked;
    const brokers = [...this.state.brokers];
    _.each(brokers, s => {
      s.selected = newVal;
    });
    this.setState({
      selectedAll: newVal,
      brokers
    });
  }

  getBrokerDetails(b, sno) {
    return (<tr key={b.name}>
      <td style={{verticalAlign: 'middle', textAlign:"center", paddingLeft: '20px'}}>
        <Label check>
          <Input type="checkbox"
            style={{'position': 'relative'}}
            checked={b.selected}
            onChange={(e) => this.toggleSelect(b.name, e)} 
          />
        </Label>
      </td>
      <td>{b.name}</td>
      <td>
        <Badge color={b.enabled === true ? 'success' : 'danger'}>
          {b.enabled === true ? 'Yes' : 'No'}
        </Badge>
      </td>
      <td>{b.provider}</td>
      <td>{b.useDealerAPIs ? 'Yes' : 'No'}</td>
      <td>{b.serverUrl}</td>
      <td>{b.dataServerUrl}</td>
      <td>{b.xtremeAgentDestUrl}</td>
      <td>
        <Badge color={b.stopped === true ? 'danger' : 'success'}>
          {b.stopped === true ? 'Yes' : 'No'}
        </Badge>
      </td>
      <td>
        <Button 
          color={!b.stopped ? 'light' : 'dark'}
          onClick={() => this.stopUnstopBrokers(!b.stopped ? true : false, b.name)}>
          {!b.stopped ? 'Stop Trading' : 'Resume Trading'}
        </Button>
        <Button color="info" style={{marginLeft: '10px'}}
          onClick={() => this.launchBrokerModal(b)}>
          Edit
        </Button>
        </td>
    </tr>);
  }

  render() {
    const selectedCount = _.filter(this.state.brokers, s => s.selected === true).length;
    return (<div>
      <h4>Brokers</h4>
      <Card>
        <CardHeader>
          <Button color='dark'
            style={{marginRight: '10px'}}
            disabled={!selectedCount}
            onClick={() => this.stopUnstopBrokers(true)}>
              Stop Trading in selected brokers
          </Button>
          <Button color='dark'
            disabled={!selectedCount}
            onClick={() => this.stopUnstopBrokers(false)}>
            Resume Trading in selected brokers
          </Button>
        </CardHeader>
        <CardBody>
          {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}
          {this.state.fetchingDataInProgress && <Alert color='secondary'>Fetching in progress</Alert>}
          {this.state.updateInProgress && <Alert color='secondary'>Updating in progress</Alert>}
          <Table className="no-wrap v-middle" size="sm" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0" style={{verticalAlign: 'middle', textAlign:"center", paddingLeft: '20px'}}>
                  <Label check>
                    <Input type="checkbox"
                      style={{'position': 'relative'}}
                      checked={this.state.selectedAll === true ? true : false}
                      onChange={(e) => this.toggleSelectAll(e)}
                    />
                  </Label>
                </th>
                <th className="border-0">Broker</th>
                <th className="border-0">Enabled</th>
                <th className="border-0">Provider</th>
                <th className="border-0">Use Dealer APIs</th>
                <th className="border-0">Server Url</th>
                <th className="border-0">Data Server Url</th>
                <th className="border-0">Xtreme Agent Dest Url</th>
                <th className="border-0">Stopped</th>
                <th className="border-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(this.state.brokers, (s, index) => {
                  return this.getBrokerDetails(s, index + 1);
                })
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <AlertModal
        isModalOpen={this.state.showAlertModal}
        title={this.state.alertModalTitle}
        message={this.state.alertModalMessage}
        onCancel={this.closeAlertModal}
      />
      {this.state.showBrokerModal && <BrokerModal
        isModalOpen={this.state.showBrokerModal}
        brokerDetails={this.state.selectedBrokerDetails}
        onCancel={this.closeBrokerModal}
      />}
    </div>);
  }
}

export default Brokers;
