/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Card,
  CardBody,
  Table,
  Button,
  Alert
} from 'reactstrap';

import HttpRequest from "request";
import Utils from "../../utils/Utils.js";
import config from "../../config.js";

const productsSortOrder = { MIS: 1, BO: 2, CO: 3, NRML: 4, CNC: 5 };

class PositionsComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      positions: [],
      fetchPositionsInProgress: false,
      squareOffInProgress: false,
      statusMessage: null,
      error: null
    }
  }

  componentWillMount() {
    this.fetchUserPositions();
  }

  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps nextProps.username = ' + nextProps.username + ', nextProps.broker = ' + nextProps.broker);
    if (this.props.username !== nextProps.username ||
      this.props.broker !== nextProps.broker) {
      this.fetchUserPositions(nextProps.username, nextProps.broker);
    }
  }

  fetchUserPositions(username, broker) {
    username = username || this.props.username;
    broker = broker || this.props.broker;

    this.setState({
      positions: [],
      fetchPositionsInProgress: true,
      error: null,
      statusMessage: null
    });

    HttpRequest(config.serverHost + "/apis/positions?username=" + username + "&broker=" + broker, { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchUserPositions: error = ', err);
        this.setState({
          fetchPositionsInProgress: false,
          error: err
        });
      }
      if (resp) {
        console.log('fetchUserPositions: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200 && _.isArray(respBody)) {
          let positions = respBody || [];
          
          positions.sort((p1, p2) => {
            return productsSortOrder[p1.productType] - productsSortOrder[p2.productType];
          });

          this.setState({
            positions,
            fetchPositionsInProgress: false
          });

        } else {
          this.setState({
            fetchPositionsInProgress: false,
            error: `fetchUserPositions: ` + (respBody.error || 'Failed to fetch positions')
          });
        }
      } else {
        this.setState({
          fetchPositionsInProgress: false,
          error: `fetchUserPositions: No error and no response`
        });
      }
    });
  }

  exitPositions(positions = []) {
    this.setState({
      squareOffInProgress: true
    });

    const data = {
      username: this.props.username,
      broker: this.props.broker,
      clientID: this.props.clientID,
      positions: []
    };

    data.positions = _.map(positions, pos => {
      return {
        tradingSymbol: pos.tradingSymbol,
        direction: pos.netQty > 0 ? "LONG" : "SHORT",
        qty: Math.abs(pos.netQty),
        productType: pos.productType,
        exchange: pos.exchange,
        segment: pos.segment
      }
    });

    HttpRequest.post({
      url: config.serverHost + "/apis/positions/exit",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('exitPositions status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: "Order Ids: " + respBody + ". Pl refresh after some time.",
          squareOffInProgress: false
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'exitPositions end point not found',
          squareOffInProgress: false
        });
      } else {
        console.error('exitPositions error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          squareOffInProgress: false
        });
      }
    });
  }

  getPositions() {
    let positions = _.filter(this.state.positions, pos => pos.productType === 'MIS' || pos.productType === 'NRML');
    positions = _.map(positions, position => {
      return {
        ...position,
        noOpenQty: position.netQty === 0 ? true : false,
      };
    });
    positions.sort((p1, p2) => {
      if (p1.productType !== p2.productType) {
        return productsSortOrder[p1.productType] - productsSortOrder[p2.productType]
      }
      if (p1.noOpenQty !== p2.noOpenQty) {
        return !p1.noOpenQty ? -1 : 1;
      }
      if (p1.tradingSymbol === p2.tradingSymbol) {
        return p1.productType.localeCompare(p2.productType);
      }
      return p1.tradingSymbol.localeCompare(p2.tradingSymbol);
    });
    return positions;
  }

  renderPositions(positions = []) {
    let totalPnl = 0;
    _.each(positions, pos => {
      totalPnl += pos.totalPnl;
    });

    return (<Card>
      <CardBody>
        <Table className="no-wrap v-middle" responsive>
          <thead>
            <tr className="border-0">
              <th className="border-0">Symbol</th>
              <th className="border-0">Product Type</th>
              <th className="border-0 number-right">Quantity</th>
              <th className="border-0 number-right">Profit/Loss</th>
              <th className="border-0 number-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              _.map(positions, (pos) => {                
                return (<tr key={pos.tradingSymbol + '-' + pos.productType} style={pos.noOpenQty ? {background: 'rgb(242, 245, 240)'} : {}}> 
                  <td>{pos.tradingSymbol}</td>
                  <td>{pos.productType}</td>
                  <td className="number-right">{pos.netQty}</td>
                  <td className={"number-right " + (pos.totalPnl > 0 ? "number-pos" : "number-neg")}>{Utils.formatNumberToCommaSeparated(pos.totalPnl)}</td>
                  <td className="number-right">
                    <Button color="primary"
                      disabled={pos.netQty === 0}
                      onClick={() => this.exitPositions([pos])}>
                      Exit
                    </Button>
                  </td>
                </tr>);
              })
            }
            <tr>
              <td colSpan="3"><b>TOTAL</b></td>
              <td className={"number-right " + (totalPnl > 0 ? "number-pos" : "number-neg")}>{Utils.formatNumberToCommaSeparated(totalPnl)}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }

  render() {
    const positions = this.getPositions();
    const openMISPositions = _.filter(positions, pos => {
      if (pos.productType === 'MIS' && pos.netQty !== 0) {
        return true;
      }
      return false;
    });
    const openNRMLPositions = _.filter(positions, pos => {
      if (pos.productType === 'NRML' && pos.netQty !== 0) {
        return true;
      }
      return false;
    });
    const openALLPositions = _.concat(openMISPositions, openNRMLPositions);
    const showAlert = this.state.error || this.state.statusMessage || this.state.fetchPositionsInProgress || this.state.squareOffInProgress;

    return (<div>
      <Button 
        color="primary" 
        disabled={openMISPositions.length === 0}
        onClick={() => this.exitPositions(openMISPositions)}>
          Exit MIS Positions
      </Button>
      <Button 
        color="primary" 
        style={{'marginLeft': '10px'}}
        disabled={openNRMLPositions.length === 0}
        onClick={() => this.exitPositions(openNRMLPositions)}>
          Exit NRML Positions
      </Button>
      <Button 
        color="primary" 
        style={{'marginLeft': '10px'}}
        disabled={openALLPositions.length === 0}
        onClick={() => this.exitPositions(openALLPositions)}>
          Exit All Positions
      </Button>

      <i onClick={() => this.fetchUserPositions()} 
        className={this.state.fetchPositionsInProgress ? "fa fa-refresh fa-spin" : "fa fa-refresh"} 
        style={{'marginLeft': '15px', 'fontSize': '20px', 'cursor': 'pointer'}}>
      </i>

      {showAlert && <Alert color={this.state.error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.fetchPositionsInProgress && <div>Fetching positions is in progress. Please wait...</div>}
          {this.state.squareOffInProgress && <div>Exit positions is in progress. Please wait...</div>}
        </div>
      </Alert>}

      {this.renderPositions(positions)}

    </div>);
  }
}

export default PositionsComp;