
import _ from 'lodash';
import url from "url";

class WebSocketClient {

  constructor(name) {
    this.name = name;
    this.ws = null;
    this.ticks = {};

    this.getCMP = this.getCMP.bind(this);
    this.onOrderUpdate = null;
    this.onPositionUpdate = null;

    this.healthCheckTimer = null;
    this.reconnect = true;
  }

  isConnected() {
    return this.ws ? true : false;
  }

  addOrderUpdateCallback(callback) {
    this.onOrderUpdate = callback;
  }

  addPositionUpdateCallback(callback) {
    this.onPositionUpdate = callback;
  }

  startHealthCheckTimer() {
    if (this.healthCheckTimer) {
      return;
    }
    this.healthCheckTimer = setTimeout(() => {
      this.healthCheckTimer = null;
      if (this.isConnected()) {
        this.ws.send(JSON.stringify({method: 'ping'}));
      } else if (this.reconnect) {
        console.log('WebSocketClient going to reconnect.');
        this.connect();
      }
      this.startHealthCheckTimer();
    }, 10 * 1000); // ping every 10 seconds
  }

  stopHealthCheckTimer() {
    if (this.healthCheckTimer) {
      clearTimeout(this.healthCheckTimer);
      this.healthCheckTimer = null;
    }
  }

  connect() {
    console.log('WebSocketClient: [' + this.name + '] connect() called.');
    if (this.isConnected()) {
      console.log('WebSocketClient already connected.');
      return;
    }

    const req = url.parse(window.location.href, false);

    // const webSocketEndpoint = 'wss://autoquant.in/socket';
    const webSocketEndpoint =  (req.protocol === 'https:' ? 'wss:' : 'ws:') + '//' + req.host + '/socket';
    console.log('Creating web socket with end point ' + webSocketEndpoint);
    this.ws = new WebSocket(webSocketEndpoint);

    this.ws.addEventListener('open', () => {
      console.log('WebSocketClient connected..');
      const reqData = {
        method: 'subscribe',
        subList: ['ticks', 'orders', 'positions']
      };
      this.ws.send(JSON.stringify(reqData));

      this.startHealthCheckTimer();
    });

    this.ws.addEventListener('close', () => {
      console.log('WebSocketClient disconnected..');
      this.ws = null;
    });

    this.ws.addEventListener('message', (event) => {
      //console.log('Websocket incoming data => ' + event.data);
      if (event && event.data) {
        try {
          const data = JSON.parse(event.data);
          if (data.status) {
            console.log('WebSocketClient: Received message = ' + data.message + ' with status = ' + data.status);
          } else if (data.ticks) {
            this.ticks = data.ticks;
          } else if (data.position) {
            // Notify listeners about the position update
            console.log('WebSocketClient: Received position update: ', data.position);
            if (_.isFunction(this.onPositionUpdate)) {
              this.onPositionUpdate(data.position);
            }
          } else if (data.order) {
            // Notify listeners about the order update
            console.log('WebSocketClient: Received order update: ', data.order);
            if (_.isFunction(this.onOrderUpdate)) {
              this.onOrderUpdate(data.order);
            }
          }
        } catch(e) {
          console.error('WebSocketClient: Error while parsing web socket incoming data ', event.data, e);
        }
      }
    });

  }

  disconnect() {
    this.reconnect = false;
    this.ticks = {};
    this.stopHealthCheckTimer();
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  getCMP(tradingSymbol) {
    return (this.ticks && this.ticks[`${tradingSymbol}`]) || 0;
  }
}

export default WebSocketClient;
