/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import {
  Card,
  CardBody,
  Table,
  FormGroup,
  Input,
  Badge,
  Row,
  Col,
  Label
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

import beepSoundFile from '../../assets/sounds/beep-02.mp3';

const ALERTS_DATA_KEY = `${config.getProductName()}.alerts`;
const ALERTS_LAST_FETCH_TIMESTAMP_KEY = `${config.getProductName()}.alerts.lastSavedTimestamp`;

const ALERTS_FETCH_INTERVAL_SECONDS = 20;

class AlertsComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTabTitle: 'CRITICAL',
      alerts: [],
      fetchingDataInProgress: false,
      lastCheckedTimestamp: null,
      error: null,
      selectedEntityName: 'ALL',
      selectedAlertLevel: 'ALL'
    };

    this.fetchAlerts = this.fetchAlerts.bind(this);
  }

  componentWillMount() {
    this.clearAlertsSessionStorage();

    const alerts = this.getAlertsFromSessionStorage();
    this.setState({
      alerts,
      lastCheckedTimestamp: this.getLastFetchTimestamp()
    }, () => {
      this.fetchAlerts();
      this.startFetchInterval();
    });
  }

  componentWillUnmount() {
    this.clearAlertsSessionStorage();
    this.clearFetchInterval();
  }

  startFetchInterval() {
    this.fetchAlertsInterval = setInterval(this.fetchAlerts, 1000);
  }

  clearFetchInterval() {
    clearTimeout(this.fetchAlertsInterval);
  }

  getAlertsFromSessionStorage() {
    let alertsStr = window.sessionStorage.getItem(ALERTS_DATA_KEY);
    if (!alertsStr) {
      alertsStr = '[]';
    }
    const alerts = JSON.parse(alertsStr);
    console.log('Alerts ' + alerts.length + " fetched from session storage");
    return alerts;
  }

  saveAlertsToSessionStorage() {
    const alerts = this.state.alerts || [];
    window.sessionStorage.setItem(ALERTS_DATA_KEY, JSON.stringify(alerts));
    console.log('Alerts ' + alerts.length + " saved to session storage");
  }

  clearAlertsSessionStorage() {
    window.sessionStorage.setItem(ALERTS_DATA_KEY, '');
    window.sessionStorage.setItem(ALERTS_LAST_FETCH_TIMESTAMP_KEY, '');
    console.log('Cleared alerts session storage');
  }

  getLastFetchTimestamp() {
    return window.sessionStorage.getItem(ALERTS_LAST_FETCH_TIMESTAMP_KEY);
  }

  updateLastFetchTimestamp(endTimeStr) {
    window.sessionStorage.setItem(ALERTS_LAST_FETCH_TIMESTAMP_KEY, endTimeStr);
  }

  playBeepSound(numTimes = 1) {
    const beepsound = new Audio(beepSoundFile);
    let count = 0;
    const intervalID = setInterval(() => {
      beepsound.play();
      if (++count === numTimes) {
        clearInterval(intervalID);
      }
    }, 1000);
  }

  fetchAlerts() {
    let startTimeStr = this.getLastFetchTimestamp();
    let startTime;
    if (_.isEmpty(startTimeStr)) {
      startTime = Utils.getTimeOfToday(6, 0 ,0); // 6 AM
      startTimeStr = Utils.formatDate(startTime);
    } else {
      startTime = Utils.parseTimestamp(startTimeStr);
    }
    const endTime = new Date();
    let endTimeStr = Utils.formatDate(endTime);
    // console.log('fetchAlerts called: startTime [' + startTimeStr + '] and endTime = [' + endTimeStr + ']');
    if (endTime - startTime < ALERTS_FETCH_INTERVAL_SECONDS * 1000) {
      return;
    }
    // store endTime in session storage
    this.updateLastFetchTimestamp(endTimeStr);

    this.setState({
      fetchingDataInProgress: true,
      error: null,
      lastCheckedTimestamp: endTimeStr
    });
    let url = '';
    if (this.props.isAdmin === 'true') {
      url = config.serverHost + `/apis/alerts/all?startTime=${encodeURI(startTimeStr)}&endTime=${encodeURI(endTimeStr)}`;
    } else {
      url = config.serverHost + `/apis/alerts/user?startTime=${encodeURI(startTimeStr)}&endTime=${encodeURI(endTimeStr)}`;
    }
    HttpRequest(url, { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchAlerts: error = ', err);
        this.setState({
          fetchingDataInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchAlerts: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          let newAlerts = respBody || [];
          const MAX_ALERTS_TO_SHOW = 8000;
          if (newAlerts.length > MAX_ALERTS_TO_SHOW) {
            // Save only latest MAX_ALERTS_TO_SHOW alerts
            console.log('fetchAlerts: newAlerts = ' + newAlerts.length + " > " + MAX_ALERTS_TO_SHOW + " hence going to consider only latest " + MAX_ALERTS_TO_SHOW + " alerts");
            newAlerts = newAlerts.slice(0, MAX_ALERTS_TO_SHOW);
          }
          const alerts = _.concat(newAlerts, this.state.alerts); // new Alerts on top
          console.log('fetchAlerts: newAlerts = ' + newAlerts.length + ', total alerts = ' + alerts.length);
                    
          this.setState({
            alerts,
            fetchingDataInProgress: false
          }, () => {
            this.saveAlertsToSessionStorage();
            if (newAlerts.length > 0) {
              let criticalAlertsCount = _.filter(newAlerts, a => a.alertLevel === 'CRITICAL').length;
              if (criticalAlertsCount > 0) {
                this.playBeepSound(criticalAlertsCount > 5 ? 5 : criticalAlertsCount);
              }
            }
          });
        } else {
          this.setState({
            fetchingDataInProgress: false,
            error: respBody.error || 'Failed to fetch Alerts'
          });
        }
      }
    });
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      {opts.label && <Label className="col-sm-3 col-form-label" for={opts.key}><b>{opts.label}</b></Label>}
      <Input
        className="col-sm-3 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option value={o.value}>{opts.key === 'strategy' ? 
              Utils.getStrategyDisplayName(o.label) : o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  getInputField(opts = {}) {
    return (<FormGroup className="form-row">
      {opts.label && <Label className="col-sm-3 col-form-label" for={opts.key}><b>{opts.label}</b></Label>}
      <Input
        className="col-sm-6 col-form-control"
        type="input" 
        name="input" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        placeholder={opts.placeholder}>
      </Input>
    </FormGroup>);
  }
  
  getEntityOptions() {
    const uniqEntityNames = _.uniqBy(this.state.alerts, 'entityName').map(a => a.entityName).sort();
    uniqEntityNames.unshift('ALL');
    return uniqEntityNames.map(v => {
      return {
        label: v,
        value: v
      }
    });
  }

  getAlertLevelOptions() {
    const uniqEntityNames = _.uniqBy(this.state.alerts, 'entityName').map(a => a.entityName).sort();
    uniqEntityNames.unshift('ALL');
    return ['ALL', 'CRITICAL', 'WARNING', 'INFO'].map(v => {
      return {
        label: v,
        value: v
      }
    });
  }

  getAlertRow(alert = {}, index) {
    let alertColor = '';
    if (alert.alertLevel === 'INFO') {
      alertColor = 'success';
    } else if (alert.alertLevel === 'WARNING') {
      alertColor = 'warning';
    } else if (alert.alertLevel === 'CRITICAL') {
      alertColor = 'danger';
    }
    return (
      <tr key={"alert-" + alert.alertLevel + "-" + index}>
        <td>{index + 1}</td>
        <td>{_.split(alert.timestamp, ' ')[1]}</td>
        <td>
          <Badge color={alertColor}>{alert.alertLevel}</Badge>
        </td>
        {this.props.isAdmin === 'true' && <td>{alert.entityType}</td>}
        {this.props.isAdmin === 'true' && <td>{alert.entityName}</td>}
        <td>{alert.operation}</td>
        <td>{alert.alertMessage}</td>
      </tr>
    );
  }

  getAlertsContent() {
    let alerts = this.state.alerts || [];
    if (this.state.selectedEntityName !== 'ALL') {
      alerts = _.filter(this.state.alerts, a => a.entityName === this.state.selectedEntityName);
    }
    if (this.state.selectedAlertLevel !== 'ALL') {
      alerts = _.filter(alerts, a => a.alertLevel === this.state.selectedAlertLevel);
    }
    if (this.state.filterText) {
      alerts = _.filter(alerts, a => {
        return _.includes(_.toLower(a.alertMessage), _.toLower(this.state.filterText));
      });
    }
    return (<div>
      <Card>
        <CardBody>
          <Row>
            <Col>
              {this.props.isAdmin === 'true' && this.getSelectField({
                key: 'username',
                label: 'Entity:',
                value: this.state.selectedEntityName,
                options: this.getEntityOptions(),
                placeholder: 'Select Entity',
                onChange: (e) => {
                  const value = e.target ? e.target.value : '';
                  this.setState({
                    selectedEntityName: value
                  });
                }
              })}
            </Col>
            <Col>
              {this.getSelectField({
                key: 'alertLevel',
                label: 'Alert Level:',
                value: this.state.selectedAlertLevel,
                options: this.getAlertLevelOptions(),
                placeholder: 'Select Alert Level',
                onChange: (e) => {
                  const value = e.target ? e.target.value : '';
                  this.setState({
                    selectedAlertLevel: value
                  });
                }
              })}
            </Col>
            <Col>
              {this.getInputField({
                key: 'keywords',
                label: 'Keywords:',
                value: this.state.filterText,
                onChange: (e) => {
                  const value = e.target ? e.target.value : '';
                  this.setState({
                    filterText: value
                  });
                }
              })}
            </Col>
          </Row>
          
          <div>
            {!this.state.fetchingDataInProgress && alerts.length === 0 && <div>No alerts found {this.state.selectedEntityName !== 'ALL' ? ' for entity name ' + this.state.selectedEntityName : ''}</div>}
            {alerts.length > 0 && <div>{this.props.isAdmin === 'true' ? `Showing ${alerts.length} alerts of ${this.state.alerts.length}` : ``}</div>}
            {alerts.length > 0 && <Table className="v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Timestamp</th>
                  <th className="border-0">Alert Level</th>
                  {this.props.isAdmin === 'true' && <th className="border-0">Entity Type</th>}
                  {this.props.isAdmin === 'true' && <th className="border-0">Entity Name</th>}
                  <th className="border-0">Operation</th>
                  <th className="border-0">Alert Message</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(alerts, (alert, index) => {
                    return this.getAlertRow(alert, index);
                  })
                }
              </tbody>
            </Table>
            }
          </div>
        </CardBody>
      </Card>
    </div>);
  }

  toggle(tab) {
    if (tab.title === this.state.activeTabTitle) {
      return;
    }
    this.setState({
      activeTabTitle: tab.title
    });
  }

  render() {

    return (<div>
      <div className="header-panel">
        <div className="title"><h4>Alerts</h4></div>
        <div className="refresh-status">
          {this.state.error && <span className="text-orange">{this.state.error}</span>}
          {!this.state.fetchingDataInProgress && this.state.lastCheckedTimestamp && <span className="text-info">Last refreshed at {this.state.lastCheckedTimestamp}</span>}
          {this.state.fetchingDataInProgress && <span className="text-info">Checking for new alerts...</span>}
        </div>
      </div>
      <div>
        {this.getAlertsContent(this.state.alerts)}
      </div>
    </div>);
  }
}

export default AlertsComp;
