
import React from 'react';

import Starter from '../views/starter/starter.jsx';
import Plans from '../components/plans/Plans.js';
import Brokers from '../components/broker/Brokers.js';
import Strategies from '../components/strategy/Strategies.js';
import TradesHistory from '../components/history/TradesHistory.js';
import Performance from '../components/history/Performance.js';
import Billing from '../components/billing/Billing.js';
import Payments from '../components/billing/Payments.js';
import FAQ from '../components/faq/FAQ.js';
import Alerts from '../components/notifications/Alerts.js';

// import Logout from '../components/auth/Logout.js';

import WrapperComponent from './WrapperComponent.jsx';

const wrapComponent = (Comp) => {
  return (props) => {
    return (<WrapperComponent {...props}>
      <Comp {...props} />
    </WrapperComponent>);
  };
};

var ThemeRoutes = [
  { 
    path: '/dashboard', 
    name: 'Dashboard', 
    icon: 'mdi mdi-briefcase', 
    component: wrapComponent(Starter)
  },
  { 
    path: '/brokers', 
    name: 'Brokers', 
    icon: 'mdi mdi-bank', 
    component: wrapComponent(Brokers)
  },
  { 
    path: '/plans', 
    name: 'Plans', 
    icon: 'mdi mdi-credit-card-multiple', 
    component: wrapComponent(Plans)
  },
  { 
    path: '/strategies', 
    name: 'Strategies', 
    icon: 'mdi mdi-folder-multiple-outline', 
    component: wrapComponent(Strategies) 
  },
  {
    path: '/equity-curve', 
    name: 'Performance', 
    icon: 'mdi mdi-chart-line', 
    component: wrapComponent(Performance)
  },
  { 
    path: '/trades-history', 
    name: 'Trades History', 
    icon: 'mdi mdi-history', 
    component: wrapComponent(TradesHistory)
  },
  { 
    path: '/billing', 
    name: 'Billing', 
    icon: 'mdi mdi-cash-multiple', 
    component: wrapComponent(Billing)
  },
   { 
    path: '/payments', 
    name: 'Payments', 
    icon: 'mdi mdi-cash-multiple', 
    component: wrapComponent(Payments)
  },
  { 
    path: '/faq', 
    name: 'FAQs', 
    icon: 'mdi mdi-comment-question-outline', 
    component: wrapComponent(FAQ)
  },
  { 
    path: '/alerts',
    name: 'Alerts',
    icon: 'mdi mdi-alert',
    component: Alerts
  },
  /*{
    path: '/logout',
    name: 'Logout',
    icon: 'mdi mdi-logout',
    component: Logout
  },*/
  { path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }
];
export default ThemeRoutes;
