/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class Billing extends React.Component {

  constructor(props) {
    super(props);

    this.fyOptions = [];
    const startYear = 2019;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    for (let y = startYear; y <= currentYear; y++) {
      if (y < currentYear || currentMonth > 3) {
        this.fyOptions.push({
          label: 'FY ' + y + '-' + (y + 1).toString().substring(2),
          value: y + '-' + (y + 1)
        });
      }
    }
    this.fyOptions.reverse(); // show latest FY on top

    this.state = {
      billingPlan: null,
      bills: [],
      selectedFY: this.fyOptions[0].value
    };
  }

  componentWillMount() {
    this.fetchBillingDetails();
  }

  fetchBillingDetails() {
    this.setState({
      fetchBillingDetailsInProgress: true,
      error: null
    });
    HttpRequest(config.serverHost + "/apis/billing", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchBillingDetails: error = ', err);
        this.setState({
          fetchBillingDetailsInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchBillingDetails: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          this.setState({
            billingPlan: (respBody || {}).billingPlan,
            bills: (respBody || {}).bills,
            fetchBillingDetailsInProgress: false
          });
        } else {
          this.setState({
            billingPlan: null,
            bills: [],
            fetchBillingDetailsInProgress: false,
            error: respBody.error || 'Failed to fetch Billing Details'
          });
        }
      }
    });
  }

  getBillingPlanDetails() {
    const billingPlan = this.state.billingPlan || {};
    return (<Card>
      <CardBody>
        <div className="d-flex">
          <div>
            <CardTitle>{'Plan: ' + (billingPlan.planName || '')}</CardTitle>
          </div>
        </div>
        {_.isEmpty(billingPlan) ? 'No Billing Plan Found' : <Table className="no-wrap v-middle" responsive>
          <tbody>
            <tr>
              <th>Billing Cycle</th>
              <td>{billingPlan.billingPeriodDays + ' days'}</td>
            </tr>
            <tr>
              <th>Fixed Cost</th>
              <td>{billingPlan.fixedCostPercentage + ' % on capital'}</td>
            </tr>
            {billingPlan.noCostProfitSharingPercentage > 0 && <tr>
              <th>No Profit Sharing Upto</th>
              <td>{'Initial ' + billingPlan.noCostProfitSharingPercentage + ' %'}</td>
            </tr>}
            <tr>
              <th>Profit Sharing</th>
              <td>{billingPlan.profitSharingPercentage + ' %'}</td>
            </tr>
          </tbody>
        </Table>}
      </CardBody>
    </Card>);
  }

  getBill(bill = {}, displayUnAccountedPnlColumn = false) {
    return (
      <tr key={"bill-" + bill.billingNumber}>
        <td>{bill.billingNumber}</td>
        <td>{Utils.formatDateToString(new Date(bill.billingStartDate))}</td>
        <td>{Utils.formatDateToString(new Date(bill.billingEndDate))}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.averageCapital)}</td>
        <td className={bill.netProfitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.netProfitLoss, true)}</td>
        <td className={bill.previousLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.previousLoss, true)}</td>
        {
          displayUnAccountedPnlColumn && <td className={bill.unAccountedPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.unAccountedPnl, true)}</td>
        }
        <td className={bill.netProfitLossAfterAdjustments > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(bill.netProfitLossAfterAdjustments, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.fixedCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.variableCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.totalCost, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.GST, true)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(bill.totalCostWithGST, true)}</td>
        <td>{bill.totalCost > 0 ? (bill.isPaid ? 'YES' : 'NO') : '-'}</td>
        <td>{Utils.formatDateToString(new Date(bill.paymentDueDate))}</td>
        <td>{bill.invoiceNumber}</td>
      </tr>
    );
  }

  getAllBills() {

    const bills = this.state.bills;

    const summary = {
      averageCapital: 0,
      netProfitLoss: 0,
      fixedCost: 0,
      variableCost: 0,
      unAccountedPnl: 0
    };
    _.each(bills, bill => {
      summary.averageCapital += bill.averageCapital;
      summary.netProfitLoss += bill.netProfitLoss;
      summary.fixedCost += bill.fixedCost;
      summary.variableCost += bill.variableCost;
      summary.unAccountedPnl += bill.unAccountedPnl;
    });
    if (bills.length > 0) {
      summary.averageCapital = Math.floor(summary.averageCapital / bills.length);
    }

    const displayUnAccountedPnlColumn = summary.unAccountedPnl !== 0 ? true : false;

    return (<Card>
      <CardBody>
        <div>
          {bills.length > 0 ? <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">Bill No.</th>
                <th className="border-0">Start Date</th>
                <th className="border-0">End Date</th>
                <th className="border-0">Average Capital</th>
                <th className="border-0">Net P/L</th>
                <th className="border-0">Prev Loss</th>
                {
                  displayUnAccountedPnlColumn && <th className="border-0">UnAccounted P/L</th>
                }
                <th className="border-0">Net P/L After Adjustments</th>
                <th className="border-0">Fixed Cost</th>
                <th className="border-0">Profit Sharing</th>
                <th className="border-0">Total Cost</th>
                <th className="border-0">GST</th>
                <th className="border-0">Total Cost With GST</th>
                <th className="border-0">Paid</th>
                <th className="border-0">Due By</th>
                <th className="border-0">Invoice No.</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(bills, bill => {
                  return this.getBill(bill, displayUnAccountedPnlColumn);
                })
              }
              <tr>
                <td colSpan="3">TOTAL</td>
                <td className="number-right">{Utils.formatNumberToCommaSeparated(summary.averageCapital)}</td>
                <td className={summary.netProfitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.netProfitLoss, true)}</td>
                <td colSpan={displayUnAccountedPnlColumn ? "3" : "2"}></td>
                <td className="number-right">{Utils.formatNumberToCommaSeparated(summary.fixedCost, true)}</td>
                <td className={summary.variableCost > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.variableCost, true)}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
              </tr>
            </tbody>
          </Table> : 'No Bills generated yet for this product'
          }
        </div>
      </CardBody>
    </Card>);
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      {opts.label && <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>}
      <Input valid
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  getReports() {
    const fyStartMonth = this.state.selectedFY.split('-')[0] + '-04-01';
    const fyEndMonth = this.state.selectedFY.split('-')[1] + '-03-31';
    const filteredBills = _.filter(this.state.bills, bill => {
      const billStartDate = Utils.formatDateToString(new Date(bill.billingStartDate));
      const billEndDate = Utils.formatDateToString(new Date(bill.billingEndDate));
      return billStartDate.localeCompare(fyStartMonth) >= 0 && billEndDate.localeCompare(fyEndMonth) <= 0;
    });
    console.log('Filtered bills => ', filteredBills);
    let totalProfitLoss = 0;
    let totalProfitLossAfterCost = 0;
    let totalCost = 0;
    let totalCapital = 0;
    let returns = 0;
    let returnsAfterCost = 0;
    if (filteredBills.length > 0) {
      _.each(filteredBills, fb => {
        totalProfitLoss += fb.netProfitLoss;
        totalCost += fb.totalCost;
        totalCapital += fb.averageCapital;
      });
      totalCapital = totalCapital / filteredBills.length;
      returns = Utils.roundOff(100 * totalProfitLoss / totalCapital);
      totalProfitLossAfterCost = totalProfitLoss - totalCost;
      returnsAfterCost = Utils.roundOff(100 * totalProfitLossAfterCost / totalCapital);
    }

    console.log('Filtered totalCapital => ' + totalCapital + ', returns = ' + returns + ', returnsAfterCost = ' + returnsAfterCost);

    return (<Card>
      <CardBody>
        <div className="d-flex">
          <div>
            <CardTitle>Returns</CardTitle>
          </div>
        </div>
        <Table className="no-wrap v-middle" responsive>
          <tbody>
            <tr>
              <th>Financial Year</th>
              <td>{this.getSelectField({
                  key: 'financial-year',
                  value: this.state.selectedFY,
                  options: this.fyOptions,
                  placeholder: 'Select FY',
                  onChange: (e) => {
                    this.setState({
                      selectedFY: e.target.value
                    });
                  }
                })}
              </td>
            </tr>
            <tr>
              <th>Capital</th>
              <td>{Utils.formatNumberToCommaSeparated(totalCapital)}</td>
            </tr>
            <tr>
              <th>Returns</th>
              <td>{Utils.formatNumberToCommaSeparated(totalProfitLoss) + ' (' + returns + ' %)'}</td>
            </tr>
            <tr>
              <th>Returns after cost</th>
              <td>{Utils.formatNumberToCommaSeparated(totalProfitLossAfterCost) + ' (' + returnsAfterCost + ' %)'}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }

  render() {

    return (<div>
      <h4>Billing</h4>
      
      <div>
        <Row>
          {this.state.error && <h3><Badge color="warning">{this.state.error}</Badge></h3>}
          {!this.state.error && <Col xs={12} md={6} lg={6}>
            {this.getBillingPlanDetails()}
          </Col>}
          {!this.state.error && <Col xs={12} md={6} lg={6}>
            {this.getReports()}
          </Col>}
        </Row>
        {!this.state.error && <Row>
          <Col>
            <h4>Bills</h4>
            {this.getAllBills()}
          </Col>
        </Row>}
      </div>
    </div>);
  }
}

export default Billing;
