/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import OpenTrades from "./OpenTrades.js";
import ActiveTrades from "./ActiveTrades.js";
import CompletedTrades from "./CompletedTrades.js";
import CancelledTrades from "./CancelledTrades.js";
import ComparePositionsComp from '../common/ComparePositionsComp.js';

import Utils from "../../utils/Utils.js";

import classnames from 'classnames';

import {
  Card,
  CardBody,
  CardHeader,
  Row, 
  Col,
  TabContent,
  TabPane, 
  Nav, 
  NavItem, 
  NavLink
} from 'reactstrap';

class TradesComp extends React.Component {

  constructor(props) {
    super(props);

    let activeTabTitle = 'Active Trades';
    if (this.props.product === 'intraday' && !Utils.isMarketOpen()) {
      activeTabTitle = 'Completed Trades';
    }
    this.state = {
      activeTabTitle
    };

    this.renderOpenTrades = this.renderOpenTrades.bind(this);
    this.renderActiveTrades = this.renderActiveTrades.bind(this);
    this.renderCompletedTrades = this.renderCompletedTrades.bind(this);
    this.renderCancelledTrades = this.renderCancelledTrades.bind(this);
  }

  renderOpenTrades(trades) {
    return (<OpenTrades trades={trades} showLess={true}/>);
  }

  renderActiveTrades(trades) {
    return (<ActiveTrades trades={trades} showLess={true}/>);
  }

  renderCompletedTrades(trades) {
    return (<CompletedTrades trades={trades} showLess={true}/>);
  }

  renderCancelledTrades(trades) {
    return (<CancelledTrades trades={trades} showLess={true}/>);
  }

  toggle(tab) {
    if (tab.title === this.state.activeTabTitle) {
      return;
    }
    this.setState({
      activeTabTitle: tab.title
    });
  }

  render() {
    const openTrades = this.props.getOpenTrades(this.props.product);
    const activeTrades = this.props.getActiveTrades(this.props.product);
    const completedTrades = this.props.getCompletedTrades(this.props.product);
    const cancelledTrades = this.props.getCancelledTrades(this.props.product);
    const algoPositions = this.props.getAlgoPositions(this.props.product);
    const brokerPositions = this.props.getBrokerPositions(this.props.product);
    console.log('Algo Pos => ', algoPositions);

    const tabs = [{
      title: 'Open Trades',
      trades: openTrades,
      getContent: this.renderOpenTrades
    }, {
      title: 'Active Trades',
      trades: activeTrades,
      getContent: this.renderActiveTrades
    }, {
      title: 'Completed Trades',
      trades: completedTrades,
      getContent: this.renderCompletedTrades
    }, {
      title: 'Cancelled Trades',
      trades: cancelledTrades,
      getContent: this.renderCancelledTrades
    }];

    const activeTab = _.find(tabs, tab => tab.title === this.state.activeTabTitle);

    return (<div>
      {this.props.showPositions && <Row>
        <Col>
          <ComparePositionsComp
            product={this.props.product}
            username={this.props.username}
            broker={this.props.broker}
            clientID={this.props.clientID}
            algoPositions={algoPositions}
            brokerPositions={brokerPositions}
            disableActions={true} />
        </Col>
      </Row>}

      <Card>
        <CardHeader>
          Trades
        </CardHeader>
        <CardBody>
          <Nav tabs>
            {
              _.map(tabs, (tab) => {
                return (<NavItem key={tab.title}>
                  <NavLink
                    className={classnames({ active: this.state.activeTabTitle === tab.title })}
                    onClick={() => { this.toggle(tab); }}
                  >
                    {`${tab.title} (${tab.trades.length})`}
                  </NavLink>
                </NavItem>);
              })
            }
          </Nav>
          <TabContent activeTab={this.state.activeTabTitle}>
            <TabPane tabId={this.state.activeTabTitle}>
              {activeTab.getContent(activeTab.trades)}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>);
  }
}

export default TradesComp;
