
import React from 'react';

import config from '../../config.js';

class TermsAndConditions extends React.Component {

  render() {
    let businessName = 'AutoQuant';
    if (config.isWay2Quant()) {
      businessName = 'Way2Quant';
    } else if (config.isXtremeQuant() || config.isProXtremeQuant()) {
      businessName = 'XtremeQuant';
    }

    return (<div>
      <ol>
        <li>Trader should accept 30% drawdown at individual strategy level and 20% drawdown combining all strategies.</li>
        <li>Trader should not expect every month to end in profit.</li>
        <li>Trader should not expect more than 8% average returns per month.</li>
        <li>Trader should not be worried about every day profit/loss statement.</li>
        <li><u>Trader should be 100% disciplined without altering orders placed by algo except rare cases like index locked in upper/lower circuit or broker hang issues.</u></li>
        <li>If it is found that any trader is not disciplined and exiting the trades by altering orders from broker terminal by applying his/her own discretionary methods 
          <u> then the account will be disabled after observing 2-3 such instances without any notice.</u></li>
        <li>{businessName} is not responsible for the loss occurred due to broker issues. For example broker not allowerd square off of some SHORT positions 
          which may result into short delivery and client may end in more loss based on bid price during auction and penalties by exchance if any. 
          <u> In this case, {businessName} considers the exit price w.r.t to the traded price at square off time because the issue is between client and broker hence {businessName} does not 
          do any offset to profit/loss.</u></li>
        <li>{businessName} is not responsible for the loss occurred due to any bugs in the algo. Traders should understand the risk before availing the algo services.</li>
        <li>{businessName} is not responsible if login failed to broker account due to any issues like corresponding API key expired, client password expired or any other network errors from broker.</li>
        <li>{businessName} is not responsible for any loss occurred due to any new unknown issues.</li>
      </ol>
      <b>If you are not comfortable with any of the above mentioned rules, this system is not for you.</b>
    </div>);
  }
}

export default TermsAndConditions;