

import Users from '../components/admin/Users.js';
import UsersBrokerLoginStatus from '../components/admin/UsersBrokerLoginStatus.js';
import Alerts from '../components/admin/Alerts.js';
import UsersAllTradesStatus from '../components/admin/UsersAllTradesStatus.js';
import SquareOffUtility from '../components/admin/SquareOffUtility.js';
import RiskManagement from '../components/admin/RiskManagement.js';
import UsersBills from '../components/admin/UsersBills.js';
import UsersPayments from '../components/admin/UsersPayments.js';
import UsersBrokerFunds from '../components/admin/UsersBrokerFunds.js';
import UsersReports from '../components/admin/UsersReports.js';
import Configuration from '../components/admin/Configuration.js';
import Miscellaneous from '../components/admin/Miscellaneous.js';
import Strategies from '../components/admin/Strategies.js';
import Brokers from '../components/admin/Brokers.js';
// import Logout from '../components/auth/Logout.js';

var ThemeRoutes = [
  { 
    path: '/clientmanager/trades',
    name: 'Trades & Positions',
    icon: 'mdi mdi-monitor',
    component: UsersAllTradesStatus
  },
  { 
    path: '/clientmanager/alerts',
    name: 'Alerts',
    icon: 'mdi mdi-alert',
    component: Alerts
  },
  { 
    path: '/clientmanager/users', 
    name: 'Users', 
    icon: 'mdi mdi-account-multiple',
    component: Users
  },
  {
    path: '/clientmanager/strategies',
    name: 'Strategies',
    icon: 'mdi mdi-folder-multiple-outline',
    component: Strategies
  },
  {
    path: '/clientmanager/brokers',
    name: 'Brokers',
    icon: 'mdi mdi-human',
    component: Brokers
  },
  { 
    path: '/clientmanager/brokerloginstatus', 
    name: 'Users Login Status',
    icon: 'mdi mdi-check-all',
    component: UsersBrokerLoginStatus
  },
  { 
    path: '/clientmanager/funds',
    name: 'Users Funds',
    icon: 'mdi mdi-bank',
    component: UsersBrokerFunds
  },
  { 
    path: '/clientmanager/rms',
    name: 'Risk Management',
    icon: 'mdi mdi-settings',
    component: RiskManagement
  },
  { 
    path: '/clientmanager/squareoff',
    name: 'SquareOff Utility',
    icon: 'mdi mdi-close-circle',
    component: SquareOffUtility
  },
  { 
    path: '/clientmanager/billing',
    name: 'Users Billing',
    icon: 'mdi mdi-cash-multiple',
    component: UsersBills
  },
  { 
    path: '/clientmanager/payments',
    name: 'Users Payments',
    icon: 'mdi mdi-cash-multiple',
    component: UsersPayments
  },
  { 
    path: '/clientmanager/reports',
    name: 'Performance Reports',
    icon: 'mdi mdi-chart-bar',
    component: UsersReports
  },
  { 
    path: '/clientmanager/config',
    name: 'Configuration',
    icon: 'mdi mdi-settings',
    component: Configuration
  },
  { 
    path: '/clientmanager/miscellaneous',
    name: 'Miscellaneous',
    icon: 'mdi mdi-food-fork-drink',
    component: Miscellaneous
  },
  /*{
    path: '/logout',
    name: 'Logout',
    icon: 'mdi mdi-logout',
    component: Logout
  },*/
  { path: '/clientmanager', pathTo: '/clientmanager/trades', name: 'Trades & Positions', redirect: true }
];
export default ThemeRoutes;
