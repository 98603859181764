/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";
import Utils from "../../utils/Utils.js";

import UpdateRMSDetailsModal from './UpdateRMSDetailsModal.js';

class RiskManagement extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      globalRMS: {},
      usersRMS: [],
      fetchingDataInProgress: false,
      error: null
    };

    this.launchUpdateRMSDetailsModal = this.launchUpdateRMSDetailsModal.bind(this);
    this.closeUpdateRMSDetailsModal = this.closeUpdateRMSDetailsModal.bind(this);
  }

  componentWillMount() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    this.fetchGlobalRMSParams().then(globalRMS => {
      this.fetchUsersRMSParams().then(usersRMS => {
        this.setState({
          globalRMS,
          usersRMS,
          fetchingDataInProgress: false
        });
      }).catch(err => {
        console.error('Failed to fetch users RMS Params: ', err);
        this.setState({
          fetchingDataInProgress: false,
          error: err
        });
      });
    }).catch(err => {
      console.error('Failed to fetch global RMS Params: ', err);
      this.setState({
        fetchingDataInProgress: false,
        error: err
      });
    });
  }

  fetchGlobalRMSParams() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/user/rms?username=global&broker=dummy", { json: true }, (err, resp, respBody) => {
        if (err) {
          return reject(err);
        }
        if (resp) {
          console.log('fetchGlobalRMSParams: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            const globalRMS = respBody || {};
            resolve(globalRMS);
          } else {
            reject(respBody.error || 'Failed to fetch global rms params');
          }
        }
      });
    });
  }

  fetchUsersRMSParams() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/clientmanager/users/rms", { json: true }, (err, resp, respBody) => {
        if (err) {
          return reject(err);
        }
        if (resp) {
          console.log('fetchUsersRMSParams: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            const usersRMS = respBody || [];
            // sort by capital
            usersRMS.sort((u1, u2) => u2.capital - u1.capital);
            resolve(usersRMS);
          } else {
            reject(respBody.error || 'Failed to fetch users rms params');
          }
        }
      });
    });
  }

  launchUpdateRMSDetailsModal(rmsDetails = {}) {
    this.setState({
      showUpdateRMSDetailsModal: true,
      selectedRMSDetails: rmsDetails
    });
  }

  closeUpdateRMSDetailsModal(rmsDetailsUpdated = false) {
    this.setState({
      showUpdateRMSDetailsModal: false,
      selectedRMSDetails: null
    }, () => {
      if (rmsDetailsUpdated === true) {
        // refresh all users RMS data
        this.setState({
          fetchingDataInProgress: true,
          error: null
        });
        this.fetchUsersRMSParams().then(usersRMS => {
          this.setState({
            usersRMS,
            fetchingDataInProgress: false,
          });
        }).catch(err => {
          console.error('Failed to fetch updated user RMS details: ', err);
          this.setState({
            error: err,
            usersRMS: [],
            fetchingDataInProgress: false,
          });
        }); 
      }
    });
  }

  getUserRMSDetails(rmsDetails = {}) {
    const globalRMS = this.state.globalRMS;
    const isDiffFromGlobal = rmsDetails.maxBuyOrdersPerDay !== globalRMS.maxBuyOrdersPerDay
      || rmsDetails.maxSellOrdersPerDay !== globalRMS.maxSellOrdersPerDay
      || rmsDetails.maxFnOBuyQtyPerSymbolPerDay !== globalRMS.maxFnOBuyQtyPerSymbolPerDay
      || rmsDetails.maxFnOSellQtyPerSymbolPerDay !== globalRMS.maxFnOSellQtyPerSymbolPerDay;
    return (
      <tr key={"rms-" + rmsDetails.username + "-" + rmsDetails.broker}>
        <td className={isDiffFromGlobal ? 'font-blue' : ''}>{rmsDetails.username}</td>
        <td className={isDiffFromGlobal ? 'font-blue' : ''}>{rmsDetails.broker}</td>
        <td className={isDiffFromGlobal ? 'font-blue' : ''}>{Utils.formatNumberToCommaSeparated(rmsDetails.capital)}</td>
        {/*<td className={rmsDetails.maxBuyOrdersPerDay !== globalRMS.maxBuyOrdersPerDay ? 'font-blue' : ''}>{rmsDetails.maxBuyOrdersPerDay || 0}</td>
        <td className={rmsDetails.maxSellOrdersPerDay !== globalRMS.maxSellOrdersPerDay ? 'font-blue' : ''}>{rmsDetails.maxSellOrdersPerDay || 0}</td>*/}
        <td className={rmsDetails.maxFnOBuyQtyPerSymbolPerDay !== globalRMS.maxFnOBuyQtyPerSymbolPerDay ? 'font-blue' : ''}>{rmsDetails.maxFnOBuyQtyPerSymbolPerDay || 0}</td>
        <td className={rmsDetails.maxFnOSellQtyPerSymbolPerDay !== globalRMS.maxFnOSellQtyPerSymbolPerDay ? 'font-blue' : ''}>{rmsDetails.maxFnOSellQtyPerSymbolPerDay || 0}</td>
        <td>
          <Button  color="primary" 
            onClick={() => this.launchUpdateRMSDetailsModal(rmsDetails)} style={{'marginRight': '10px'}}>EDIT</Button>
        </td>
      </tr>
    );
  }

  render() {
    return (<div>
      <h4>Risk Management</h4>
      <Card>
        <CardBody>
          <div>
            {this.state.fetchingDataInProgress === true && <div>
                Fetching details in progress...
              </div>}
            {this.state.error && <div className='text-orange'>
              {this.state.error}
            </div>}
            {!this.state.fetchingDataInProgress && !this.state.error 
              && this.state.usersRMS.length === 0 && <div>
              No RMS data found.
            </div>}
            {!this.state.fetchingDataInProgress && !this.state.error 
              && this.state.usersRMS.length > 0 && <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">Username</th>
                  <th className="border-0">Broker</th>
                  <th className="border-0">Capital</th>
                  {/*<th className="border-0">Max Buy orders per day</th>
                  <th className="border-0">Max Sell orders per day</th>*/}
                  <th className="border-0">Max FnO Buy qty per symbol per day</th>
                  <th className="border-0">Max FnO Sell qty per symbol per day</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(this.state.usersRMS, rmsDetails => {
                    return this.getUserRMSDetails(rmsDetails);
                  })
                }
              </tbody>
            </Table>
            }
          </div>
        </CardBody>
      </Card>
      {this.state.showUpdateRMSDetailsModal && <UpdateRMSDetailsModal 
        isModalOpen={this.state.showUpdateRMSDetailsModal}
        onCancel={this.closeUpdateRMSDetailsModal}
        rmsDetails={this.state.selectedRMSDetails}
      />}
    </div>);
  }
}

export default RiskManagement;
