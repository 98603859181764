import _ from 'lodash';
import React from 'react';

import {
  Alert
} from 'reactstrap';
import config from '../../config.js';
import { fetchFAQs } from '../../utils/RestAPIs.js';

class FAQ extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      faqs: []
    };
  }

  componentWillMount() {
    fetchFAQs().then(faqs => {
      this.setState({
        faqs: faqs || []
      });
    });
  }

  getFAQ(faq = {}) {
    return (<div key={faq.sno}>
      <Alert color="info">
        <b>{faq.sno + ' ' + faq.question}</b>
        <hr />
        {faq.answer.replaceAll('{HOST}', config.serverHost).replaceAll('{EMAIL}', config.getSupportEmail())}
      </Alert>
    </div>);
  }

  render() {
    return (<div>
      <h4>Frequently Asked Questions:</h4>
      {
        _.map(this.state.faqs, faq => {
          return this.getFAQ(faq);
        })
      }
    </div>);
  }
}

export default FAQ;