/*
  Author: Sreenivas Doosa
*/

import React from "react";

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button
} from 'reactstrap';

class AlertModal extends React.Component {

  render() {
    
    return (<Modal 
        size={this.props.isBigModal ? "lg" : "sm"}
        isOpen={this.props.isModalOpen}>

        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>
          {
            this.props.message
          }
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={this.props.onCancel}>{'Ok'}</Button>
        </ModalFooter>

    </Modal>);
  }
}

export default AlertModal;
