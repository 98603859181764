/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Alert,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Badge
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class UsersPayments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      usersPayments: [],
      fetchingDataInProgress: false,
      filterTextUser: null,
      filterTextStatus: null,
      error: null
    };

    this.onUserFilterTextChange = this.onUserFilterTextChange.bind(this);
    this.onStatusFilterTextChange = this.onStatusFilterTextChange.bind(this);
  }

  componentWillMount() {
    this.fetchUsersPayments();
  }

  fetchUsersPayments() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    HttpRequest(config.serverHost + "/apis/clientmanager/payments", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchUsersPayments: error = ', err);
        this.setState({
          fetchingDataInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchUsersPayments: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          const usersPayments = respBody || [];
          
          this.setState({
            usersPayments: usersPayments,
            fetchingDataInProgress: false
          });
        } else {
          this.setState({
            usersPayments: [],
            fetchingDataInProgress: false,
            error: respBody.error || 'Failed to fetch usersPayments'
          });
        }
      }
    });
  }

  getSummary() {
    const summary = {
      totalPayments: 0,
      totalPaymentsSuccessful: 0,
      totalPaymentsCreated: 0,
      totalPaymentsFailed: 0,
      totalPaymentsRefunded: 0,
      totalAmountReceived: 0,
      totalAmountRefunded: 0,
      totalNetAmount: 0
    };
    _.each(this.state.usersPayments, payment => {
      summary.totalPayments++;
      if (payment.status === 'paid' || payment.status === 'refunded') {
        summary.totalAmountReceived += payment.amount;
        summary.totalPaymentsSuccessful++;
      }
      if (payment.amountRefunded > 0) {
        summary.totalAmountRefunded += payment.amountRefunded;
        summary.totalPaymentsRefunded++;
      }
      if (payment.status === 'created') {
        summary.totalPaymentsCreated++;
      }
      if (payment.status === 'failed') {
        summary.totalPaymentsFailed++;
      }
    });
    summary.totalNetAmount = summary.totalAmountReceived - summary.totalAmountRefunded;

    return (<Alert color="light">
      <Row>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="info">
              {"Total payments initiated: " + Utils.formatNumberToCommaSeparated(summary.totalPayments)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="success">
              {"No. of successful payments: " + Utils.formatNumberToCommaSeparated(summary.totalPaymentsSuccessful)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="danger">
              {"No. of payments failed: " + Utils.formatNumberToCommaSeparated(summary.totalPaymentsFailed)}
            </Badge>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="info">
              {"Total Amount Received: " + Utils.formatNumberToCommaSeparated(summary.totalAmountReceived)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="warning">
              {"Total Amount Refunded: " + Utils.formatNumberToCommaSeparated(summary.totalAmountRefunded)}
            </Badge>
          </h4>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <h4>
            <Badge color="success">
              {"Total Net Amount: " + Utils.formatNumberToCommaSeparated(summary.totalNetAmount)}
            </Badge>
          </h4>
        </Col>
      </Row>
    </Alert>);
  }

  onUserFilterTextChange(e) {
    this.setState({
      filterTextUser: e.target.value
    });
  }

  onStatusFilterTextChange(e) {
    this.setState({
      filterTextStatus: e.target.value
    });
  }

  getPayment(payment = {}, sno) {
    return (
      <tr key={"payment-" + payment.receiptId}>
        <td>{sno}</td>
        <td>{payment.username}</td>
        <td>{payment.orderId}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(payment.amount, true)}</td>
        <td>{payment.planName}</td>
        <td className="number-right">{payment.validityPeriodDays} days</td>
        <td>{_.startCase(payment.status)}</td>
        <td>{payment.paymentId}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(payment.amountRefunded, true)}</td>
        <td>{Utils.formatDate(payment.lastUpdatedAt)}</td>
      </tr>
    );
  }

  getAllPayments() {

    let payments = this.state.usersPayments;
    if (_.isEmpty(this.state.filterTextUser) === false) {
      payments = _.filter(payments, p => {
        if (_.includes(_.toLower(p.username), _.toLower(this.state.filterTextUser))) {
          return true;
        }
        return false;
      });
    }
    if (_.isEmpty(this.state.filterTextStatus) === false) {
      payments = _.filter(payments, p => {
        if (p.status === this.state.filterTextStatus) {
          return true;
        }
        return false;
      });
    }

    return (<Card>
      <CardBody>
        <div>
          {this.state.fetchingDataInProgress === true && <div>
              Fetching payments in progress...
          </div>}
          {this.state.error && <div className='text-orange'>
            {this.state.error}
          </div>}
          {!this.state.fetchingDataInProgress && !this.state.error 
            && (!this.state.usersPayments || this.state.usersPayments.length === 0) && <div>
            No payments found.
          </div>}

          {this.state.usersPayments && this.state.usersPayments.length > 0 && <div>
            <div className="filter-inputs">
              <div className="filter-input">
                <label>Filter By:</label>
                <input 
                  value={this.state.filterTextUser || ''}
                  placeholder="username" 
                  onChange={this.onUserFilterTextChange}></input>
              </div>
              <div className="filter-input">
                <label>Filter By:</label>
                <input 
                  value={this.state.filterTextStatus || ''}
                  placeholder="status" 
                  onChange={this.onStatusFilterTextChange}></input>
              </div>
            </div>
          </div>}

          {payments.length > 0 && <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">SNo</th>
                <th className="border-0">Username</th>
                <th className="border-0">Order Id</th>
                <th className="border-0 number-right">Amount Paid</th>
                <th className="border-0">Plan</th>
                <th className="border-0 number-right">Validity</th>
                <th className="border-0">Status</th>
                <th className="border-0">Payment ID</th>
                <th className="border-0 number-right">Amount Refunded</th>
                <th className="border-0">Last Updated At</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(payments, (payment, index) => {
                  return this.getPayment(payment, index + 1);
                })
              }
            </tbody>
          </Table>}
        </div>
      </CardBody>
    </Card>);
  }

  render() {

    return (<div>
      <h4>Users Payments</h4>
      <div>
        {this.getSummary()}
        {this.getAllPayments()}
      </div>
    </div>);
  }
}

export default UsersPayments;
