

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Input,
  Col,
  Button
} from 'reactstrap';

import PnlChart from './PnlChart.js';
import PerformanceReport from './PerformanceReport.js';

import { fetchDaywiseReport }  from '../../utils/RestAPIs.js';
import Utils from '../../utils/Utils.js';
import DatePicker from "react-datepicker";

class Performance extends React.Component {

  constructor(props) {
    super(props);

    this.rangeStartDate = new Date('2019-10-01');
    this.rangeEndDate = new Date();

    const now = new Date();
    const firstDateOfTheMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    this.state = {
      startDate: firstDateOfTheMonth,
      endDate: new Date(),
      records: [],
      selectedBroker: (props.user.brokers[0] || {}).broker,
      selectedStrategy: null,
      strategies: [],
      error: null
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStrategyChange = this.handleStrategyChange.bind(this);
    this.handleBrokerChange = this.handleBrokerChange.bind(this);
    this.getStrategyDisplayName = this.getStrategyDisplayName.bind(this);
    this.fetchReport = this.fetchReport.bind(this);
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date
    }, () => {
      this.validateInput();
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date
    }, () => {
      this.validateInput();
    });
  }

  validateInput() {
    if (this.state.startDate.getTime() > this.state.endDate.getTime()) {
      this.setState({
        inputError: 'Start Date can not be greater than End Date'
      });
    } else {
      this.setState({
        inputError: null
      });
    }
  }

  handleStrategyChange(e) {
    this.setState({
      selectedStrategy: e.target.value
    });
  }

  handleBrokerChange(e) {
    this.setState({
      selectedBroker: e.target.value,
      records: [],
      selectedStrategy: null,
      strategies: [],
      error: null
    }, () => {
      this.fetchReport();
    });
  }

  getStrategyDisplayName(record = {}) {
    return record.strategy;
  }

  fetchReport() {

    const startDateStr = Utils.formatDateToString(this.state.startDate);
    const endDateStr = Utils.formatDateToString(this.state.endDate);

    this.setState({
      fetchReportInProgress: true,
      records: [],
      strategies: [],
      selectedStrategy: null
    });

    return fetchDaywiseReport({
      username: this.props.username,
      broker: this.state.selectedBroker,
      startDateStr,
      endDateStr
    }).then(data => {
      let records = data || [];
      let strategies = [];
      let selectedStrategy = this.state.selectedStrategy;

      // sort records by date
      records.sort((r1, r2) => r1.dateStr.localeCompare(r2.dateStr));

      _.each(records, record => {
        if (_.some(strategies, s => s === record.strategy) === false) {
          strategies.push(record.strategy);
        }
      });

      if (strategies.length === 1) {
        selectedStrategy = strategies[0];
      }

      this.setState({
        fetchReportInProgress: false,
        records,
        strategies,
        selectedStrategy
      });
    }).catch(err => {
      console.log('fetchReport: err => ', err);
      this.setState({
        error: err.error || 'Failed to fetch report',
        fetchReportInProgress: false
      });
    });
  }

  render() {
    const error = this.state.inputError || this.state.error;
    return (<div>
      <h4>Performance</h4>
      <div>
        <Col>
          <Card>
            <CardBody>
              <div className="trades-history-input">
                <div className="date-picker-wrapper">
                  <label>Start Date:</label>
                  <DatePicker 
                    dateFormat="dd-MM-yyyy"
                    minDate={this.rangeStartDate}
                    maxDate={this.rangeEndDate}
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange} />
                </div>

                <div className="date-picker-wrapper">
                  <label>End Date:</label>
                  <DatePicker 
                    dateFormat="dd-MM-yyyy"
                    minDate={this.rangeStartDate}
                    maxDate={this.rangeEndDate}
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange} />
                </div>

                <Button className="date-picker-submit btn-info"
                  onClick={this.fetchReport}
                  disabled={this.state.fetchReportInProgress || _.isEmpty(this.state.inputError) === false} >
                  {this.state.fetchReportInProgress ? "Fetching Report..." : "Fetch Reports" }
                </Button>
              </div>

              {error && <div className="error-text">{error}</div>}
              {this.state.fetchReportInProgress && <div>Fetching report...</div>}

              {_.isEmpty(error) && !this.state.fetchReportInProgress ? <div>
                {this.props.user.brokers.length > 1 && <div 
                  style={{marginBottom: '15px'}}
                  className="ml-auto d-flex no-block align-items-center">
                  <span style={{fontWeight: 'bold', fontSize: '16px', marginRight: '5px'}}>Select Broker: </span>
                  <div className="dl">
                     <Input type="select" 
                      className="custom-select" 
                      value={this.state.selectedBroker}
                      onChange={this.handleBrokerChange}>
                      {
                        _.map(this.props.user.brokers, b => {
                          return (<option
                            key={b.broker}
                            value={b.broker}>
                            {b.broker}
                          </option>);
                        })
                      }
                    </Input>
                  </div>
                </div>}
                {this.state.records.length > 0 && <PnlChart broker={this.state.selectedBroker} records={this.state.records}/>}
                <PerformanceReport 
                  records={this.state.records}
                  strategies={this.state.strategies}
                  selectedStrategy={this.state.selectedStrategy}
                  getStrategyDisplayName={this.getStrategyDisplayName} />
              </div> : null}
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>);
  }
}

export default Performance;