/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button
} from 'reactstrap';

import UserStrategiesModal from '../common/UserStrategiesModal.js';

import Utils from "../../utils/Utils.js";

import imgDefault from '../../assets/images/default.png';
import imgZerodha from '../../assets/images/zerodha.png';
import imgFyers from '../../assets/images/fyers.png';
import img5Paisa from '../../assets/images/5paisa.png';
import imgIIFL from '../../assets/images/iifl.png';

class StrategiesByBroker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showUserStrategiesModal: false
    }
  }

  onEditButtonClick() {
    this.setState({
      showUserStrategiesModal: true
    });
  }

  closeUserStrategiesModal(reload = false) {
    this.setState({
      showUserStrategiesModal: false
    }, () => {
      if (reload) {
        this.props.fetchUserDetails();
      }
    });
  }

  getBrokerLogo(broker) {
    let brokerLogo = null;
    if (broker === 'zerodha') {
      brokerLogo = imgZerodha;
    } else if (broker === 'fyers') {
      brokerLogo = imgFyers;
    } else if (broker === '5paisa') {
      brokerLogo = img5Paisa;
    } else if (broker === 'iifl') {
      brokerLogo = imgIIFL;
    } else {
      brokerLogo = imgDefault;
    }
    return brokerLogo;
  }

  getStrategyDetails(strategy = {}) {
    let brokerLogo = this.getBrokerLogo(strategy.broker);
    let status = strategy.enabled ? 'Enabled' : 'Disabled';
    if (strategy.enabled && strategy.expiryDate > 0) {
      const expiryTimestamp = new Date(strategy.expiryDate);
      const now = new Date();
      if (now > expiryTimestamp) {
        status = 'Expired';
      } else {
        status = `${Utils.calculateDaysDiff(expiryTimestamp, now)} days left`;
      }
    }
    
    return (<Card>
      <CardBody>
        <div className="d-flex">
          <div>
            <CardTitle>{Utils.getStrategyDisplayName(strategy.strategyName)}</CardTitle>
          </div>
          <div className="ml-auto">
            <i className={'fa fa-circle ' + (status === 'Expired' || status === 'Disabled' ? 'text-orange' : 'text-success')} id="tlp1"></i>
          </div>
        </div>
        <Table className="no-wrap v-middle" responsive>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{Utils.getStrategyDisplayName(strategy.strategyName)}</td>
            </tr>
            <tr>
              <th>Broker</th>
              <td><div className="mr-2"><img src={brokerLogo} alt="user" width="25" /><span>{_.toUpper(strategy.broker)}</span></div></td>
            </tr>
            <tr>
              <th>Status</th>
              <th className={status === 'Expired' || status === 'Disabled' ? 'text-orange' : 'text-success'}>
                {status}
              </th>
            </tr>
            <tr>
              <th>Capital</th>
              <td>{Utils.formatNumberToCommaSeparated(strategy.capital)}</td>
            </tr>
            {strategy.lots > 0 && <tr>
              <th>Lots</th>
              <td>{strategy.lots}</td>
            </tr>}
            {strategy.compoundingEnabled === true && !_.isEmpty(strategy.compoundingMethod) && <tr>
              <th>Compounding Method</th>
              <td>{strategy.compoundingMethod}</td>
            </tr>}
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }

  render() {

    let enabledStrategies = [];
    let disabledStrategies = [];
    let totalCapitalEnabled = 0, totalCapitalDisabled = 0, totalOverlapCapital = 0;
    _.each(this.props.userStrategies, s => {
      if (s.enabled === true) {
        enabledStrategies.push(s.name);
        if (s.isOverlapCapital) {
          totalOverlapCapital += s.capital;
        } else {
          totalCapitalEnabled += s.capital;  
        }
      } else {
        disabledStrategies.push(s.name);
        totalCapitalDisabled += s.capital;
      }
    });

    return (<div>
      <Button color="primary"
        style={{'marginTop': '10px'}}
        onClick={() => this.onEditButtonClick()}>
        MANAGE STRATEGIES
      </Button>
      
      <hr />
      <div>
        <Row>
          <Col>
            <h3><Badge color="success">Total Active Capital: {Utils.formatNumberToCommaSeparated(totalCapitalEnabled)}</Badge></h3>
          </Col>
          {totalOverlapCapital > 0 && <Col>
            <h3><Badge color="secondary">Total Overlap Capital: {Utils.formatNumberToCommaSeparated(totalOverlapCapital)}</Badge></h3>
          </Col>}
          {totalCapitalDisabled > 0 && <Col>
            <h3><Badge color="warning">Total In-Active Capital: {Utils.formatNumberToCommaSeparated(totalCapitalDisabled)}</Badge></h3>
          </Col>}
        </Row>

        <Row>
          {_.map(this.props.userStrategies, strategy => {
              return (<Col key={strategy.strategyName + strategy.broker} xs={12} sm={12} md={6} lg={6}>
                {this.getStrategyDetails(strategy)}
              </Col>);
          })}
        </Row>
      </div>

      {this.state.showUserStrategiesModal && <UserStrategiesModal
        isModalOpen={this.state.showUserStrategiesModal}
        username={this.props.username}
        broker={this.props.broker}
        allStrategies={this.props.allStrategies}
        userStrategies={this.props.userStrategies}
        onCancel={this.closeUserStrategiesModal.bind(this)}
      />}
    </div>);
  }
}

export default StrategiesByBroker;
