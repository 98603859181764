/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Alert
} from 'reactstrap';

import HttpRequest from "request";
import { 
  getBrokerDetails,
  getAllBrokeragePlans
} from "../../utils/RestAPIs.js";
import config from "../../config.js";

class UserBrokerComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      brokers: [],
      validations: {},
      brokeragePlans: []
    };

    this.createOrUpdateUserBrokerDetails = this.createOrUpdateUserBrokerDetails.bind(this);
    this.onCancel = this.onCancel.bind(this);

    console.log('UserBrokerComp: supported brokers: ', props.supportedBrokers);
    this.brokerOptions = _.map(props.supportedBrokers, b => {
      return {
        label: _.toUpper(b),
        value: b
      };
    });
  }

  componentWillMount() {
    getBrokerDetails().then(brokers => {
      this.setState({
        brokers,
        data: this.prepareDataFromProps()
      }, () => {
        this.fetchBrokeragePlans();
      });
    }).catch(err => {
      this.setState({
        error: 'Failed to get broker details'
      }, () => {
        this.fetchBrokeragePlans();
      });
    });
  }

  fetchBrokeragePlans() {
    getAllBrokeragePlans().then(brokeragePlans => {
      this.setState({
        brokeragePlans: brokeragePlans || []
      });
    }).catch(err => {
      this.setState({
        error: 'Failed to fetch brokerage plans'
      });
    });
  }

  getBrokeragePlanOptions() {
    const options = [{label: 'Select brokerage plan', value: ''}];
    _.each(this.state.brokeragePlans, bp => {
      options.push({
        label: bp.planName,
        value: bp.planName
      });
    });
    return options;
  }

  prepareDataFromProps(props) {
    props = props || this.props;

    const userBrokerDetails = props.userBrokerDetails || {};
    const data = {
      username: userBrokerDetails.username,
      enabled: userBrokerDetails.enabled,
      autoLogin: userBrokerDetails.autoLogin,
      loginVerified: userBrokerDetails.loginVerified || false
    };
    if (_.isEmpty(userBrokerDetails.broker)) {
      // new user broker details
      return {
        ...data,
        broker: this.props.supportedBrokers[0]
      };
    }

    _.set(data, 'broker', userBrokerDetails.broker);
    _.set(data, 'clientID', userBrokerDetails.clientID);
    _.set(data, 'useApiOf', userBrokerDetails.useApiOf);
    _.set(data, 'clientPassword', userBrokerDetails.clientPassword);
    _.set(data, 'clientPIN', userBrokerDetails.clientPIN);
    _.set(data, 'panOrDOB', userBrokerDetails.panOrDOB);
    _.set(data, 'appKey', userBrokerDetails.appKey);
    _.set(data, 'appSecret', userBrokerDetails.appSecret);
    _.set(data, 'xtremeAgentUrl', userBrokerDetails.xtremeAgentUrl);
    _.set(data, 'brokeragePlan', userBrokerDetails.brokeragePlan);
    
    return data;
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel(this.state.userBrokerDetailsUpdated ? true : false);
    }
  }

  isNewUserBroker() {
    return this.props.userBrokerDetails.broker ? false : true;
  }

  createOrUpdateUserBrokerDetails(e) {
    e.preventDefault();

    this.setState({
      updateInProgress: true,
      userBrokerDetailsUpdated: false,
      statusMessage: null
    });
    const autoLogin = _.get(this.state.data, 'autoLogin', false);
    const data = {
      userBrokerDetails: {
        username: _.get(this.state.data, 'username'),
        broker: _.get(this.state.data, 'broker'),
        clientID: _.get(this.state.data, 'clientID'),
        useApiOf: _.get(this.state.data, 'useApiOf'),
        clientPassword: autoLogin ? _.get(this.state.data, 'clientPassword') : null,
        clientPIN: autoLogin ? _.get(this.state.data, 'clientPIN') : null,
        totpKey: autoLogin ? _.get(this.state.data, 'totpKey') : null,
        panOrDOB: autoLogin ? _.get(this.state.data, 'panOrDOB') : null,
        appKey: _.get(this.state.data, 'appKey'),
        appSecret: _.get(this.state.data, 'appSecret'),
        xtremeAgentUrl: _.get(this.state.data, 'xtremeAgentUrl'),
        enabled: _.get(this.state.data, 'enabled'),
        autoLogin: _.get(this.state.data, 'autoLogin'),
        brokeragePlan: _.get(this.state.data, 'brokeragePlan')
      }
    };

    // For new user broker POST else PUT
    const httpFunc = this.isNewUserBroker() ? HttpRequest.post : HttpRequest.put;

    httpFunc({
      url: config.serverHost + "/apis/user/broker",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('createOrUpdateUserBrokerDetails status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: 'Successfully ' + (!this.isNewUserBroker() ? 'updated' : 'created') + ' user broker details',
          updateInProgress: false,
          userBrokerDetailsUpdated: true
        });
      } else if (resp.statusCode === 404) {
        const error = 'createOrUpdateUserBrokerDetails end point not found';
        this.setState({
          error,
          updateInProgress: false
        });
      } else {
        console.error('createOrUpdateUserBrokerDetails error => ', respBody);
        const error = JSON.parse(respBody).error || JSON.parse(respBody).result;
        this.setState({
          error,
          updateInProgress: false
        });
      }
    });
  }

  getValidationError(key, value) {
    if (_.isEmpty(value)) {
      return null;
    }
    //TODO: Currently we dont have any validations : will add max length checks

    return null;
  }

  onFieldChange(key, ev) {
    const value = ev ? (ev.target.type.toLowerCase() === 'checkbox' ?
                  ev.target.checked : ev.target.value) : '';
    // console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const data = {...this.state.data};
    _.set(data, key, _.trim(value));

    const newValidations = {...this.state.validations};
    newValidations[key] = this.getValidationError(key, value);

    this.setState({
      data,
      validations: newValidations,
      statusMessage: null,
      error: null
    });
  }

  getInputField(opts = {}) {
    const validationError = _.isEmpty(opts.value) ? null : this.state.validations[opts.key];
    return (<div>
      {opts.type !== 'checkbox' && <FormGroup className="form-row">
        <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
        <Input valid={validationError ? false : true}
          className="col-sm-6 col-form-control"
          type={opts.type || 'text'} 
          name="text" 
          id={opts.key} 
          onChange={opts.onChange}
          value={opts.value}
          disabled={opts.disabled}
          placeholder={opts.placeholder}>
        </Input>
        {validationError && <div className="col-sm-6 text-orange">{validationError}</div>}
      </FormGroup>}
      {opts.type === 'checkbox' && <FormGroup className="form-row">
        <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
        <Input
          className="col-sm-6 col-form-control"
          type={opts.type} 
          id={opts.key} 
          onChange={opts.onChange}
          checked={opts.value}
          disabled={opts.disabled}
          placeholder={opts.placeholder}>
        </Input>
      </FormGroup>}
    </div>);
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input valid
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        disabled={opts.disabled}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.label} value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  isFormValid() {
    if (_.isEmpty(_.get(this.state.data, 'username'))
      || _.isEmpty(_.get(this.state.data, 'broker'))
      || _.isEmpty(_.get(this.state.data, 'clientID'))
      || _.isEmpty(_.get(this.state.data, 'brokeragePlan'))) {
      return false;
    }
    const autoLogin = _.get(this.state.data, 'autoLogin');
    const broker = _.get(this.state.data, 'broker');
    const useApiOf = _.get(this.state.data, 'useApiOf');
    const brokerDetails = _.find(this.state.brokers, b => b.name === broker) || {};
    if (brokerDetails.useCommonApp === false && _.isEmpty(useApiOf)) {
      if (_.isEmpty(_.get(this.state.data, 'appKey'))
        || _.isEmpty(_.get(this.state.data, 'appSecret'))) {
        return false;
      }
    }
    if (autoLogin && this.isNewUserBroker()) {
      if (_.isEmpty(_.get(this.state.data, 'clientPassword'))) {
        return false;
      }
      if (broker === 'zerodha' && _.isEmpty(_.get(this.state.data, 'totpKey'))) {
        return false;
      }
      if (broker === 'fyers') {
        if (_.isEmpty(_.get(this.state.data, 'clientPIN'))) {
          return false;
        }
        if (_.isEmpty(_.get(this.state.data, 'totpKey'))) {
          return false;
        }
      }
    }

    let isValid = true;
    _.each(this.state.validations, (v, key) => {
      if (v) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  renderBody(validationError) {
    const showAlert = this.state.error || this.state.statusMessage || this.state.updateInProgress || validationError;
    const error = this.state.error || validationError;
    const autoLogin = _.get(this.state.data, 'autoLogin', false);
    const loginVerified = _.get(this.state.data, 'loginVerified', false);
    const broker = _.get(this.state.data, 'broker');
    const brokerDetails = _.find(this.state.brokers, b => b.name === broker) || {};

    return (<div>
      {this.getInputField({
        key: 'username',
        label: 'Username',
        disabled: true, // always disabled
        value: _.get(this.state.data, 'username'),
        onChange: (e) => this.onFieldChange('username', e)
      })}
      {this.getSelectField({
        key: 'broker',
        label: 'Broker',
        disabled: !this.isNewUserBroker() && loginVerified === true ? true : false,
        value: _.get(this.state.data, 'broker'),
        options: this.brokerOptions,
        placeholder: 'Select broker',
        onChange: (e) => this.onFieldChange('broker', e)
      })}
      {this.getInputField({
        key: 'clientID',
        label: 'Broker Client ID',
        disabled: !this.isNewUserBroker() && loginVerified === true ? true : false,
        value: _.get(this.state.data, 'clientID'),
        onChange: (e) => this.onFieldChange('clientID', e)
      })}
      {this.getSelectField({
        key: 'brokeragePlan',
        label: 'Brokerage Plan',
        options: this.getBrokeragePlanOptions(),
        placeholder: 'Select brokerage plan',
        value: _.get(this.state.data, 'brokeragePlan'),
        onChange: (e) => this.onFieldChange('brokeragePlan', e)
      })}
      {this.getInputField({
        key: 'useApiOf',
        label: 'Share API with Username',
        disabled: false,
        value: _.get(this.state.data, 'useApiOf'),
        onChange: (e) => this.onFieldChange('useApiOf', e)
      })}
      {this.props.allowAutoLogin === true && this.getInputField({
        key: 'autoLogin',
        type: 'checkbox',
        label: 'Auto Login by Algo?',
        value: _.get(this.state.data, 'autoLogin'),
        onChange: (e) => this.onFieldChange('autoLogin', e)
      })}
      {autoLogin && this.getInputField({
        key: 'clientPassword',
        label: 'Broker Client Password',
        type: 'password',
        value: _.get(this.state.data, 'clientPassword'),
        onChange: (e) => this.onFieldChange('clientPassword', e)
      })}
      {autoLogin && (broker === 'fyers') && this.getInputField({
        key: 'clientPIN',
        type: 'password',
        label: 'Broker Client PIN',
        value: _.get(this.state.data, 'clientPIN'),
        onChange: (e) => this.onFieldChange('clientPIN', e)
      })}
      {autoLogin && this.getInputField({
        key: 'totpKey',
        label: 'Broker Client TOTP Key',
        value: _.get(this.state.data, 'totpKey'),
        onChange: (e) => this.onFieldChange('totpKey', e)
      })}
      {brokerDetails.useCommonApp === false && this.getInputField({
        key: 'appKey',
        label: 'API Key',
        value: _.get(this.state.data, 'appKey'),
        onChange: (e) => this.onFieldChange('appKey', e)
      })}
      {brokerDetails.useCommonApp === false && this.getInputField({
        key: 'appSecret',
        label: 'API Secret',
        value: _.get(this.state.data, 'appSecret'),
        onChange: (e) => this.onFieldChange('appSecret', e)
      })}
      {this.props.isAdmin === true && this.getInputField({
        key: 'xtremeAgentUrl',
        label: 'Xtreme Agent Url (AWS)',
        value: _.get(this.state.data, 'xtremeAgentUrl'),
        onChange: (e) => this.onFieldChange('xtremeAgentUrl', e)
      })}
      
      {showAlert && <Alert color={this.state.error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {error && <div>{error}</div>}
          {this.state.statusMessage && <div>{this.state.statusMessage}</div>}
          {this.state.updateInProgress && <div>Update is in progress. Please wait...</div>}
        </div>
      </Alert>}
    </div>);
  }

  onLogoutButtonClick() {
    const data = {};
    HttpRequest.post({
      url: config.serverHost + "/logout",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('Logout status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        window.location.reload();
      }
    });
  }

  render() {
    const validationError = this.getValidationError();
    let title = !this.isNewUserBroker() ? 'Edit User Broker Details' :  'New User Broker Details';
    if (this.props.title) {
      title = this.props.title;
    }

    return (<div>
      <Modal isOpen={this.props.isModalOpen} size="lg">
        <ModalHeader>
          {title}
        </ModalHeader>
        
        <ModalBody>
          {this.renderBody(validationError)}
        </ModalBody>

         <ModalFooter>
          {this.props.showLogoutOption === true && <Button 
            color="danger" 
            disabled={this.state.updateInProgress}
            onClick={this.onLogoutButtonClick.bind(this)}>
            Get me out
          </Button>}

          <Button 
            color="primary" 
            disabled={this.state.updateInProgress || !this.isFormValid()}
            onClick={this.createOrUpdateUserBrokerDetails}>
              {!this.isNewUserBroker() ? 'Update' : 'Create'}
          </Button>
          {' '}
          <Button 
            color="secondary" 
            disabled={this.state.updateInProgress}
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default UserBrokerComp;
