/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Table,
  Card,
  CardBody
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

class UsersBrokerFunds extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      usersBrokersList: [],
      fetchingUsersInProgress: false,
      error: null,
      fetchingUserBrokerFundsInProgress: {}
    };

    this.refreshUserBrokerFunds = this.refreshUserBrokerFunds.bind(this);
  }

  getUserBrokerFunds(username, broker) {
    return _.find(this.state.usersBrokersList, ub => ub.username === username && ub.broker === broker);
  }

  componentWillMount() {
    this.setState({
      fetchingUsersInProgress: true,
      error: null
    });

    this.fetchUserBrokersDetails().then(users => {
      console.log('Number of users brokers fetched = ' + users.length);
      this.setState({
        usersBrokersList: this.sortUsersBrokersList(users),
        fetchingUsersInProgress: false
      });

    }).catch(err => {
      this.setState({
        usersBrokersList: [],
        fetchingUsersInProgress: false,
        error: 'Failed to fetch list of users brokers'
      });
    });
  }

  sortUsersBrokersList(usersBrokersList = []) {

    const getDiffPercentage = (ub = {}) => {
      const brokerFunds = ub.brokerFunds || {};
      const brokerTotalMargin = brokerFunds.totalBalance || 0;
      const totalCapital = this.getTotalCapitalOfUserBroker(ub);
      const brokerAQDiff = brokerTotalMargin - totalCapital;
      const diffPercentage = totalCapital > 0 ? Utils.roundOff(brokerAQDiff * 100 / totalCapital) : 0;
      return diffPercentage;
    };

    return usersBrokersList.sort((ub1, ub2) => {
      return getDiffPercentage(ub1) - getDiffPercentage(ub2);
    });
  }

  getTotalCapitalOfUserBroker(ub = {}) {
    let totalCapital = 0;
    _.each(ub.productToCapitalMap, productCapital => {
      totalCapital += productCapital;
    });
    return totalCapital;
  }

  fetchUserBrokersDetails() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/clientmanager/users/funds", { json: true }, (err, resp, respBody) => {
        if (err) {
          console.log('fetchUserBrokersDetails: error = ', err);
          return reject(err);
        }
        if (resp) {
          console.log('fetchUserBrokersDetails: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            let usersBrokers = respBody || [];
            // sort by total capital in descending order
            usersBrokers.sort((a, b) => this.getTotalCapitalOfUserBroker(b) - this.getTotalCapitalOfUserBroker(a));
            resolve(usersBrokers);

          } else {
            reject(`fetchUserBrokersDetails: ` + (respBody.error || 'Failed to fetch users brokers'));
          }
        } else {
          reject(`fetchUserBrokersDetails: No error and no response`);
        }
      });
    });
  }

  fetchUserBrokerFunds(username, broker) {
    const defaultData = {
      username,
      broker,
      brokerFunds: {}
    };
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + `/apis/user/funds?username=${username}&broker=${broker}`, { json: true }, (err, resp, respBody) => {
        if (err) {
          console.error(`fetchUserBrokerFunds:${username}-${broker}: error = `, err);
          resolve(defaultData); // resolve with default
        } else {
          if (resp) {
            console.log(`fetchUserBrokerFunds:${username}-${broker}: resp.statusCode = ` + resp.statusCode);
            if (resp.statusCode === 200) {
              const brokerFunds = respBody || {};
              resolve({
                username,
                broker,
                brokerFunds
              });
            } else {
              console.error(`fetchUserBrokerFunds:${username}-${broker}: ` + (respBody.error || 'Failed to fetch users'));
              resolve(defaultData); // resolve with default
            }
          } else {
            console.error(`fetchUserBrokerFunds:${username}-${broker}: No error and no response`);
            resolve(defaultData); // resolve with default
          }
        }
      });
    });
  }

  refreshUserBrokerFunds(username, broker) {
    const key = username + '-' + broker;
    console.log('refreshUserBrokerFunds called for ' + username + '-' + broker);
    const fetchingUserBrokerFundsInProgress = {...this.state.fetchingUserBrokerFundsInProgress};
    fetchingUserBrokerFundsInProgress[key] = true;
    this.setState({
      fetchingUserBrokerFundsInProgress
    });
    this.fetchUserBrokerFunds(username, broker).then(result => {
      const fetchingUserBrokerFundsInProgress = {...this.state.fetchingUserBrokerFundsInProgress};
      const usersBrokersList = _.map(this.state.usersBrokersList, ub => {
        if (ub.username === username && ub.broker === broker) {
          return {
            ...ub,
            brokerFunds: result.brokerFunds
          }
        }
        return ub;
      });

      fetchingUserBrokerFundsInProgress[key] = false;
      
      this.setState({
        usersBrokersList: this.sortUsersBrokersList(usersBrokersList),
        fetchingUserBrokerFundsInProgress
      });
    });
  }

  getUserBrokerDetails(ub = {}, sno) {
    const rowKey = 'user-' + ub.username.replace(/\./g, '') + ub.broker;
    const brokerFunds = ub.brokerFunds || {};
    const brokerUtilizedMargin = brokerFunds.utilizedAmount || 0;
    const brokerTotalMargin = brokerFunds.totalBalance || 0;
    // const intradayCapital = ub.productToCapitalMap['INTRADAY'] || 0;
    // const positionalCapital = ub.productToCapitalMap['POSITIONAL'] || 0;
    const totalCapital = this.getTotalCapitalOfUserBroker(ub);
    const brokerAQDiff = brokerTotalMargin - totalCapital;
    const diffPercentage = totalCapital > 0 ? Utils.roundOff(brokerAQDiff * 100 / totalCapital) : 0;

    return (
      <tr key={rowKey}>
        <td>{sno}</td>
        <td>{ub.username}</td>
        <td>{_.toUpper(ub.broker)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(totalCapital, false)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(brokerUtilizedMargin, false)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(brokerTotalMargin, false)}</td>
        <td className={(brokerAQDiff < 0 ? "number-neg " : "") + "number-right"}>{Utils.formatNumberToCommaSeparated(brokerAQDiff, false) + ' (' + diffPercentage + ' %)'}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(brokerFunds.collaterals, false)}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(brokerFunds.clearBalance, false)}</td>
        <td style={{'textAlign': 'center'}}>
          <i onClick={() => this.refreshUserBrokerFunds(ub.username, ub.broker)} 
            className={this.state.fetchingUserBrokerFundsInProgress[`${ub.username}-${ub.broker}`] ? "fa fa-refresh fa-spin" : "fa fa-refresh"} 
            style={{'fontSize': '20px', 'cursor': 'pointer'}}>
          </i>
        </td>
      </tr>
    );
  }

  render() {
    return (<div>
      <h4>Users Capital and Funds Details</h4>
      <Card>
        <CardBody>
          <div>
            {this.state.fetchingUsersInProgress === true && <div>
              Fetching users capital and funds in progress...
            </div>}
            {this.state.error && <div className='text-orange'>
              {this.state.error}
            </div>}
            {!this.state.fetchingUsersInProgress && !this.state.error 
              && this.state.usersBrokersList.length === 0 && <div>
              No details found.
            </div>}
            {!this.state.fetchingUsersInProgress && !this.state.error 
              && this.state.usersBrokersList.length > 0 && <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Username</th>
                  <th className="border-0">Broker</th>
                  <th className="border-0 number-right">AQ Capital</th>
                  <th className="border-0 number-right">Utilized Margin</th>
                  <th className="border-0 number-right">Total Margin</th>
                  <th className="border-0 number-right">Broker AQ Diff</th>
                  <th className="border-0 number-right">Collaterals</th>
                  <th className="border-0 number-right">Cash</th>
                  <th className="border-0">Refresh</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(this.state.usersBrokersList, (ub, index) => {
                    return this.getUserBrokerDetails(ub, index + 1);
                  })
                }
              </tbody>
            </Table>}
          </div>
        </CardBody>
      </Card>
    </div>);
  }
}

export default UsersBrokerFunds;
