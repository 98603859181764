/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";
import HttpRequest from "request";
import config from "../../config.js";
import { fetchBrokerLoginRules } from '../../utils/RestAPIs.js';
import ConfirmationModal from '../common/ConfirmationModal.js';

import {
  Alert,
  Button
} from 'reactstrap';

class BrokerLogin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      allowLogin: false,
      loginNotes: '',
      isLoggedIn: false,
      showLogoutConfirmationModal: false,
      logInLogoutError: null
    };

    this.checkLogin = this.checkLogin.bind(this);
    this.logIn = this.logIn.bind(this);
    this.logOut = this.logOut.bind(this);
    this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this);
    this.checkToAllowLogin = this.checkToAllowLogin.bind(this);

    this.closeLogoutConfirmationModal = this.closeLogoutConfirmationModal.bind(this);
  }

  componentWillMount() {
    this.checkLogin(this.props.broker);
    this.checkToAllowLogin();
  }

  checkToAllowLogin() {
    console.log('checkToAllowLogin() called');
    fetchBrokerLoginRules(this.props.broker).then(resp => {
      if (resp.allowLogin === true) {
        this.setState({
          allowLogin: true,
          loginNotes: ''
        });
      } else {
        this.setState({
          allowLogin: false,
          loginNotes: 'You can login to broker after ' + resp.loginStartDateTimeStr + ' IST'
        }, () => {
          this.allowLoginTimer = setTimeout(() => {
            this.checkToAllowLogin();
          }, 30 * 1000);
        });
      }
    }).catch(err => {
      console.log('fetchBrokerLoginRules error => ', err);
    });
  }

  componentWillUnmount() {
    if (this.allowLoginTimer) {
      clearTimeout(this.allowLoginTimer);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.broker !== newProps.broker) {
      // reset state on broker change
      this.setState({
        isLoggedIn: false,
        showLogoutConfirmationModal: false,
        logInLogoutError: null
      });

      this.checkLogin(newProps.broker);
    }
  }

  checkLogin(broker) {
    broker = broker || this.props.broker;
    HttpRequest(config.serverHost + "/apis/broker/logincheck/" + broker, { json: true }, (err, resp, data) => {
      if (err) {
        console.error(err);
        this.setState({
          logInLogoutError: `Could not fetch login status`
        });
        return;
      }
      this.setState({
        isLoggedIn: data.isLoggedIn
      });
    });
  }

  logOut(e) {
    e.preventDefault();
    const broker = this.props.broker;

    this.setState({
      showLogoutConfirmationModal: false,
      logInLogoutError: null
    });

    var data = {};
    HttpRequest.post({
      url: config.serverHost + "/apis/broker/logout/" + broker,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log(`${broker}: Logout status code = ${resp.statusCode}`);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          isLoggedIn: false
        });
      } else {
        console.error(`${broker}: Error =>`, err);
        respBody = JSON.parse(respBody);
        this.setState({
          logInLogoutError: `${broker} Logout failed.  ` + (respBody.error || '')
        });
      }
    });
  }

  logIn(e) {
    e.preventDefault();
    const broker = this.props.broker;
    window.location.href = config.serverHost + "/apis/broker/login/" + broker;
  }

  onLogoutButtonClick(e) {
    e.preventDefault();

    this.setState({
      showLogoutConfirmationModal: true,
      logInLogoutError: null
    });
  }

  closeLogoutConfirmationModal() {
    this.setState({
      showLogoutConfirmationModal: false
    });
  }

  render() {
    return (
      <div>
        <div>
          <Button color={this.state.isLoggedIn === false ? 'info' : 'orange'}
            className="broker-login-button"
            disabled={!this.state.allowLogin}
            onClick={this.state.isLoggedIn === false ? this.logIn : this.onLogoutButtonClick}>
            {this.state.isLoggedIn === false ? "Signin " + _.toUpper(this.props.broker) : "Signout " + _.toUpper(this.props.broker)}
          </Button>
          {this.state.logInLogoutError ? <Alert color = "danger">
            {this.state.logInLogoutError}
          </Alert> :  <Alert color={this.state.isLoggedIn === true ? 'success' : 'warning'}>
            {this.state.isLoggedIn === true ? 'Logged In.' : 'Not logged in. ' + this.state.loginNotes}
          </Alert>}
        </div>
              
        <ConfirmationModal
          isModalOpen={this.state.showLogoutConfirmationModal}
          title={"Logout " + _.toUpper(this.props.broker)}
          message={"Are you sure want to singout from " + _.toUpper(this.props.broker) + " ?"}
          onConfirm={this.logOut}
          onCancel={this.closeLogoutConfirmationModal}
        />
      </div>
    );
  }
}

export default BrokerLogin;
