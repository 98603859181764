/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormGroup,
  Label,
  Input,
  Alert
} from 'reactstrap';

import PositionsComp from '../common/PositionsComp.js';

import HttpRequest from "request";
import config from "../../config.js";
import Utils from '../../utils/Utils.js';
import { fetchAllStrategies } from "../../utils/RestAPIs.js";

const productOptions = [
  { label: 'Select Product', value: '' },
  { label: 'INTRADAY', value: 'INTRADAY' },
  { label: 'POSITIONAL', value: 'POSITIONAL' }
];

class SquareOffUtility extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      strategies: [],
      strategyOptions: [],
      usersBrokersList: [],
      fetchingDataInProgress: false,
      squareOffInProgress: false,
      statusMessage: null,
      error: null
    };

    this.prepareBrokerOptions(props);

    this.squareOffTrades = this.squareOffTrades.bind(this); 
  }

  componentWillMount() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });

    this.fetchStrategiesDetails().then(strategies => {
      this.fetchUsersDetails().then(users => {
        const usersBrokersList = this.prepareUsersBrokersList(users);
        this.setState({
          strategies,
          usersBrokersList,
          fetchingDataInProgress: false
        }, () => {
          this.updateStrategyOptions(strategies);  
        });
      }).catch(err => {
        this.setState({
          usersBrokersList: [],
          strategyOptions: [],
          fetchingDataInProgress: false,
          error: err
        });
      });
    }).catch(err => {
      this.setState({
        strategyOptions: [],
        fetchingDataInProgress: false,
        error: err
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps brokers => ', nextProps.supportedBrokers);
    this.prepareBrokerOptions(nextProps);
  }

  prepareBrokerOptions(props) {
    this.brokerOptions = [{
      label: 'Select Broker', value: '' 
    }];
    _.each(props.supportedBrokers, b => {
      this.brokerOptions.push({
        label: _.toUpper(b),
        value: b
      });
    });
    this.brokerOptions.push({
      label: 'ALL',
      value: 'ALL'
    });

    const singleBroker = config.getSubDomain();
    if (singleBroker) {
      this.brokerOptions = _.filter(this.brokerOptions, b => b.value === singleBroker);
    }
  }

  prepareUsersBrokersList(users) {
    const usersBrokersList = [];
    _.each(users, user => {
      _.each(user.brokers, ub => {
        if (user.enabled && ub.enabled) {
          usersBrokersList.push({
            username: user.username,
            broker: ub.broker,
            clientID: ub.clientID
          });
        }
      })
    });
    usersBrokersList.sort((a, b) => a.username.localeCompare(b.username));
    return usersBrokersList;
  }

  updateStrategyOptions(strategies) {
    console.log('strategies => ', strategies);
    strategies = strategies || this.state.strategies;

    const strategyOptions = [{
      label: 'Select Strategy', value: '' 
    }];
    const selectedProduct = _.get(this.state.data, 'product');
    _.each(strategies, s => {
      if (selectedProduct === s.product) {
        strategyOptions.push({
          label: s.name,
          value: s.name
        });
      }
    });
    // add ALL at the end
    if (strategyOptions.length > 1) {
      strategyOptions.push({
        label: 'ALL',
        value: 'ALL'
      });
    }
    this.setState({
      strategyOptions
    });
  }

  fetchStrategiesDetails() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    return fetchAllStrategies({publicOnly: false}).then(allStrategies => {
      Utils.updateStrategyToDetailsMappings(allStrategies);
      const strategies = _.filter(allStrategies, s => {
        return s.isPrivate === true ? false : true;
      });
      this.setState({
        strategies: strategies || [],
        fetchingDataInProgress: false
      });
      return strategies;
    }).catch(err => {
      this.setState({
        strategies: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch strategies'
      });
      return err;
    });
  }

  fetchUsersDetails() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/clientmanager/users", { json: true }, (err, resp, respBody) => {
        if (err) {
          console.log('fetchUsersDetails: error = ', err);
          return reject(err);
        }
        if (resp) {
          console.log('fetchUsersDetails: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            let users = respBody || [];
            // sort users based on activation date
            users.sort((u1, u2) => {
              return u1.activationDate - u2.activationDate;
            });
            resolve(users);

          } else {
            reject(`fetchUsersDetails: ` + (respBody.error || 'Failed to fetch users'));
          }
        } else {
          reject(`fetchUsersDetails: No error and no response`);
        }
      });
    });
  }

  squareOffTrades(e) {
    e.preventDefault();

    this.setState({
      squareOffInProgress: true
    });

    const data = this.state.data || {};

    HttpRequest.post({
      url: config.serverHost + "/apis/clientmanager/trades/squareoff",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('Update SquareOffUtility status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: JSON.parse(respBody).status,
          squareOffInProgress: false
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'Update SquareOffUtility end point not found',
          squareOffInProgress: false
        });
      } else {
        console.error('Update SquareOffUtility error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          squareOffInProgress: false
        });
      }
    });
  }

  onFieldChange(key, event) {
    const value = event.target ? event.target.value : '';
    console.log('onFieldChange called with key = ' + key + ', value = ' + value);

    const data = {...this.state.data};
    _.set(data, key, value);

    if (key === 'product') {
      _.set(data, 'strategy', '');      
    }

    this.setState({
      data,
      statusMessage: null,
      error: null
    }, () => {
      if (key === 'product') {
        this.updateStrategyOptions();
      } else if (key === 'username') {
      }
    });
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      <Label className="col-sm-6 col-form-label" for={opts.key}><b>{opts.label}</b></Label>
      <Input
        className="col-sm-6 col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option key={o.value} value={o.value}>{opts.key === 'strategy' ? 
              Utils.getStrategyDisplayName(o.label) : o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  isSquareOffButtonDisabled() {
    if (this.state.squareOffInProgress) {
      return true;
    }
    if (_.isEmpty(_.get(this.state.data, 'product'))
      || _.isEmpty(_.get(this.state.data, 'broker'))
      || _.isEmpty(_.get(this.state.data, 'strategy'))) {
      return true;
    }
    return false;
  }

  renderBulkSquareOff() {
    return (<div>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <div>
              <CardTitle>Bulk Square Off</CardTitle>
              <CardSubtitle>This utility squares off all trades placed by Algo of given product/strategy/broker for all clients</CardSubtitle>
            </div>
          </div>
          <div>
            <div>
              {this.state.fetchingDataInProgress === true && <div>
                Fetching details in progress...
              </div>}
            </div>
            {!this.state.fetchingDataInProgress && <div>
              {this.getSelectField({
                key: 'product',
                label: 'PRODUCT',
                value: _.get(this.state.data, 'product'),
                options: productOptions,
                placeholder: 'Select product',
                onChange: (e) => this.onFieldChange('product', e)
              })}
              {this.getSelectField({
                key: 'broker',
                label: 'BROKER',
                value: _.get(this.state.data, 'broker'),
                options: this.brokerOptions,
                placeholder: 'Select broker',
                onChange: (e) => this.onFieldChange('broker', e)
              })}
              {this.getSelectField({
                key: 'strategy',
                label: 'STRATEGY',
                value: _.get(this.state.data, 'strategy'),
                options: this.state.strategyOptions,
                placeholder: 'Select strategy',
                onChange: (e) => this.onFieldChange('strategy', e)
              })}

              <Button 
                color="primary" 
                disabled={this.isSquareOffButtonDisabled()}
                onClick={this.squareOffTrades}>
                  {`Square Off ${_.get(this.state.data, 'product') || ''}#${_.get(this.state.data, 'broker') || ''}#${_.get(this.state.data, 'strategy') || ''}`}
              </Button>
            </div>}
          </div>
        </CardBody>
      </Card>
    </div>);
  }

  renderPositionsPanel() {
    let userBrokersOptions = [{
      label: 'Select User',
      value: ''
    }];
    userBrokersOptions = _.concat(userBrokersOptions, _.map(this.state.usersBrokersList, ub => {
      return {
        label: ub.username + "-" + ub.broker,
        value: ub.username + "-" + ub.broker + "-" + ub.clientID
      };
    }));

    const userBrokerPairs = _.get(this.state.data, 'username', '').split('-');
    const username = userBrokerPairs[0];
    const broker = userBrokerPairs[1];
    const clientID = userBrokerPairs[2];

    return (<div>
      <Card>
        <CardBody>
          {this.getSelectField({
            key: 'username',
            label: 'Select user to fetch all positions (algo + outside):'.toUpperCase(),
            value: _.get(this.state.data, 'username'),
            options: userBrokersOptions,
            placeholder: 'Select User',
            onChange: (e) => this.onFieldChange('username', e)
          })}

          {username && broker && <PositionsComp 
            username={username}
            broker={broker}
            clientID={clientID} />}

        </CardBody>
      </Card>
    </div>);
  }

  render() {
    const showAlert = this.state.error || this.state.statusMessage || this.state.squareOffInProgress;
    return (<div>
      <h4>SquareOff Utility</h4>
      <Row>
        <Col lg={8} md={12} xs={12}>
          {this.renderBulkSquareOff()}
        </Col>
      </Row>
      {showAlert && <Alert color={this.state.error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.squareOffInProgress && <div>Square off request is in progress. Please wait...</div>}
        </div>
      </Alert>}
      <Row>
        <Col>
          {this.renderPositionsPanel()}
        </Col>
      </Row>
    </div>);
  }
}

export default SquareOffUtility;
