/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardHeader,
  CardText,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  ButtonGroup
} from 'reactstrap';
import DatePicker from "react-datepicker";

import HttpRequest from "request";
import config from "../../config.js";
import { fetchAllStrategies } from "../../utils/RestAPIs.js";
import Utils from "../../utils/Utils.js";

const tradingDaysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday'
];

const sortByOptions = [
  {label: 'Capital', value: 'capital'},
  {label: 'Returns', value: 'returns'},
  {label: 'Username', value: 'username'}
];

class UsersReports extends React.Component {

  constructor(props) {
    super(props);

    this.rangeStartDate = new Date('2019-10-01');
    this.rangeEndDate = new Date();

    const now = new Date();
    const firstDateOfTheMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    this.state = {
      startDate: firstDateOfTheMonth,
      endDate: new Date(),
      selectedDays: _.clone(tradingDaysOfWeek),
      usersReports: [],
      strategiesReports: [],
      sortByField: 'capital',
      hideColumns: {
        //'MR-DT-1': true,
        //'Golden-Ratio': true,
        //'OPT-S-BNF-SUPERTREND': true,
        'OPT-S-SUPERTREND': true,
        'OPT-S-TEST': true
      },
      fetchReportInProgress: false,
      error: null,
      inputError: null
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.fetchPerformanceReports = this.fetchPerformanceReports.bind(this);
    this.onSortByFieldChange = this.onSortByFieldChange.bind(this);
    this.onHideColumnsToggle = this.onHideColumnsToggle.bind(this);
    this.onMultidaySelectChange = this.onMultidaySelectChange.bind(this);
    this.toggleDaySelection = this.toggleDaySelection.bind(this);
  }

  componentWillMount() {
    fetchAllStrategies().then(allStrategies => {
      this.setState({
        allStrategies
      });
    });
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date
    }, () => {
      this.validateInput();
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date
    }, () => {
      this.validateInput();
    });
  }

  onMultidaySelectChange(e) {
    const values = _.map(e.target.selectedOptions, option => option.value);
    this.setState({
      selectedDays: values
    }, () => {
      //this.fetchPerformanceReports();
    });
  }

  toggleDaySelection(day) {
    let selectedDays = [...this.state.selectedDays];
    if (_.some(selectedDays, sd => sd === day)) {
      // remove from selected list
      selectedDays = _.filter(selectedDays, sd => sd !== day);
    } else {
      // add to selected list
      selectedDays.push(day);
    }
    this.setState({
      selectedDays
    });
  }

  validateInput() {
    if (this.state.startDate.getTime() > this.state.endDate.getTime()) {
      this.setState({
        inputError: 'Start Date can not be greater than End Date'
      });
    } else {
      this.setState({
        inputError: null
      });
    }
  }

  isOverlapStrategy(strategyName) {
    const strategyDetails = _.find(this.state.allStrategies, s => s.name === strategyName);
    return strategyDetails ? (strategyDetails.isOverlapCapital === true ? true : false) : false;
  }

  fetchPerformanceReports() {

    const startDateStr = Utils.formatDateToString(this.state.startDate);
    const endDateStr = Utils.formatDateToString(this.state.endDate);
    const days = this.state.selectedDays.length === tradingDaysOfWeek.length ? '' : this.state.selectedDays.join(',');

    this.setState({
      usersReports: [],
      strategiesReports: [],
      fetchReportInProgress: true,
      error: null
    });
    HttpRequest(config.serverHost + `/apis/clientmanager/users/reports?from=${startDateStr}&to=${endDateStr}&days=${days}`, { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchPerformanceReports: error = ', err);
        this.setState({
          fetchReportInProgress: false,
          error: err
        });
        return;
      }
      if (resp) {
        console.log('fetchPerformanceReports: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200) {
          const result = respBody || {};
          const usersReports = result.userReports || [];
          const strategiesReports = result.strategyReports || [];
          // sort as per display order
          strategiesReports.sort((sr1, sr2) => {
            const pr1 = Utils.getStrategyDisplayOrder(sr1.strategyName);
            const pr2 = Utils.getStrategyDisplayOrder(sr2.strategyName);
            return pr1 - pr2;
          });
          
          this.setState({
            usersReports,
            strategiesReports,
            fetchReportInProgress: false
          });
        } else {
          this.setState({
            usersReports: [],
            strategiesReports: [],
            fetchReportInProgress: false,
            error: respBody.error || 'Failed to fetch users reports'
          });
        }
      }
    });
  }

  renderInputForm() {
    return (<div className="trades-history-input">
      <div className="date-picker-wrapper">
        <label>Start Date:</label>
        <DatePicker 
          dateFormat="dd-MM-yyyy"
          minDate={this.rangeStartDate}
          maxDate={this.rangeEndDate}
          selected={this.state.startDate}
          onChange={this.handleStartDateChange} />
      </div>

      <div className="date-picker-wrapper">
        <label>End Date:</label>
        <DatePicker 
          dateFormat="dd-MM-yyyy"
          minDate={this.rangeStartDate}
          maxDate={this.rangeEndDate}
          selected={this.state.endDate}
          onChange={this.handleEndDateChange} />
      </div>

      <ButtonGroup className="reports-button-group">
        {
          tradingDaysOfWeek.map(day => {
            return (<Button color={_.some(this.state.selectedDays, sd => sd === day) ? "info" : "secondary"}
              onClick={() => this.toggleDaySelection(day)}>
              {day.substring(0, 3)}
            </Button>);
          })
        }
      </ButtonGroup>

      <Button className="date-picker-submit btn-success"
        onClick={this.fetchPerformanceReports}
        disabled={this.state.fetchReportInProgress || _.isEmpty(this.state.inputError) === false} >
        {this.state.fetchReportInProgress ? "Fetching Reports..." : "Fetch Reports" }
      </Button>

      
    </div>);
  }

  renderReportsSummary() {
    return (<Row>
      {
        _.map(this.state.strategiesReports, sr => {
          return (<Col sm="3" key={sr.strategyName}>
            <Card>
              <CardHeader style={{ backgroundColor: 'rgb(204 198 199)', borderColor: '#333' }} >{Utils.getStrategyDisplayName(sr.strategyName)}</CardHeader>
              <CardBody>
                <CardText>
                  <span><strong>{'Capital : '}</strong> {Utils.formatNumberToCommaSeparated(sr.capital, false)}</span>
                  <br/>
                  <span><strong>{'Returns : '}</strong> 
                    <span className={sr.netProfitLoss > 0 ? "number-pos" : "number-neg"}>
                      {Utils.formatNumberToCommaSeparated(sr.netProfitLoss, false) + ' | ' + Utils.roundOff(sr.returns) + ' %'}
                    </span>
                  </span>
                </CardText>
              </CardBody>
            </Card>
          </Col>);
        })
      }
    </Row>);
  }

  getUserReportDetails(userReport = {}, sno) {
    const totalStrategyReport = userReport.strategyReportsMap['CAPITAL-DEPLOYED'] || { capital: 0, returns: 0};
    if (totalStrategyReport.capital === 0) {
      return null;
    }
    return (
      <tr key={"user-" + userReport.username}>
        <td>{sno}</td>
        <td>{userReport.username}</td>
        <td className="number-right">{Utils.formatNumberToCommaSeparated(totalStrategyReport.capital, false)}</td>
        <td className={totalStrategyReport.returns > 0 ? "number-right number-pos" : "number-right number-neg"}>
          {totalStrategyReport.returns + ' %'}
        </td>
        {
          _.map(_.filter(this.state.strategiesReports, sr => !this.state.hideColumns[sr.strategyName]), sr => {
            if (!userReport.strategyReportsMap[sr.strategyName]) {
              return (<td key={sr.strategyName}></td>);
            }
            const netPL = userReport.strategyReportsMap[sr.strategyName].netProfitLoss;
            return (<td 
              key={sr.strategyName} 
              className={netPL > 0 ? "number-right number-pos" : "number-right number-neg"}>
              {Utils.formatNumberToCommaSeparated(netPL, false)}
            </td>);
          })
        }
      </tr>
    );
  }

  onSortByFieldChange(event) {
    const value = event.target ? event.target.value : '';
    this.setState({
      sortByField: value
    });
  }

  onHideColumnsToggle(strategyName) {
    const hideColumns = {...this.state.hideColumns};
    hideColumns[strategyName] = hideColumns[strategyName] ? false : true;
    this.setState({
      hideColumns
    });
  }

  renderReportsTable(usersReports = []) {
    return (<div>      
      <div className="filter-inputs">
        {
          _.map(_.filter(this.state.strategiesReports, sr => sr.strategyName !== 'CAPITAL-DEPLOYED'), sr => {
            return (<div className="filter-input" key={sr.strategyName}>
              <input
                type="checkbox"
                checked={this.state.hideColumns[sr.strategyName] ? false : true}
                onChange={() => this.onHideColumnsToggle(sr.strategyName)}>
              </input>
              <label>&nbsp;{Utils.getStrategyDisplayName(sr.strategyName)}</label>
            </div>);
          })
        }
        <div className="filter-input">
          <label><strong>Sort By:</strong></label>
          <Input type="select" 
            onChange={this.onSortByFieldChange}
            value={this.state.sortByField}>
            {
              _.map(sortByOptions, s => {
                return (<option value={s.value} key={s.value}>{s.label}</option>);
              })
            }
          </Input>
        </div>
      </div>

      <Table className="no-wrap v-middle" size="sm" responsive>
        <thead>
          <tr className="border-0">
            <th className="border-0">SNo</th>
            <th className="border-0">Username</th>
            <th className="border-0 number-right">Capital</th>
            <th className="border-0 number-right">Returns</th>
            {
              _.map(_.filter(this.state.strategiesReports, sr => !this.state.hideColumns[sr.strategyName]), sr => {
                return (<th className="border-0 number-right" key={sr.strategyName}>{Utils.getStrategyDisplayName(sr.strategyName)}</th>);
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            _.map(usersReports, (ur, index) => {
              return this.getUserReportDetails(ur, index + 1);
            })
          }
        </tbody>
      </Table>
    </div>);
  }

  render() {

    let usersReports = this.state.usersReports || [];
    usersReports.sort((ur1, ur2) => {
      const totalStrategyReportUr1 = ur1.strategyReportsMap['CAPITAL-DEPLOYED'] || { capital: 0, returns: 0};
      const totalStrategyReportUr2 = ur2.strategyReportsMap['CAPITAL-DEPLOYED'] || { capital: 0, returns: 0};
      if (this.state.sortByField === 'capital') {
        return totalStrategyReportUr2.capital - totalStrategyReportUr1.capital; // descending order
      } else if (this.state.sortByField === 'returns') {
        return totalStrategyReportUr1.returns - totalStrategyReportUr2.returns; // ascending order
      } else if (this.state.sortByField === 'username') {
        return ur1.username.localeCompare(ur2.username); // ascending order
      }
      return ur1.username.localeCompare(ur2.username);
    });

    return (<div>
      <h4>Performance Reports</h4>
      <Card>
        <CardBody>
          {this.renderInputForm()}

          {this.renderReportsSummary()}

          <div>
            {this.state.fetchReportInProgress === true && <div>
              Fetching reports in progress...
            </div>}
            {this.state.error && <div className='text-orange'>
              {this.state.error}
            </div>}
            {usersReports.length > 0 && this.renderReportsTable(usersReports)}
          </div>
        </CardBody>
      </Card>
    </div>);
  }
}

export default UsersReports;
