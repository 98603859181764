/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import AlertModal from '../common/AlertModal.js';
import ConfirmationModal from '../common/ConfirmationModal.js';

import UserComp from '../common/UserComp.js';
import { closeAccount } from '../../utils/RestAPIs.js';
import Utils from "../../utils/Utils.js";

class UsersBasicDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedUserDetails: null,
      showUserComp: false,
      filterTextUser: null,
      showAccountClosureConfirmationModal: false
    };

    this.launchUserComp = this.launchUserComp.bind(this);
    this.closeUserComp = this.closeUserComp.bind(this);
    this.onUserFilterTextChange = this.onUserFilterTextChange.bind(this);
    this.onAccountCloseButtonClick = this.onAccountCloseButtonClick.bind(this);
    this.closeAccountClosureConfirmationModal = this.closeAccountClosureConfirmationModal.bind(this);
    this.closeAccount = this.closeAccount.bind(this);
    this.launchAlertModal = this.launchAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
  }
  
  launchAlertModal(title, message) {
    this.setState({
      showAlertModal: true,
      alertModalTitle: title,
      alertModalMessage: message
    });
  }

  closeAlertModal() {
    this.setState({
      showAlertModal: false,
      alertModalTitle: null,
      alertModalMessage: null,

    });
  }

  launchUserComp(userDetails) {
    this.setState({
      selectedUserDetails: userDetails,
      showUserComp: true
    });
  }

  closeUserComp(reload) {
    this.setState({
      selectedUserDetails: null,
      showUserComp: false
    }, () => {
      if (reload === true && _.isFunction(this.props.fetchUsersDetails)) {
        this.props.fetchUsersDetails();
      }
    });
  }

  onAccountCloseButtonClick(e, accountToCloseUsername) {
    e.preventDefault();

    this.setState({
      accountToCloseUsername,
      showAccountClosureConfirmationModal: true
    });
  }

  closeAccountClosureConfirmationModal() {
    this.setState({
      showAccountClosureConfirmationModal: false
    });
  }

  closeAccount() {
    closeAccount(this.state.accountToCloseUsername).then(resp => {
      this.closeAccountClosureConfirmationModal();
      this.launchAlertModal('Account Closure Status', this.state.accountToCloseUsername +  ' : ' +  resp.status);
    }).catch(err => {
      this.closeAccountClosureConfirmationModal();
      this.launchAlertModal('Account Closure Status', `Failed to close account ${this.state.accountToCloseUsername}: ${err}`);
    });
  }

  onAccountEnableDisableButtonClick(e, user = {}) {
    e.preventDefault();
    var data = { 
      username: user.username
    };

    HttpRequest.put({
        url: config.serverHost + "/apis/clientmanager/" + (user.enabled ? "disable" : "enable")  + "/username",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('Enable/Disable user status code = ' + resp.statusCode);
      let error = (JSON.parse(respBody) || {}).error;
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        // refresh all user details
        this.props.fetchUsersDetails();
        this.launchAlertModal('Account Status', `Account ${user.username} ${user.enabled ? 'disabled' : 'enabled'} successfully`);
      } else if (resp.statusCode === 404) {
        this.launchAlertModal('Account Status', `Failed to ${user.enabled ? 'disable' : 'enable'} account ${user.username} : ${error}`);
      } else {
        this.launchAlertModal('Account Status', `Failed to ${user.enabled ? 'disable' : 'enable'} account ${user.username} : ${error}`);
      }
    });
  }

  onUserFilterTextChange(e) {
    this.setState({
      filterTextUser: e.target.value
    });
  }

  getUserBasicDetails(user = {}, sno) {
    return (
      <tr key={"user-" + user.username}>
        <td>{sno}</td>
        <td>{user.username}</td>
        <td className={user.enabled === true ? 'text-success' : 'text-orange'}>
          {user.enabled === true ? 'Active' : 'Inactive'}
        </td>
        <td>{user.firstname}</td>
        <td>{user.lastname}</td>
        <td>{user.alias}</td>
        <td>{user.email}</td>
        <td>{user.mobile}</td>
        {!this.props.serverConfig.isPrepaid && <td>{user.billingPlan}</td>}
        <td>{Utils.formatDateToString(new Date(user.activationDate))}</td>
        <td className={user.autoLogin === true ? 'text-success' : 'text-orange'}>
          {user.autoLogin === true ? 'Enabled' : 'Disabled'}
        </td>
        <td>
          <Button 
            color="primary" 
            onClick={() => this.launchUserComp(user)}>
              Edit
          </Button>
          <Button 
            style={{'marginLeft': '5px'}}
            color={user.enabled ? 'warning' : 'success'}
            onClick={(e) => this.onAccountEnableDisableButtonClick(e, user)}>
              {user.enabled ? 'Disable' : 'Enable'}
          </Button>
          <Button 
            style={{'marginLeft': '5px'}}
            color="danger"
            disabled={user.closed === true}
            onClick={(e) => this.onAccountCloseButtonClick(e, user.username)}>
              {user.closed ? 'Closed' : 'Close Account'}
          </Button>
        </td>
      </tr>
    );
  }

  render() {
    let users = this.props.users;
    if (_.isEmpty(this.state.filterTextUser) === false) {
      users = _.filter(users, user => {
        if (_.includes(_.toLower(user.username), _.toLower(this.state.filterTextUser))) {
          return true;
        }
        return false;
      });
    }

    return (<div>
      <Card>
        <CardBody>
          <div className="filter-inputs">
            <div className="filter-input">
              <label>Filter By:</label>
              <input 
                value={this.state.filterTextUser || ''}
                placeholder="username" 
                onChange={this.onUserFilterTextChange}></input>
            </div>
          </div>
          <div>
            {this.props.fetchingDataInProgress === true && <div>
              Fetching details in progress...
            </div>}
            {this.props.error && <div className='text-orange'>
              {this.props.error}
            </div>}
            {!this.props.fetchingDataInProgress && !this.props.error 
              && (!users || users.length === 0) && <div>
              No users found.
            </div>}
            {users && users.length > 0 && <Table className="no-wrap v-middle" size="sm" responsive>
              <thead>
                <tr className="border-0">
                  <th className="border-0">SNo</th>
                  <th className="border-0">Username</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">First Name</th>
                  <th className="border-0">Last Name</th>
                  <th className="border-0">Alias</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Mobile</th>
                  {!this.props.serverConfig.isPrepaid && <th className="border-0">Billing Plan</th>}
                  <th className="border-0">Activation Date</th>
                  <th className="border-0">Auto Login</th>
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(users, (user, index) => {
                    return this.getUserBasicDetails(user, index + 1);
                  })
                }
              </tbody>
            </Table>}
          </div>
        </CardBody>
      </Card>
      {this.state.showUserComp && <UserComp 
        userDetails={this.state.selectedUserDetails}
        isModalOpen={this.state.showUserComp}
        onCancel={this.closeUserComp}
        serverConfig={this.props.serverConfig}
        fromClientManager={true}
      />}
      <ConfirmationModal
        isModalOpen={this.state.showAccountClosureConfirmationModal}
        title="Account Closure"
        message={"Are you sure want to close the account of " + this.state.accountToCloseUsername + " ? (This operation cannot be undone.)"}
        onConfirm={this.closeAccount}
        onCancel={this.closeAccountClosureConfirmationModal}
      />
      <AlertModal
        isModalOpen={this.state.showAlertModal}
        title={this.state.alertModalTitle}
        message={this.state.alertModalMessage}
        onCancel={this.closeAlertModal}
      />
    </div>);
  }
}

export default UsersBasicDetails;
