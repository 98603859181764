
const Colors = {
  black: "#000000",
  magenta: "#ff00ff",
  indigo: "#4B0082",
  violet: "#9400D3",
  red: "#FF0000",
  blue: "#0000FF",
  orange: "#FF7F00",
  green: "#00FF00",
  brown: "#a52a2a",
  cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgrey: "#a9a9a9",
  darkgreen: "#006400",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",

  aqua: "#00ffff",
  beige: "#f5f5dc",
  azure: "#f0ffff",
  darkkhaki: "#bdb76b",
  yellow: "#FFFF00",
  
  darkviolet: "#9400d3",
  fuchsia: "#ff00ff",
  gold: "#ffd700",
  //green: "#008000",
  //indigo: "#4b0082",
  khaki: "#f0e68c",
  lightblue: "#add8e6",
  lightcyan: "#e0ffff",
  lightgreen: "#90ee90",
  lightgrey: "#d3d3d3",
  lightpink: "#ffb6c1",
  lightyellow: "#ffffe0",
  lime: "#00ff00",
  
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  //orange: "#ffa500",
  pink: "#ffc0cb",
  purple: "#800080",
  //violet: "#800080",
  //red: "#ff0000",
  silver: "#c0c0c0",
  white: "#ffffff",
  //yellow: "#ffff00"
};

export default Colors;