import React from 'react';
import {
	Nav,
	Navbar,
	NavbarBrand,
  NavItem,
  Collapse,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import config from '../../config.js';
import { logoutUser } from "../../utils/RestAPIs.js";

import autoQuantLogo from '../../assets/images/logo_autoquant.png';
import xtremeQuantLogo from '../../assets/images/logo_xtremequant.png';
import way2QuantLogo from '../../assets/images/logo_way2quant.png';
import profilePhoto from '../../assets/images/users/d1.jpg';

import UserComp from '../common/UserComp.js';
import ChangeUserPasswordComp from '../common/ChangeUserPasswordComp.js';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.state = {
			isOpen: false
		};

		this.launchUserComp = this.launchUserComp.bind(this);
    this.closeUserComp = this.closeUserComp.bind(this);

    this.launchChangePasswordComp = this.launchChangePasswordComp.bind(this);
    this.closeChangePasswordComp = this.closeChangePasswordComp.bind(this);
	}
	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}

	onLogoutButtonClick() {
		logoutUser().then(resp => {
      this.props.onLogoutSuccess();
    }).catch(err => {
      this.props.onLogoutSuccess();
    });
	}

	launchUserComp() {
		this.setState({
      showUserComp: true
    });
	}

	closeUserComp(reload) {
		this.setState({
      showUserComp: false
    }, () => {
      if (reload === true) {
        this.props.refreshUserDetails();
      }
    });
	}

	launchChangePasswordComp() {
		this.setState({
			showChangePasswordComp: true
		});
	}

	closeChangePasswordComp() {
		this.setState({
			showChangePasswordComp: false
		});
	}

  getProfileMenuView(displayUserName) {
    return (<UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className="pro-pic">
        <img
          src={profilePhoto}
          alt="user"
          className="rounded-circle"
          width="31"
        />
      </DropdownToggle>
      <DropdownMenu right className="user-dd">
        <DropdownItem onClick={this.launchUserComp}>
          <i className="ti-user mr-1 ml-1" /> My Account{displayUserName ? ' (' + displayUserName + ')' : null}
        </DropdownItem>
        {config.isAutoQuant() && <DropdownItem onClick={this.launchChangePasswordComp}>
          <i className="ti-settings mr-1 ml-1" /> Change Password
        </DropdownItem>}
        <DropdownItem divider />
        <DropdownItem onClick={this.onLogoutButtonClick.bind(this)}>
          <i className="fa fa-power-off mr-1 ml-1" /> Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>);
  }

	render() {
		let productName = 'AutoQuant';
		let imgLogo = autoQuantLogo;
		let skinClassName = 'skin1';
		let displayNameClassName = 'font-white';

		if (config.isWay2Quant()) {
			productName = 'Way2Quant';
			imgLogo = way2QuantLogo;
			skinClassName = 'skin3';
			displayNameClassName = 'font-black';
		} else if (config.isXtremeQuant()) {
			productName = 'XtremeQuant';
			imgLogo = xtremeQuantLogo;
			skinClassName = 'skin6';
			displayNameClassName = 'font-black';
		} else if (config.isProXtremeQuant()) {
			productName = 'Pro XtremeQuant';
			imgLogo = xtremeQuantLogo;
			skinClassName = 'skin5';
			displayNameClassName = 'font-white';
		}
		
		return (
			<header className="topbar navbarbg" data-navbarbg={skinClassName}>
				<Navbar className="top-navbar" dark expand="md">
          {this.props.public === true ? (<div className="navbar-header" id="logobg" data-logobg={skinClassName}>
            <h3><NavbarBrand>Welcome to {productName}</NavbarBrand></h3>
            </div>)
            : 
  					(<div className="navbar-header" id="logobg" data-logobg={skinClassName}>
  						{/*--------------------------------------------------------------------------------*/}
              {/* Mobile View Toggler  [visible only after 768px screen]                         */}
              {/*--------------------------------------------------------------------------------*/}
              <a className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu} href={() => false}>
                <i className={displayNameClassName + " ti-menu ti-close"} />
              </a>
              
              {/*--------------------------------------------------------------------------------*/}
  						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
  						{/*--------------------------------------------------------------------------------*/}
  						<NavbarBrand href="/">
  							<div><img src={imgLogo} alt="logo" height={60} /></div>
  						</NavbarBrand>

              {/* The below profile menu is for mobile view */}
              {this.props.user && <div className="profile-mobile d-md-none">{this.getProfileMenuView(this.props.user.firstname + ' ' + this.props.user.lastname)}</div>}
  					</div>)
          }
					{this.props.user && <Collapse className="navbarbg" navbar data-navbarbg={skinClassName} >
            <Nav className="ml-auto float-right" navbar>
              <NavItem>
                  <div className={displayNameClassName} style={{ marginTop: '25px' }}>{this.props.user.firstname + ' ' + this.props.user.lastname}</div>
              </NavItem>
              {this.getProfileMenuView()}
            </Nav>
          </Collapse>}
				</Navbar>
				{this.state.showUserComp && <UserComp 
	        userDetails={this.props.user}
	        isModalOpen={this.state.showUserComp}
	        onCancel={this.closeUserComp}
	        serverConfig={this.props.serverConfig}
	      />}
	      {this.state.showChangePasswordComp && <ChangeUserPasswordComp 
	        username={this.props.user.username}
	        isModalOpen={this.state.showChangePasswordComp}
	        onCancel={this.closeChangePasswordComp}
	      />}
			</header>
		);
	}
}
export default Header;
