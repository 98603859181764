

import _ from 'lodash';
import React from 'react';
import {
  Card,
  CardBody,
  Input,
  Col,
  Row,
  Alert
} from 'reactstrap';
import { Line, Bar } from 'react-chartjs-2';
import {
  fetchAllStrategies 
} from '../../utils/RestAPIs.js';
import Colors from '../../utils/Colors.js';
import Utils from '../../utils/Utils.js';

const chartTypes = [
  'Line chart',
  'Line chart in %',
  'Bar chart',
  'Bar chart in %'
];

class PnlChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedChartType: 'Line chart',
      chartData: {},
      selectedStrategy: 'CAPITAL-DEPLOYED'
    };

    this.handleStrategyChange = this.handleStrategyChange.bind(this);
    this.handleChartTypeChange = this.handleChartTypeChange.bind(this);

    this.strategies = [];

    this.colorsArr = _.transform(Colors, (result, value, key) => {
      result.push({
        name: key,
        value: value
      });
    }, []);
  }

  componentWillMount() {
    fetchAllStrategies().then(allStrategies => {
      this.setState({
        allStrategies: allStrategies || []
      }, () => {
        this.strategies = [];
        let colorIndex = 1;
        _.each(this.props.records, record => {
          if (_.some(this.strategies, s => s.name === record.strategy) === false) {
            this.strategies.push({
              name: record.strategy,
              color: this.colorsArr[colorIndex++].value,
              displayOrder: Utils.getStrategyDisplayOrder(record.strategy)
            });
          }
        });
        // sort strategies by display order
        this.strategies.sort((s1, s2) => s1.displayOrder - s2.displayOrder);

        if (this.strategies.length > 1) {
          this.updateChart();
        } else if (this.strategies.length === 1) {
          this.updateChart(this.strategies[0].name);
        }
        
      });
    });
  }

  isOverlapStrategy(strategyName) {
    const strategyData = _.find(this.state.allStrategies, s => s.name === strategyName);
    return strategyData ? strategyData.isOverlapCapital || false : false;
  }

  handleStrategyChange(e) {
    const selectedStrategy = e.target.value;
    this.updateChart(selectedStrategy);
  }

  handleChartTypeChange(e) {
    const selectedChartType = e.target.value;
    this.setState({
      selectedChartType
    }, () => {
      this.updateChart(this.state.selectedStrategy);
    });
  }

  updateChart(selectedStrategy) {
    selectedStrategy = selectedStrategy || this.state.selectedStrategy;
    if (!this.props.records || this.props.records.length === 0) {
      this.setState({
        chartData: {}
      });
      return;
    }

    let dateLabels = [{index: 0, label: ''}];    
    let dateIndex = 1;
    _.each(this.props.records, record => {
      
      const date = record.dateStr;
      if (_.some(dateLabels, dl => dl.label === date) === false) {
        dateLabels.push({
          index: dateIndex++,
          label: date
        });
      }
      const strategy = _.find(this.strategies, s => s.name === record.strategy);
      const dateLabel = _.find(dateLabels, dl => dl.label === date);
      if (!strategy.records) {
        strategy.records = [];
      }
      strategy.records[dateLabel.index] = record;
      // adding capital at each index
      if (!strategy.capital) { // this is an array
        strategy.capital = [];
      }
      strategy.capital[dateLabel.index] = record.capital || 0;
    });

    // sort dateLabels by index
    dateLabels.sort((a, b) => a.index - b.index);
    
    // calculate cumulative pl of each strategy
    _.each(this.strategies, s => {
      s.PL = [0];
      s.cumulativePL = [0];
      s.PLPercentage = [0];
      s.cumulativePLPercentage = [0];
      for (let i = 1; i < dateLabels.length; i++) {
        const index = dateLabels[i].index;
        const record = s.records[index];
        if (record) {
          s.PL[index] = record.netPL;
          s.cumulativePL[index] = s.cumulativePL[index - 1] + record.netPL;
        } else {
          s.PL[index] = 0;
          s.cumulativePL[index] = s.cumulativePL[index - 1];
          s.capital[index] = 0;
        }
        s.PLPercentage[index] = (s.PL[index] === 0 || s.capital[index] === 0) ? 0 : Utils.roundOff(100 * s.PL[index] / s.capital[index]);
        s.cumulativePLPercentage[index] = Utils.roundOff(s.cumulativePLPercentage[index - 1] + s.PLPercentage[index]);
      }
    });
    
    const xAxisLabels = _.map(dateLabels, dl => dl.label);
    const chartData = {
      labels: this.state.selectedChartType.startsWith('Line chart') ? xAxisLabels : xAxisLabels.slice(1),
      datasets: []
    }

    if (!selectedStrategy) {
      _.each(this.strategies, s => {
        let data = [];
        if (this.state.selectedChartType === 'Line chart') {
          data = s.cumulativePL;
        } else if (this.state.selectedChartType === 'Line chart in %') {
          data = s.cumulativePLPercentage;
        } else if (this.state.selectedChartType === 'Bar chart') {
          data = s.PL.slice(1);
        } else if (this.state.selectedChartType === 'Bar chart in %') {
          data = s.PLPercentage.slice(1);
        } 
        chartData.datasets.push({
          //stack: 'stockKey1234',
          label: Utils.getStrategyDisplayName(s.name),
          borderWidth: 1,
          backgroundColor: `${s.color}1A`, // adding alpha at the end
          borderColor: s.color,
          pointBorderColor: s.color,
          pointBackgroundColor: s.color,
          data: data
        });
      });
    } else {
      const s = _.find(this.strategies, s => s.name === selectedStrategy);
      if (s) {
        let data = [];
        if (this.state.selectedChartType === 'Line chart') {
          data = s.cumulativePL;
        } else if (this.state.selectedChartType === 'Line chart in %') {
          data = s.cumulativePLPercentage;
        } else if (this.state.selectedChartType === 'Bar chart') {
          data = s.PL.slice(1);
        } else if (this.state.selectedChartType === 'Bar chart in %') {
          data = s.PLPercentage.slice(1);
        } 
        chartData.datasets.push({
          label: Utils.getStrategyDisplayName(s.name),
          borderWidth: 1,
          backgroundColor: `${s.color}1A`, // adding alpha at the end
          borderColor: s.color,
          pointBorderColor: s.color,
          pointBackgroundColor: s.color,
          data: data
        });
      }
    }
    console.log('==== Pnl chart data => ', chartData);

    this.setState({
      selectedStrategy,
      chartData
    });
  }

  render() {
    let profitLoss = null, profitPercentage = 0, minCapital = 0, maxCapital = 0;
    const s = _.find(this.strategies, s => s.name === this.state.selectedStrategy);
    if (s && _.isEmpty(this.state.chartData) === false) {
      profitLoss = s.cumulativePL[s.cumulativePL.length - 1];
      let capitalSum = 0, avgCapital = 0;
      for (let i = 0; i < s.PL.length; i++) {
        if (s.capital[i] > 0) {
          capitalSum += s.capital[i];
          if (minCapital === 0) {
            minCapital = s.capital[i];
          }
          if (maxCapital === 0) {
            maxCapital = s.capital[i];
          }
          minCapital = Math.min(minCapital, s.capital[i]);
          maxCapital = Math.max(maxCapital, s.capital[i]);
        }
      }
      avgCapital = s.capital.length > 0 ? Math.floor(capitalSum / s.capital.length) : 0;
      if (minCapital === maxCapital) {
        profitPercentage = maxCapital > 0 ? Utils.roundOff(100 * profitLoss / maxCapital) : 0;
      } else {
        profitPercentage = avgCapital > 0 ? Utils.roundOff(100 * profitLoss / avgCapital) : 0;
      }
      console.log(this.props.broker + " render() minCapital = " + minCapital + ", maxCapital = " + maxCapital + ", avgCapital = " + avgCapital + ", strategy = " + s.name + ", pnl = " + profitLoss + ", returns = " + profitPercentage);
    }
    let capitalStr = '';
    if (minCapital !== maxCapital) {
      capitalStr = `a capital range of ${Utils.formatNumberToCommaSeparated(minCapital)} - ${Utils.formatNumberToCommaSeparated(maxCapital)}`;
    } else {
      capitalStr = `a capital of ${Utils.formatNumberToCommaSeparated(maxCapital)}`;
    }
  
    const chartOptions = { 
      maintainAspectRatio: false,
      legend: { 
        display: true,
        labels: { 
          fontFamily: "Nunito Sans" 
        } 
      }, 
      scales: { 
        yAxes: [{ 
          stacked: false,
          gridLines: { 
            display: false 
          }, 
          ticks: { 
            fontFamily: "Nunito Sans", 
            beginAtZero: true
          } 
        }],
        xAxes: [{ 
          gridLines: { 
            display: false 
          }, 
          ticks: { 
            fontFamily: "Nunito Sans" 
          } 
        }] 
      } 
    };

    return (<div>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            {profitLoss && <div className="d-flex no-block align-items-center">
              <Alert color={profitLoss > 0 ? 'success' : 'warning'}>
                Net Pnl = {Utils.formatNumberToCommaSeparated(profitLoss)} ({profitPercentage + ' %'}) on {capitalStr}
              </Alert>
            </div>}
            {this.strategies.length > 0 && <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Input type="select" 
                  className="custom-select" 
                  value={this.state.selectedStrategy}
                  onChange={this.handleStrategyChange}>
                  {
                    _.map(this.strategies, s => {
                      return (<option
                        key={s.name}
                        value={s.name}>
                        {Utils.getStrategyDisplayName(s.name)}
                      </option>);
                    })
                  }
                </Input>
              </div>
            </div>}
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Input type="select" 
                  className="custom-select" 
                  value={this.state.selectedChartType}
                  onChange={this.handleChartTypeChange}>
                  {
                    _.map(chartTypes, ch => {
                      return (<option
                        key={ch}
                        value={ch}>
                        {ch}
                      </option>);
                    })
                  }
                </Input>
              </div>
            </div>
          </div>
          <Row>
            <Col>
              {this.state.chartData && <div className="campaign ct-charts">
                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 280 }}>
                  {_.isEmpty(this.state.chartData) ? <div className="d-flex align-items-center">
                    <Alert color={'warning'}>
                      <h3>No data found for selected input</h3>
                    </Alert>
                  </div> : (this.state.selectedChartType.startsWith('Line chart') ? 
                    <Line data={this.state.chartData} options={chartOptions} /> 
                    :
                    <Bar data={this.state.chartData} options={chartOptions} />)
                  }
                </div>
              </div>}
            </Col>
          </Row>
        </CardBody>
        <div className='bottom-notes'><b>NOTE:</b> The returns shown above are indicative numbers. Please refer to your Broker`s P&L Statement for actuals returns.</div>
      </Card>
    </div>);
  }
}

export default PnlChart;
