import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import config from "../../config.js";
import { logoutUser } from "../../utils/RestAPIs.js";

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
		this.expandLogo = this.expandLogo.bind(this);
		this.onItemClick = this.onItemClick.bind(this);
		this.activeRoute.bind(this);
	}
	/*--------------------------------------------------------------------------------*/
	/*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
	/*--------------------------------------------------------------------------------*/
	expandLogo() {
		//document.getElementById("logobg").classList.toggle("expand-logo");
	}
  
	onItemClick(prop = {}) {
		if (prop.name === 'Logout') {
      logoutUser().then(resp => {
        this.props.onLogoutSuccess();
      }).catch(err => {
        this.props.onLogoutSuccess();
      });
		}
	}

	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }

	render() {
		let displayName = 'Dashboard';
    /*if (this.props.user) {
      if (this.props.user.firstname || this.props.user.lastname) {
        displayName = (this.props.user.firstname || '') + ' ' + (this.props.user.lastname || '');
      } else {
        displayName = this.props.user.username;
      }
    }*/
    let skinClassName = 'skin1';
    if (config.isWay2Quant()) {
      skinClassName = 'skin3';
    } else if (config.isXtremeQuant()) {
      skinClassName = 'skin6';
    } else if (config.isProXtremeQuant()) {
      skinClassName = 'skin5';
    }

		return (
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={skinClassName} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
				<div className="scroll-sidebar">
					<PerfectScrollbar className="sidebar-nav">
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}
						<Nav id="sidebarnav">
							{this.props.routes.map((prop, key) => {
								if (prop.redirect) {
									return null;
								}
								else {
									return (
										/*--------------------------------------------------------------------------------*/
										/* Adding Sidebar Item                                                            */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key} onClick={() => this.onItemClick(prop)}>
											<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
												<i className={prop.icon} />
												<span className="hide-menu">{prop.name === 'Dashboard' ? displayName : prop.name}</span>
											</NavLink>
										</li>
									);
								}
							})}
						</Nav>
					</PerfectScrollbar>
				</div>
			</aside>
		);
	}
}
export default Sidebar;
