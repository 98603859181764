
import _ from 'lodash';
import React from 'react';

import {
  Row,
  Card,
  CardBody,
  Table,
  Button,
  Alert,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import Utils from "../../utils/Utils.js";
import HttpRequest from "request";
import config from "../../config.js";
import DatePicker from "react-datepicker";

const productOptions = [
  { label: 'ALL', value: 'ALL' },
  { label: 'INTRADAY', value: 'INTRADAY' },
  { label: 'POSITIONAL', value: 'POSITIONAL' }
];

const COLS = [{
  title: 'Strategy',
  value: (trade) => {
    return (<td>{Utils.getStrategyDisplayName(trade.strategy)}</td>);
  }
}, {
  title: 'Symbol',
  value: (trade) => {
    return (<td>{trade.tradingSymbol}</td>);
  }
}, {
  title: 'Type',
  value: (trade) => {
    return (<td>{trade.direction}</td>);
  }
}, {
  title: 'Start Time',
  value: (trade) => {
    return (<td>{Utils.formatDate(new Date(trade.startTimestamp))}</td>);
  } 
}, {
  title: 'End Time',
  value: (trade) => {
    return (<td>{Utils.formatDate(new Date(trade.endTimestamp))}</td>);
  } 
}, {
  title: 'Quantity',
  value: (trade) => {
    return (<td>{trade.filledQuantity}</td>);
  }
}, {
  title: 'Entry',
  value: (trade) => {
    return (<td>{Utils.formatNumberToCommaSeparated(trade.entry, true)}</td>);
  }
}, {
  title: 'Exit',
  value: (trade) => {
    return (<td>{Utils.formatNumberToCommaSeparated(trade.exit, true)}</td>);
  }
}, {
  title: 'Profit/Loss',
  value: (trade) => {
    return (<td className={trade.profitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(trade.profitLoss, true)}</td>);
  }
}, {
  title: 'Charges',
  value: (trade) => {
    return (<td className="number-right">{Utils.formatNumberToCommaSeparated(trade.charges, true)}</td>);
  }
}, {
  title: 'Net P/L',
  value: (trade) => {
    return (<td className={trade.netProfitLoss > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(trade.netProfitLoss, true)}</td>);
  }
}, {
  title: 'Exit Reason',
  value: (trade) => {
    return (<td>{trade.exitReason}</td>)
  }
}];

const showHideColumns = ['Start Time', 'End Time', 'Exit Reason'];

class TradesHistory extends React.Component {

  constructor(props) {
    super(props);

    this.rangeStartDate = new Date('2019-10-01');
    this.rangeEndDate = new Date();

    const now = new Date();
    const firstDateOfTheMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    this.state = {
      product: 'ALL',
      broker: 'ALL',
      startDate: firstDateOfTheMonth,
      endDate: new Date(),
      trades: [],
      error: null,
      filterText: null,
      filterLongSelected: true,
      filterShortSelected: true,
      hideColumns: {
        //'Strategy': true,
        //'End Time': true,
        'Exit Reason': true
      }
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.fetchTradesHistory = this.fetchTradesHistory.bind(this);
    this.onFilterTextChange = this.onFilterTextChange.bind(this);
    this.onFilterLongToggle = this.onFilterLongToggle.bind(this);
    this.onFilterShortToggle = this.onFilterShortToggle.bind(this);
    this.onHideColumnsToggle = this.onHideColumnsToggle.bind(this);
  }

  fetchTradesHistory() {
    const startDateStr = Utils.formatDateToString(this.state.startDate);
    const endDateStr = Utils.formatDateToString(this.state.endDate);

    this.setState({
      fetchTradesInProgress: true,
      error: null
    });

    HttpRequest(config.serverHost + "/apis/trades/history?from=" + startDateStr + "&to=" + endDateStr + "&product=" + this.state.product + "&broker=" + this.state.broker, { json: true }, (err, resp, result) => {
      console.log('fetchTradesHistory: resp.statusCode = ' + resp.statusCode);
      if (resp.statusCode === 200) {
        const trades = result || [];
        trades.forEach(function (td) {
          td.entry = Utils.roundOff(td.entry);
          td.exit = Utils.roundOff(td.exit);
          td.profitLoss = Utils.roundOff(td.profitLoss);
          td.charges = Utils.roundOff(td.charges);
          td.netProfitLoss = Utils.roundOff(td.netProfitLoss);
        });
        this.setState({
          trades: trades,
          fetchTradesInProgress: false,
          error: null
        });
      } else {
        this.setState({
          trades: [],
          fetchTradesInProgress: false,
          error: 'Failed to fetch history of trades. ' + (result.error || '')
        });
        console.error("fetchTradesHistory: Error => ", result, err);
      }
    });
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date
    }, () => {
      this.validateInput();
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date
    }, () => {
      this.validateInput();
    });
  }

  validateInput() {
    if (this.state.startDate.getTime() > this.state.endDate.getTime()) {
      this.setState({
        error: 'Start Date can not be greater than End Date'
      });
    } else {
      this.setState({
        error: null
      });
    }
  }

  getTradeRow(trade = {}, index) {
    const colsToDisplay = _.filter(COLS, col => !this.state.hideColumns[col.title]);
    return (
      <tr key={"trade-" + index}>
        <td>{index + 1}</td>
        {_.map(colsToDisplay, col => {
          return col.value(trade);
        })}
      </tr>
    );
  }

  filterTrades() {
    let filteredTrades = this.state.trades;
    if (!this.state.filterLongSelected) {
      filteredTrades = _.filter(filteredTrades, td => td.direction !== 'LONG');
    }
    if (!this.state.filterShortSelected) {
      filteredTrades = _.filter(filteredTrades, td => td.direction !== 'SHORT');
    }
    if (_.isEmpty(this.state.filterText) === false) {
      filteredTrades = _.filter(filteredTrades, td => {
        if (_.startsWith(_.toLower(td.tradingSymbol), _.toLower(this.state.filterText))
          || _.startsWith(_.toLower(Utils.getStrategyDisplayName(td.strategy)), _.toLower(this.state.filterText))) {
          return true;
        }
        return false;
      });
    }
    return filteredTrades;
  }

  onFilterTextChange(e) {
    this.setState({
      filterText: e.target.value
    });
  }

  onFilterLongToggle() {
    this.setState({
      filterLongSelected: !this.state.filterLongSelected
    });
  }

  onFilterShortToggle() {
    this.setState({
      filterShortSelected: !this.state.filterShortSelected
    });
  }

  onHideColumnsToggle(title) {
    const hideColumns = {...this.state.hideColumns};
    hideColumns[title] = hideColumns[title] ? false : true;
    this.setState({
      hideColumns
    });
  }

  getBrokerOptions() {
    const brokerOptions = [{
      label: 'ALL', value: 'ALL'
    }];
    _.each(this.props.user.brokers, b => {
      brokerOptions.push({
        label: b.broker, value: b.broker
      });
    });
    return brokerOptions;
  }

  getSelectField(opts = {}) {
    return (<FormGroup className="form-row">
      <Label className="col-form-label" for={opts.key}>{opts.label}</Label>
      <Input
        className="col-form-control"
        type="select" 
        name="select" 
        id={opts.key} 
        onChange={opts.onChange}
        value={opts.value}
        placeholder={opts.placeholder}>
        {
          _.map(opts.options, o => {
            return (<option value={o.value}>{o.label}</option>);
          })
        }
      </Input>
    </FormGroup>);
  }

  renderTrades() {
    const trades = this.filterTrades(this.state.trades);
    let summary = Utils.calculateTradesSummary(trades);
    const colsToDisplay = _.filter(COLS, col => !this.state.hideColumns[col.title]);

    return (<div>
      {trades.length > 0 && <div className="d-flex align-items-center">
        <Alert color={summary.totalNetPL > 0 ? 'success' : 'warning'}>
          {'PL = ' + Utils.formatNumberToCommaSeparated(summary.totalPL, true) + ' | Charges = ' + Utils.formatNumberToCommaSeparated(summary.totalCharges, true) + ' | Net = ' + Utils.formatNumberToCommaSeparated(summary.totalNetPL, true)}
        </Alert>
      </div>}
      {trades.length > 0 && <div><b>NOTE:</b> The returns shown above are indicative numbers. Please refer to your Broker`s P&L Statement for actuals returns.</div>}
      
        
      <div className="filter-inputs">
        {
          _.map(showHideColumns, title => {
            return (<div className="filter-input" key={title}>
              <input
                type="checkbox"
                checked={this.state.hideColumns[title] ? false : true}
                onChange={() => this.onHideColumnsToggle(title)}>
              </input>
              <label>&nbsp;{title}</label>
            </div>);
          })
        }
        <div className="filter-input">
          <label>LONG:</label>
          <input
            type="checkbox"
            checked={this.state.filterLongSelected}
            onChange={this.onFilterLongToggle}></input>
        </div>
        <div className="filter-input">
          <label>SHORT:</label>
          <input
            type="checkbox"
            checked={this.state.filterShortSelected}
            onChange={this.onFilterShortToggle}></input>
        </div>
        <div className="filter-input">
          <label>Filter By:</label>
          <input 
            value={this.state.filterText}
            placeholder="symbol or strategy" 
            onChange={this.onFilterTextChange}></input>
        </div>
      </div>
      
      <Table className="no-wrap v-middle" size="sm" responsive>
        <thead>
          <tr className="border-0">
            <th className="border-0">SNo</th>
            {_.map(colsToDisplay, col => {
              return (<th className="border-0">{col.title}</th>);
            })}
          </tr>
        </thead>
        <tbody>
          {
            trades.map((td, index) => {
              return this.getTradeRow(td, index);
            })
          }
          <tr>
            <td colSpan={_.findIndex(colsToDisplay, col => col.title === 'Profit/Loss') + 1}>TOTAL PROFIT/LOSS</td>
            <td className={summary.totalPL > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.totalPL, true)}</td>
            <td className="number-right">{Utils.formatNumberToCommaSeparated(summary.totalCharges, true)}</td>
            <td className={summary.totalNetPL > 0 ? "number-right number-pos" : "number-right number-neg"}>{Utils.formatNumberToCommaSeparated(summary.totalNetPL, true)}</td>
          </tr>
        </tbody>
      </Table>
    </div>);
  }

  render() {
    return (<div>
      <h4>Trades History</h4>
      
      <div>
        <Row>
          <Card>
            <CardBody>
              <div className="trades-history-input">
                <div className="date-picker-wrapper">
                  <label>START DATE:</label>
                  <DatePicker 
                    dateFormat="dd-MM-yyyy"
                    minDate={this.rangeStartDate}
                    maxDate={this.rangeEndDate}
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange} />
                </div>

                <div className="date-picker-wrapper">
                  <label>END DATE:</label>
                  <DatePicker 
                    dateFormat="dd-MM-yyyy"
                    minDate={this.rangeStartDate}
                    maxDate={this.rangeEndDate}
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange} />
                </div>

                {this.getSelectField({
                  key: 'broker',
                  label: 'BROKER',
                  value: _.get(this.state.broker),
                  options: this.getBrokerOptions(),
                  placeholder: 'Select broker',
                  onChange: (event) => {
                    this.setState({
                      broker: event.target.value
                    });
                  } 
                })}

                {this.getSelectField({
                  key: 'product',
                  label: 'PRODUCT',
                  value: _.get(this.state.product),
                  options: productOptions,
                  placeholder: 'Select product',
                  onChange: (event) => {
                    this.setState({
                      product: event.target.value
                    });
                  } 
                })}

                <Button className="date-picker-submit btn-info"
                  onClick={this.fetchTradesHistory}
                  disabled={_.isEmpty(this.state.error) === false || this.state.fetchTradesInProgress} >
                  {this.state.fetchTradesInProgress ? "Generating Report..." : "Generate Report" }
                </Button>
              </div>

              {this.state.error && <div className="error-text">{this.state.error}</div>}

              {!this.state.error && !this.state.fetchTradesInProgress && this.renderTrades()}
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>);
  }
}

export default TradesHistory;