/*
  Author: Sreenivas Doosa
*/
import React from 'react';

import AlertsComp from "../common/AlertsComp";

class Alerts extends React.Component {

  render() {
    return (<AlertsComp isAdmin="false" />);
  }
}

export default Alerts;
